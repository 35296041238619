import { useState } from "react";
import { EnforcementSiteDetails } from "./siteDetails";
import StringHelpers from "../../utilities/stringHelpers";

export const AllSitesSummaries = (props) => {
	const [selectedSiteId, setSelectedSiteId] = useState(null);
	const [selectedClientId, setSelectedClientId] = useState(null);

	return (
		<div className="card-body p-0 mt-3">
			<div className="row">
				<div className="col-2 p-0 navbar-vertical scrollbar perfect-scrollbar navbar-light enforcement-sites-navbar">
					<div class="navbar-vertical-content">
						{props.siteSummaries?.map((item) => (
							<a
								onClick={() => {
									setSelectedSiteId(item.siteId);
									setSelectedClientId(item.clientId);
								}}
								key={item.siteId}
								className="nav-link cursor-pointer">
								<div className="d-flex align-items-center text-800 justify-content-between">
									<p
										className={`m-0 font-weight-bolder ${
											selectedSiteId === item.siteId ? "text-primary" : ""
										}`}>
										{item.siteName}
									</p>
									<span
										className={`badge ml-2 ${
											item.count > 0 ? "badge-success" : "badge-secondary"
										}`}>
										{StringHelpers.formatDecimal(item.count, 0)}
									</span>
								</div>
							</a>
						))}
					</div>
				</div>
				<div className="col-10 rounded">
					{(selectedSiteId && selectedClientId) ? (
						<EnforcementSiteDetails
							status={props.status}
							type={props.type}
							filterEnd={props.filterEnd}
							filterStart={props.filterStart}
							searchQuery={props.searchQuery}
							selectedSiteId={selectedSiteId}
							selectedClientId={selectedClientId}
							organisationId={props.organisationId}
							token={props.token}
						/>
					) : (
						<div className="d-flex bg-white card" style={{ height: "500px" }} />
					)}
				</div>
			</div>
		</div>
	);
};
