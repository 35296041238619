import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Api from '../api/api';
import Enums from '../../utilities/enum';
import { useToasts } from 'react-toast-notifications'
import moment from 'moment';
import Pagination from '../shared/pagination';
import Loader from '../shared/loader';
import EditUser from './edit-user';

function UserManagement(props) {
    const [invites, setInvites] = useState(null);
    const [users, setUsers] = useState(null);

    const [editUser, setEditUser] = useState(null);
    const [deleteUserId, setDeleteUserId] = useState(null);

    const [userPageNumber, setUserPageNumber] = useState(0);
    const [invitePageNumber, setInvitePageNumber] = useState(0);

    var addNewUrl = "/organisation/" + props.organisationId + "/user-management/invite-user";
    const { addToast } = useToasts();

    const getInvites = function () {
        Api.getInvitations(
            (data) => setInvites(data.reverse()),
            (error) => console.log(error),
            invitePageNumber,
            props.organisationId,
            props.token
        )
    };

    const changeUserPageNumber = function (num) {
        setUserPageNumber(num);
    };

    const changeInvitePageNumber = function (num) {
        setInvitePageNumber(num);
    };

    const getUsers = function () {
        Api.getUsers(
            (data) => setUsers(data),
            (error) => console.log(error),
            userPageNumber,
            props.organisationId,
            props.token
        )
    };

    const removeUser = function (id) {
        Api.removeUser(
            () => getUsers(),
            (error) => console.log(error),
            id, props.organisationId, props.token
        )
        setEditUser(null);
    };

    const onSaveUser= function() {
        getInvites();
        getUsers();
        setEditUser(null);
    }

    const resend = function (inviteId) {
        Api.resendInvitation(
            () => inviteAction('Invite Resent Successfully', 'success'),
            (error) => console.log(error),
            inviteId,
            props.token
        )
    };

    const revoke = function (invite) {
        if (invite.isAccepted !== true && invite.isRevoked !== true) {
            Api.revokeInvitation(
                () => inviteAction('Invite Revoked Successfully', 'error'),
                (error) => console.log(error),
                invite.id,
                props.token
            )
        }
        getInvites();
    };

    const inviteAction = function (content, toastType) {
        addToast(content, {
            appearance: toastType,
            autoDismiss: true,
        });

        getInvites();
    };

    useEffect(() => {
        getInvites();
        getUsers();
    }, [])

    return (
        <>
            {
                deleteUserId &&
                    <div className="modal fade" id="delete-user-modal" tabIndex="-1" role="dialog" aria-labelledby="On Hold Modal" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="deleteModalLabel">Are you sure you want to delete this user?</h5>
                                    <button onClick={() => setDeleteUserId(null)} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal" onClick={() => setDeleteUserId(null)}>Cancel</button>
                                    <button type="button" className="btn btn-danger btn-sm ml-2" data-dismiss="modal" onClick={() => removeUser(deleteUserId)}>
                                        Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            {invites === null || users === null && <Loader />}
            {invites !== null && users !== null && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-2 col-sm-auto d-flex align-items-center pr-0">
                                            <h5 className="mb-0">User List</h5>
                                        </div>
                                        <div className="col-10 ml-auto text-right pl-0 filter-buttons-wrapper">
                                            <button className="btn btn-primary btn-sm ml-1" onClick={() => { setUsers(null); getUsers(); }}><i className="fas fa-redo"></i> Refresh</button>
                                            <Link className="btn btn-success btn-sm ml-1" to={addNewUrl}><i className="fas fa-plus"></i> Add</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th>Name</th>
                                                <th>Email Address</th>
                                                <th>Role</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users !== null && users.map((user) => <tr key={user.id}>
                                                <td><button className="button-no-style" onClick={() => setEditUser(user)}>{user.firstName} {user.lastName}</button></td>
                                                <td>{user.emailAddress}</td>
                                                <td>{Enums.UserRoles[user.organisations[0].roleId]}</td>
                                                {
                                                    (props.user.isGlobalAdmin || props.user.organisations.find(org => org.organisation.id === props.organisationId).roleName === "OrganisationAdmin") &&
                                                    (user.organisations.find(item => item.organisation.id === props.organisationId) !== undefined) &&
                                                    <td className="text-right">
                                                        <i 
                                                            role="button" 
                                                            data-toggle="modal" 
                                                            data-target="#delete-user-modal" 
                                                            onClick={() => setDeleteUserId(user.id)} 
                                                            class="fas fa-trash-alt"
                                                        ></i>
                                                    </td>
                                                    
                                                }
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="d-flex justify-content-center row ">
                                        <div className="col-auto ">
                                            <Pagination totalResults={users.length} currentPage={userPageNumber} changePageNumber={changeUserPageNumber} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-2 col-sm-auto d-flex align-items-center pr-0">
                                            <h5 className="mb-0">Invite List</h5>
                                        </div>
                                        <div className="col-10 ml-auto text-right pl-0 filter-buttons-wrapper">
                                            <button className="btn btn-primary btn-sm ml-1" onClick={() => { setInvites(null); getInvites(); }}><i className="fas fa-redo"></i> Refresh</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th>Email Address</th>
                                                <th>Role</th>
                                                <th>Status</th>
                                                <th className="text-center">Date Created</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invites !== null && invites.map((invite) => {
                                                var status = "Pending";
                                                if (invite.isAccepted) {
                                                    status = "Accepted";
                                                }
                                                else if (invite.isRevoked) {
                                                    status = "Revoked";
                                                }
                                                var modalId = "#modal" + invite.id;
                                                return (
                                                    <>
                                                        <tr>
                                                            <td><button className="button-no-style" data-toggle="modal" data-target={modalId}>{invite.emailAddress}</button></td>
                                                            <td>{Enums.UserRoles[invite.role]}</td>
                                                            <td>{status}</td>
                                                            <td className="text-center">{moment(invite.timestamp).format("DD/MM/yyyy HH:mm")}</td>
                                                        </tr>
                                                        <div className="modal fade" id={modalId.substring(1)} tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title" id="deleteModalLabel">Invite to {invite.emailAddress}</h5>
                                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <div className="row">
                                                                            <div className="col-12 ">
                                                                                <p className="font-weight-semi-bold  mt-1 mb-1">Role</p>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="form-group mb-0">
                                                                                    {Enums.UserRoles[invite.role]}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12 ">
                                                                                <p className="font-weight-semi-bold  mt-1 mb-1">Invite Status</p>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="form-group mb-0">
                                                                                    {status}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12 ">
                                                                                <p className="font-weight-semi-bold  mt-1 mb-1">Date Created</p>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="form-group mb-0">
                                                                                    {moment(invite.timestamp).format("DD/MM/yyyy HH:mm")}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {status !== 'Pending' &&
                                                                            <div className="row">
                                                                                <div className="col-12 ">
                                                                                    <p className="font-weight-semi-bold  mt-1 mb-1">Status</p>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <div className="form-group mb-0">
                                                                                        {status}
                                                                                    </div>
                                                                                </div>
                                                                            </div>}
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                                                        {status === 'Pending' && <>
                                                                            <button type="button" className="btn btn-danger btn-sm ml-2" data-dismiss="modal" onClick={() => revoke(invite)}>Revoke Invitation</button>
                                                                            <button type="button" className="btn btn-primary btn-sm ml-2" data-dismiss="modal" onClick={() => resend(invite.id)}>
                                                                                Resend Email</button>
                                                                        </>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="d-flex justify-content-center row ">
                                        <div className="col-auto ">
                                            <Pagination totalResults={invites.length} currentPage={invitePageNumber} changePageNumber={changeInvitePageNumber} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}

            {editUser && editUser !== null &&
                <EditUser onCancel={() => setEditUser(null)} onSave={onSaveUser} user={editUser} organisationId={props.organisationId} token={props.token} />
            }
        </>
    )
}

export default UserManagement;