import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Enums from '../../utilities/enum';

import Loader from '../shared/loader';
import moment from 'moment';

import Pagination from '../shared/pagination';
function AllocatedBreaches(props) {
    const [breaches, setBreaches] = useState(null)
    const [allContraventions, setAllContraventions] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [lastPage, setLastPage] = useState(false);
    const [pageCount, setPageCount] = useState(0);

    const [loading, setLoading] = useState(false);

    const getAllContraventions = function () {
        Api.getContraventions(
            (data) => setAllContraventions(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        )
    };

    const getBreaches = function () {
        Api.getBreaches(
            (data) => {
                setBreaches(data);
                setLoading(false);
            },
            (error) => console.log(error),
            null, null, props.user.id, true, null, null, null, null, pageNumber,
            props.organisationId,
            props.token,
        )
    };

    const changePageNumber = function (num) {
        setPageNumber(num);
    };

    const isLastPage = function () {
        if (breaches !== null && breaches !== undefined) {
            setLastPage(false);
            setPageCount(Math.ceil(breaches.totalCount / 100));
            if (pageNumber + 1 >= Math.ceil(breaches.totalCount / 100)) {
                setLastPage(true)
            }
        }
    };

    useEffect(() => {
        getBreaches();
        isLastPage();
        setLoading(true);
    }, [pageNumber]);

    useEffect(() => {
        isLastPage();
    }, [breaches]);

    useEffect(() => {
        getBreaches();
        getAllContraventions();
    }, [])

    return (
        <>
            {breaches === null && <Loader />}
            {breaches !== null &&
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-4 d-flex align-items-center pr-0">
                                            <h5 className="mb-0">Your Allocated Ticket Dashboard</h5>
                                        </div>
                                        <div className="col-8 ml-auto text-right pl-0 filter-buttons-wrapper">
                                            <button className="btn btn-primary btn-sm ml-1" onClick={() => {setBreaches(null); getBreaches();}}><i className="fas fa-redo"></i> Refresh</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th>No.</th>
                                                <th className="text-center">VRM</th>
                                                <th className="text-center">Contravention Date</th>
                                                <th className="text-center">In Time</th>
                                                <th className="text-center">Out Time</th>
                                                <th>Duration</th>
                                                <th>Type</th>
                                                <th>Allocated To</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading === true && <><tr>
                                                <td colSpan={9}>
                                                    <Loader />
                                                </td></tr></>}
                                            {loading === false && <>
                                                {breaches !== null && allContraventions !== null && breaches.items.map((breach, i) => {
                                                    if (breach.parkingSession !== null) {
                                                        var entry = moment(breach.parkingSession.entry.timestamp);
                                                        var exit = moment(breach.parkingSession.exit.timestamp);

                                                        var hours = exit.diff(entry, 'hours');
                                                        entry.add(hours, 'hours');
                                                        var mins = exit.diff(entry, 'minutes');
                                                        entry.add(mins, 'minutes')
                                                        var secs = exit.diff(entry, 'seconds');
                                                    }

                                                    var url = '/organisation/' + props.organisationId + '/breach/' + breach.id;

                                                    var contravention;
                                                    allContraventions.forEach(item => {
                                                        if (item.id === breach.contraventionId) {
                                                            contravention = item;
                                                        }
                                                    });
                                                    i++;
                                                    var index = i + (100 * pageNumber);
                                                    return (
                                                        <tr>
                                                            <td>{index}</td>
                                                            <td><Link to={url} className="number-plate-link"><h6 className="mb-0 small-number-plate">{breach.vrm}</h6></Link></td>
                                                            <td className="text-center">{moment(breach.timestamp).format("DD/MM/yyyy")}</td>
                                                            {breach.parkingSession !== null && <>
                                                                <td className="text-center">{moment(breach.parkingSession.entry.timestamp).format("HH:mm")}</td>
                                                                <td className="text-center">{moment(breach.parkingSession.exit.timestamp).format("HH:mm")} </td>
                                                                <td>{hours !== 0 && <>{hours} hours,<br /></>}{mins} minutes,<br />{secs} seconds</td>
                                                            </>}
                                                            {breach.parkingSession === null && <>
                                                                <td></td>
                                                                <td className="text-center">{moment(breach.timestamp).format("HH:mm")} </td>
                                                                <td></td>
                                                            </>}
                                                            <td>{Enums.ContraventionTypes[contravention.contraventionType]}</td>
                                                            <td>{breach.assignedToUserName}</td>
                                                            <td>{Enums.BreachStatus[breach.breachStatus]}</td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </>}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="d-flex justify-content-center row align-items-center">
                                        <div className="col-auto align-items-center">
                                            <Pagination totalResults={breaches.totalCount} currentPage={pageNumber} changePageNumber={changePageNumber} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default AllocatedBreaches;