import stringHelpers from "../../utilities/stringHelpers";

export const ReportTable = (props) => {
    return (
        <div className="card-body bg-white border-top p-0">
            <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                <thead className="bg-200 text-900">
                    <tr>
                        { props.tableData && props.tableData.length > 0
                            && Object
                                .entries(props.enum)
                                .map(([key, value]) =>
                                    <th className={ Number(props.tableData[0][key]) || key === "subTotal" || key === "value" || key === "amount" ? "text-right" : "" }>
                                        { value }
                                    </th>)
                        }
                    </tr>
                </thead>

                <tbody>
                    {
                        props.tableData && props.tableData.length > 0 && props.tableData.map((item, index) =>
                            <tr key={ index }>
                                {
                                    Object.keys(props.enum)
                                        .map((key, index) =>
                                            <td className={ (Number(item[key]) || key === "subTotal" || key === "value" || key === "amount") ? "text-right" : "" }
                                                key={ index }>
                                                { 
                                                    key === "value" ? stringHelpers.formatDecimal(item[key], 0) : item[key] 
                                                }
                                            </td>)
                                }
                            </tr>)
                    }
                    {
                        props.totalCount && (
                            <tr className="font-weight-bold w-100">
                                <td>Total</td>
                                <td className="text-right" colSpan={ 3 }>
                                    { props.totalCount }
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}