import { useState } from "react";
import { CameraTemplate, formattedCameraTemplate } from '../documents/cameraTemplate';

export const Webhooks = (props) => {

    const webHookBaseUrl = `https://api.advancedparking.tech/${props.organisationId}/webhooks/`;
    const webhooksContent = [
        {
            title: "Mav",
            imageUrl: "/assets/img/logos/cameras/mav.svg",
            webhookUrl: `${webHookBaseUrl}mav`,
            cameraTemplate: null,
        },
        {
            title: "Mobotix",
            imageUrl: "/assets/img/logos/cameras/mobotix.svg",
            webhookUrl: `${webHookBaseUrl}mobotix`,
            cameraTemplate: formattedCameraTemplate,
        },
        {
            title: "hikvision",
            imageUrl: "/assets/img/logos/cameras/hikvision.svg",
            webhookUrl: `${webHookBaseUrl}hikvision`,
            cameraTemplate: null,
        },
    ]

    const [cameraTemplateModalContent, setCameraTemplateModalContent] = useState(null);
    const [copyResultMessage, setCopyResultMessage] = useState(null);

    const handleCameraTemplateCopy = (data) => {
        navigator.clipboard.writeText(data)
            .then(() => {
                setCopyResultMessage("Successfully copied!");
            })
            .catch(() => {
                setCopyResultMessage("Unable to copy, please try again!");
            })

            setTimeout(() => {
                setCopyResultMessage(null);
            }, 2000)
    }

    return (
        <div className="card mb-3">
            {
                cameraTemplateModalContent && (
                    <div className="modal fade" id="templateModal" tabIndex="-1" role="dialog" aria-labelledby="ModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="deleteModalLabel">
                                        {cameraTemplateModalContent.title}
                                    </h5>
                                    <button
                                        onClick={() => setCopyResultMessage(null)}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                {cameraTemplateModalContent.children}
                                <div className="modal-footer">
                                    {
                                        copyResultMessage && <p className="fs-1">{copyResultMessage}</p>
                                    }
                                    <button
                                        type="button"
                                        className="btn ml-2 d-flex align-items-center gap-3"
                                        onClick={() => handleCameraTemplateCopy(formattedCameraTemplate)}
                                    >
                                        <i className="fas fa-copy mr-2"></i>
                                        Copy
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className="card-header">
                <div className="row align-items-center">
                    <div className="col">
                        <h5 className="mb-0">Webhook URLs</h5>
                    </div>
                </div>
            </div>
            <div className="card-body bg-light border-top">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Camera Type</th>
                                <th scope="col">Webhook URL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                webhooksContent.map(item => (
                                    <tr key={item.title}>
                                        <th scope="row">
                                            <img src={item.imageUrl} alt={item.title} style={{ maxHeight: "25px" }} />
                                        </th>
                                        <td>
                                            {item.webhookUrl}
                                            <button
                                                className='btn'
                                                onClick={() => navigator.clipboard.writeText(item.webhookUrl)}
                                            >
                                                <div>
                                                    <i className="fas fa-copy" />
                                                </div>
                                            </button>
                                            {
                                                item.cameraTemplate && (
                                                    <button
                                                        data-toggle="modal"
                                                        data-target="#templateModal"
                                                        className='btn'
                                                        onClick={() => setCameraTemplateModalContent({
                                                            title: `${item.title} Message Template`,
                                                            children: <CameraTemplate />
                                                        })}
                                                    >
                                                        <i className="far fa-file-code" />
                                                    </button>
                                                )
                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}

