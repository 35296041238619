
import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Api from '../api/api';
import moment from 'moment';

import Loader from '../shared/loader';
import stringHelpers from '../../utilities/stringHelpers';
import arrayUtilities from '../../utilities/arrayUtilities';

function AllowedVrm(props) {

    const isClient = props.user.organisations.find(x => x.organisation.id === props.organisationId)?.roleId === 5;
    const clientId = props.user.organisations.find(x => x.organisation.id === props.organisationId)?.clientId;

    const [allowedVrmId, setAllowedVrmId] = useState(props.match.params.allowedVrmId);
    const [allowedVrm, setAllowedVrm] = useState(null);

    const [isNew, setIsNew] = useState(false);
    const [isInEdit, setIsInEdit] = useState(false);
    const [canSave, setCanSave] = useState(false);

    const [allowListUrl, setAllowListUrl] = useState("");

    const [vrm, setVrm] = useState("");
    const [vehicleMake, setVehicleMake] = useState("");
    const [vehicleModel, setVehicleModel] = useState("");
    const [siteName, setSiteName] = useState("");
    const [colour, setColour] = useState("");
    const [driverName, setDriverName] = useState("");
    const [driverMobileNumber, setDriverMobileNumber] = useState("");
    const [driverEmailAddress, setDriverEmailAddress] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [indefinite, setIndefinite] = useState(false);
    const [reason, setReason] = useState("");


    const [site, setSite] = useState("");
    const [sites, setSites] = useState("");

    const [anprCameras, setAnprCameras] = useState(null);
    const [anprCameraId, setAnprCameraId] = useState(null);
    const [roi, setRoi] = useState(null);

    const [errorMessage, setErrorMessage] = useState("");

    const history = useHistory();

    const getAllowedVrm = function () {
        Api.getAllowedVrm(
            (data) => {
                setAllowedVrm(data);
            },
            (error) => console.log(error),
            props.organisationId,
            allowedVrmId,
            props.token
        );
    };

    const getSites = function (callback) {

        if (!isClient) {
            Api.getSites(
                (data) => {
                    setSites(data);
                    if (callback) {
                        callback();
                    }
                },
                (error) => console.log(error),
                props.organisationId,
                props.token
            )
        }
        else {
            Api.getClientSites(
                (data) => {
                    setSites(data);
                    if (callback) {
                        callback();
                    }
                },
                (error) => console.log(error),
                clientId,
                props.organisationId,
                props.token
            )
        }

    };

    const submit = function () {
        if (canSave) {
            var submitSiteId;

            if (site !== null) {
                submitSiteId = site.id;
            } else {
                submitSiteId = null;
            }
            Api.addAllowedVrm(
                (data) => {
                    setAllowedVrm(data);
                    setErrorMessage("");
                    setIsNew(false);
                    history.push("allowed-vrm/" + data.id);
                },
                (error) => {
                    setErrorMessage(error.errorMessage)
                },
                vrm,
                vehicleMake,
                vehicleModel,
                siteName,
                driverName,
                driverMobileNumber,
                startDate,
                endDate,
                reason,
                submitSiteId,
                anprCameraId,
                roi,
                props.organisationId,
                props.token
            );
        }
    };

    const edit = function () {
        if (canSave) {
            var editSiteId;
            if (site !== null) {
                editSiteId = site.id;
            }
            else {
                editSiteId = null;
            }
            Api.editAllowedVrm(
                () => {
                    setIsInEdit(false);
                    getAllowedVrm();
                    setErrorMessage("");
                },
                (error) => setErrorMessage(error),
                vrm,
                vehicleMake,
                vehicleModel,
                siteName,
                driverName,
                driverMobileNumber,
                startDate,
                endDate,
                reason,
                editSiteId,
                anprCameraId,
                roi,
                props.organisationId,
                allowedVrmId,
                props.token
            );
        }
    };

    const getAnprCameraName = function (id) {
        if (!arrayUtilities.isNullOrEmpty(anprCameras)) {
            let anprCamera = anprCameras.find(x => x.id === id);
            if (anprCamera && anprCamera !== null) {
                return anprCamera.name;
            }
        }

        return null;
    }

    useEffect(() => {
        if (allowedVrm !== null) {
            setVrm(allowedVrm.vrm);
            setVehicleMake(allowedVrm.vehicleMake);
            setVehicleModel(allowedVrm.vehicleModel);
            setSite(sites.find(x => x.id === allowedVrm.siteId));
            setAnprCameraId(allowedVrm.anprCameraId);
            setRoi(allowedVrm.roi);
            setDriverName(allowedVrm.driverName);
            setDriverMobileNumber(allowedVrm.driverMobileNumber);
            setStartDate(allowedVrm.startDate);
            setEndDate(allowedVrm.endDate);
            setReason(allowedVrm.notes);
            if (allowedVrm.startDate === null && allowedVrm.endDate === null) {
                setIndefinite(true);
            }
        }
        setErrorMessage("");
    }, [allowedVrm, isInEdit]);

    useEffect(() => {

        getSites(() => {
            if (allowedVrmId !== undefined) {
                getAllowedVrm();
            } else {
                setIsNew(true);
            }
        });
    }, []);

    useEffect(() => {
        if (site && site !== null) {
            setSiteName(site.name);

            Api.getSiteCameras((data) => {
                setAnprCameras(data);
            }, () => {
                setAnprCameras(null);
            }, props.organisationId, site.id, props.token);
        } else {
            setSiteName("");
            setAnprCameras(null);
        }
    }, [site]);

    useEffect(() => {
        setCanSave((!isClient || site && site !== null) && !stringHelpers.isNullOrEmpty(vrm) && (indefinite || (startDate && startDate !== "" && endDate && endDate !== "")));

        if (isClient) {
            setAllowListUrl("/")
        } else {
            setAllowListUrl("/organisation/" + props.organisationId + "/allow-list")
        }
    }, [site, vrm, indefinite, startDate, endDate]);

    return (
        <>{!allowedVrm && !isNew && <Loader />}{!isNew && allowedVrm && allowedVrm !== null && <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h5 className="mb-0 d-flex align-items-center">Allowed VRM - <p className="small-number-plate ml-2 mb-0">{allowedVrm.vrm}</p></h5>
                                    </div>
                                    <Link className="btn btn-falcon-default btn-sm mr-3 py-1" type="button" to={allowListUrl}><i className="fas fa-reply"></i> Back</Link>

                                    <button className="btn btn-falcon-default btn-sm dropdown-toggle py-2 mr-3 dropdown-caret-none" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fas fa-ellipsis-h"></i></button>
                                    <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-start">
                                        <button type="button" className="dropdown-item text-danger" data-toggle="modal" data-target="#deleteModal">
                                            <i className="fas fa-times"></i> Delete Allowed VRM</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h5 className="mb-0">Details</h5>
                                    </div>
                                    <div className="col-auto">
                                        <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setIsInEdit(!isInEdit)}>
                                            <i className="fas fa-edit"></i> Edit</button>
                                    </div>
                                </div>
                            </div>
                            {!isInEdit &&
                                <div className="card-body bg-light border-top details-container">
                                    <div className="row">
                                        <div className="col-md-3 col-12  ">
                                            <p className="font-weight-semi-bold  mb-3 mt-1">VRM</p>
                                        </div>
                                        <div className="col-md-6 col-12 "><p className="small-number-plate mb-0">{allowedVrm.vrm}</p></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Vehicle Make</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{allowedVrm.vehicleMake}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3 ">Vehicle Model</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{allowedVrm.vehicleModel}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3 ">Colour</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{allowedVrm.colour}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Site</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{allowedVrm.siteName && allowedVrm.siteName !== null && <>{allowedVrm.siteName}</>}{!allowedVrm.siteName && <>All</>}</div>
                                    </div>
                                    {!stringHelpers.isNullOrEmpty(allowedVrm.anprCameraName) &&
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">ANPR Camera</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{allowedVrm.anprCameraName}</div>
                                        </div>
                                    }
                                    {!stringHelpers.isNullOrEmpty(allowedVrm.roi) &&
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Region of Interest</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{allowedVrm.roi}</div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Driver Name</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{allowedVrm.driverName}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Driver Mobile Number</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{allowedVrm.driverMobileNumber}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Driver Email Address</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{allowedVrm.driverEmailAddress}</div>
                                    </div>
                                    {!allowedVrm.indefinite && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Period Start</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{allowedVrm.startDate !== null && <>{moment(allowedVrm.startDate).format("DD/MM/yyyy, HH:mm")}</>}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Period End</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{allowedVrm.endDate !== null && <>{moment(allowedVrm.endDate).format("DD/MM/yyyy, HH:mm")}</>}</div>
                                        </div>
                                    </>}
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Reason</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{allowedVrm.notes}</div>
                                    </div>
                                </div>
                            }
                            {isInEdit &&
                                <>
                                    <div className="card-body bg-light border-top details-container">
                                        {errorMessage !== "" && <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>}
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">VRM<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12 ">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0 small-number-plate" type="text" value={vrm} onChange={(e) => setVrm(e.target.value.toUpperCase())} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Vehicle Make</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={vehicleMake} onChange={(e) => setVehicleMake(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Vehicle Model</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={vehicleModel} onChange={(e) => setVehicleModel(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Colour</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={colour} onChange={(e) => setColour(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Site<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12 ">
                                                <div className="form-group mb-0">
                                                    <div className="dropdown">
                                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-site" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            {siteName && siteName !== null && <>{siteName}</>}
                                                            {!siteName &&
                                                                <>
                                                                    {isClient && <>Select site</>}
                                                                    {!isClient && <>All sites</>}
                                                                </>
                                                            }
                                                        </button>
                                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-site" >
                                                            <div className="bg-white py-2 rounded-soft">
                                                                <button className="dropdown-item text-base px-3 py-1" onClick={() => setSite(null)}>
                                                                    {isClient && <>Select site</>}
                                                                    {!isClient && <>All sites</>}
                                                                </button>
                                                                {sites && sites !== null && sites.map((currentSite) => {
                                                                    return (
                                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setSite(currentSite)}>{currentSite.name}</button>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {site && site !== null &&
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">ANPR Camera</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">
                                                    <div className="form-group mb-1">
                                                        <div className="dropdown">
                                                            <button className="btn btn-falcon-default dropdown-toggle btn-sm edit-dropdown" id="select-anpr-camera" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{stringHelpers.isNullOrEmpty(anprCameraId) ? "Select ANPR Camera" : getAnprCameraName(anprCameraId)}</button>
                                                            <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-anpr-camera" >
                                                                <div className="bg-white py-3 rounded-soft">
                                                                    <button className="dropdown-item text-base px-3 py-2" onClick={() => setAnprCameraId(null)}>None</button>
                                                                    {anprCameras && anprCameras !== null && anprCameras.map((anprCamera) => {
                                                                        return (
                                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setAnprCameraId(anprCamera.id)}>{anprCamera.name}</button>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {!stringHelpers.isNullOrEmpty(anprCameraId) &&
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">Region of Interest</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-0">
                                                        <input className="form-control mb-1" type="text" value={roi} onChange={(e) => setRoi(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Driver Name</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={driverName} onChange={(e) => setDriverName(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Driver Mobile Number</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={driverMobileNumber} onChange={(e) => setDriverMobileNumber(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Driver Email Address</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={driverEmailAddress} onChange={(e) => setDriverEmailAddress(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1 ">Indefinite Period</p>
                                            </div>
                                            <div className="col-md-6 col-12 custom-control custom-switch">
                                                <input className="custom-control-input" type="checkbox" id="switch1" checked={indefinite} onChange={() => {
                                                    if (!indefinite) {
                                                        setStartDate(null);
                                                        setEndDate(null);
                                                    }
                                                    setIndefinite(!indefinite)
                                                }} />
                                                <label className="custom-control-label ml-3" htmlFor="switch1"></label>
                                            </div>
                                        </div>
                                        {!indefinite && <>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">Period Start<span className="req"></span></p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="datetime-local" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">Period End<span className="req"></span></p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="datetime-local" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Reason</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <textarea className="form-control mb-0" rows={4} value={reason} onChange={(e) => setReason(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer border-top ">
                                        <div className="row client-edit-buttons">
                                            <div className="col-12 col-sm-auto ml-auto  pl-0">
                                                <div id="dashboard-actions">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setIsInEdit(false)}>
                                                        <i className="fas fa-times"></i> Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => edit()}>
                                                        <i className="fas fa-check"></i> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteModalLabel">Are you sure you want to delete this Allowed VRM?</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger btn-sm ml-2" data-dismiss="modal" onClick={() => Api.deleteAllowedVrm(() => history.push("/organisation/" + props.organisationId + "/allow-list"), props.organisationId, allowedVrmId, props.token)}>
                                Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>}
            {isNew && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Add New Allow VRM</h5>
                                        </div>

                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    {errorMessage !== "" && <div className="row">
                                        <div className="offset-md-3 col-md-6">
                                            <p className="error-message">
                                                {errorMessage}
                                            </p>
                                        </div>
                                    </div>}
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mt-1 mb-1">VRM<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-1 small-number-plate" autoFocus={true} type="text" value={vrm} onChange={(e) => setVrm(e.target.value.toUpperCase())} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mt-1 mb-1">Vehicle Make</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={vehicleMake} onChange={(e) => setVehicleMake(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mt-1 mb-1">Vehicle Model</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={vehicleModel} onChange={(e) => setVehicleModel(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mt-1 mb-1">Colour</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={colour} onChange={(e) => setColour(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mt-1 mb-1">Site<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            <div className="form-group mb-1">
                                                <div className="dropdown">
                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm edit-dropdown" id="select-site" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{siteName && siteName !== null && <>{siteName}</>}{!siteName && <>Select Site</>}</button>
                                                    <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-site" >
                                                        <div className="bg-white py-3 rounded-soft">
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setSite(null)}>All</button>
                                                            {sites && sites !== null && sites.map((currentSite) => {
                                                                return (
                                                                    <button className="dropdown-item text-base px-3 py-2" onClick={() => setSite(currentSite)}>{currentSite.name}</button>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {site && site !== null &&
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">ANPR Camera</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">
                                                <div className="form-group mb-1">
                                                    <div className="dropdown">
                                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm edit-dropdown" id="select-anpr-camera" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{stringHelpers.isNullOrEmpty(anprCameraId) ? "Select ANPR Camera" : getAnprCameraName(anprCameraId)}</button>
                                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-anpr-camera" >
                                                            <div className="bg-white py-3 rounded-soft">
                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setAnprCameraId(null)}>None</button>
                                                                {anprCameras && anprCameras !== null && anprCameras.map((anprCamera) => {
                                                                    return (
                                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setAnprCameraId(anprCamera.id)}>{anprCamera.name}</button>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {!stringHelpers.isNullOrEmpty(anprCameraId) &&
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Region of Interest</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="text" value={roi} onChange={(e) => setRoi(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mt-1 mb-1">Driver Name</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={driverName} onChange={(e) => setDriverName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mt-1 mb-1">Driver Mobile Number</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={driverMobileNumber} onChange={(e) => setDriverMobileNumber(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mt-1 mb-1">Driver Email Address</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={driverEmailAddress} onChange={(e) => setDriverEmailAddress(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1 ">Indefinite Period</p>
                                        </div>
                                        <div className="col-md-6 col-12 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="switch1" checked={indefinite} onChange={() => {
                                                if (!indefinite) {
                                                    setStartDate(null);
                                                    setEndDate(null);
                                                }
                                                setIndefinite(!indefinite)
                                            }} />
                                            <label className="custom-control-label ml-3" htmlFor="switch1"></label>
                                        </div>
                                    </div>
                                    {!indefinite && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Period Start<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="datetime-local" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Period End<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="datetime-local" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Reason</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <textarea className="form-control mb-0" rows={4} value={reason} onChange={(e) => setReason(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="row client-edit-buttons">
                                        <div className="col-12 col-sm-auto ml-auto pl-0">
                                            <div id="dashboard-actions">
                                                <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => history.push("/organisation/" + props.organisationId + "/allow-list")}>
                                                    <i className="fas fa-times"></i> Cancel</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => submit()}>
                                                    <i className="fas fa-check"></i> Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}</>
    )
}

export default AllowedVrm;