import { Link } from 'react-router-dom';
import Enums from '../../utilities/enum';
import { useState } from 'react';

export const DevicesStatusTable = (props) => {
    const [modalId, setModalId] = useState(null)
    return (
        <tbody>
            {
                props.filteredDevices?.map(item => {
                    const url = '/organisation/' + props.organisationId + '/site/' + item?.filteredDevice?.siteId + '/camera/' + item?.filteredDevice?.id
                    const deviceName = <h6 className="mb-0">{ item?.filteredDevice?.name }</h6>

                    return (
                        <tr key={ item?.filteredDevice?.id }>
                            <td>
                                {
                                    props.deviceType === Enums.DeviceType[0]
                                        ? (<Link to={ url }>{ deviceName }</Link>)
                                        : (deviceName)
                                }
                            </td>
                            <td>{ item?.filteredDevice?.siteName }</td>
                            <td className="text-right heartbeat pr-3">
                                { item?.pillButton }
                            </td>
                            {
                                props.deviceType === "Kiosk" && props.viewAll &&
                                <td className="text-right">
                                    <button
                                        onClick={ () => setModalId(item?.filteredDevice?.id) }
                                        className="btn btn-default border btn-sm ml-1"
                                        data-toggle="modal"
                                        data-target="#delete-modal">
                                        <i class="fas fa-trash-alt mr-2"></i>Delete
                                    </button>
                                </td>
                            }
                        </tr>
                    )
                })
            }

            {
                modalId && <>
                    <div className="modal fade" id="delete-modal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="deleteModalLabel">Are you sure you want to delete this Kiosk?</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-danger btn-sm ml-2" data-dismiss="modal" onClick={ () => props.handleDelete(modalId) }>
                                        Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </tbody>
    )

}