import { Link } from "react-router-dom/cjs/react-router-dom";

export const ReportSummaryCard = ({ reportSummary, organisationId }) => {
    const reportDetailsUrl = `/organisation/${organisationId}/reports/${reportSummary.urlSlug}`

    return (
        <div
        className="shadow-sm report-summary-card border rounded rounded-3 d-flex p-3"
        style={{position: "relative"}}>
            <div className="px-1 d-flex flex-column py-1 justify-content-between">
                <div style={{flexGrow: "1"}}>
                    <p className="font-weight-bold m-0 mb-1" style={{fontSize: "18px"}}>{ reportSummary.title }</p>
                    <p style={ { fontSize: "14px", margin: "0" } }>{ reportSummary.summaryDescription }</p>
                </div>
                <Link 
                to={ reportDetailsUrl } 
                className="btn btn-secondary nowrap align-self-start mt-3" 
                style={{fontSize: "12px"}}
                >
                    View Report<i class="fas fa-chevron-right ml-2"></i>
                </Link>
            </div>
            <i
             className={ `${ reportSummary.iconClass } text-${reportSummary.iconBackground}` }
             style={{
                fontSize: "140px",
                opacity: "0.15",
                position: "absolute",
                bottom: "0",
                right: "0",
            }}></i>
        </div>
    )
}