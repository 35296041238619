import React, { useState, useEffect, useRef } from 'react';
import Api from '../api/api';
import StringHelpers from '../../utilities/stringHelpers';
import Loader from '../shared/loader';
import moment from 'moment';
import Enums from '../../utilities/enum';
import Modal from 'react-bootstrap/Modal';

export default function BreachPayments(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [showAddPayment, setShowAddPayment] = useState(false);
    const [breachPayments, setBreachPayments] = useState(null);   

    const [paymentAmount, setPaymentAmount] = useState("");
    const [paymentReference, setPaymentReference] = useState("");
    const [paymentNote, setPaymentNote] = useState("");
    const [canSavePayment, setCanSavePayment] = useState(false);

    const getBreachPayments = function() {
        setIsLoading(true);

        Api.getBreachPayments(
            (data) => {
                setBreachPayments(data);
                setIsLoading(false)
            },
            (error) => console.log(error),
            props.breachId,
            props.organisationId,
            props.token
        )
    };

    const savePayment = function() {

        setIsLoading(true);

        Api.addBreachPayment(
            () => {
                if (props.onSavePayment) {
                    window.scrollTo(0, 0);
                    props.onSavePayment();
                }
            },
            (error) => console.log(error),
            props.breachId,
            paymentAmount,
            paymentReference,
            paymentNote,
            props.organisationId,
            props.token
        )

        setShowAddPayment(false);
    }

    useEffect(() => {
        getBreachPayments();
    }, []);

    useEffect(() => {
        setCanSavePayment(!isNaN(paymentAmount) && paymentAmount > 0 && !StringHelpers.isNullOrEmpty(paymentReference));
    }, [paymentAmount, paymentReference]);

    useEffect(() => {
        setPaymentAmount("");
        setPaymentReference("");
        setPaymentNote("");
    }, [showAddPayment])

    return <>
        <div className="card mb-3">
            <div className="card-header">
                <div className="row align-items-center justify-content-between">
                    <div className="col-1 d-flex align-items-center pr-0">
                        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">PCN Payments</h5>
                        {isLoading &&
                            <Loader />
                        }
                    </div>                                        
                    <div className="col-2 text-right pl-0 filter-buttons-wrapper">
                        <button className="btn btn-success btn-sm ml-1" onClick={() => setShowAddPayment(true)}><i className="fas fa-plus"></i> Add</button>
                    </div>
                </div>
            </div>
            <div className="card-body bg-light border-top p-0 details-container">
                <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                    <thead>
                        <tr>
                            <th>Date/Time</th>
                            <th>Amount</th>
                            <th>Payment Provider</th>
                            <th>Reference</th>
                        </tr>
                    </thead>
                    {!isLoading && breachPayments !== null && breachPayments.length > 0 &&
                        <tbody>
                            {breachPayments.map((breachPayment) =>
                                <tr>
                                    <td>{moment(breachPayment.timestamp).format("DD/MM/yyyy HH:mm")} </td>
                                    <td>£{breachPayment.amount.toFixed(2)}</td>
                                    <td>{Enums.PaymentProvider[breachPayment.paymentProvider]}</td>
                                    <td>{breachPayment.paymentProviderId}</td>
                                </tr>
                            )}
                        </tbody>
                    }
                </table>
            </div>
        </div>

        <Modal show={showAddPayment} size="lg">
            <Modal.Header>
                <Modal.Title>
                    Add a payment
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12 ">
                        <p className="font-weight-semi-bold  mt-1 mb-2">Amount:</p>
                    </div>
                    <div className="col-12">
                        <div className="form-group mb-0">
                           <input className="form-control" type='number' step="0.01" value={paymentAmount} onChange={(e) => setPaymentAmount(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 ">
                        <p className="font-weight-semi-bold  mt-1 mb-2">Reference:</p>
                    </div>
                    <div className="col-12">
                        <div className="form-group mb-0">
                           <input className="form-control" type='text' value={paymentReference} onChange={(e) => setPaymentReference(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 ">
                        <p className="font-weight-semi-bold  mt-1 mb-2">Note:</p>
                    </div>
                    <div className="col-12">
                        <div className="form-group mb-0">
                           <textarea rows={3} className="form-control" value={paymentNote} onChange={(e) => setPaymentNote(e.target.value)} />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-danger mt-1" onClick={() => setShowAddPayment(false)}>Cancel</button>
                <button className="btn btn-sm btn-success mt-1" disabled={!canSavePayment} onClick={savePayment}>Save</button>
            </Modal.Footer>
        </Modal>
    </>
}