import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Api from '../api/api';
import authentication from '../authentication/b2c';

function AcceptInvite(props) {
    const [inviteId, setInviteId] = useState(props.match.params.inviteId);

    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [emailAddress, setEmailAddress] = useState("");

    const [canSave, setCanSave] = useState(false);

    const submit = function () {
        var userId = "";
        if (props.userId === undefined) {
            userId = props.user.id;
        } else {
            userId = props.userId;
        }
        Api.acceptInvitation(
            () => window.location.replace("/"),
            (error) => console.log(error),
            first, last, emailAddress, userId, inviteId,
            props.token
        )
    };

    useEffect(() => {
        if (first !== null && last !== null && first !== "" && last !== "" && emailAddress !== null && emailAddress !== "") {
            setCanSave(true);
        }
    }, [first, last, emailAddress]);

    useEffect(() => {
        if (props.user !== null) {
            setFirst(props.user.firstName);
            setLast(props.user.lastName);
            setEmailAddress(props.user.emailAddress);
        } else {
            if (props.name !== null && props.name !== "unknown" && props.name !== undefined) {
                var names = props.name.toString();
                var splitNames = names.split(' ');

                setFirst(splitNames[0]);
                var i = 0;
                var lastNames = "";
                splitNames.forEach(name => {
                    if (i !== 0) {
                        lastNames += name + " ";
                    } else {
                        i++;
                    }
                });
                setLast(lastNames);
            }
            if (props.email !== null && props.email !== "") {
                setEmailAddress(props.email);
            }
        }
    }, [inviteId]);

    return (
        <>
            <div className="container-fluid mt-5 accept-invite">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-2 col-sm-auto d-flex align-items-center pr-0">
                                        <h5 className="mb-0 font-weight-semi-bold">Accept Invite</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row mb-1">
                                    <div className="col-12 ">
                                        <p className="font-weight-bold mb-1">First Name</p>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mb-0">
                                            <input className="form-control mb-1" type="text" value={first} onChange={(e) => setFirst(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 ">
                                        <p className="font-weight-bold mb-1">Last Name</p>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mb-0">
                                            <input className="form-control mb-1" type="text" value={last} onChange={(e) => setLast(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="row client-edit-buttons">
                                    <div className="col-auto ml-auto  pl-0">
                                        <div id="dashboard-actions">
                                            <Link className="btn btn-danger btn-sm ml-2" to="/"><i className="fas fa-times"></i> Cancel</Link>
                                            <button className="btn btn-falcon-default btn-sm ml-2" onClick={() => authentication.signOut()}>Sign Out</button>
                                            <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => submit()}>
                                                <i className="fas fa-check"></i> Accept Invite</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AcceptInvite;