
export const groupedBarChartColours = ["#ff7e34", "#fcd206", "#5B7999", "#EDFF00", "#8DA7BB", "#FF33FF", "#FFF800", "#B7CCD8"]

export const createGroupedChartTraces = (optionsArray, valuesByProperty) => {
    return optionsArray.reduce((acc, curr, index) => {
        acc.push({
            x: Object.keys(valuesByProperty).map(item => `${item.slice(0, 15)}...`),
            y: Object.values(valuesByProperty).map(item => {
                if (item[curr]) {
                    return item[curr]
                } else return 0
            }),
            type: "bar",
            name: `${ curr }`,
            marker: { color: groupedBarChartColours[index] },
            textposition: 'none'
        })
        return acc;
    }, [])
}