import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import UploadFile from '../shared/file-uploader';
import Loader from '../shared/loader';
import moment from 'moment';
import Enums from '../../utilities/enum';
import StringHelpers from '../../utilities/stringHelpers';
import arrayUtilities from '../../utilities/arrayUtilities';

function Site(props) {
    const [siteId, setSiteId] = useState(props.match.params.siteId);
    const [clientId, setClientId] = useState(props.match.params.clientId);
    const [site, setSite] = useState(null);
    const [clientUrl, setClientUrl] = useState("");
    const [cameraUrl, setCameraUrl] = useState("");
    const [contraventionUrl, setContraventionUrl] = useState("");
    const [payDisplayUrl, setPayDisplayUrl] = useState("");

    const [isNew, setIsNew] = useState(false);
    const [detailsInEdit, setDetailsInEdit] = useState(false);
    const [camerasInEdit, setCamerasInEdit] = useState(false);
    const [contraventionsInEdit, setContraventionsInEdit] = useState(false);
    const [allowListInEdit, setAllowListInEdit] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [allTags, setAllTags] = useState(null);
    const [selectedTags, setSelectedTags] = useState(null);
    const [allContraventions, setAllContraventions] = useState(null);
    const [siteContraventions, setSiteContraventions] = useState(null);
    const [attachments, setAttachments] = useState(null);

    //details
    const [name, setName] = useState("");
    const [siteCode, setSiteCode] = useState("");
    const [parkonomySiteCode, setParkonomySiteCode] = useState("");
    const [ringGoZone, setRingGoZone] = useState("");
    const [permitPrefix, setPermitPrefix] = useState("");
    const [iasIpcCode, setIasIpcCode] = useState("");
    const [status, setStatus] = useState(false);
    const [contractExpiry, setContractExpiry] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [addressLine3, setAddressLine3] = useState("");
    const [postCode, setPostCode] = useState("");
    const [townOrCity, setTownOrCity] = useState("");
    const [iasEnabled, setIasEnabled] = useState(false);
    const [bpaEnabled, setBpaEnabled] = useState(false);
    const [bpaCode, setBpaCode] = useState("");
    const [pofaEnabled, setPofaEnabled] = useState(false);

    //cameras
    const [anprEnabled, setAnprEnabled] = useState(true);
    const [maxAllowedParkingDuration, setMaxAllowedParkingDuration] = useState(0);
    const [considerationPeriod, setConsiderationPeriod] = useState(0);
    const [allowListEntryGrace, setAllowListEntryGrace] = useState(0);
    const [allowListExitGrace, setAllowListExitGrace] = useState(0);
    const [contraventionsForOverStay, setContraventionsForOverStay] = useState(null);
    const [contraventionsForShortReturn, setContraventionsForShortReturn] = useState(null);
    const [parkingSessionLimit, setParkingSessionLimit] = useState(0);
    const [noReturnWithin, setNoReturnWithin] = useState(0);
    const [variableAnprEnabled, setVariableAnprEnabled] = useState(true);
    const [variableAnprStart, setVariableAnprStart] = useState(null);
    const [variableAnprEnd, setVariableAnprEnd] = useState(null);
    const [variableAnprMaxAllowedParkingDuration, setVariableAnprMaxAllowedParkingDuration] = useState(0);
    const [variableAnprConsiderationPeriod, setVariableAnprConsiderationPeriod] = useState(0);
    const [variableAnprAllowListEntryGrace, setVariableAnprAllowListEntryGrace] = useState(0);
    const [variableAnprAllowListExitGrace, setVariableAnprAllowListExitGrace] = useState(0);
    const [variableAnprContraventionsForOverStay, setVariableAnprContraventionsForOverStay] = useState(null);
    const [variableAnprContraventionsForShortReturn, setVariableAnprContraventionsForShortReturn] = useState(null);
    const [variableAnprParkingSessionLimit, setVariableAnprParkingSessionLimit] = useState(0);
    const [variableAnprNoReturnWithin, setVariableAnprNoReturnWithin] = useState(0);

    //spaces
    const [payAndDisplaySpaces, setPayAndDisplaySpaces] = useState(0);
    const [preAllocatedSpaces, setPreAllocatedSpaces] = useState(0);
    const [disabledSpaces, setDisabledSpaces] = useState(0);
    const [permitSpaces, setPermitSpaces] = useState(0);
    const [otherSpaces, setOtherSpaces] = useState(0);

    //camera list
    const [cameras, setCameras] = useState(null);

    //pd machines list
    const [pdMachines, setPdMachines] = useState(null);

    //allow list 
    const [allowList, setAllowList] = useState(null);
    const [search, setSearch] = useState(null);
    const [filterStatus, setFilterStatus] = useState(null);
    const [filterEnd, setFilterEnd] = useState(null);
    const [filterEndTemp, setFilterEndTemp] = useState(null);
    const [filterStart, setFilterStart] = useState(null);
    const [filterStartTemp, setFilterStartTemp] = useState(null);

    //cameras
    const [camerasSearch, setCamerasSearch] = useState(null);
    const [cameraFilterStatus, setCameraFilterStatus] = useState(null);

    //pd machines
    const [pdSearch, setPdSearch] = useState(null);
    const [pdFilterStatus, setPdFilterStatus] = useState(null);

    //new allow list item
    const [vrm, setVrm] = useState("");
    const [vehicleMake, setVehicleMake] = useState("");
    const [vehicleModel, setVehicleModel] = useState("");
    const [colour, setColour] = useState("");
    const [driverName, setDriverName] = useState("");
    const [driverMobileNumber, setDriverMobileNumber] = useState("");
    const [driverEmailAddress, setDriverEmailAddress] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [indefinite, setIndefinite] = useState(false);
    const [reason, setReason] = useState("");
    const [anprCameraId, setAnprCameraId] = useState(null);
    const [roi, setRoi] = useState(null);

    //notes
    const [notes, setNotes] = useState(null);
    const [note, setNote] = useState("");

    //commission
    const [commission, setCommission] = useState(0);
    const [commissionStructure, setCommissionStructure] = useState(0);
    const [commissionStructures, setCommissionStructures] = useState([]);

    //attachment
    const [attachmentFileId, setAttachmentFileId] = useState("");
    const [canAddAllowList, setCanAddAllowList] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    //pay and display
    const [isPayDisplay, setIsPayDisplay] = useState(true);
    const [contraventionsForFailToPay, setContraventionsForFailToPay] = useState(null);
    const [contraventionsForFailToPayWithinPeriod, setContraventionsForFailToPayWithinPeriod] = useState(null);
    const [contraventionsForOverStayPaymentPeriod, setContraventionsForOverStayPaymentPeriod] = useState(null);

    const history = useHistory();

    const clearFilters = function () {
        setSearch(null);
        setCamerasSearch(null);
        setPdSearch(null);
        setFilterStatus(null);
        setCameraFilterStatus(null);
        setPdFilterStatus(null);
        setFilterStart(null);
        setFilterStartTemp(null);
        setFilterEnd(null);
        setFilterEndTemp(null);
    };

    const setFilterDates = function () {
        setFilterStart(filterStartTemp);
        setFilterEnd(filterEndTemp);
    };

    const setAllCommissionStructures = function () {
        var temp = [];
        for (let index = 0; index => 0; index++) {
            if (Enums.CommissionStructure[index] !== undefined) {
                temp.push(index);
            } else {
                break;
            }
        }
        setCommissionStructures(temp);
    };

    const checkIfInDateRange = function (start, end) {
        const startDate = moment(start);
        const endDate = moment(end);
        const filterStartDate = moment(filterStart);
        const filterEndDate = moment(filterEnd);

        if (filterStart !== null && startDate.isValid()) {
            var diff = startDate.diff(filterStartDate, 'days');
            console.log(diff)
            if (!(diff > 0)) {
                return false
            }
        }
        if (filterEnd !== null && endDate.isValid()) {
            var diff = endDate.diff(filterEndDate);

            if (!(diff < 0)) {
                return false
            }
        }
        return true;
    };

    const addNote = function () {
        if (note !== "" || note !== null) {
            Api.addSiteNote(
                () => getNotes(),
                (error) => console.log(error),
                note, props.organisationId, siteId, props.token
            )
        }
    };

    const getNotes = function () {
        Api.getSiteNotes(
            (data) => setNotes(data),
            (error) => console.log(error),
            props.organisationId, siteId, props.token
        )
    };

    const checkIfNotValid = function (status, start, end) {
        if (status === "Indefinite" || status === null) {
            return true;
        }
        const startDate = moment(start);
        const endDate = moment(end);
        const today = moment.now();
        if (status === "Active") {
            if (!(startDate.isValid() && endDate.isValid())) {
                return false;
            }
            else {
                if (startDate.diff(today) < 0 && endDate.diff(today) > 0)
                    return false;
            }
        } else if (status === "Deactivated") {
            if (startDate.diff(today) > 0 || endDate.diff(today) < 0) {
                return false;
            }
        }
        return true;
    };

    const getAllowList = function () {
        Api.getAllowList(
            (data) => setAllowList(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        );
    };

    const getSite = function () {
        Api.getSite(
            (data) => setSite(data),
            (error) => console.log(error),
            siteId,
            props.organisationId,
            props.token,
        )
    };

    const getSiteContraventions = function () {
        Api.getSiteContraventions(
            (data) => setSiteContraventions(data),
            (error) => console.log(error),
            props.organisationId,
            siteId,
            props.token
        )
    };

    const getAllContraventions = function () {
        Api.getContraventions(
            (data) => setAllContraventions(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        )
    };

    const submit = function () {
        if (canSave) {
            var anprStart = variableAnprStart + ':00';
            var anprEnd = variableAnprEnd + ':00';

            var contraventionsForOverStayId = null;
            if (contraventionsForOverStay !== null) {
                contraventionsForOverStayId = contraventionsForOverStay.id;
            }
            var contraventionsForShortReturnId = null;
            if (contraventionsForShortReturn !== null) {
                contraventionsForShortReturnId = contraventionsForShortReturn.id;
            }
            var variableAnprContraventionsForOverStayId = null;
            if (variableAnprContraventionsForOverStay !== null) {
                variableAnprContraventionsForOverStayId = variableAnprContraventionsForOverStay.id;
            }
            var variableAnprContraventionsForShortReturnId = null;
            if (variableAnprContraventionsForShortReturn !== null) {
                variableAnprContraventionsForShortReturnId = variableAnprContraventionsForShortReturn.id;
            }
            var contraventionsForOverStayPaymentPeriodId = null;
            if (contraventionsForOverStayPaymentPeriod !== null) {
                contraventionsForOverStayPaymentPeriodId = contraventionsForOverStayPaymentPeriod.id;
            }
            var contraventionsForFailToPayWithinPeriodId = null;
            if (contraventionsForFailToPayWithinPeriod !== null) {
                contraventionsForFailToPayWithinPeriodId = contraventionsForFailToPayWithinPeriod.id;
            }
            var contraventionsForFailToPayId = null;
            if (contraventionsForFailToPay !== null) {
                contraventionsForFailToPayId = contraventionsForFailToPay.id;
            }
            Api.addSite(
                (data) => {
                    setSite(data);
                    setErrorMessage("");
                    setIsNew(false);
                },
                (error) => setErrorMessage(error),
                name, siteCode, iasIpcCode, status, addressLine1, addressLine2, addressLine3, postCode, townOrCity, iasEnabled, bpaEnabled, bpaCode, pofaEnabled, anprEnabled, maxAllowedParkingDuration, considerationPeriod, allowListEntryGrace, allowListExitGrace, contraventionsForOverStayId, contraventionsForShortReturnId,
                parkingSessionLimit, noReturnWithin, variableAnprEnabled, anprStart, anprEnd, variableAnprMaxAllowedParkingDuration, variableAnprConsiderationPeriod, variableAnprAllowListEntryGrace, variableAnprAllowListExitGrace, variableAnprContraventionsForOverStayId,
                variableAnprContraventionsForShortReturnId, variableAnprParkingSessionLimit, variableAnprNoReturnWithin, StringHelpers.isNullOrEmpty(contractExpiry) ? null : contractExpiry, payAndDisplaySpaces, preAllocatedSpaces, disabledSpaces, permitSpaces, otherSpaces,
                permitPrefix, parkonomySiteCode, ringGoZone, contraventionsForOverStayPaymentPeriodId, contraventionsForFailToPayWithinPeriodId, contraventionsForFailToPayId, isPayDisplay,
                props.organisationId,
                clientId,
                props.token
            );
        } else {
            setErrorMessage("There are validation errors.");

        }
    };

    const edit = function () {
        if (canSave) {
            var anprStart = variableAnprStart + ':00';
            var anprEnd = variableAnprEnd + ':00';

            var contraventionsForOverStayId = null;
            if (contraventionsForOverStay !== null) {
                contraventionsForOverStayId = contraventionsForOverStay.id;
            }
            var contraventionsForShortReturnId = null;
            if (contraventionsForShortReturn !== null) {
                contraventionsForShortReturnId = contraventionsForShortReturn.id;
            }
            var variableAnprContraventionsForOverStayId = null;
            if (variableAnprContraventionsForOverStay !== null) {
                variableAnprContraventionsForOverStayId = variableAnprContraventionsForOverStay.id;
            }
            var variableAnprContraventionsForShortReturnId = null;
            if (variableAnprContraventionsForShortReturn !== null) {
                variableAnprContraventionsForShortReturnId = variableAnprContraventionsForShortReturn.id;
            }
            var contraventionsForOverStayPaymentPeriodId = null;
            if (contraventionsForOverStayPaymentPeriod !== null) {
                contraventionsForOverStayPaymentPeriodId = contraventionsForOverStayPaymentPeriod.id;
            }
            var contraventionsForFailToPayWithinPeriodId = null;
            if (contraventionsForFailToPayWithinPeriod !== null) {
                contraventionsForFailToPayWithinPeriodId = contraventionsForFailToPayWithinPeriod.id;
            }
            var contraventionsForFailToPayId = null;
            if (contraventionsForFailToPay !== null) {
                contraventionsForFailToPayId = contraventionsForFailToPay.id;
            }
            Api.editSite(
                (data) => {
                    setDetailsInEdit(false);
                    setCamerasInEdit(false);
                    setContraventionsInEdit(false);
                    setAllowListInEdit(false);
                    setSite(data);
                    setErrorMessage("");
                },
                (error) => setErrorMessage(error),
                name, siteCode, iasIpcCode, status, addressLine1, addressLine2, addressLine3, postCode, townOrCity, iasEnabled, bpaEnabled, bpaCode, pofaEnabled, anprEnabled, maxAllowedParkingDuration, considerationPeriod, allowListEntryGrace, allowListExitGrace, contraventionsForOverStayId, contraventionsForShortReturnId,
                parkingSessionLimit, noReturnWithin, variableAnprEnabled, anprStart, anprEnd, variableAnprMaxAllowedParkingDuration, variableAnprConsiderationPeriod, variableAnprAllowListEntryGrace, variableAnprAllowListExitGrace, variableAnprContraventionsForOverStayId,
                variableAnprContraventionsForShortReturnId, variableAnprParkingSessionLimit, variableAnprNoReturnWithin, StringHelpers.isNullOrEmpty(contractExpiry) ? null : contractExpiry, payAndDisplaySpaces, preAllocatedSpaces, disabledSpaces, permitSpaces, otherSpaces,
                permitPrefix, parkonomySiteCode, ringGoZone, contraventionsForOverStayPaymentPeriodId, contraventionsForFailToPayWithinPeriodId, contraventionsForFailToPayId, isPayDisplay,
                props.organisationId,
                siteId,
                clientId,
                props.token
            );
        } else {
            setErrorMessage("There are validation errors.")
        }
    };

    const getAllTags = function () {
        Api.getTags(
            (data) => setAllTags(data),
            () => console.log("no tag found"),
            props.organisationId,
            props.token,
        )
    };

    const getCameras = function () {
        Api.getSiteCameras(
            (data) => setCameras(data),
            (error) => console.log(error),
            props.organisationId,
            siteId,
            props.token
        )
    };

    const getPdMachines = function () {
        Api.getSitePayDisplays(
            (data) => setPdMachines(data),
            (error) => console.log(error),
            siteId,
            props.organisationId,
            props.token
        )
    };

    const updateTag = function (addOrDelete, tag) {
        if (addOrDelete) {
            Api.deleteSiteTag(
                () => getSite(),
                () => getSite(),
                props.organisationId,
                siteId,
                tag.id,
                props.token
            )
        } else {
            Api.addSiteTag(
                () => getSite(),
                () => getSite(),
                props.organisationId,
                siteId,
                tag.id,
                props.token
            )
        }
    };

    const getAttachments = function () {
        Api.getSiteAttachments(
            (data) => setAttachments(data),
            (error) => console.log(error),
            props.organisationId,
            siteId, props.token
        )
    };

    const addToSiteAllowList = function () {
        if (canAddAllowList) {
            Api.addAllowedVrm(
                (data) => getAllowList(),
                (error) => console.log(error),
                vrm, vehicleMake, vehicleModel, site.name, driverName, driverMobileNumber, startDate, endDate, reason,
                siteId, anprCameraId, roi,
                props.organisationId,
                props.token
            )
        }
    };

    const getAnprCameraName = function (id) {
        if (!arrayUtilities.isNullOrEmpty(cameras)) {
            let anprCamera = cameras.find(x => x.id === id);
            if (anprCamera && anprCamera !== null) {
                return anprCamera.name;
            }
        }

        return null;
    }

    useEffect(() => {
        if (siteId !== null && siteId !== undefined) {
            getSite();
            setContraventionUrl("/organisation/" + props.organisationId + "/site/" + siteId + "/contravention");
            setPayDisplayUrl("/organisation/" + props.organisationId + "/site/" + siteId + "/pay-display-machine");
            getPdMachines();
            getCameras();
            getSiteContraventions();
            getNotes();
            getAttachments();
        } else {
            setIsNew(true);
        }
        setClientUrl("/organisation/" + props.organisationId + '/client/' + clientId);
        getAllContraventions();
        getAllTags();
        getAllowList();
        setAllCommissionStructures();
    }, []);

    useEffect(() => {
        if (name === null || name === "" || siteCode === null || siteCode === "" || addressLine1 === null || addressLine1 === "" || townOrCity === null || townOrCity === "" || postCode === null || postCode === "") {
            setCanSave(false);
        } else {
            setCanSave(true);
        }

    }, [name, siteCode, addressLine1, townOrCity, postCode]);

    useEffect(() => {
        if (attachmentFileId !== null && attachmentFileId !== "") {
            Api.addSiteAttachment(
                (data) => {
                    getAttachments();
                },
                (error) => console.log(error),
                attachmentFileId, props.organisationId, siteId, props.token
            )
        }
    }, [attachmentFileId]);

    useEffect(() => {
        if (vrm === null || vrm === "") {
            setCanAddAllowList(false);
        } else {
            setCanAddAllowList(true);
        }

    }, [vrm]);

    useEffect(() => {
        if (allContraventions !== null && site !== null) {
            setContraventions();
        }
    }, [allContraventions, site]);

    const setContraventions = function () {
        allContraventions.forEach(item => {
            if (item.id === site.overstayContraventionId) {
                setContraventionsForOverStay(item)
            }
            if (item.id === site.shortReturnContraventionId) {
                setContraventionsForShortReturn(item)
            }
            if (item.id === site.variableAnprOverstayContraventionId) {
                setVariableAnprContraventionsForOverStay(item)
            }
            if (item.id === site.variableAnprShortReturnContraventionId) {
                setVariableAnprContraventionsForShortReturn(item)
            }
            if (item.id === site.failedToMakePaymentContraventionId) {
                setContraventionsForFailToPay(item)
            }
            if (item.id === site.failedToMakePaymentWithinConsiderationPeriodContraventionId) {
                setContraventionsForFailToPayWithinPeriod(item)
            }
            if (item.id === site.overstayedPaymentPeriodContraventionId) {
                setContraventionsForOverStayPaymentPeriod(item)
            }
        });
    };

    useEffect(() => {
        if (site !== null) {
            setSiteId(site.id);
            setSelectedTags(site.tags);
            //details
            setName(site.name);
            setSiteCode(site.siteCode);
            setParkonomySiteCode(site.parkonomySiteCode);
            setRingGoZone(site.ringGoZone);
            setPermitPrefix(site.permitPrefix);
            setIasIpcCode(site.iasCode);
            setStatus(site.isActive);
            setContractExpiry(site.contractExpiry);
            setAddressLine1(site.addressLine1);
            setAddressLine2(site.addressLine2);
            setAddressLine3(site.addressLine3);
            setTownOrCity(site.townOrCity);
            setPostCode(site.postCode);
            setIasEnabled(site.iasEnabled);
            setBpaEnabled(site.bpaEnabled);
            setBpaCode(site.bpaCode);
            setPofaEnabled(site.pofaEnabled);
            //cameras
            setAnprEnabled(site.isAnprEnabled);
            setMaxAllowedParkingDuration(site.maxAllowedParkingDurationMinutes);
            setConsiderationPeriod(site.considerationPeriodMinutes);
            setAllowListEntryGrace(site.allowListEntryGraceMinutes);
            setAllowListExitGrace(site.allowListExitGraceMinutes);
            setParkingSessionLimit(site.enforcementLimitMinutes);
            setNoReturnWithin(site.noReturnWithinMinutes);
            setVariableAnprEnabled(site.isVariableAnprEnabled);
            if (site.variableAnprStart !== null) {
                setVariableAnprStart(site.variableAnprStart.hours + ':' + site.variableAnprStart.minutes);
            }
            if (site.variableAnprEnd !== null) {
                setVariableAnprEnd(site.variableAnprEnd.hours + ':' + site.variableAnprEnd.minutes);
            }
            setVariableAnprMaxAllowedParkingDuration(site.variableAnprMaxAllowedParkingDurationMinutes);
            setVariableAnprConsiderationPeriod(site.variableAnprConsiderationPeriodMinutes);
            setVariableAnprAllowListEntryGrace(site.variableAnprAllowListEntryGraceMinutes);
            setVariableAnprAllowListExitGrace(site.variableAnprAllowListExitGraceMinutes);
            setVariableAnprParkingSessionLimit(site.variableAnprEnforcementLimitMinutes);
            setVariableAnprNoReturnWithin(site.variableAnprNoReturnWithinMinutes);
            //spaces
            setPayAndDisplaySpaces(site.payAndDisplaySpaces);
            setPreAllocatedSpaces(site.preAllocatedSpaces);
            setDisabledSpaces(site.disabledSpaces);
            setPermitSpaces(site.permitSpaces);
            setOtherSpaces(site.otherSpaces);
            //contraventions
            setContraventionsForFailToPay(site.failedToMakePaymentContraventionId);
            setContraventionsForFailToPayWithinPeriod(site.failedToMakePaymentWithinConsiderationPeriodContraventionId);
            setContraventionsForOverStayPaymentPeriod(site.overstayedPaymentPeriodContraventionId);
            setIsPayDisplay(site.isPayAndDisplayEnabled);

            if (allContraventions !== null) {
                setContraventions();
            }
            //url
            setCameraUrl("/organisation/" + props.organisationId + '/site/' + siteId + '/camera')
        }
        setErrorMessage("");
    }, [site, detailsInEdit, camerasInEdit, contraventionsInEdit, allowListInEdit]);

    return (
        <>
            {!site && !isNew && <Loader />}{site && site !== null && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col color-code-row">
                                            <h5 className="mb-0">Site - {site.name}</h5>
                                            <div className="tag-container">
                                                {selectedTags !== null && selectedTags !== undefined && selectedTags.map((tag) => {
                                                    const colorStyle = {
                                                        backgroundColor: '#' + tag.colourCode,
                                                    }
                                                    return (
                                                        <>
                                                            <div style={colorStyle} className="tag-colour-disc">
                                                            </div>
                                                            <div className="disc-text">
                                                                {tag.name}
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <Link className="btn btn-falcon-default btn-sm mr-3 py-1" type="button" to={clientUrl}><i className="fas fa-reply"></i> Back</Link>
                                        <button className="btn btn-falcon-default btn-sm dropdown-toggle py-2 mr-3 dropdown-caret-none" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fas fa-ellipsis-h"></i></button>
                                        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-start">
                                            <button type="button" className="dropdown-item text-danger" data-toggle="modal" data-target="#deleteModal">
                                                <i className="fas fa-times"></i> Delete Site</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3 details-container">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Site Details</h5>
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setDetailsInEdit(!detailsInEdit)}>
                                                <i className="fas fa-edit"></i> Edit</button>
                                        </div>
                                    </div>
                                </div>

                                {!detailsInEdit && <>
                                    <div className="card-body bg-light border-top">
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold ">Site Name</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.name}</div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">Tags</p>
                                            </div>
                                            <div className=" col-md-6 col-12  display-flex">{selectedTags !== null && selectedTags !== undefined && selectedTags.map((tag) => {
                                                return (
                                                    <div className="tagged-name">{tag.name}</div>
                                                )
                                            })}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">Site Code</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.siteCode}</div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">Parkonomy Site Code</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.parkonomySiteCode}</div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">RingGo Zone(s)</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.ringGoZone}</div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">Permit Prefix</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.permitPrefix}</div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">Contract Expiry</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.contractExpiry !== null && moment(site.contractExpiry).format("DD/MM/yyyy")}</div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Status</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.isActive && <i className="fas fa-check text-success"></i>}{!site.isActive && <i className="fas fa-times text-danger"></i>}</div>
                                        </div>
                                        <hr className="border-dashed border-bottom-0"></hr>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">Address</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">
                                                <div>{site.addressLine1}</div>
                                                <div>{site.addressLine2}</div>
                                                <div>{site.addressLine3}</div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">Town or City</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.townOrCity}</div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">Post Code</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.postCode}</div>
                                        </div>
                                        <hr className="border-dashed border-bottom-0"></hr>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">Payment Methods</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">data</div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">IAS Status</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.iasEnabled && <i className="fas fa-check text-success"></i>}{!site.iasEnabled && <i className="fas fa-times text-danger"></i>}</div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">IAS/IPC Code</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.iasCode}</div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">BPA Status</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.bpaEnabled && <i className="fas fa-check text-success"></i>}{!site.bpaEnabled && <i className="fas fa-times text-danger"></i>}</div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">BPA Site Code</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.bpaCode}</div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">POFA Enabled</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.pofaEnabled && <i className="fas fa-check text-success"></i>}{!site.pofaEnabled && <i className="fas fa-times text-danger"></i>}</div>
                                        </div>
                                        {/* <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">Commission</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.commission}</div>
                                        </div> */}
                                        {/* <div className="row">
  <div className=" col-md-3 col-12 ">
<p className="font-weight-semi-bold">Debt Collection Method</p>
  </div>
  <div className=" col-md-6 col-12 ">{organisation.debtCollectionMethod}</div>
 </div> */}
                                        <hr className="border-dashed border-bottom-0"></hr>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">Pay and Display Spaces</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.payAndDisplaySpaces}</div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">Pre-Allocated Spaces</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.preAllocatedSpaces}</div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">Disabled Spaces</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.disabledSpaces}</div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">Permit Spaces</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.permitSpaces}</div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold">Other Spaces</p>
                                            </div>
                                            <div className=" col-md-6 col-12 ">{site.otherSpaces}</div>
                                        </div>
                                    </div>
                                </>}
                                {detailsInEdit && <>
                                    <div className="card-body bg-light border-top details-container">
                                        {errorMessage !== "" && <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>}
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">Site Name<span className="req"></span></p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1"><button className="button-no-style tag-list-button collapsed pl-0" id="tags" type="button" data-toggle="collapse" data-target="#tagList" aria-expanded="true" aria-controls="tagList">Tags <i className="fas fa-chevron-down"></i></button></p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="collapse checkbox-dropdown" id="tagList" aria-labelledby="Tag List" data-parent="#tags">
                                                    <div className="row justify-content-start">
                                                        {allTags !== null && allTags !== undefined && allTags.map((tag) => {
                                                            var isSelected = false;
                                                            selectedTags.forEach(selectedTag => {
                                                                if (selectedTag.id === tag.id) {
                                                                    isSelected = true;
                                                                }
                                                            });
                                                            return (
                                                                <div className="col-6">
                                                                    <div className="form-group mb-0">
                                                                        <div className="custom-control custom-checkbox">
                                                                            <input className="custom-control-input" type="checkbox" id={tag.name} checked={isSelected} onChange={(e) => { updateTag(isSelected, tag) }} />
                                                                            <label className="custom-control-label" htmlFor={tag.name}>{tag.name}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">Site Code<span className="req"></span></p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={siteCode} onChange={(e) => setSiteCode(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">Parkonomy Site Code</p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={parkonomySiteCode} onChange={(e) => setParkonomySiteCode(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">RingGo Zone(s)</p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-2">
                                                    <input className="form-control mb-0" type="text" value={ringGoZone} onChange={(e) => setRingGoZone(e.target.value)} />
                                                    <small id="emailHelp" class="form-text text-muted">Enter your RingGo zones as a comma seperated list, i.e 00001, 00002</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">Permit Prefix<span className="req"></span></p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={permitPrefix} onChange={(e) => setPermitPrefix(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">Contract Expiry</p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="date" value={StringHelpers.isNullOrEmpty(contractExpiry) ? null : contractExpiry.substring(0, 10)} onChange={(e) => setContractExpiry(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">Status</p>
                                            </div>
                                            <div className=" col-md-6 col-12 custom-control custom-switch">
                                                <input className="custom-control-input" type="checkbox" id="site-status" checked={status} onChange={() => setStatus(!status)} />
                                                <label className="custom-control-label ml-3" htmlFor="site-status"></label>
                                                {status && <>Active</>}{!status && <>Deactivated</>}
                                            </div>
                                        </div>

                                        <hr className="border-dashed border-bottom-0"></hr>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Address Line 1<span className="req"></span></p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={addressLine1} onChange={(e) => setAddressLine1(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Address Line 2</p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Address Line 3</p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={addressLine3} onChange={(e) => setAddressLine3(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Town or City<span className="req"></span></p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={townOrCity} onChange={(e) => setTownOrCity(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Post Code<span className="req"></span></p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={postCode} onChange={(e) => setPostCode(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="border-dashed border-bottom-0"></hr>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">IAS Status</p>
                                            </div>
                                            <div className=" col-md-6 col-12 custom-control custom-switch">
                                                <input className="custom-control-input" type="checkbox" id="site-ias-enabled" checked={iasEnabled} onChange={() => setIasEnabled(!iasEnabled)} />
                                                <label className="custom-control-label ml-3" htmlFor="site-ias-enabled"></label>
                                                {iasEnabled && <>Active</>}{!iasEnabled && <>Deactivated</>}
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">IAS/IPC Code</p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={iasIpcCode} onChange={(e) => setIasIpcCode(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">BPA Status</p>
                                            </div>
                                            <div className=" col-md-6 col-12 custom-control custom-switch">
                                                <input className="custom-control-input" type="checkbox" id="site-bpa-enabled" checked={bpaEnabled} onChange={() => setBpaEnabled(!bpaEnabled)} />
                                                <label className="custom-control-label ml-3" htmlFor="site-bpa-enabled"></label>
                                                {bpaEnabled && <>Active</>}{!bpaEnabled && <>Deactivated</>}
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">BPA Site Code</p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={bpaCode} onChange={(e) => setBpaCode(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">POFA Status</p>
                                            </div>
                                            <div className=" col-md-6 col-12 custom-control custom-switch">
                                                <input className="custom-control-input" type="checkbox" id="site-pofa-enabled" checked={pofaEnabled} onChange={() => setPofaEnabled(!pofaEnabled)} />
                                                <label className="custom-control-label ml-3" htmlFor="site-pofa-enabled"></label>
                                                {pofaEnabled && <>Active</>}{!pofaEnabled && <>Deactivated</>}
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            <div className="col-3">
                                                <p className="font-weight-semi-bold mt-1 mb-0">Commission</p>
                                            </div>
                                            <div className="col-6">
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <button className="btn btn-falcon-default btn-sm dropdown-toggle edit-dropdown ws-normal" id="select-commission-structure" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{Enums.CommissionStructure[commissionStructure]}</button>
                                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-commission-structure">
                                                            <div className="bg-white py-3 rounded-soft">
                                                                {commissionStructures !== null && commissionStructures.map((item) => {
                                                                    return (
                                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setCommissionStructure(item)}>{Enums.CommissionStructure[item]}</button>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {commissionStructure === 0 &&
                                                        <input className="form-control mb-0" type="number" min={0} value={commission} onChange={(e) => setCommission(e.target.value)} />
                                                    }
                                                    {commissionStructure === 1 &&
                                                        <input className="form-control mb-0" type="number" max={100} min={0} value={commission} onChange={(e) => setCommission(e.target.value)} />
                                                    }
                                                </div>
                                            </div>
                                        </div> */}
                                        <hr className="border-dashed border-bottom-0"></hr>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Pay and Display Spaces</p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="number" value={payAndDisplaySpaces} onChange={(e) => setPayAndDisplaySpaces(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Pre-Allocated Spaces</p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="number" value={preAllocatedSpaces} onChange={(e) => setPreAllocatedSpaces(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Disabled Spaces</p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="number" value={disabledSpaces} onChange={(e) => setDisabledSpaces(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Permit Spaces</p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="number" value={permitSpaces} onChange={(e) => setPermitSpaces(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Other Spaces</p>
                                            </div>
                                            <div className=" col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="number" value={otherSpaces} onChange={(e) => setOtherSpaces(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer border-top ">
                                        <div className="row client-edit-buttons">
                                            <div className="col-6 col-sm-auto ml-auto  pl-0">
                                                <div id="dashboard-actions">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setDetailsInEdit(false)}>
                                                        <i className="fas fa-times"></i> Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => edit()}>
                                                        <i className="fas fa-check"></i> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-2">
                                            <h5 className="mb-0">
                                                <button className="button-no-style button-card-header collapsed" id="note-button" type="button" data-toggle="collapse" data-target="#notes" aria-expanded="true" aria-controls="site-details">
                                                    Notes <i className="fas fa-chevron-down"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div className="col-2 text-right pl-0 filter-buttons-wrapper">
                                            <button className="btn btn-success btn-sm ml-1" data-toggle="modal" data-target="#note-modal" ><i className="fas fa-plus"></i> Add</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="note-modal" tabIndex="-1" role="dialog" aria-labelledby="On Hold Modal" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="deleteModalLabel">Add a note to this site.</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-2">Note:</p>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group mb-0">
                                                            <textarea className="form-control mb-0" rows={4} value={note} onChange={(e) => setNote(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" data-dismiss="modal" onClick={() => addNote()}>
                                                    Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container collapse" id="notes" aria-labelledby="Allow List" data-parent="#note-button">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th>Origin</th>
                                                <th>Note</th>
                                                <th className="text-center">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {notes !== null && notes.map((item) => {
                                                return (
                                                    <>
                                                        <tr className="align-items-center">
                                                            <td>{item.note !== null &&
                                                                <h6 classname="pt-1">
                                                                    {item.userName}
                                                                </h6>}
                                                            </td>
                                                            <td>{item.note}</td>
                                                            <td className="text-center">{moment(item.timestamp).format("DD/MM/yyyy")} {moment(item.timestamp).format("HH:mm")}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-2">
                                            <h5 className="mb-0">
                                                <button className="button-no-style button-card-header collapsed" id="attachment" type="button" data-toggle="collapse" data-target="#attachment-details" aria-expanded="true" aria-controls="site-details">
                                                    Attachments <i className="fas fa-chevron-down"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div className="col-auto">
                                            <button className="btn btn-falcon-default my-0" data-toggle="modal" data-target="#add-new-evidence">Add Attachment </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade save-dialog success-dialog" id="add-new-evidence" tabIndex="-1" role="dialog">
                                    <UploadFile onSuccess={(file) => setAttachmentFileId(file.id)} type="Attachments" organisationId={props.organisationId} />
                                </div>
                                <div className="card-body bg-light border-top details-container collapse" id="attachment-details" aria-labelledby="Allow List" data-parent="#attachment">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th className="thumbnail-col"></th>
                                                <th>File Name</th>
                                                <th className="text-center">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {attachments && attachments.map((item) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td className="thumbnail-col">{item.isImage &&
                                                                <img src={item.url} className="attachment-thumb" />
                                                            }
                                                                {!item.isImage && <>
                                                                    {item.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                                    {item.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                                    {item.contentType !== "application/word.doc" && item.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                                                </>}
                                                            </td>
                                                            <td><a href={item.url} target="_blank"><h6 className="mb-0">{item.filename}</h6></a></td>
                                                            <td className="text-center">{item.timestamp !== null && <>{moment(item.timestamp).format("DD/MM/yyyy")}</>}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">
                                                <button className="button-no-style button-card-header collapsed" id="site-contras" type="button" data-toggle="collapse" data-target="#site-contras-list" aria-expanded="true" aria-controls="site-contras-list">
                                                    Site Contraventions <i className="fas fa-chevron-down"></i>
                                                </button>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                {siteContraventions && allContraventions && (allContraventions.length !== 0) &&
                                    <div className="card-body bg-light border-top details-container collapse" id="site-contras-list" aria-labelledby="Site Contraventions List" data-parent="#site-contras">
                                        <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer">
                                            <thead className="bg-200 text-900">
                                                <tr>
                                                    <th>Contravention</th>
                                                    <th>Type</th>
                                                    <th className="text-center">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allContraventions.map((contravention) => {
                                                    var url = contraventionUrl + '/' + contravention.id;
                                                    var isActive = false;
                                                    siteContraventions.forEach(item => {
                                                        if (contravention.id === item.contraventionId) {
                                                            isActive = item.isActive;
                                                        }
                                                    });
                                                    return (
                                                        <tr>
                                                            <td><Link to={url}><h6 className="mb-0">{contravention.name}</h6></Link></td>
                                                            <td>{Enums.ContraventionTypes[contravention.contraventionType]}</td>
                                                            <td className="text-center">{isActive && <><i className="fas fa-check text-success"></i></>}{!isActive && <><i className="fas fa-times text-danger"></i></>}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col-2">
                                            <h5 className="mb-0">
                                                <button className="button-no-style button-card-header collapsed" id="site-allowed-vrms" type="button" data-toggle="collapse" data-target="#site-allow-list" aria-expanded="true" aria-controls="site-allow-list">
                                                    Site Allow List <i className="fas fa-chevron-down"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div className="col-auto ml-auto text-right pl-0 filter-buttons-wrapper">
                                            <div className="input-group mr-1">
                                                {search === null && <>
                                                    <input className="form-control" value={null} type="text" placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                                </>}
                                                {search !== null && <>
                                                    <input className="form-control" value={search} type="text" autoFocus={true} placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                                </>}
                                                <div className="input-group-append">
                                                    <button className="btn btn-falcon-default btn-sm py-0" onClick={() => clearFilters()}><i className="fas fa-times"></i> Clear</button>
                                                </div>
                                            </div>
                                            <button className="btn btn-falcon-default btn-sm mr-1 ml-1 nowrap" data-toggle="modal" data-target="#dateModal">Filter by Date</button>
                                            <div className="filter-buttons" id="select-status">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{filterStatus && filterStatus !== null && <>Status: {filterStatus}</>}{!filterStatus && <>Filter by Status</>}</button>
                                                <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-status" >
                                                    <div className="bg-white py-3 rounded-soft">
                                                        <button className="dropdown-item text-base px-3 py-2 edit-dropdown" onClick={() => setFilterStatus(null)}>All</button>
                                                        <button className="dropdown-item text-base px-3 py-2 edit-dropdown" onClick={() => setFilterStatus("Active")}>Active</button>
                                                        <button className="dropdown-item text-base px-3 py-2 edit-dropdown" onClick={() => setFilterStatus("Deactivated")}>Deactivated</button>
                                                        <button className="dropdown-item text-base px-3 py-2 edit-dropdown" onClick={() => setFilterStatus("Indefinite")}>Indefinite</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <button className="btn btn-success btn-sm ml-1 nowrap" type="button" data-toggle="modal" data-target="#addToAllowList"><i className="fas fa-plus"></i> Add</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="addToAllowList" tabIndex="-1" role="dialog" aria-labelledby="addNewModalLabel" aria-hidden="true">
                                    <div className="modal-dialog site-allow-list" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="addNewModalLabel">Add new VRM to the {site.name} allow list.</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body details-container">
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">VRM*</p>
                                                    </div>
                                                    <div className="col-md-8 col-12 ">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-1" type="text" value={vrm} onChange={(e) => setVrm(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Vehicle Make</p>
                                                    </div>
                                                    <div className="col-md-8 col-12 ">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-0" type="text" value={vehicleMake} onChange={(e) => setVehicleMake(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Vehicle Model</p>
                                                    </div>
                                                    <div className="col-md-8 col-12 ">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-0" type="text" value={vehicleModel} onChange={(e) => setVehicleModel(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Colour</p>
                                                    </div>
                                                    <div className="col-md-8 col-12 ">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-0" type="text" value={colour} onChange={(e) => setColour(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Site</p>
                                                    </div>
                                                    <div className="col-md-8 col-12 ">
                                                        <p className="font-weight-semi-bold text-left mt-1 mb-1">{site.name}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">ANPR Camera</p>
                                                    </div>
                                                    <div className="col-md-8 col-12 ">
                                                        <div className="form-group mb-1">
                                                            <div className="dropdown">
                                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm edit-dropdown" id="select-anpr-camera" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{StringHelpers.isNullOrEmpty(anprCameraId) ? "Select ANPR Camera" : getAnprCameraName(anprCameraId)}</button>
                                                                <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-anpr-camera" >
                                                                    <div className="bg-white py-3 rounded-soft">
                                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setAnprCameraId(null)}>None</button>
                                                                        {cameras && cameras !== null && cameras.map((anprCamera) => {
                                                                            return (
                                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setAnprCameraId(anprCamera.id)}>{anprCamera.name}</button>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Region of Interest</p>
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="form-group mb-0">
                                                            <input className="form-control mb-1" type="text" value={roi} onChange={(e) => setRoi(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Driver Name</p>
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="form-group mb-0">
                                                            <input className="form-control mb-1" type="text" value={driverName} onChange={(e) => setDriverName(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Driver Mobile Number</p>
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="form-group mb-0">
                                                            <input className="form-control mb-1" type="text" value={driverMobileNumber} onChange={(e) => setDriverMobileNumber(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Driver Email Address</p>
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-0" type="text" value={driverEmailAddress} onChange={(e) => setDriverEmailAddress(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold mb-1 ">Indefinite Period</p>
                                                    </div>
                                                    <div className="col-md-8 col-12 custom-control custom-switch">
                                                        <input className="custom-control-input" type="checkbox" id="switch1" checked={indefinite} onChange={() => {
                                                            if (!indefinite) {
                                                                setStartDate(null);
                                                                setEndDate(null);
                                                            }
                                                            setIndefinite(!indefinite)
                                                        }} />
                                                        <label className="custom-control-label ml-3" htmlFor="switch1"></label>
                                                    </div>
                                                </div>
                                                {!indefinite && <>
                                                    <div className="row">
                                                        <div className="col-md-4 col-12 ">
                                                            <p className="font-weight-semi-bold  mt-1 mb-1">Period Start*</p>
                                                        </div>
                                                        <div className="col-md-8 col-12">
                                                            <div className="form-group mb-0">
                                                                <input className="form-control mb-1" type="datetime-local" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4 col-12 ">
                                                            <p className="font-weight-semi-bold  mt-1 mb-1">Period End*</p>
                                                        </div>
                                                        <div className="col-md-8 col-12">
                                                            <div className="form-group mb-0">
                                                                <input className="form-control mb-1" type="datetime-local" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}
                                                <div className="row">
                                                    <div className="col-md-4 col-12 ">
                                                        <p className="font-weight-semi-bold mt-1 mb-1">Reason</p>
                                                    </div>
                                                    <div className="col-md-8 col-12">
                                                        <div className="form-group mb-1">
                                                            <textarea className="form-control mb-0" rows={3} value={reason} onChange={(e) => setReason(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-danger btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canAddAllowList} data-dismiss="modal" onClick={() => addToSiteAllowList()}>
                                                    Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="dateModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="deleteModalLabel">Choose the start and end date to filter by</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Period Start</p>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group mb-0">
                                                            {!filterStartTemp &&
                                                                <>
                                                                    <input className="form-control mb-1" type="datetime-local" value={null} onChange={(e) => setFilterStartTemp(e.target.value)} />

                                                                </>}
                                                            {filterStartTemp &&
                                                                <input className="form-control mb-1" type="datetime-local" value={filterStartTemp} onChange={(e) => setFilterStartTemp(e.target.value)} />}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Period End</p>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group mb-0">
                                                            {!filterEndTemp &&
                                                                <>
                                                                    <input className="form-control mb-1" type="datetime-local" value={null} onChange={(e) => setFilterEndTemp(e.target.value)} />
                                                                </>}
                                                            {filterEndTemp &&
                                                                <input className="form-control mb-1" type="datetime-local" value={filterEndTemp} onChange={(e) => setFilterEndTemp(e.target.value)} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" data-dismiss="modal" onClick={() => setFilterDates()}>
                                                    Filter</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {allowList && (allowList.length !== 0) &&
                                    <div className="card-body bg-light border-top details-container collapse" id="site-allow-list" aria-labelledby="Site Allow List" data-parent="#site-allowed-vrms">
                                        <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer">
                                            <thead className="bg-200 text-900">
                                                <tr>
                                                    <th>Vehicle Registration</th>
                                                    <th>Driver Name</th>
                                                    <th>Vehicle Make</th>
                                                    <th>Vehicle Model</th>
                                                    <th>Site</th>
                                                    <th className="text-center">Start Date</th>
                                                    <th className="text-center">End Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {allowList.filter(function (item) {
                                                    var vrm = new String(item.vrm).toLowerCase();
                                                    var driverName = new String(item.driverName).toLowerCase();
                                                    var make = new String(item.vehicleMake).toLowerCase();
                                                    var model = new String(item.vehicleModel).toLowerCase();
                                                    if (item.siteId == siteId || item.siteId === null) {
                                                        return (
                                                            (
                                                                search === null ||
                                                                vrm.search(search.toLowerCase()) !== -1
                                                                || driverName.search(search.toLowerCase()) !== -1
                                                                || make.search(search.toLowerCase()) !== -1
                                                                || model.search(search.toLowerCase()) !== -1
                                                            )
                                                            && (filterStatus === null || (item.startDate === null && item.endDate === null && filterStatus === "Indefinite")
                                                                || !checkIfNotValid(filterStatus, item.startDate, item.endDate)
                                                            )
                                                            && checkIfInDateRange(item.startDate, item.endDate).valueOf()
                                                        )
                                                    }
                                                })
                                                    .map((item) => {
                                                        var url = "/organisation/" + props.organisationId + "/allowed-vrm/" + item.id;
                                                        return (
                                                            <tr>
                                                                <td><Link to={url} className="number-plate-link"><h6 className="mb-0 small-number-plate">{item.vrm}</h6></Link></td>
                                                                <td>{item.driverName}</td>
                                                                <td>{item.vehicleMake}</td>
                                                                <td>{item.vehicleModel}</td>
                                                                <td>{item.siteName && <>{item.siteName}</>}{item.siteName === null && <>All</>}</td>
                                                                <td className="text-center">{item.startDate !== null && <>{moment(item.startDate).format("DD/MM/yyyy")}</>}</td>
                                                                <td className="text-center">{item.endDate !== null && <>{moment(item.endDate).format("DD/MM/yyyy")}</>}</td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </table>

                                    </div>
                                }
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">
                                                <button className="button-no-style button-card-header collapsed" id="camera-settings" type="button" data-toggle="collapse" data-target="#camera-settings-list" aria-expanded="true" aria-controls="camera-settings-list">
                                                    ANPR Settings <i className="fas fa-chevron-down"></i>
                                                </button></h5>
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setCamerasInEdit(!camerasInEdit)}>
                                                <i className="fas fa-edit"></i> Edit</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="collapse" id="camera-settings-list" aria-labelledby="Camera Settings List" data-parent="#camera-settings">
                                    <div className="card-body bg-light border-top details-container" >
                                        {!camerasInEdit && <>
                                            <div className="row align-items-center mb-3">
                                                <div className=" col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mb-1">ANPR Enabled</p>
                                                </div>
                                                <div className=" col-md-6 col-12 ">{site.isAnprEnabled && <i className="fas fa-check text-success"></i>}{!site.isAnprEnabled && <i className="fas fa-times text-danger"></i>}</div>
                                            </div>
                                            {anprEnabled && <>
                                                <div className="row">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold">Max Allowed Parking Duration (mins)</p>
                                                    </div>
                                                    <div className=" col-md-6 col-12 ">{site.maxAllowedParkingDurationMinutes}</div>
                                                </div>
                                                <div className="row">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold">Consideration Period (mins)</p>
                                                    </div>
                                                    <div className=" col-md-6 col-12 ">{site.considerationPeriodMinutes}</div>
                                                </div>
                                                <div className="row">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold">Allow List Entry Grace (mins)</p>
                                                    </div>
                                                    <div className=" col-md-6 col-12 ">{site.allowListEntryGraceMinutes}</div>
                                                </div>
                                                <div className="row">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold">Allow List Exit Grace (mins)</p>
                                                    </div>
                                                    <div className=" col-md-6 col-12 ">{site.allowListExitGraceMinutes}</div>
                                                </div>
                                                <div className="row">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold">Contravention for Over Stay</p>
                                                    </div>
                                                    <div className=" col-md-6 col-12 ">{contraventionsForOverStay !== null && contraventionsForOverStay.name}</div>
                                                </div>
                                                <div className="row">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold">Contravention for Short Return</p>
                                                    </div>
                                                    <div className=" col-md-6 col-12 ">{contraventionsForShortReturn !== null && contraventionsForShortReturn.name}</div>
                                                </div>
                                                {isPayDisplay && <>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold">Contravention for Failure to Make Payment</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12 ">{contraventionsForFailToPay !== null && contraventionsForFailToPay.name}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold">Contravention for Failure to Make Payment within Consideration Period</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12 ">{contraventionsForFailToPayWithinPeriod !== null && contraventionsForFailToPayWithinPeriod.name}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold">Contravention for Overstayed Payment Period</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12 ">{contraventionsForOverStayPaymentPeriod !== null && contraventionsForOverStayPaymentPeriod.name}</div>
                                                    </div>
                                                </>}
                                                <div className="row">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold">Parking Session Limit (mins)</p>
                                                    </div>
                                                    <div className=" col-md-6 col-12 ">{site.enforcementLimitMinutes}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3 col-12">
                                                        <p className="font-weight-semi-bold ">No Return Within (mins)</p>
                                                    </div>
                                                    <div className="col-md-6 col-12">{site.noReturnWithinMinutes}</div>
                                                </div>
                                                <hr className="border-dashed border-bottom-0"></hr>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h5 className="font-weight-semi-bold mt-1 text-left">Pay and Display Settings</h5>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mb-1">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold mb-1">Pay and Display Settings</p>
                                                    </div>
                                                    <div className=" col-md-6 col-12 ">{site.isPayAndDisplayEnabled && <i className="fas fa-check text-success"></i>}{!site.isPayAndDisplayEnabled && <i className="fas fa-times text-danger"></i>}</div>
                                                </div>
                                                {isPayDisplay && <>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold">Contravention for Failure to Make Payment</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12 ">{contraventionsForFailToPay !== null && contraventionsForFailToPay.name}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold">Contravention for Failure to Make Payment within Consideration Period</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12 ">{contraventionsForFailToPayWithinPeriod !== null && contraventionsForFailToPayWithinPeriod.name}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold">Contravention for Overstayed Payment Period</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12 ">{contraventionsForOverStayPaymentPeriod !== null && contraventionsForOverStayPaymentPeriod.name}</div>
                                                    </div>
                                                </>}
                                                <hr className="border-dashed border-bottom-0"></hr>
                                                <div className="row mb-1">
                                                    <div className="col-12">
                                                        <h5 className="font-weight-semi-bold mt-1 text-left">Variable ANPR Settings</h5>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center mb-3">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold mb-1">Variable ANPR Settings</p>
                                                    </div>
                                                    <div className=" col-md-6 col-12 ">{site.isVariableAnprEnabled && <i className="fas fa-check text-success"></i>}{!site.isVariableAnprEnabled && <i className="fas fa-times text-danger"></i>}</div>
                                                </div>
                                                {site.isVariableAnprEnabled && <>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold">Variable ANPR Start Time</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12 ">{site.variableAnprStart !== null && <>{site.variableAnprStart.hours}:{site.variableAnprStart.minutes}:00</>}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold">Variable ANPR End Time</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12 ">{site.variableAnprEnd !== null && <>{site.variableAnprEnd.hours}:{site.variableAnprEnd.minutes}:00</>}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold">Max Allowed Parking Duration (mins)</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12 ">{site.variableAnprMaxAllowedParkingDurationMinutes}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold">Consideration Period (mins)</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12 ">{site.variableAnprConsiderationPeriodMinutes}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold">Allow List Entry Grace (mins)</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12 ">{site.variableAnprAllowListEntryGraceMinutes}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold">Allow List Exit Grace (mins)</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12 ">{site.variableAnprAllowListExitGraceMinutes}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold">Contravention for Over Stay</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12 ">{variableAnprContraventionsForOverStay !== null && variableAnprContraventionsForOverStay.name}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold">Contravention for Short Return</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12 ">{variableAnprContraventionsForShortReturn !== null && variableAnprContraventionsForShortReturn.name}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold">Parking Session Limit (mins)</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12 ">{site.variableAnprEnforcementLimitMinutes}</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold">No Return Within (mins)</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12 ">{site.variableAnprNoReturnWithinMinutes}</div>
                                                    </div>
                                                </>}
                                            </>}
                                        </>}
                                        {camerasInEdit && <>
                                            {errorMessage !== "" && <div className="row">
                                                <div className="offset-md-3 col-md-6">
                                                    <p className="error-message">
                                                        {errorMessage}
                                                    </p>
                                                </div>
                                            </div>}
                                            <div className="row align-items-center">
                                                <div className=" col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mt-1 mb-1">ANPR Enabled</p>
                                                </div>
                                                <div className="col-md-6 col-12 custom-control custom-switch">
                                                    <input className="custom-control-input" type="checkbox" id="anpr-enabled" checked={anprEnabled} onChange={() => setAnprEnabled(!anprEnabled)} />
                                                    <label className="custom-control-label ml-3" htmlFor="anpr-enabled"></label>
                                                    {anprEnabled && <>Active</>}{!anprEnabled && <>Deactivated</>}
                                                </div>
                                            </div>
                                            {anprEnabled && <>
                                                <div className="row">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold mt-1 mb-1">Max Allowed Parking Duration (mins)</p>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-0" type="number" value={maxAllowedParkingDuration} onChange={(e) => setMaxAllowedParkingDuration(e.target.value)} />
                                                            <p className="text-secondary" style={{fontSize: "12px"}}>Applicable for maximum-stay sites.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold mt-1 mb-1">Consideration Period (mins)</p>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-0" type="number" value={considerationPeriod} onChange={(e) => setConsiderationPeriod(e.target.value)} />
                                                            <p className="text-secondary" style={{fontSize: "12px"}}>Maximum length of stay between entry and exit that does not result in a parking charge.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold mt-1 mb-1">Allow List Entry Grace (mins)</p>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-0" type="number" value={allowListEntryGrace} onChange={(e) => setAllowListEntryGrace(e.target.value)} />
                                                            <p className="text-secondary" style={{fontSize: "12px"}}>Following entry, vehicles on allow list must be either added via portal or make a payment within this time to avoid a breach.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold mt-1 mb-1">Allow List Exit Grace (mins)</p>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-0" type="number" value={allowListExitGrace} onChange={(e) => setAllowListExitGrace(e.target.value)} />
                                                            <p className="text-secondary" style={{fontSize: "12px"}}>Following payment expiry or portal permit expiry, vehicles on allow list must exit within this time to avoid a breach.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold mt-1 mb-1">Contravention for Over Stay (mins)</p>
                                                    </div>
                                                    <div className="col-md-6 col-12 ">
                                                        <div className="form-group mb-0">
                                                            <div className="dropdown ">
                                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown ws-normal" id="select-overstay" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{contraventionsForOverStay === null && <>Select Contravention</>}{contraventionsForOverStay !== null && <>{contraventionsForOverStay.name} ({Enums.ContraventionTypes[contraventionsForOverStay.contraventionType]})</>}</button>
                                                                <div className="dropdown-menu py-1 edit-dropdown" aria-labelledby="select-overstay" >
                                                                    <button className="dropdown-item text-base px-3" onClick={() => setContraventionsForOverStay(null)}>None</button>
                                                                    <div className="bg-white rounded-soft">
                                                                        {allContraventions !== null && allContraventions.map((item) => {
                                                                            return (
                                                                                <button className="dropdown-item text-base px-3" onClick={() => setContraventionsForOverStay(item)}>{item.name} ({Enums.ContraventionTypes[item.contraventionType]})</button>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold mt-1 mb-1">Contravention for Short Return</p>
                                                    </div>
                                                    <div className="col-md-6 col-12 ">
                                                        <div className="form-group mb-0">
                                                            <div className="dropdown ">
                                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-shortreturn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{contraventionsForShortReturn === null && <>Select Contravention</>}{contraventionsForShortReturn !== null && <>{contraventionsForShortReturn.name} ({Enums.ContraventionTypes[contraventionsForShortReturn.contraventionType]})</>}</button>
                                                                <div className="dropdown-menu py-1 edit-dropdown" aria-labelledby="select-shortreturn" >
                                                                    <button className="dropdown-item text-base px-3" onClick={() => setContraventionsForShortReturn(null)}>None</button>
                                                                    <div className="bg-white rounded-soft">
                                                                        {allContraventions !== null && allContraventions.map((item) => {
                                                                            return (
                                                                                <button className="dropdown-item text-base px-3" onClick={() => setContraventionsForShortReturn(item)}>{item.name} ({Enums.ContraventionTypes[item.contraventionType]})</button>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold mt-1 mb-1">Parking Session Limit (mins)</p>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-0" type="number" value={parkingSessionLimit} onChange={(e) => setParkingSessionLimit(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold mt-1 mb-1">No Return Within (mins)</p>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <div className="form-group mb-1">
                                                            <input className="form-control mb-0" type="number" value={noReturnWithin} onChange={(e) => setNoReturnWithin(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="border-dashed border-bottom-0"></hr>
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <h5 className="font-weight-semi-bold mt-1 text-left">Pay and Display Settings</h5>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold mt-1 mb-1">Pay and Display Settings</p>
                                                    </div>
                                                    <div className="col-md-6 col-12 custom-control custom-switch">
                                                        <input className="custom-control-input" type="checkbox" id="pay-display-enabled" checked={isPayDisplay} onChange={() => setIsPayDisplay(!isPayDisplay)} />
                                                        <label className="custom-control-label ml-3" htmlFor="pay-display-enabled"></label>
                                                        {isPayDisplay && <>Active</>}{!isPayDisplay && <>Deactivated</>}
                                                    </div>
                                                </div>
                                                {isPayDisplay && <>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Contravention for Failure to Make Payment</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">
                                                            <div className="form-group mb-0">
                                                                <div className="dropdown ">
                                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-fail-pay" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{contraventionsForFailToPay === null && <>Select Contravention</>}{contraventionsForFailToPay !== null && <>{contraventionsForFailToPay.name} ({Enums.ContraventionTypes[contraventionsForFailToPay.contraventionType]})</>}</button>
                                                                    <div className="dropdown-menu py-1 edit-dropdown" aria-labelledby="select-fail-pay" >
                                                                        <button className="dropdown-item text-base px-3" onClick={() => setContraventionsForFailToPay(null)}>None</button>
                                                                        <div className="bg-white rounded-soft">
                                                                            {siteContraventions !== null && siteContraventions.map((item) => {
                                                                                var contra = null;
                                                                                allContraventions.forEach(contravention => {
                                                                                    if (contravention.id === item.contraventionId) {
                                                                                        contra = contravention;
                                                                                    }
                                                                                });
                                                                                return (
                                                                                    <button className="dropdown-item text-base px-3" onClick={() => setContraventionsForFailToPay(contra)}>{contra.name} ({Enums.ContraventionTypes[contra.contraventionType]})</button>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Contravention for Failure to Make Payment within Consideration Period</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">
                                                            <div className="form-group mb-0">
                                                                <div className="dropdown ">
                                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-fail-pay-period" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{contraventionsForFailToPayWithinPeriod === null && <>Select Contravention</>}{contraventionsForFailToPayWithinPeriod !== null && <>{contraventionsForFailToPayWithinPeriod.name} ({Enums.ContraventionTypes[contraventionsForFailToPayWithinPeriod.contraventionType]})</>}</button>
                                                                    <div className="dropdown-menu py-1 edit-dropdown" aria-labelledby="select-fail-pay-period" >
                                                                        <button className="dropdown-item text-base px-3" onClick={() => setContraventionsForFailToPayWithinPeriod(null)}>None</button>
                                                                        <div className="bg-white rounded-soft">
                                                                            {siteContraventions !== null && siteContraventions.map((item) => {
                                                                                var contra = null;
                                                                                allContraventions.forEach(contravention => {
                                                                                    if (contravention.id === item.contraventionId) {
                                                                                        contra = contravention;
                                                                                    }
                                                                                });
                                                                                return (
                                                                                    <button className="dropdown-item text-base px-3" onClick={() => setContraventionsForFailToPayWithinPeriod(contra)}>{contra.name} ({Enums.ContraventionTypes[contra.contraventionType]})</button>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Contravention for Overstayed Payment Period</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">
                                                            <div className="form-group mb-0">
                                                                <div className="dropdown ">
                                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-overstay-period" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{contraventionsForOverStayPaymentPeriod === null && <>Select Contravention</>}{contraventionsForOverStayPaymentPeriod !== null && <>{contraventionsForOverStayPaymentPeriod.name} ({Enums.ContraventionTypes[contraventionsForOverStayPaymentPeriod.contraventionType]})</>}</button>
                                                                    <div className="dropdown-menu py-1 edit-dropdown" aria-labelledby="select-overstay-period" >
                                                                        <button className="dropdown-item text-base px-3" onClick={() => setContraventionsForOverStayPaymentPeriod(null)}>None</button>
                                                                        <div className="bg-white rounded-soft">
                                                                            {siteContraventions !== null && siteContraventions.map((item) => {
                                                                                var contra = null;
                                                                                allContraventions.forEach(contravention => {
                                                                                    if (contravention.id === item.contraventionId) {
                                                                                        contra = contravention;
                                                                                    }
                                                                                });
                                                                                return (
                                                                                    <button className="dropdown-item text-base px-3" onClick={() => setContraventionsForOverStayPaymentPeriod(contra)}>{contra.name} ({Enums.ContraventionTypes[contra.contraventionType]})</button>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}
                                                <hr className="border-dashed border-bottom-0"></hr>
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <h5 className="font-weight-semi-bold mt-1 text-left">Variable ANPR Settings</h5>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center">
                                                    <div className=" col-md-3 col-12 ">
                                                        <p className="font-weight-semi-bold mt-1 mb-1">Variable ANPR Settings</p>
                                                    </div>
                                                    <div className="col-md-6 col-12 custom-control custom-switch">
                                                        <input className="custom-control-input" type="checkbox" id="vary-anpr" checked={variableAnprEnabled} onChange={() => setVariableAnprEnabled(!variableAnprEnabled)} />
                                                        <label className="custom-control-label ml-3" htmlFor="vary-anpr"></label>
                                                        {variableAnprEnabled && <>Active</>}{!variableAnprEnabled && <>Deactivated</>}
                                                    </div>
                                                </div>
                                                {variableAnprEnabled && <>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Max Allowed Parking Duration (mins)</p>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="number" value={variableAnprMaxAllowedParkingDuration} onChange={(e) => setVariableAnprMaxAllowedParkingDuration(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Variable ANPR Start Time</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="time" value={variableAnprStart} onChange={(e) => setVariableAnprStart(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row align-items-center">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Variable ANPR End Time</p>
                                                        </div>
                                                        <div className=" col-md-6 col-12">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="time" value={variableAnprEnd} onChange={(e) => setVariableAnprEnd(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Consideration Period (mins)</p>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="number" value={variableAnprConsiderationPeriod} onChange={(e) => setVariableAnprConsiderationPeriod(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Allow List Entry Grace (mins)</p>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="number" value={variableAnprAllowListEntryGrace} onChange={(e) => setVariableAnprAllowListEntryGrace(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Allow List Exit Grace (mins)</p>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="number" value={variableAnprAllowListExitGrace} onChange={(e) => setVariableAnprAllowListExitGrace(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Contravention for Over Stay</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">
                                                            <div className="form-group mb-0">
                                                                <div className="dropdown ">
                                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-overstay" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{variableAnprContraventionsForOverStay === null && <>Select Contravention</>}{variableAnprContraventionsForOverStay !== null && <>{variableAnprContraventionsForOverStay.name} ({Enums.ContraventionTypes[variableAnprContraventionsForOverStay.contraventionType]})</>}</button>
                                                                    <div className="dropdown-menu py-1 edit-dropdown" aria-labelledby="select-overstay" >
                                                                        <button className="dropdown-item text-base px-3" onClick={() => setVariableAnprContraventionsForOverStay(null)}>None</button>
                                                                        <div className="bg-white rounded-soft">
                                                                            {siteContraventions !== null && siteContraventions.map((item) => {
                                                                                var contra = null;
                                                                                allContraventions.forEach(contravention => {
                                                                                    if (contravention.id === item.contraventionId) {
                                                                                        contra = contravention;
                                                                                    }
                                                                                });
                                                                                return (
                                                                                    <button className="dropdown-item text-base px-3" onClick={() => setVariableAnprContraventionsForOverStay(contra)}>{contra.name} ({Enums.ContraventionTypes[contra.contraventionType]})</button>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Contravention for Short Return</p>
                                                        </div>
                                                        <div className="col-md-6 col-12 ">
                                                            <div className="form-group mb-0">
                                                                <div className="dropdown ">
                                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-varshortreturn" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{variableAnprContraventionsForShortReturn === null && <>Select Contravention</>}{variableAnprContraventionsForShortReturn !== null && <>{variableAnprContraventionsForShortReturn.name} ({Enums.ContraventionTypes[variableAnprContraventionsForShortReturn.contraventionType]})</>}</button>
                                                                    <div className="dropdown-menu py-1 edit-dropdown" aria-labelledby="select-varshortreturn" >
                                                                        <button className="dropdown-item text-base px-3" onClick={() => setVariableAnprContraventionsForShortReturn(null)}>None</button>
                                                                        <div className="bg-white rounded-soft">
                                                                            {siteContraventions !== null && siteContraventions.map((item) => {
                                                                                var contra = null;
                                                                                allContraventions.forEach(contravention => {
                                                                                    if (contravention.id === item.contraventionId) {
                                                                                        contra = contravention;
                                                                                    }
                                                                                });
                                                                                return (
                                                                                    <button className="dropdown-item text-base px-3" onClick={() => setVariableAnprContraventionsForShortReturn(contra)}>{contra.name} ({Enums.ContraventionTypes[contra.contraventionType]})</button>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Parking Session Limit (mins)</p>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="number" value={variableAnprParkingSessionLimit} onChange={(e) => setVariableAnprParkingSessionLimit(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className=" col-md-3 col-12 ">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">No Return Within (mins)</p>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="number" value={variableAnprNoReturnWithin} onChange={(e) => setVariableAnprNoReturnWithin(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}
                                            </>}
                                        </>}
                                    </div>
                                    {camerasInEdit && <>
                                        <div className="card-footer border-top ">
                                            <div className="row client-edit-buttons">
                                                <div className="col-6 col-sm-auto ml-auto  pl-0">
                                                    <div id="dashboard-actions">
                                                        <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setCamerasInEdit(false)}>
                                                            <i className="fas fa-times"></i> Cancel</button>
                                                        <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => edit()}>
                                                            <i className="fas fa-check"></i> Confirm</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">
                                                <button className="button-card-header button-no-style collapsed" id="cameras" type="button" data-toggle="collapse" data-target="#cameras-list" aria-expanded="true" aria-controls="cameras-list">
                                                    Cameras <i className="fas fa-chevron-down"></i></button></h5>
                                        </div>
                                        <div className="col-auto ml-auto text-right pl-0 filter-buttons-wrapper">
                                            <div className="input-group mr-1">
                                                {camerasSearch === null && <>
                                                    <input className="form-control" value={null} type="text" placeholder="search..." onChange={(e) => setCamerasSearch(e.target.value)} />
                                                </>}
                                                {camerasSearch !== null && <>
                                                    <input className="form-control" value={camerasSearch} type="text" autoFocus={true} placeholder="search..." onChange={(e) => setCamerasSearch(e.target.value)} />
                                                </>}
                                                <div className="input-group-append">
                                                    <button className="btn btn-falcon-default btn-sm py-0" onClick={() => clearFilters()}><i className="fas fa-times"></i> Clear</button>
                                                </div>
                                            </div>
                                            <div className="filter-buttons" id="select-camera-status">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-camera-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{cameraFilterStatus && cameraFilterStatus !== null && <>Status: Active</>}{!cameraFilterStatus && cameraFilterStatus !== null && <>Status: Deactivated</>}{cameraFilterStatus === null && <>Filter by Status</>}</button>
                                                <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-status" >
                                                    <div className="bg-white py-3 rounded-soft">
                                                        <button className="dropdown-item text-base px-3 py-2 edit-dropdown" onClick={() => setCameraFilterStatus(null)}>All</button>
                                                        <button className="dropdown-item text-base px-3 py-2 edit-dropdown" onClick={() => setCameraFilterStatus(true)}>Active</button>
                                                        <button className="dropdown-item text-base px-3 py-2 edit-dropdown" onClick={() => setCameraFilterStatus(false)}>Deactivated</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link type="button" className="btn btn-success btn-sm ml-2 nowrap" to={cameraUrl}>
                                                <i className="fas fa-plus"></i> Add</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container collapse" id="cameras-list" aria-labelledby="Tag List" data-parent="#cameras">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th>Camera Name</th>
                                                <th>Camera ID</th>
                                                <th className="text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cameras && cameras.filter(function (item) {
                                                var name = new String(item.name).toLowerCase();
                                                return (
                                                    (
                                                        camerasSearch === null ||
                                                        name.search(camerasSearch.toLowerCase()) !== -1
                                                    )
                                                    && (cameraFilterStatus === item.isActive || cameraFilterStatus === null)
                                                )
                                            }).map((camera) => {
                                                var url = cameraUrl + "/" + camera.id;
                                                return (
                                                    <tr>
                                                        <td><Link to={url}><h6 className="mb-0">{camera.name}</h6></Link></td>
                                                        <td>{camera.macAddress}</td>
                                                        <td className="text-center">{camera.isActive && <><i className="fas fa-check text-success"></i></>}{!camera.isActive && <><i className="fas fa-times text-danger"></i></>}</td>
                                                    </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col-3">
                                            <h5 className="mb-0">
                                                <button className="button-card-header button-no-style collapsed" id="pd-machines" type="button" data-toggle="collapse" data-target="#pd-list" aria-expanded="true" aria-controls="pd-list">
                                                    Pay & Display Machines <i className="fas fa-chevron-down"></i></button></h5>
                                        </div>
                                        <div className="col-auto ml-auto text-right pl-0 filter-buttons-wrapper">
                                            <div className="input-group mr-1">
                                                {pdSearch === null && <>
                                                    <input className="form-control" value={null} type="text" placeholder="search..." onChange={(e) => setPdSearch(e.target.value)} />
                                                </>}
                                                {pdSearch !== null && <>
                                                    <input className="form-control" value={pdSearch} type="text" autoFocus={true} placeholder="search..." onChange={(e) => setPdSearch(e.target.value)} />
                                                </>}
                                                <div className="input-group-append">
                                                    <button className="btn btn-falcon-default btn-sm py-0" onClick={() => clearFilters()}><i className="fas fa-times"></i> Clear</button>
                                                </div>
                                            </div>
                                            <div className="filter-buttons" id="select-pd-status">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-pd-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{pdFilterStatus && pdFilterStatus !== null && <>Status: Active</>}{!pdFilterStatus && pdFilterStatus !== null && <>Status: Deactivated</>}{pdFilterStatus === null && <>Filter by Status</>}</button>
                                                <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-pd-status" >
                                                    <div className="bg-white py-3 rounded-soft">
                                                        <button className="dropdown-item text-base px-3 py-2 edit-dropdown" onClick={() => setPdFilterStatus(null)}>All</button>
                                                        <button className="dropdown-item text-base px-3 py-2 edit-dropdown" onClick={() => setPdFilterStatus(true)}>Active</button>
                                                        <button className="dropdown-item text-base px-3 py-2 edit-dropdown" onClick={() => setPdFilterStatus(false)}>Deactivated</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link type="button" className="btn btn-success btn-sm ml-2 nowrap" to={payDisplayUrl}>
                                                <i className="fas fa-plus"></i> Add</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container collapse" id="pd-list" aria-labelledby="Pay and Display Machines List" data-parent="#pd-machines">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th>Name</th>
                                                <th>City Code</th>
                                                <th>Machine ID</th>
                                                <th>Flowbird Terminal ID</th>
                                                <th className="text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pdMachines && pdMachines.filter(function (item) {
                                                var name = new String(item.name).toLowerCase();
                                                return (
                                                    (
                                                        pdSearch === null ||
                                                        name.search(pdSearch.toLowerCase()) !== -1
                                                    )
                                                    && (pdFilterStatus === item.isActive || pdFilterStatus === null)
                                                )
                                            }).map((machine) => {
                                                var url = payDisplayUrl + "/" + machine.id;
                                                return (
                                                    <tr>
                                                        <td><Link to={url}><h6 className="mb-0">{machine.name}</h6></Link></td>
                                                        <td>{machine.cityCode}</td>
                                                        <td>{machine.machineId}</td>
                                                        <td>{machine.flowbirdTerminalId}</td>
                                                        <td className="text-center">{machine.isActive && <><i className="fas fa-check text-success"></i></>}{!machine.isActive && <><i className="fas fa-times text-danger"></i></>}</td>
                                                    </tr>)
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="deleteModalLabel">Are you sure you want to delete this site?</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                            <button type="button" className="btn btn-danger btn-sm ml-2" data-dismiss="modal" onClick={() => Api.deleteSite(() => history.push(clientUrl), props.organisationId, siteId, props.token)}>
                                                Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>} {!site && isNew && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3 details-container">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Create New Site</h5>
                                        </div>

                                    </div>
                                </div>
                                <div className="card-body bg-light border-top">
                                    {errorMessage !== "" && <div className="row">
                                        <div className="offset-md-3 col-md-6">
                                            <p className="error-message">
                                                {errorMessage}
                                            </p>
                                        </div>
                                    </div>}
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Site Name<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" autoFocus={true} value={name} onChange={(e) => setName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Tags</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <p className="font-weight-semi-bold mb-1 text-left">You will be able to add tags once the site has been created.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Site Code<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={siteCode} onChange={(e) => setSiteCode(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Contract Expiry</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="date" value={contractExpiry.substring(0, 10)} onChange={(e) => setContractExpiry(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Status</p>
                                        </div>
                                        <div className="col-md-6 col-12 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="site-status" checked={status} onChange={() => setStatus(!status)} />
                                            <label className="custom-control-label ml-3" htmlFor="site-status"></label>
                                            {status && <>Active</>}{!status && <>Deactivated</>}
                                        </div>
                                    </div>
                                    <hr className="border-dashed border-bottom-0"></hr>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Address Line 1<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={addressLine1} onChange={(e) => setAddressLine1(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Address Line 2</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Address Line 3</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={addressLine3} onChange={(e) => setAddressLine3(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Town or City<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={townOrCity} onChange={(e) => setTownOrCity(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Post Code<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={postCode} onChange={(e) => setPostCode(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="border-dashed border-bottom-0"></hr>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">IAS Status</p>
                                        </div>
                                        <div className="col-md-6 col-12 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="site-ias-enabled" checked={iasEnabled} onChange={() => setIasEnabled(!iasEnabled)} />
                                            <label className="custom-control-label ml-3" htmlFor="site-ias-enabled"></label>
                                            {iasEnabled && <>Active</>}{!iasEnabled && <>Deactivated</>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">IAS/IPC Code</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={iasIpcCode} onChange={(e) => setIasIpcCode(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">BPA Status</p>
                                        </div>
                                        <div className="col-md-6 col-12 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="site-bpa-enabled" checked={bpaEnabled} onChange={() => setBpaEnabled(!bpaEnabled)} />
                                            <label className="custom-control-label ml-3" htmlFor="site-bpa-enabled"></label>
                                            {bpaEnabled && <>Active</>}{!bpaEnabled && <>Deactivated</>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">BPA Site Code</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={bpaCode} onChange={(e) => setBpaCode(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">POFA Status</p>
                                        </div>
                                        <div className=" col-md-6 col-12 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="site-pofa-enabled" checked={pofaEnabled} onChange={() => setPofaEnabled(!pofaEnabled)} />
                                            <label className="custom-control-label ml-3" htmlFor="site-pofa-enabled"></label>
                                            {pofaEnabled && <>Active</>}{!pofaEnabled && <>Deactivated</>}
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-3">
                                            <p className="font-weight-semi-bold mt-1 mb-0">Commission</p>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <button className="btn btn-falcon-default btn-sm dropdown-toggle edit-dropdown ws-normal" id="select-commission-structure" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{Enums.CommissionStructure[commissionStructure]}</button>
                                                    <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-commission-structure">
                                                        <div className="bg-white py-3 rounded-soft">
                                                            {commissionStructures !== null && commissionStructures.map((item) => {
                                                                return (
                                                                    <button className="dropdown-item text-base px-3 py-2" onClick={() => setCommissionStructure(item)}>{Enums.CommissionStructure[item]}</button>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                                {commissionStructure === 0 &&
                                                    <input className="form-control mb-0" type="number" min={0} value={commission} onChange={(e) => setCommission(e.target.value)} />
                                                }
                                                {commissionStructure === 1 &&
                                                    <input className="form-control mb-0" type="number" max={100} min={0} value={commission} onChange={(e) => setCommission(e.target.value)} />
                                                }
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="card mb-3 details-container">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">ANPR Settings</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top">
                                    <div className="row align-items-center mb-3">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">ANPR Enabled</p>
                                        </div>
                                        <div className="col-md-6 col-12 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="anpr-enabled" checked={anprEnabled} onChange={() => setAnprEnabled(!anprEnabled)} />
                                            <label className="custom-control-label ml-3" htmlFor="anpr-enabled"></label>
                                            {anprEnabled && <>Active</>}{!anprEnabled && <>Deactivated</>}
                                        </div>
                                    </div>
                                    {anprEnabled && <>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">Max Allowed Parking Duration (mins)</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="number" value={maxAllowedParkingDuration} onChange={(e) => setMaxAllowedParkingDuration(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">Consideration Period (mins)</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="number" value={considerationPeriod} onChange={(e) => setConsiderationPeriod(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">Allow List Entry Grace (mins)</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="number" value={allowListEntryGrace} onChange={(e) => setAllowListEntryGrace(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">Allow List Exit Grace (mins)</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="number" value={allowListExitGrace} onChange={(e) => setAllowListExitGrace(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">Parking Session Limit (mins)</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="number" value={parkingSessionLimit} onChange={(e) => setParkingSessionLimit(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">No Return Within (mins)</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="number" value={noReturnWithin} onChange={(e) => setNoReturnWithin(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="border-dashed border-bottom-0"></hr>
                                        <div className="row">
                                            <div className="col-12 ">
                                                <h5 className="font-weight-bold mt-1 text-left mb-3">Pay and Display Settings</h5>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">Pay and Display Settings</p>
                                            </div>
                                            <div className="col-md-6 col-12 custom-control custom-switch">
                                                <input className="custom-control-input" type="checkbox" id="pay-display-enabled" checked={isPayDisplay} onChange={() => setIsPayDisplay(!isPayDisplay)} />
                                                <label className="custom-control-label ml-3" htmlFor="pay-display-enabled"></label>
                                                {isPayDisplay && <>Active</>}{!isPayDisplay && <>Deactivated</>}
                                            </div>
                                        </div>
                                        {isPayDisplay && <>
                                            <div className="row">
                                                <div className=" col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mt-1 mb-1">Contravention for Failure to Make Payment</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">
                                                    <div className="form-group mb-0">
                                                        <div className="dropdown ">
                                                            <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-fail-pay" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{contraventionsForFailToPay === null && <>Select Contravention</>}{contraventionsForFailToPay !== null && contraventionsForFailToPay.name}</button>
                                                            <div className="dropdown-menu py-1 edit-dropdown" aria-labelledby="select-fail-pay" >
                                                                <button className="dropdown-item text-base px-3" onClick={() => setContraventionsForFailToPay(null)}>None</button>
                                                                <div className="bg-white rounded-soft">
                                                                    {siteContraventions !== null && siteContraventions.map((item) => {
                                                                        var contra = null;
                                                                        allContraventions.forEach(contravention => {
                                                                            if (contravention.id === item.contraventionId) {
                                                                                contra = contravention;
                                                                            }
                                                                        });
                                                                        return (
                                                                            <button className="dropdown-item text-base px-3" onClick={() => setContraventionsForFailToPay(contra)}>{contra.name}</button>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className=" col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mt-1 mb-1">Contravention for Failure to Make Payment within Consideration Period</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">
                                                    <div className="form-group mb-0">
                                                        <div className="dropdown ">
                                                            <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-fail-pay-period" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{contraventionsForFailToPayWithinPeriod === null && <>Select Contravention</>}{contraventionsForFailToPayWithinPeriod !== null && contraventionsForFailToPayWithinPeriod.name}</button>
                                                            <div className="dropdown-menu py-1 edit-dropdown" aria-labelledby="select-fail-pay-period" >
                                                                <button className="dropdown-item text-base px-3" onClick={() => setContraventionsForFailToPayWithinPeriod(null)}>None</button>
                                                                <div className="bg-white rounded-soft">
                                                                    {siteContraventions !== null && siteContraventions.map((item) => {
                                                                        var contra = null;
                                                                        allContraventions.forEach(contravention => {
                                                                            if (contravention.id === item.contraventionId) {
                                                                                contra = contravention;
                                                                            }
                                                                        });
                                                                        return (
                                                                            <button className="dropdown-item text-base px-3" onClick={() => setContraventionsForFailToPayWithinPeriod(contra)}>{contra.name}</button>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className=" col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mt-1 mb-1">Contravention for Overstayed Payment Period</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">
                                                    <div className="form-group mb-0">
                                                        <div className="dropdown ">
                                                            <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-overstay-period" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{contraventionsForOverStayPaymentPeriod === null && <>Select Contravention</>}{contraventionsForOverStayPaymentPeriod !== null && contraventionsForOverStayPaymentPeriod.name}</button>
                                                            <div className="dropdown-menu py-1 edit-dropdown" aria-labelledby="select-overstay-period" >
                                                                <button className="dropdown-item text-base px-3" onClick={() => setContraventionsForOverStayPaymentPeriod(null)}>None</button>
                                                                <div className="bg-white rounded-soft">
                                                                    {siteContraventions !== null && siteContraventions.map((item) => {
                                                                        var contra = null;
                                                                        allContraventions.forEach(contravention => {
                                                                            if (contravention.id === item.contraventionId) {
                                                                                contra = contravention;
                                                                            }
                                                                        });
                                                                        return (
                                                                            <button className="dropdown-item text-base px-3" onClick={() => setContraventionsForOverStayPaymentPeriod(contra)}>{contra.name}</button>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                        <hr className="border-dashed border-bottom-0"></hr>
                                        <div className="row">
                                            <div className="col-12 ">
                                                <h5 className="font-weight-bold mt-1 text-left mb-3">Variable ANPR Settings</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">Variable ANPR Settings</p>
                                            </div>
                                            <div className="col-md-6 col-12 custom-control custom-switch">
                                                <input className="custom-control-input" type="checkbox" id="vary-anpr" checked={variableAnprEnabled} onChange={() => setVariableAnprEnabled(!variableAnprEnabled)} />
                                                <label className="custom-control-label ml-3" htmlFor="vary-anpr"></label>
                                                {variableAnprEnabled && <>Active</>}{!variableAnprEnabled && <>Deactivated</>}
                                            </div>
                                        </div>
                                        {variableAnprEnabled && <>
                                            <div className="row">
                                                <div className=" col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mt-1 mb-1">Max Allowed Parking Duration (mins)</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="number" value={variableAnprMaxAllowedParkingDuration} onChange={(e) => setVariableAnprMaxAllowedParkingDuration(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className=" col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mt-1 mb-1">Variable ANPR Start Time</p>
                                                </div>
                                                <div className=" col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="time" value={variableAnprStart} onChange={(e) => setVariableAnprStart(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className=" col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mt-1 mb-1">Variable ANPR End Time</p>
                                                </div>
                                                <div className=" col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="time" value={variableAnprEnd} onChange={(e) => setVariableAnprEnd(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className=" col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mt-1 mb-1">Consideration Period (mins)</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="number" value={variableAnprConsiderationPeriod} onChange={(e) => setVariableAnprConsiderationPeriod(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className=" col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mt-1 mb-1">Allow List Entry Grace (mins)</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="number" value={variableAnprAllowListEntryGrace} onChange={(e) => setVariableAnprAllowListEntryGrace(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className=" col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mt-1 mb-1">Allow List Exit Grace (mins)</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="number" value={variableAnprAllowListExitGrace} onChange={(e) => setVariableAnprAllowListExitGrace(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className=" col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mt-1 mb-1">Parking Session Limit (mins)</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="number" value={variableAnprParkingSessionLimit} onChange={(e) => setVariableAnprParkingSessionLimit(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className=" col-md-3 col-12">
                                                    <p className="font-weight-semi-bold mt-1 mb-1">No Return Within (mins)</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="number" value={variableAnprNoReturnWithin} onChange={(e) => setVariableAnprNoReturnWithin(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                    </>}
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="row client-edit-buttons">
                                        <div className="col-6 col-sm-auto ml-auto  pl-0">
                                            <div id="dashboard-actions">
                                                <Link className="btn btn-falcon-default btn-sm" to={clientUrl}><i className="fas fa-times"></i> Cancel</Link>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => submit()}>
                                                    <i className="fas fa-check"></i> Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}

export default Site;