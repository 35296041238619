import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Api from '../api/api';
import Enums from '../../utilities/enum';
import Loader from '../shared/loader';

function Clients(props) {
    const [clients, setClients] = useState(null);

    const [type, setType] = useState(null);
    const [status, setStatus] = useState(null);
    const [city, setCity] = useState(null);
    const [search, setSearch] = useState(null);

    const [cityFilterList, setCityFilterList] = useState(null);
    const clientAddUrl = "/organisation/" + props.organisationId + "/client"

    const getClients = function () {
        Api.getClients(
            (data) => setClients(data),
            () => setClients(null),
            props.organisationId,
            props.token
        );
    };

    const setCityList = function () {
        if (clients !== null && clients !== undefined) {
            var list = new Array();
            clients.forEach(client => {
                if (!list.includes(client.townOrCity)) {
                    list.push(client.townOrCity);
                }
            });
            setCityFilterList(list);
        }
    };

    const clearFilters = function () {
        setSearch(null);
        setCity(null);
        setStatus(null);
        setType(null);
    };

    useEffect(() => {
        getClients();
    }, []);

    useEffect(() => {
        setCityList()
    }, [clients]);
    return (
        <>
            {clients === null && <Loader />} {clients && clients !== null && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-1 d-flex align-items-center pr-0">
                                            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Your Clients</h5>
                                        </div>
                                        <div className="col-auto text-right pl-0 filter-buttons-wrapper">
                                        <div className="input-group">
                                            {search === null && <>
                                                <input className="form-control" value={null} type="text" placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                            </>}
                                            {search !== null && <>
                                                <input className="form-control" value={search} type="text" autoFocus={true} placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                            </>}
                                            <div className="input-group-append">
                                                <button className="btn btn-falcon-default btn-sm py-0" onClick={() => clearFilters()}><i className="fas fa-times"></i> Clear</button>
                                            </div>
                                        </div>
                                            <div className="filter-buttons" id="select-city">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-city" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{city && city !== null && <>City: {city}</>}{city === null && <>Filter by City</>}</button>
                                                <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-city" >
                                                    <div className="bg-white py-3 rounded-soft">
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setCity(null)}>All</button>
                                                        {cityFilterList && cityFilterList !== null && cityFilterList.map((currentCity) => {
                                                            if (!(currentCity === null || currentCity === "")) {
                                                                return (
                                                                    <button className="dropdown-item text-base px-3 py-2" onClick={() => setCity(currentCity)}>{currentCity}</button>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="filter-buttons" id="select-type">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-type" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{type !== null && <>Type: {Enums.ClientTypes[type]}</>}{type === null && <>Filter by Type</>} </button>
                                                <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-type" >
                                                    <div className="bg-white py-3 rounded-soft">
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(null)}>All</button>
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(0)}>Managing Agent</button>
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(1)}>Landowner</button>
                                                        {/* <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(2)}>Debt Collector</button> */}
                                                        {/* <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(3)}>Site Enforcer</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="filter-buttons" id="select-status">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{status && <>Status: Active</>}{!status && status !== null && <>Status: Deactivated</>}{status === null && <>Filter by Status</>}</button>
                                                <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-status" >
                                                    <div className="bg-white py-3 rounded-soft">
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(null)}>All</button>
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(true)}>Active</button>
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(false)}>Deactivated</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link className="btn btn-success btn-sm ml-1 nowrap" to={clientAddUrl}><i className="fas fa-plus"></i> Add</Link>
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                    <thead className="bg-200 text-900">
                                        <tr>
                                            <th>Client Name</th>
                                            <th>Town/City</th>
                                            <th>Type</th>
                                            <th className="text-center">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {clients.filter(function (client) {
                                            var townOrCity = new String(client.townOrCity).toLowerCase();
                                            var name = new String(client.name).toLowerCase();
                                            return ((client.townOrCity === city || city === null) && (client.clientType === type || type === null) && (client.isActive === status || status === null) && (search === null || townOrCity.search(search.toLowerCase()) !== -1 || name.search(search.toLowerCase()) !== -1))
                                        }).map((client) => {
                                            var url = "/organisation/"+ props.organisationId+"/client/" + client.id;
                                            return (
                                                <tr>
                                                    <td><Link to={url}><h6 className="mb-0">{client.name}</h6></Link></td>
                                                    <td>{client.townOrCity}</td>
                                                    <td>{Enums.ClientTypes[client.clientType]}</td>
                                                    <td className="text-center">{client.isActive && <><i className="fas fa-check text-success"></i></>}{!client.isActive && <><i className="fas fa-times text-danger"></i></>}</td>
                                                </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}

export default Clients;