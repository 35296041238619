import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Enums from '../../utilities/enum';
import moment from 'moment';
import Loader from '../shared/loader';

function ClientDetails(props) {
    const [clientId, setClientId] = useState(props.match.params.clientId);
    const [organisationName, setOrganisationName] = useState(null);
    const [client, setClient] = useState(null);
    const [sites, setSites] = useState(null);
    const [sitesUrl, setSitesUrl] = useState("");
    const [clientTypes, setClientTypes] = useState([]);

    const [name, setName] = useState("");
    const [type, setType] = useState(null);
    const [companyAddress, setCompanyAddress] = useState("");
    const [postCode, setPostCode] = useState("");
    const [townOrCity, setTownOrCity] = useState("");
    const [county, setCounty] = useState("");
    const [country, setCountry] = useState("");
    const [status, setStatus] = useState(false);
    const [parkfolioUsername, setParkfolioUsername] = useState("");
    const [parkfolioPassword, setParkfolioPassword] = useState("");
    const [isPortalEnabled, setIsPortalEnabled] = useState(false);

    const [notes, setNotes] = useState(null);
    const [note, setNote] = useState("");

    const [companyEmailAddress, setCompanyEmailAddress] = useState("");
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState("");

    const [emailAddress, setEmailAddress] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [contactFirstName, setContactFirstName] = useState("");
    const [contactLastName, setContactLastName] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [isNew, setIsNew] = useState(false);
    const [isInEdit, setIsInEdit] = useState(false);
    const [canSave, setCanSave] = useState(false);

    const [filterStatus, setFilterStatus] = useState(null);
    const [city, setCity] = useState(null);
    const [search, setSearch] = useState(null);

    const [cityFilterList, setCityFilterList] = useState(null);

    const [allTags, setAllTags] = useState(null);
    const [selectedTags, setSelectedTags] = useState(null);

    const [errorMessage, setErrorMessage] = useState("");

    const history = useHistory();

    const clientsUrl = "/organisation/" + props.organisationId + "/clients";

    const getClient = function () {
        Api.getSingleClient(
            (data) => setClient(data),
            () => setClient(null),
            props.organisationId,
            clientId,
            props.token
        );
    }

    const submit = function () {
        if (canSave) {
            Api.addClient(
                (data) => {
                    setClient(data);
                    setErrorMessage("");
                    setIsNew(false);
                    history.push("/organisation/" + props.organisationId + "/client/" + data.id);
                },
                (error) => setErrorMessage(error),
                name, type, companyAddress, postCode, townOrCity, county, country, status, companyEmailAddress, companyPhoneNumber, emailAddress, mobileNumber, contactFirstName, contactLastName, jobTitle, phoneNumber, parkfolioUsername, parkfolioPassword, isPortalEnabled,
                props.organisationId,
                props.token
            );
        } else {
            // validation messages
        }
    }

    const edit = function () {
        if (canSave) {
            Api.editClient(
                () => {
                    setIsInEdit(false);
                    setErrorMessage("");
                    getClient();
                },
                (error) => {
                    setErrorMessage(error)
                },
                name, type, companyAddress, postCode, townOrCity, county, country, status, companyEmailAddress, companyPhoneNumber, emailAddress, mobileNumber, contactFirstName, contactLastName, jobTitle, phoneNumber, parkfolioUsername, parkfolioPassword, isPortalEnabled,
                props.organisationId,
                clientId,
                props.token
            );
        } else {
            // validation messages
        }
    };
    
    const setCityList = function () {
        if (sites !== null) {
            var list = new Array();
            sites.forEach(site => {
                if (!list.includes(site.townOrCity)) {
                    list.push(site.townOrCity);
                }
            });
            setCityFilterList(list);
        }
    };

    const getSites = function () {
        Api.getClientSites(
            (data) => setSites(data),
            (error) => console.log(error),
            clientId,
            props.organisationId,
            props.token
        );
    }

    const setOrganisation = function () {
        var organisations = props.user.organisations;
        organisations.forEach(org => {
            if (org.organisation.id === props.organisationId) {
                setOrganisationName(org.organisation.name);
            }
        });
    };

    const getAllTags = function () {
        Api.getTags(
            (data) => setAllTags(data),
            () => console.log("No Tags Found"),
            props.organisationId,
            props.token
        )
    }

    const updateTag = function (addOrDelete, tag) {
        //if(addOrDelete = false) => we are adding a tag
        if (addOrDelete) {
            Api.deleteClientTag(
                () => getClient(),
                () => getClient(),
                props.organisationId,
                clientId,
                tag.id,
                props.token
            )
        } else {
            Api.addClientTag(
                () => getClient(),
                () => getClient(),
                props.organisationId,
                clientId,
                tag.id,
                props.token
            )
        }
    };

    const addNote = function () {
        if(note !== "" || note !== null){
            Api.addClientNote(
                () => getNotes(),
                (error) => console.log(error),
                note, props.organisationId, clientId, props.token
            )
        }
    };

    const getNotes = function(){
        Api.getClientNotes(
            (data) => setNotes(data),
            (error) => console.log(error),
            props.organisationId, clientId, props.token
        )
    };

    const setTypes = function (types) {
        var tempClientTypes = [];
        var i = 0;
        while (true) {
            if(types[i] === undefined){
                break;
            } else {
                tempClientTypes.push(types[i])
                i++;
            }
        }
        setClientTypes(tempClientTypes);
    };

    const clearFilters = function () {
        setSearch(null);
        setCity(null);
        setFilterStatus(null);
    };

    useEffect(() => {
        if (clientId !== undefined) {
            getClient();
            getNotes();
            getSites();
            setSitesUrl('/organisation/'+props.organisationId+'/client/' + clientId + '/site');
        } else {
            setIsNew(true);
        }
        setOrganisation();
        setTypes(Enums.ClientTypes);
        getAllTags();
    }, []);

    useEffect(() => {
        setCityList()
    }, [sites]);

    useEffect(() => {
        if (client !== null) {
            setName(client.name);
            setType(client.clientType);
            setCompanyAddress(client.addressLine1);
            setTownOrCity(client.townOrCity);
            setCounty(client.county);
            setPostCode(client.postCode);
            setCountry(client.country);
            setCompanyPhoneNumber(client.companyPhoneNumber);
            setCompanyEmailAddress(client.companyEmailAddress);
            setContactFirstName(client.primaryContactFirstName);
            setContactLastName(client.primaryContactSurname);
            setJobTitle(client.primaryContactJobTitle);
            setMobileNumber(client.primaryContactMobile);
            setPhoneNumber(client.primaryContactPhoneNumber);
            setEmailAddress(client.primaryContactEmail);
            setStatus(client.isActive);
            setParkfolioUsername(client.parkfolioUsername);
            setParkfolioPassword(client.parkfolioPassword);
            setIsPortalEnabled(client.isPortalEnabled);
            setSelectedTags(client.tags);
            setSitesUrl('/organisation/'+props.organisationId+'/client/' + client.id + '/site');
        }
        setErrorMessage("");

    }, [client, isInEdit]);

    useEffect(() => {
        if ((name === null || name === "") ||
            (type === null || type === "") ||
            (companyAddress === null || companyAddress === "") ||
            (townOrCity === null || townOrCity === "") ||
            (country === null || country === "") ||
            (postCode === null || postCode === "")) {
            setCanSave(false);
        } else {
            setCanSave(true);
        }
    }, [name, type, companyAddress, townOrCity, postCode, country])

    return (
        <>
            {!client && !isNew && <Loader />} {client && client !== null && !isNew && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col color-code-row">
                                            <h5 className="mb-0">Client - {client.name}</h5>
                                            <div className="tag-container">
                                                {selectedTags !== null && selectedTags !== undefined && selectedTags.map((tag) => {
                                                    const colorStyle = {
                                                        backgroundColor: '#' + tag.colourCode,
                                                    }
                                                    return (
                                                        <>
                                                            <div style={colorStyle} className="tag-colour-disc">
                                                            </div>
                                                            <div className="disc-text">
                                                                {tag.name}
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <Link className="btn btn-falcon-default btn-sm mr-3 py-1" type="button" to={clientsUrl}><i className="fas fa-reply"></i> Back</Link>

                                        <button className="btn btn-falcon-default btn-sm dropdown-toggle py-2 dropdown-caret-none mr-3" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fas fa-ellipsis-h"></i></button>
                                        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-start">
                                            <button type="button" className="dropdown-item text-danger" data-toggle="modal" data-target="#deleteModal">
                                                <i className="fas fa-times"></i> Delete Client</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Details</h5>
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setIsInEdit(!isInEdit)}>
                                                <i className="fas fa-edit"></i> Edit</button>
                                        </div>
                                    </div>
                                </div>
                                {!isInEdit &&
                                    <div className="card-body bg-light border-top details-container">
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Client Name</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{client.name}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Tags</p>
                                            </div>
                                            <div className="col-md-6 col-12  display-flex">{selectedTags !== null && selectedTags !== undefined && selectedTags.map((tag) => {
                                                return (
                                                    <div className="tagged-name">{tag.name}</div>
                                                )
                                            })}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Client Type</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{Enums.ClientTypes[client.clientType]}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Client Address</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{client.addressLine1}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">City or Town</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{client.townOrCity}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">County</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{client.county}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Post Code</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{client.postCode}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Country</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{client.country}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Company Telephone</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{client.companyPhoneNumber}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Company Email Address</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{client.companyEmailAddress}</div>
                                        </div>
                                        <hr className="border-dashed border-bottom-0"></hr>
                                        <div className="row">
                                            <div className="col-md-5 col-12 ">
                                                <h5 className="font-weight-semi-bold text-left mb-3">Primary Contact Details</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">First Name</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{client.primaryContactFirstName}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Last Name</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{client.primaryContactSurname}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Job Title</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{client.primaryContactJobTitle}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Mobile Number</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{client.primaryContactMobile}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Phone Number</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{client.primaryContactPhoneNumber}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Email Address</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{client.primaryContactEmail}</div>
                                        </div>

                                        <hr className="border-dashed border-bottom-0"></hr>
                                        <div className="row">
                                            <div className="col-md-5 col-12 ">
                                                <h5 className="font-weight-semi-bold text-left mb-3">Parkfolio Settings</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Parkfolio Username</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{client.parkfolioUsername}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Parkfolio Password</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{"•".repeat(client.parkfolioPassword?.length ?? 0)}</div>
                                        </div>

                                        <hr className="border-dashed border-bottom-0"></hr>
                                        <div className="row align-items-center">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-1">Status</p>
                                            </div>
                                            <div className="col-md-6 col-12  ">{client.isActive && <i className="fas fa-check text-success"></i>}{!client.isActive && <i className="fas fa-times text-danger"></i>}</div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-1">Portal Enabled</p>
                                            </div>
                                            <div className="col-md-6 col-12  ">{client.isPortalEnabled && <i className="fas fa-check text-success"></i>}{!client.isPortalEnabled && <i className="fas fa-times text-danger"></i>}</div>
                                        </div>
                                    </div>
                                } {isInEdit &&
                                    <>
                                    <div className="card-body bg-light border-top details-container">
                                        {errorMessage !== "" && <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>}
                                        <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">Client Name<span className="req"></span></p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className=" col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mt-1 mb-1"><button className="button-no-style tag-list-button collapsed" id="tags" type="button" data-toggle="collapse" data-target="#tagList" aria-expanded="true" aria-controls="tagList">Tags <i className="fas fa-chevron-down"></i></button></p>
                                                </div>
                                                <div className=" col-md-6 col-12">
                                                    <div className="collapse checkbox-dropdown" id="tagList" aria-labelledby="Tag List" data-parent="#tags">
                                                        <div className="row justify-content-start">
                                                            {allTags !== null && allTags !== undefined && allTags.map((tag) => {
                                                                var isSelected = false;
                                                                selectedTags.forEach(selectedTag => {
                                                                    if (selectedTag.id === tag.id) {
                                                                        isSelected = true;
                                                                    }
                                                                });
                                                                return (
                                                                    <div className=" col-6">
                                                                        <div className="form-group mb-0">
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input className="custom-control-input" type="checkbox" id={tag.name} checked={isSelected} onChange={(e) => { updateTag(isSelected, tag) }} />
                                                                                <label className="custom-control-label" htmlFor={tag.name}>{tag.name}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">Client Type</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">
                                                    <div className="form-group mb-0">
                                                        <div className="dropdown ">
                                                            <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-type" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{Enums.ClientTypes[type]}</button>
                                                            <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-type" >
                                                                <div className="bg-white py-2 rounded-soft">
                                                                    {clientTypes.map((type, i = 0) => {
                                                                        i++;
                                                                        return (
                                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(i - 1)}>{type}</button>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">Client Address<span className="req"></span></p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="text" value={companyAddress} onChange={(e) => setCompanyAddress(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">City or Town<span className="req"></span></p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="text" value={townOrCity} onChange={(e) => setTownOrCity(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">County</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="text" value={county} onChange={(e) => setCounty(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">Post Code<span className="req"></span></p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="text" value={postCode} onChange={(e) => setPostCode(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">Country<span className="req"></span></p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="text" value={country} onChange={(e) => setCountry(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">Client Telephone</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="text" value={companyPhoneNumber} onChange={(e) => setCompanyPhoneNumber(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">Client Email Address</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="text" value={companyEmailAddress} onChange={(e) => setCompanyEmailAddress(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="border-dashed border-bottom-0"></hr>
                                            <div className="row">
                                                <div className="col-md-5 col-12 ">
                                                <h5 className="font-weight-semi-bold my-1 text-left">Primary Contact Details</h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mt-1 mb-1">First Name</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="text" value={contactFirstName} onChange={(e) => setContactFirstName(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mt-1 mb-1">Last Name</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="text" value={contactLastName} onChange={(e) => setContactLastName(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">Job Title</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="text" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">Mobile Number</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="text" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">Phone Number</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">Email Address</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="text" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <hr className="border-dashed border-bottom-0"></hr>
                                            <div className="row">
                                                <div className="col-md-5 col-12 ">
                                                <h5 className="font-weight-semi-bold my-1 text-left">Parkfolio Settings</h5>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mt-1 mb-1">Parkfolio Username</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="text" value={parkfolioUsername} onChange={(e) => setParkfolioUsername(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mt-1 mb-1">Parkfolio Password</p>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group mb-1">
                                                        <input className="form-control mb-0" type="text" value={parkfolioPassword} onChange={(e) => setParkfolioPassword(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>


                                            <hr className="border-dashed border-bottom-0"></hr>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mb-1">Status</p>
                                                </div>
                                                <div className="col-md-6 col-12 custom-control custom-switch">
                                                    <input className="custom-control-input" type="checkbox" id="switch1" checked={status} onChange={() => setStatus(!status)} />
                                                    <label className="custom-control-label ml-3" htmlFor="switch1"></label>
                                                    {status && <>Active</>}{!status && <>Deactivated</>}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mb-1">Portal Enabled</p>
                                                </div>
                                                <div className="col-md-6 col-12 custom-control custom-switch">
                                                    <input className="custom-control-input" type="checkbox" id="portal-enabled" checked={isPortalEnabled} onChange={() => setIsPortalEnabled(!isPortalEnabled)} />
                                                    <label className="custom-control-label ml-3" htmlFor="portal-enabled"></label>
                                                    {isPortalEnabled && <>Active</>}{!isPortalEnabled && <>Deactivated</>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer border-top ">
                                            <div className="row client-edit-buttons">
                                                <div className="col-12 col-sm-auto ml-auto  pl-0">
                                                    <div id="dashboard-actions">
                                                        <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setIsInEdit(false)}>
                                                            <i className="fas fa-times"></i> Cancel</button>
                                                        <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => edit()}>
                                                            <i className="fas fa-check"></i> Confirm</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-2">
                                            <h5 className="mb-0">
                                                <button className="button-no-style button-card-header collapsed" id="note-button" type="button" data-toggle="collapse" data-target="#notes" aria-expanded="true" aria-controls="site-details">
                                                    Notes <i className="fas fa-chevron-down"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div className="col-2 text-right pl-0 filter-buttons-wrapper">
                                            <button className="btn btn-success btn-sm ml-1" data-toggle="modal" data-target="#note-modal" ><i className="fas fa-plus"></i> Add</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="note-modal" tabIndex="-1" role="dialog" aria-labelledby="On Hold Modal" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="deleteModalLabel">Add a note to this client.</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-2">Note:</p>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group mb-0">
                                                        <textarea className="form-control mb-0" rows={4} value={note} onChange={(e) => setNote(e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                            <button type="button" className="btn btn-success btn-sm ml-2" data-dismiss="modal" onClick={() => addNote()}>
                                                Confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div className="card-body bg-light border-top details-container collapse" id="notes" aria-labelledby="Allow List" data-parent="#note-button">
                                <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                    <thead className="bg-200 text-900">
                                        <tr>
                                            <th>Origin</th>
                                            <th>Note</th>
                                            <th className="text-center">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {notes !== null && notes.map((item) => {
                                            return (
                                                <>
                                                    <tr className="align-items-center">
                                                        <td>{item.note !== null &&
                                                                <h6 classname="pt-1">
                                                                    {item.userName}
                                                                </h6>}
                                                        </td>
                                                        <td>{item.note}</td>
                                                        <td className="text-center">{moment(item.timestamp).format("DD/MM/yyyy")} {moment(item.timestamp).format("HH:mm")}</td>
                                                    </tr>
                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                </div>
                            </div>

                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row justify-content-between align-items-center">
                                        <div className="col-2">
                                            <h5 className="mb-0">
                                                <button className="button-no-style button-card-header collapsed" id="sites" type="button" data-toggle="collapse" data-target="#site-list" aria-expanded="true" aria-controls="site-list">
                                                    Sites <i className="fas fa-chevron-down"></i>
                                                </button>
                                            </h5>
                                        </div>
                                        <div className="col-auto filter-buttons-wrapper">
                                        <div className="input-group mr-1">
                                            {search === null && <>
                                                <input className="form-control" value={null} type="text" placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                            </>}
                                            {search !== null && <>
                                                <input className="form-control" value={search} type="text" autoFocus={true} placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                            </>}
                                            <div className="input-group-append">
                                                <button className="btn btn-falcon-default btn-sm py-0" onClick={() => clearFilters()}><i className="fas fa-times"></i> Clear</button>
                                            </div>
                                        </div>
                                            <div className="filter-buttons" id="select-city">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-city" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{city && city !== null && <>City: {city}</>}{city === null && <>Filter by City</>}</button>
                                                <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-city" >
                                                    <div className="bg-white py-3 rounded-soft">
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setCity(null)}>All</button>
                                                        {cityFilterList && cityFilterList !== null && cityFilterList.map((currentCity) => {
                                                            return (
                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setCity(currentCity)}>{currentCity}</button>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="filter-buttons" id="select-status">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{filterStatus && <>Status: Active</>}{!filterStatus && filterStatus !== null && <>Status: Deactivated</>}{filterStatus === null && <>Filter by Status</>}</button>
                                                <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-status" >
                                                    <div className="bg-white py-3 rounded-soft">
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setFilterStatus(null)}>All</button>
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setFilterStatus(true)}>Active</button>
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setFilterStatus(false)}>Deactivated</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link to={sitesUrl} className="btn btn-success btn-sm ml-1 nowrap"><i className="fas fa-plus"></i> Add</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container collapse" id="site-list" aria-labelledby="Sites List" data-parent="#sites">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer dataTable">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th>Name</th>
                                                <th>Town/City</th>
                                                <th>Site Enforcer</th>
                                                <th>Contract Expiry</th>
                                                <th>Site Code</th>
                                                <th className="text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sites && sites !== null &&

                                                sites.filter(function (site) {
                                                    var townOrCity = new String(site.townOrCity).toLowerCase();
                                                    var name = new String(site.name).toLowerCase();
                                                    return ((site.isActive === filterStatus || filterStatus === null) && (search === null || townOrCity.search(search.toLowerCase()) !== -1 || name.search(search.toLowerCase()) !== -1))
                                                }).map((site) => {
                                                    var url = sitesUrl + '/' + site.id;
                                                    return (
                                                        <tr>
                                                            <td><Link to={url}><h6>{site.name}</h6></Link></td>
                                                            <td>{site.townOrCity}</td>
                                                            <td>{organisationName}</td>
                                                            <td>{site.contractExpiry !== null && <>{moment(site.contractExpiry).format("DD/MM/yyyy")}</>}</td>
                                                            <td>{site.siteCode}</td>
                                                            <td className="text-center">{site.isActive && <><i className="fas fa-check text-success"></i></>}{!site.isActive && <><i className="fas fa-times text-danger"></i></>}</td>
                                                        </tr>)
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="deleteModalLabel">Are you sure you want to delete this client?</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                This will remove any sites listed below:
                                <ul>
                                    {sites && sites !== null &&

                                        sites.map((site) => {
                                            return (
                                                <li>{site.name}</li>)
                                        })}
                                </ul>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                <button type="button" className="btn btn-danger btn-sm ml-2" data-dismiss="modal" onClick={() => Api.deleteClient(() => history.push(clientsUrl), props.organisationId, clientId, props.token)}>
                                    Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>} {isNew && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="col-md-5 col-12 col-sm-auto d-flex align-items-center pr-0">
                                        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Add new Client</h5>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                {errorMessage !== "" && <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>}
                                    <div className="row align-items-center">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Client Name<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={name} autoFocus={true} onChange={(e) => setName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Tags</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <p className="font-weight-semi-bold mb-1">You will be able to add tags once the client has been created.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Client Type</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            <div className="form-group mb-0">
                                                <div className="dropdown">
                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-type" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{type !== null && Enums.ClientTypes[type]}{type === null && <>Select the Client Type</>}</button>
                                                    <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-type" >
                                                        <div className="bg-white py-2 rounded-soft">
                                                            {clientTypes.map((type, i = 0) => {
                                                                i++;
                                                                return (
                                                                    <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(i - 1)}>{type}</button>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Client Address<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={companyAddress} onChange={(e) => setCompanyAddress(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">City or Town<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={townOrCity} onChange={(e) => setTownOrCity(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">County</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={county} onChange={(e) => setCounty(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Post Code<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={postCode} onChange={(e) => setPostCode(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Country<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={country} onChange={(e) => setCountry(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Client Telephone</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={companyPhoneNumber} onChange={(e) => setCompanyPhoneNumber(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Client Email Address</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={companyEmailAddress} onChange={(e) => setCompanyEmailAddress(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="border-dashed border-bottom-0"></hr>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <h5 className="font-weight-semi-bold my-1 text-left">Primary Contact Details</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">First Name</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={contactFirstName} onChange={(e) => setContactFirstName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Last Name</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={contactLastName} onChange={(e) => setContactLastName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Job Title</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Mobile Number</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Phone Number</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Email Address</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Status</p>
                                        </div>
                                        <div className="col-md-6 col-12 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="switch1" checked={status} onChange={() => setStatus(!status)} />
                                            <label className="custom-control-label ml-3" htmlFor="switch1"></label>
                                            {status && <>Active</>}{!status && <>Deactivated</>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Portal Enabled</p>
                                        </div>
                                        <div className="col-md-6 col-12 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="portal-enabled" checked={isPortalEnabled} onChange={() => setIsPortalEnabled(!isPortalEnabled)} />
                                            <label className="custom-control-label ml-3" htmlFor="portal-enabled"></label>
                                            {isPortalEnabled && <>Active</>}{!isPortalEnabled && <>Deactivated</>}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="row client-edit-buttons">
                                        <div className="col-12 col-sm-auto ml-auto  pl-0">
                                            <div id="dashboard-actions">
                                                <Link className="btn btn-falcon-default btn-sm" to="/clients"><i className="fas fa-times"></i> Cancel</Link>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => submit()}>
                                                    <i className="fas fa-check"></i> Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}

export default ClientDetails;