import moment from 'moment';

export const DatePicker = (props) => {
    return (
        <div className="date-picker-container">
            <label class="col-form-label col-form-label-sm">{props.title}</label>
            <div className="form-group mb-0">
                <input
                    className="form-control form-control-sm"
                    type="date"
                    value={moment(props.date).format('yyyy-MM-DD')}
                    onChange={e => props.handleChange(e.target.value)}
                />
            </div>
        </div>
    )
}