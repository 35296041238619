import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Loader from '../shared/loader';

function Tags(props) {
    const [organisationId, setOrganisationId] = useState(props.match.params.organisationId);
    const [tags, setTags] = useState(null);
    const [tagUrl, setTagUrl] = useState("");

    const getTags = function () {
        Api.getTags(
            (data) => setTags(data),
            (error) => console.log(error),
            organisationId,
            props.token
        )
    }

    useEffect(() => {
        if (organisationId !== undefined) {
            getTags();
            setTagUrl("/organisation/" + organisationId + "/tag-settings/tag")
        }
    }, [])

    return (
        <>
            {!tags && <Loader />} {tags && tags !== null && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Tags</h5>
                                        </div>
                                        <div className="col ml-auto text-right pl-0 filter-buttons-wrapper">
                                            <Link className="btn btn-success btn-sm mr-1 ml-1" to={tagUrl}><i className="fas fa-plus"></i> Add</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th>Name</th>
                                                <th className="text-center">Colour</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tags && tags.map((tag) => {
                                                const colorStyle = {
                                                    backgroundColor: '#' + tag.colourCode,
                                                }
                                                const tagUrl = "/organisation/" + organisationId + "/tag-settings/tag/" + tag.id;
                                                return (
                                                    <tr>
                                                        <td><Link to={tagUrl}>{tag.name}</Link></td>
                                                        <td className="text-center"><div style={colorStyle} className="tag-colour-disc"></div></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}
export default Tags;