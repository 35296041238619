import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Loader from '../shared/loader';

function Organisation(props) {
    const [organisationId, setOrganisationId] = useState(props.match.params.organisationId);
    const [organisation, setOrganisation] = useState(null);
    const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);

    const [isNew, setIsNew] = useState(false);
    const [isInEdit, setIsInEdit] = useState(false);
    const [canSave, setCanSave] = useState(false);

    const [organisationName, setOrganisationName] = useState("");
    const [debtCollectionMethod, setDebtCollectionMethod] = useState("");
    const [status, setStatus] = useState(false);
    const [shardName, setShardName] = useState("");

    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [addressLine3, setAddressLine3] = useState("");
    const [postCode, setPostCode] = useState("");
    const [townOrCity, setTownOrCity] = useState("");

    const [emailAddress, setEmailAddress] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [contactName, setContactName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");

    const [organisationSettingsUrl, setOrganisationSettingsUrl] = useState("");
    const orgsUrl = "/organisation/" + organisationId + "/organisations";
    const [errorMessage, setErrorMessage] = useState("");

    const getOrganisation = function () {
        Api.getOrganisation(
            (data) => setOrganisation(data),
            (error) => console.log(error),
            organisationId,
            props.token
        )
    };

    const getUser = function () {
        Api.getUser((data) => setIsGlobalAdmin(data.isGlobalAdmin),
            () => setIsGlobalAdmin(false),
            props.token)
    };

    const submit = function () {
        if (canSave && shardName !== null && shardName !== "") {
            Api.addOrganisation(
                (data) => {
                    setOrganisation(data);
                    setIsNew(false);
                    setErrorMessage("");
                },
                (error) => setErrorMessage(error),
                organisationName, debtCollectionMethod, status, shardName, addressLine1, addressLine2, addressLine3, postCode, townOrCity, emailAddress, mobileNumber, contactName, phoneNumber,
                props.token
            );
        }
    };

    const edit = function () {
        if (canSave) {
            Api.editOrganisation(
                (data) => {
                    setIsInEdit(false);
                    setOrganisation(data);
                    setErrorMessage("");
                },
                (error) => setErrorMessage(error),
                organisationName, debtCollectionMethod, status, addressLine1, addressLine2, addressLine3, postCode, townOrCity, emailAddress, mobileNumber, contactName, phoneNumber,
                organisationId,
                props.token
            );
        }
    };

    useEffect(() => {
        if ((organisationName === null || organisationName === "") ||
            // (debtCollectionMethod === null || debtCollectionMethod === "")||
            (contactName === null || contactName === "") ||
            (emailAddress === null || emailAddress === "") ||
            (postCode === null || postCode === "") ||
            (townOrCity === null || townOrCity === "") ||
            (addressLine1 === null || addressLine1 === "")) {
            setCanSave(false);
        } else {
            setCanSave(true);
        }
    }, [organisationName, shardName, contactName, emailAddress, postCode, townOrCity, addressLine1, debtCollectionMethod]);

    useEffect(() => {
        if (organisation !== null) {
            setOrganisationId(organisation.id);
            setOrganisationName(organisation.name)
            //  setDebtCollectionMethod(organisation.debtCollectionMethod)
            setStatus(organisation.isActive)
            setAddressLine1(organisation.addressLine1)
            setAddressLine2(organisation.addressLine2)
            setAddressLine3(organisation.addressLine3)
            setPostCode(organisation.postCode)
            setTownOrCity(organisation.townOrCity)
            setEmailAddress(organisation.contactEmailAddress)
            setMobileNumber(organisation.contactMobileNumber)
            setContactName(organisation.contactName)
            setPhoneNumber(organisation.contactPhoneNumber)
        }
        setErrorMessage("");
    }, [organisation, isInEdit]);

    useEffect(() => {
        if (organisationId !== undefined) {
            getOrganisation();
            setOrganisationSettingsUrl("/organisation/" + organisationId + "/organisation-settings")
        } else {
            setIsNew(true);
        }
        getUser();
    }, []);

    return (<>
        {!organisation && !isNew && <Loader />} {organisation && organisation !== null && <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h5 className="mb-0">Organisation - {organisation.name}</h5>
                                    </div>
                                    <Link className="btn btn-falcon-default btn-sm mr-3 py-1" type="button" to={orgsUrl}><i className="fas fa-reply"></i> Back</Link>
                                    {isGlobalAdmin && <>
                                        <button className="btn btn-falcon-default btn-sm py-2 dropdown-toggle dropdown-caret-none mr-3" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fas fa-ellipsis-h"></i></button>
                                        <div className="dropdown-menu dropdown-menu-right ml-3" x-placement="bottom-start">
                                            <a className="dropdown-item" href="/"><i className="fas fa-random"></i> Select Organisation</a>
                                            <div className="dropdown-divider"></div>
                                            <Link className="dropdown-item" to={organisationSettingsUrl}><i className="fas fa-cog"></i> Settings</Link>
                                            <button type="button" className="dropdown-item text-danger" data-toggle="modal" data-target="#deleteModal">
                                                <i className="fas fa-times"></i> Delete Organisation
                                            </button>
                                        </div></>}
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3 ">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h5 className="mb-0">Details</h5>
                                    </div>
                                    <div className="col-auto">
                                        <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setIsInEdit(!isInEdit)}>
                                            <i className="fas fa-edit"></i> Edit</button>
                                    </div>
                                </div>
                            </div>
                            {!isInEdit &&
                                <div className="card-body bg-light border-top details-container">
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-3">Name</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{organisation.name}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-3">Debt Collection Method</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{organisation.debtCollectionMethod}</div>
                                    </div>
                                    <hr className="border-dashed border-bottom-0"></hr>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-3">Address</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            <div>{organisation.addressLine1}</div>
                                            <div>{organisation.addressLine2}</div>
                                            <div>{organisation.addressLine3}</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Town or City</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{organisation.townOrCity}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Post Code</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{organisation.postCode}</div>
                                    </div>
                                    <hr className="border-dashed border-bottom-0"></hr>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Contact Name</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{organisation.contactName}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Contact Email Address</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{organisation.contactEmailAddress}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Contact Mobile Number</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{organisation.contactMobileNumber}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Contact Phone Number</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{organisation.contactPhoneNumber}</div>
                                    </div>
                                    <hr className="border-dashed border-bottom-0"></hr>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-3">Status</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{organisation.isActive && <i className="fas fa-check text-success"></i>}{!organisation.isActive && <i className="fas fa-times text-danger"></i>}</div>
                                    </div>
                                </div>
                            } {isInEdit &&
                                <>
                                    <div className="card-body bg-light border-top details-container">
                                        {errorMessage !== "" && <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>}
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-1">Name<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={organisationName} onChange={(e) => setOrganisationName(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-1">Debt Collection Method</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={debtCollectionMethod} onChange={(e) => setDebtCollectionMethod(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="border-dashed border-bottom-0"></hr>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-1">Address Line 1<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={addressLine1} onChange={(e) => setAddressLine1(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-1">Address Line 2</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-1">Address Line 3</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={addressLine3} onChange={(e) => setAddressLine3(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-1">Town or City<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={townOrCity} onChange={(e) => setTownOrCity(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-1">Post Code<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={postCode} onChange={(e) => setPostCode(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="border-dashed border-bottom-0"></hr>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-1">Contact Name<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={contactName} onChange={(e) => setContactName(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-1">Contact Email Address<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-1">Contact Mobile Number</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-1">Contact Phone Number</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="border-dashed border-bottom-0"></hr>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Status</p>
                                            </div>
                                            <div className="col-md-6 col-12 custom-control custom-switch">
                                                <input className="custom-control-input" type="checkbox" id="switch1" checked={status} onChange={() => setStatus(!status)} />
                                                <label className="custom-control-label ml-3" for="switch1"></label>
                                                {status && <>Active</>}{!status && <>Deactivated</>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer border-top ">
                                        <div className="row client-edit-buttons">
                                            <div className="col-12 col-sm-auto ml-auto  pl-0">
                                                <div id="dashboard-actions">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setIsInEdit(false)}>
                                                        <i className="fas fa-times"></i> Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => edit()}>
                                                        <i className="fas fa-check"></i> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            }
                        </div>

                    </div>
                </div>
            </div>
            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteModalLabel">Are you sure you want to delete this organisation?</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            This will remove any clients or sites created within this organisation.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger btn-sm ml-2" data-dismiss="modal">
                                Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>} {!organisation && isNew && <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="col-md-5 col-12 col-sm-auto d-flex align-items-center pr-0">
                                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Add new Organisation</h5>
                                </div>
                            </div>
                            <div className="card-body bg-light border-top details-container">
                                {errorMessage !== "" && <div className="row">
                                    <div className="offset-md-3 col-md-6">
                                        <p className="error-message">
                                            {errorMessage}
                                        </p>
                                    </div>
                                </div>}
                                <div className="row">
                                    <div className="col-md-5 col-12 ">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Organisation Name<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-5 col-12">
                                        <div className="form-group mb-0">
                                            <input className="form-control mb-1" type="text" autoFocus={true} value={organisationName} onChange={(e) => setOrganisationName(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 col-12 ">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Debt Collection Method</p>
                                    </div>
                                    <div className="col-md-5 col-12">
                                        <div className="form-group mb-0">
                                            <input className="form-control mb-1" type="text" value={debtCollectionMethod} onChange={(e) => setDebtCollectionMethod(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 col-12 ">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Database Name<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-5 col-12">
                                        <div className="form-group mb-0">
                                            <input className="form-control mb-1" type="text" value={shardName} onChange={(e) => setShardName(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <hr className="border-dashed border-bottom-0"></hr>
                                <div className="row">
                                    <div className="col-md-5 col-12 ">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Address Line 1<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-5 col-12">
                                        <div className="form-group mb-0">
                                            <input className="form-control mb-1" type="text" value={addressLine1} onChange={(e) => setAddressLine1(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 col-12 ">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Address Line 2</p>
                                    </div>
                                    <div className="col-md-5 col-12">
                                        <div className="form-group mb-0">
                                            <input className="form-control mb-1" type="text" value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 col-12 ">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Address Line 3</p>
                                    </div>
                                    <div className="col-md-5 col-12">
                                        <div className="form-group mb-0">
                                            <input className="form-control mb-1" type="text" value={addressLine3} onChange={(e) => setAddressLine3(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 col-12 ">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Town or City<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-5 col-12">
                                        <div className="form-group mb-0">
                                            <input className="form-control mb-1" type="text" value={townOrCity} onChange={(e) => setTownOrCity(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 col-12 ">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Post Code<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-5 col-12">
                                        <div className="form-group mb-0">
                                            <input className="form-control mb-1" type="text" value={postCode} onChange={(e) => setPostCode(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <hr className="border-dashed border-bottom-0"></hr>
                                <div className="row">
                                    <div className="col-md-5 col-12 ">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Contact Name<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-5 col-12">
                                        <div className="form-group mb-0">
                                            <input className="form-control mb-1" type="text" value={contactName} onChange={(e) => setContactName(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 col-12 ">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Email Address<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-5 col-12">
                                        <div className="form-group mb-0">
                                            <input className="form-control mb-1" type="text" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 col-12 ">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Mobile Number</p>
                                    </div>
                                    <div className="col-md-5 col-12">
                                        <div className="form-group mb-0">
                                            <input className="form-control mb-1" type="text" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 col-12 ">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Phone Number</p>
                                    </div>
                                    <div className="col-md-5 col-12">
                                        <div className="form-group mb-0">
                                            <input className="form-control mb-1" type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <hr className="border-dashed border-bottom-0"></hr>
                                <div className="row">
                                    <div className="col-md-5 col-12 ">
                                        <p className="font-weight-semi-bold mb-1">Status</p>
                                    </div>
                                    <div className="col-md-5 col-12 custom-control custom-switch">
                                        <input className="custom-control-input" type="checkbox" id="switch1" checked={status} onChange={() => setStatus(!status)} />
                                        <label className="custom-control-label ml-3" htmlFor="switch1"></label>
                                        {status && <>Active</>}{!status && <>Deactivated</>}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer border-top ">
                                <div className="row client-edit-buttons">
                                    <div className="col-12 col-sm-auto ml-auto  pl-0">
                                        <div id="dashboard-actions">
                                            <Link className="btn btn-falcon-default btn-sm" to="/organisations"><i className="fas fa-times"></i> Cancel</Link>
                                            <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => submit()}>
                                                <i className="fas fa-check"></i> Confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>}
    </>)
}
export default Organisation;