import Plot from 'react-plotly.js';

export const ReportsCharts = ({ data }) => {
    return (
        <div id="chart-div" className="mb-3 w-100" style={ { width: "100%", height: "450px" } }>
            <Plot
                style={ { width: "100%" } }
                data={ data }
                useResizeHandler={ true }
                layout={ { height: 450, autosize: true, hoverlabel: { bgcolor: "#FFF" } } }
                config={ { displayModeBar: false, responsive: true } }
            />
        </div>
    )
}