import React, { useState, useEffect } from 'react';

var cloneDeep = require('lodash.clonedeep');

export default function UploadFiles(props) {

    const [uploads, setUploads] = useState([]);
    const [style, setStyle] = useState(null);

    const onSelectFiles = function (e) {

        if (e.target.files && e.target.files.length > 0) {

            let newUploads = [];
            for (let i = 0; i < e.target.files.length; i++) {
                newUploads.push({ file: e.target.files[i] });
            }

            setUploads(uploads.concat(newUploads));
        }
        e.target.value = null;
    };

    const selectFiles = function () {
        document.getElementById("upload-files-select").click();
    };

    const done = function () {

        setUploads([]);

        if (props.onClose) {
            props.onClose();
        }
    };

    useEffect(() => {
        if (props.hideDone) {
            const newStyle = {
                border: 'none',
            }
            setStyle(newStyle);
        }
    }, []);

    return <div className="modal-dialog" role="document">
        <div className="modal-content" style={style}>
            <div className="modal-header">
                <h4 className="modal-title">Add {props.type} from my device</h4>
            </div>
            <div className="modal-body">
                <div className="upload-files-wrapper">
                    <p className="text-center">
                        <button className="btn btn-falcon-default" onClick={selectFiles}>Select {props.type} from my device</button>
                    </p>
                    <input type="file" id="upload-files-select" name="upload-files-select" onChange={e => onSelectFiles(e)} style={{ display: 'none' }} />
                    {uploads && uploads !== null && uploads.length > 0 &&
                        <div className="files-wrapper">
                            {uploads.map((upload) =>
                                <UploadFile file={upload.file} onSuccess={props.onSuccess} organisationId={props.organisationId} resizeImage={props.resizeImage}/>
                            )}
                        </div>
                    }
                </div>
            </div>
            {!props.hideDone &&
                <div className="modal-footer">
                    <div>
                        <button type="button" className="btn btn-falcon-default" data-dismiss="modal" onClick={done}>Done</button>
                    </div>
                </div>}
        </div>
    </div>;
}

function UploadFile(props) {

    const [documentId, setDocumentId] = useState(null);
    const [progress, setProgress] = useState(1);
    const [isComplete, setIsComplete] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {

        const payload = new FormData();
        payload.append('file', props.file);

        const xhr = new XMLHttpRequest();


        xhr.upload.onerror = (e) => {
            setIsError(true);
            setIsComplete(false);
        };

        xhr.onreadystatechange = function () {

            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    setIsError(false);
                    setIsComplete(true);

                    if (props.onSuccess) {

                        let result = JSON.parse(xhr.response);
                        setDocumentId(result.attachmentId);
                        props.onSuccess(result);
                    }
                } else {
                    setIsError(true);
                    setIsComplete(false);
                }
            }
        };

        xhr.upload.onprogress = (e) => {

            let done = e.position || e.loaded;
            let total = e.totalSize || e.total;
            let perc = Math.max(1, Math.floor(done / total * 1000) / 10);
            setProgress(perc);
        };

        if (props.resizeImage) {
            xhr.open('POST', "https://api.advancedparking.tech/" + props.organisationId +'/attachments/upload-and-resize-image', true);
        } else {
            xhr.open('POST', "https://api.advancedparking.tech/" + props.organisationId +'/attachments/upload', true);
        }
        xhr.send(payload);

    }, []);

    return <div className="file-wrapper row justify-content-between align-items-center">
        <div className="col-10">
            <p className="mb-0 nowrap w-auto pl-1">{props.file.name}</p>
            {isError &&
                <div className="text-danger">
                    <i className="fas fa-times"></i> Upload failed.</div>
            }
        </div>
        {!isError && !isComplete &&
            <div className="col-12">
                <div className="w-100 px-1">
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ "width": progress + "%" }} />
                    </div>
                </div>
            </div>
        }
        <div className="col-2">
            {isComplete && !isError && <i className="fas fa-check text-success mx-1"></i>}
        </div>
    </div>;
}