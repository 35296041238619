import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Api from '../api/api';
import Enums from '../../utilities/enum';
import StringHelpers from '../../utilities/stringHelpers';
import { SketchPicker } from 'react-color';

export default function ItemDialog(props) {

    const [name, setName] = useState(props.item ? props.item.name : "");
    const [x, setX] = useState(props.item ? props.item.x : 0);
    const [y, setY] = useState(props.item ? props.item.y : 0);
    const [height, setHeight] = useState(props.item ? props.item.height : 0);
    const [width, setWidth] = useState(props.item ? props.item.width: 0);
    const [page, setPage] = useState(props.item ? props.item.page : 0);
    const [content, setContent] = useState(props.item ? props.item.content : "");
    const [itemType, setItemType] = useState(props.item ? props.item.letterTemplateItemType : 0);

    const [font, setFont] = useState(props.item ? props.item.font : "Arial");
    const [fontSize, setFontSize] = useState(props.item ? props.item.fontSize : 10);
    const [fontColour, setFontColour] = useState(props.item ? props.item.fontColour : "#000000");
    const [colourStyle, setColourStyle] = useState(null);
    const [lineSpacing, setLineSpacing] = useState(props.item ? props.item.lineSpacing : 1.0);
    const [wrapText, setWrapText] = useState(props.item ? props.item.wrapText : false);
    
    const [placeholders, setPlaceholders] = useState(null);

    const getPlaceholders = function(){
        Api.getTemplatePlaceholders(
            (data) => setPlaceholders(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        )
    };
    
    const handleColorChange = function (color) {
        setFontColour(color.hex.substring(0, 7));
    };

    
    const addPlaceholder = function (text, index) {
        var input = null;

        if (index === null) {
            input = document.getElementById('content-input');
        } else {
            var id = 'content-input-' + index;
            input = document.getElementById(id);
        }

        let textToInsert = ' ' + text + ' ';
        let cursorPosition = input.selectionStart;
        let textBeforeCursorPosition = input.value.substring(0, cursorPosition);
        let textAfterCursorPosition = input.value.substring(cursorPosition);
        var newContent = textBeforeCursorPosition + textToInsert + textAfterCursorPosition;

        setContent(newContent);
    };

    const cancel = function() {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    const save = function() {
        if (props.onSave) {
            props.onSave({ name, x, y, height, width, page, content, letterTemplateItemType: itemType, font, fontSize, fontColour, lineSpacing, wrapText});
        }
    };

    useEffect(() => {
        getPlaceholders();
    }, []);

    useEffect(() => {
        const style = {
            backgroundColor: fontColour,
            borderColor: '#000000',
            border: '1px solid',
        };

        setColourStyle(style);
    }, [fontColour]);


    return <Modal show size="xl">
        <Modal.Header>
        <Modal.Title>Edit Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row mb-1">
                <div className="col-4">
                    <div className="row">
                        <div className="col-12">
                            <p className="font-weight-semi-bold  mt-1 mb-1">Name</p>
                            <div className="form-group mb-1">
                                <input className="form-control mb-0" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="row">
                        <div className="col-12">
                            <p className="font-weight-semi-bold mb-1 mt-1">Item Type</p>
                            <div className="form-group mb-1">
                                <div className="dropdown ">
                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-0 edit-dropdown" id="select-type" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{itemType !== null && <>{Enums.LetterTemplateItemType[itemType]}</>}{itemType === null && <>Select Item Type</>}</button>
                                    <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-type" >
                                        <div className="bg-white py-2 rounded-soft">
                                            {Object.keys(Enums.LetterTemplateItemType).map((item) => 
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setItemType(Number(item))}>{Enums.LetterTemplateItemType[item]}</button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="row">
                        <div className="col-12">
                            <p className="font-weight-semi-bold  mt-1 mb-1">Page</p>
                            <div className="form-group mb-1">
                                <input className="form-control mb-0" type="number" value={page} onChange={(e) => setPage(Number(e.target.value))} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {itemType === 0 &&
            <>
            <div className="row">
                <div className="col-2">
                    <div className="row">
                        <div className="col-12">
                            <p className="font-weight-semi-bold mt-1 mb-1">X</p>
                            <div className="form-group mb-1">
                                <input className="form-control mb-0" type="number" value={x} onChange={(e) => setX(Number(e.target.value))} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-2">
                    <div className="row">
                        <div className="col-12">
                            <p className="font-weight-semi-bold  mt-1 mb-1">Y</p>
                            <div className="form-group mb-1">
                                <input className="form-control mb-0" type="number" value={y} onChange={(e) => setY(Number(e.target.value))} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="row">
                        <div className="col-12">
                            <p className="font-weight-semi-bold  mt-1 mb-1">Height</p>
                            <div className="form-group mb-1">
                                <input className="form-control mb-0" type="number" value={height} onChange={(e) => setHeight(Number(e.target.value))} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="row justify-content-end">
                        <div className="col-12">
                            <p className="font-weight-semi-bold my-1">Width</p>
                            <div className="form-group mb-1">
                                <input className="form-control mb-0" type="number" value={width} onChange={(e) => setWidth(Number(e.target.value))} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-8">
                            <div className="row">
                                <div className="col-12">
                                    <p className="font-weight-semi-bold my-1">Content</p>
                                    <div className="form-group mb-1">
                                        <textarea rows={4} className="form-control mb-0" id="content-input" type="text" value={content} onChange={(e) => setContent(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <p className="font-weight-semi-bold mb-2">Add Placeholders</p>
                                    {placeholders !== null && placeholders.map((item) => {
                                        return (
                                            <button className="btn btn-falcon-default btn-sm m-1" onClick={() => addPlaceholder(item.formattedName, null)}>{item.description}</button>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="row">
                                <div className="col-12">
                                    <p className="font-weight-semi-bold mb-1 mt-1">Font</p>
                                    <div className="form-group mb-1">
                                        <input className="form-control mb-0" type="text" value={font} onChange={(e) => setFont(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <p className="font-weight-semi-bold mt-1 mb-1">Font Colour</p>
                                    <div className="form-group mb-1">
                                        <div className="col-3 pl-0 color-code-row"><div className="color-code-row"><button id="color-picker-button" type="button" data-toggle="collapse" data-target="#color-picker" className="ml-0 mr-3 button-no-style tag-colour-disc" style={colourStyle}></button>{fontColour}</div></div>
                                    </div>
                                </div>
                                <div className="col-6 custom-control custom-switch">
                                    <p className="font-weight-semi-bold mt-1 mb-1 ml-2">Wrap Text</p>
                                    <input className="custom-control-input" type="checkbox" id="wrap-text" checked={wrapText} onChange={() => setWrapText(!wrapText)} />
                                    <label className="custom-control-label ml-5" htmlFor="wrap-text"></label>
                                </div>
                                <div className="col-12">
                                    <p className="font-weight-semi-bold  mt-1 mb-1">Font Size &nbsp; {fontSize}</p>
                                    <div className="form-group mb-1">
                                        <input className="form-control mb-0" type="range" step={1} min={8} max={30} value={fontSize} onChange={(e) => setFontSize(Number(e.target.value))} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <p className="font-weight-semi-bold  mt-1 mb-1">Line Spacing &nbsp; {lineSpacing}</p>
                                    <div className="form-group mb-1">
                                        <input className="form-control mb-0" type="range" step={0.1} min={1} max={3} value={lineSpacing} onChange={(e) => setLineSpacing(Number(e.target.value))} />
                                    </div>
                                </div>
                                <div className="col-12 color-picker-document collapse" id="color-picker" data-parent="#color-picker-button">
                                    <SketchPicker
                                        color={fontColour}
                                        onChange={handleColorChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>}
            {itemType === 1 && <>
                <div className="row">
                    <div className="col-12">
                        <p className="font-weight-semi-bold mt-1 mb-1">Select Placeholder</p>
                    </div>
                </div>
            </>}
        </Modal.Body>
        <Modal.Footer>
            <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={cancel}>Cancel</button>
            <button type="button" className="btn btn-success btn-sm ml-2" data-dismiss="modal" onClick={save}>Save</button>
        </Modal.Footer>
    </Modal>;
        
      
}