export const fetchPreviousFullMonth = () => {
        const today = new Date();
        const previousMonth = today.getMonth() - 1;
        const previousYear = previousMonth < 0 ? today.getFullYear() - 1 : today.getFullYear();
        const firstDayOfMonth = new Date(previousYear, previousMonth, 1, 0);
        const lastDayOfMonth = new Date(previousYear, previousMonth + 1, 0, 23, 59);

        return {
          firstDate: firstDayOfMonth,
          lastDate: lastDayOfMonth
        };     
}