export const ReportTypeEnum = {
    "average-stay": "Average Length of Stay",
    "daily-totals": "Daily Totals",
    "parking-durations": "Parking Durations",
    "pcns-paid": "PCN Revenue",
    "pcns-issued": "PCNs Issued",
    "export-breaches": "Export Breaches",
    "contraventions": "Contraventions",
    "breaches": "Breaches"
}

export const AverageStayTableEnum = {
    "label": "Date",
    "value": "Length of Stay (mins)",
}

export const DailyTotalsEnum = {
    "label": "Date",
    "value": "Number of Vehicles",
}

export const ParkingDurationsEnum = {
    "label": "Duration",
    "value": "Number of Vehicles",
}

export const PCNPaidEnum = {
    "label": "Site Name",
    "amount": "Amount",
    "count": "PCNs Paid",
    "subTotal": "Subtotal",
}

export const PCNsIssuedEnum = {
    "label": "Site Name",
    "value": "PCNs Issued"
}

export const ExportBreachesEnum = {
    "vrm": "VRM",
    "referenceNumber": "Reference Number",
    "siteName": "Site Name",
    "entryTime": "Entry Time",
    "entryDate": "Entry Date",
    "exitTime": "Exit Time",
    "exitDate": "Exit Date",
    "duration": "Duration",
    "contravention": "Contravention"
}

export const BreachesEnum = {
    "label": "Date",
    "value": "Number of Vehicles"
}

export const ContraventionsEnum = {
    "label": "Date",
    "contraventionName": "Contravention Name",
    "contraventionType": "Contravention Type",
    "value": "Number of Vehicles",
}

export const VehicleTypeEnum = {
    "0": "All Vehicles",
    "1": "Allow List Vehicles",
    "2": "General Vehicles",
}

export const DateRangeTypeEnum = {
    "0": "Daily",
    "1": "Weekly",
    "2": "Monthly",
}

export const ReportsEnumsList = {
    "average-stay": AverageStayTableEnum,
    "pcns-paid": PCNPaidEnum,
    "breaches": BreachesEnum,
    "daily-totals": DailyTotalsEnum,
    "export-breaches": ExportBreachesEnum,
    "parking-durations": ParkingDurationsEnum,
    "pcns-issued": PCNsIssuedEnum,
    "contraventions": ContraventionsEnum
}