import React from 'react';
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";

import authentication from '../authentication/b2c';

function ClientPortalHeader(props) {
    return (
        <>
        <nav className="navbar navbar-light navbar-glass navbar-top sticky-kit navbar-expand-lg">
            <a className="navbar-brand mr-1 mr-sm-3" href="/">
                <div className="d-flex align-items-center"><img className="mr-2" src="/assets/img/apt-logos/logo.svg" alt="" width="100" />
                </div>
            </a>
            <ul className="navbar-nav navbar-nav-icons ml-auto flex-row align-items-center">
                <li className="nav-item dropdown dropdown-on-hover">
                    <a className="nav-link pr-0 user-icon" id="navbarDropdownUser" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {props.user !== null && props.user !== undefined &&
                            <>
                                {props.user.firstName[0]}{props.user.lastName[0]}
                            </>}
                    </a>
                    <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="navbarDropdownUser">
                        <div className="bg-white rounded-soft py-2">
                            {props.user !== undefined && props.user.organisations.length > 1 &&
                                <>
                                    <a className="dropdown-item" href="/">Select Organisation</a>
                                    <div className="dropdown-divider"></div>
                                </>}
                                {/* <Link className="btn btn-primary btn-sm ml-1" to="/user-management/accept-invite/2ae8b43d-1555-42de-a6ac-f4eaa97da745">Accept Invite</Link> */}
                                <button type="button" className="dropdown-item" data-toggle="modal" data-target="#signOutModal">Sign Out</button>
                        </div>
                    </div>
                </li>
            </ul>
        </nav>
                  <div className="modal fade" id="signOutModal" tabIndex="-1" role="dialog" aria-labelledby="signOutModalLabel" aria-hidden="true">
                  <div className="modal-dialog" role="document">
                      <div className="modal-content">
                          <div className="modal-header">
                              <h5 className="modal-title" id="signOutModalLabel">Are you sure you want to Sign Out?</h5>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                              </button>
                          </div>
                          <div className="modal-footer">
                              <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                              <button className="btn btn-primary btn-sm" data-dismiss="modal" onClick={() => authentication.signOut()}>Sign Out</button>
                          </div>
                      </div>
                  </div>
              </div>
          </>
    )
}
export default ClientPortalHeader;