const cameraTemplateJsonData = {

    "cameraname": "$mac$",

    "plate": "$plate$",

    "date": "$date$",

    "utcdate": "$utcdate$",

    "timestamp": "$timestamp$",

    "country": "$countrycode2$",

    "multiplate": "$multiplate$",

    "charheight": "$charheight$",

    "processingtime": "$processingtime$",

    "confidence": "$confidence$",

    "direction": "$direction$",

    "image": "$image$",

    "imagesize": "$imagesize$",

    "plateimage": "$plateimage$",

    "plateimagesize": "$plateimagesize$",

    "messageid": "$uuid$",

    "roi": "$roiid$"
}

export const formattedCameraTemplate = JSON.stringify(cameraTemplateJsonData, null, 2);

export const CameraTemplate = () => {
    return (
        <div className="p-4">
            <pre>
                {formattedCameraTemplate}
            </pre>
        </div>
    )
}

