export default {
    distinct(array) {
        return [...new Set(array)];
    },

    newOrConcat(array, element) {
        return this.isNullOrEmpty(array) ? [element] : array.concat([element]);
    },

    toggle(element, array) {
        if (this.isNullOrEmpty(array)) {
            return [ element ];
        }
        
        if (!array.includes(element)) {
            return array.concat([ element ])
        }
        
        return array.filter((x) => x !== element);
    },

    isNullOrEmpty(array) {
        return !array || array === null || !Array.isArray(array) || array.length === 0;
    }
}