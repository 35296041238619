import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Loader from '../shared/loader';

function CancellationReasons(props) {
    const [reasons, setReasons] = useState(null);

    const getReasons = function () {
        Api.getCancellationReasons(
            (data) => setReasons(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        )
    };

    useEffect(() => {
        getReasons();
    }, []);

    var addNewUrl = '/organisation/' + props.organisationId + '/cancellation-reason';
    return (
        <>
            {reasons === null && <Loader />}
            {reasons !== null && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto d-flex align-items-center pr-0">
                                            <h5 className="mb-0">Cancellation Reasons</h5>
                                        </div>
                                        <div className="col-auto text-right pl-0 filter-buttons-wrapper">
                                            <Link className="btn btn-success btn-sm mr-1 ml-1" to={addNewUrl}><i className="fas fa-plus"></i> Add</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top p-0">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th>Name</th>
                                                <th>Description</th>
                                                <th className="text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {reasons.map((reason) => {
                                                var url = '/organisation/' + props.organisationId + '/cancellation-reason/' + reason.id;
                                                return (
                                                    <tr>
                                                        <td><Link to={url}><h6 className="mb-0">{reason.name}</h6></Link></td>
                                                        <td>{reason.description}</td>
                                                        <td className="text-center">{reason.isActive && <><i className="fas fa-check text-success"></i></>}{!reason.isActive && <><i className="fas fa-times text-danger"></i></>}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}
export default CancellationReasons;