import { useEffect, useState } from "react";
import Api from "../api/api";
import Loader from "../shared/loader";
import { DataTable } from "../shared/dataTable";
import { DatePicker } from "../shared/datePicker";
import Pagination from "../shared/pagination";

const KadoeDataEnum = {
    "vrm": "VRM",
    "pcnReference": "PCN Reference",
    "enquiryDate": "Enquiry Date",
    "responseDate": "Response Date",
    "forename": "First Name",
    "surname": "Last Name",
    "companyName": "Company Name",
    "address": "Address",
    "make": "Vehicle Make",
    "model": "Vehicle Model",
    "colour": "Colour",
    "errorDescription": "Error Description",
}

export default function KadoeData(props) {

    const [kadoeData, setKadoeData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [kadoeSearch, setKadoeSearch] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [lastPage, setLastPage] = useState(false);

    const getKadoeData = (searchQuery, from, to, pageNumber) => {
        setIsLoading(true);
        Api.getKadoeRequests(
            data => {
                setKadoeData(data);
                setIsLoading(false);
            },
            () => {
                setKadoeData(null);
                setIsLoading(false);
            },
            searchQuery,
            from, to, pageNumber, props.organisationId, props.token
        )
    }
    const changepageNumber = (num) => {
        setPageNumber(num);
    };
    const isLastPage = () => {
        if (kadoeData && kadoeData !== undefined) {
            setLastPage(false)
            setPageCount(Math.ceil(kadoeData.totalCount / 100));
            if (pageNumber + 1 >= Math.ceil(kadoeData.totalCount / 100)) {
                setLastPage(true)
            }
        }
    };
    useEffect(() => getKadoeData("", null, null, 0), [])
    useEffect(() => getKadoeData(kadoeSearch, fromDate, toDate, pageNumber), [fromDate, toDate, kadoeSearch, pageNumber]);
    useEffect(() => isLastPage(), [kadoeData]);
    
    const clearFilters = () => {
        setFromDate(null);
        setToDate(null);
        setKadoeSearch("");
    }

    const handleRefresh = () => {
        clearFilters();
        getKadoeData("", null, null, 0);
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-3">
                        <div className="card-header raw-data-card-header">
                            <h5 className="mb-0">
                                Kadoe Requests
                            </h5>
                            <div className="raw-data-filters-container">
                                <input
                                    className="form-control kadoe-search-bar"
                                    value={kadoeSearch}
                                    type="text"
                                    autoFocus={true}
                                    placeholder="search..."
                                    onChange={e => setKadoeSearch(e.target.value)}
                                />
                                <button
                                    className="btn btn-falcon-default btn-sm py-0 d-flex align-items-center"
                                    onClick={clearFilters}><i className="fas fa-times mr-2"></i> Clear
                                </button>
                                <div className="date-pickers">
                                    <DatePicker title="From" date={fromDate} handleChange={date => setFromDate(date)} />
                                    <DatePicker title="To" date={toDate} handleChange={date => setToDate(date)} />
                                </div>
                                <button
                                    className="btn btn-primary btn-sm mr-1 ml-1 nowrap"
                                    onClick={handleRefresh}>
                                    <i className="fas fa-redo"></i> Refresh
                                </button>
                            </div>
                        </div>
                        {isLoading
                            ? <Loader />
                            : <DataTable
                                enum={KadoeDataEnum}
                                tableData={kadoeData?.items?.map(element => ({...element, breachUrl:`/organisation/${props.organisationId}/breach/${element.breachId}`}))} />}
                        <div className="d-flex justify-content-center row align-items-center pt-2">
                            <div className="col-auto align-items-center">
                                <Pagination totalResults={kadoeData?.totalCount} currentPage={pageNumber} changePageNumber={changepageNumber} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}