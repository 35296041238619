import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Enums from '../../utilities/enum';
import Loader from '../shared/loader';

function ProfileManagement(props) {
    const [canSave, setCanSave] = useState(false);
    const [isInEdit, setIsInEdit] = useState(false);
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");

    const [user, setUser] = useState(null);

    const edit = function () {
        Api.editUser(
            (data) => {
                setUser(data);
                setIsInEdit(false);
            },
            (error) => console.log(error),
            first, last, props.user.id,
            props.token
        )
    };

    const getUser = function () {
        Api.getUser(
            (data) => setUser(data),
            (error) => console.log(error),
            props.token
        )
    };

    useEffect(() => {
        if (first !== "" && last !== "") {
            setCanSave(true);
        } else {
            setCanSave(false);
        }
    }, [first, last])

    useEffect(() => {
        getUser();
    }, [])

    useEffect(() => {
        if (user !== null) {
            setFirst(user.firstName);
            setLast(user.lastName);
        }
    }, [isInEdit, user])

    return (
        <>
        {user === null && <Loader />}
        {user !== null && <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h5 className="mb-0">Profile Management</h5>
                                    </div>
                                    <div className="col-auto">
                                        <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setIsInEdit(!isInEdit)}>
                                            <i className="fas fa-edit"></i> Edit</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body bg-light border-top details-container">
                                {!isInEdit && <>
                                    <div className="row">
                                        <div className="col-md-5 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">First Name</p>
                                        </div>
                                        <div className="col-md-5 col-12 ">{user.firstName}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Last Name</p>
                                        </div>
                                        <div className="col-md-5 col-12 ">{user.lastName}</div>
                                    </div>
                                </>}
                                {isInEdit && <>
                                    <div className="row">
                                        <div className="col-md-5 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">First Name</p>
                                        </div>
                                        <div className="col-md-5 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={first} onChange={(e) => setFirst(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Last Name</p>
                                        </div>
                                        <div className="col-md-5 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={last} onChange={(e) => setLast(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </>}
                                <div className="row">
                                    <div className="col-md-5 col-12 ">
                                        <p className="font-weight-semi-bold  mb-3">Email Address</p>
                                    </div>
                                    <div className="col-md-5 col-12 ">{user.emailAddress}</div>
                                </div>
                                <div className="row">
                                    <div className="col-12 ">
                                        <p className="font-weight-semi-bold text-center mt-2 mb-1">Organisations</p>
                                    </div>
                                </div>
                                {user.organisations.map((organisation) => {
                                    return (
                                        <>
                                            <hr className="border-dashed border-bottom-0"></hr>

                                            <div className="row">
                                                <div className="col-md-5 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">Organisation Name</p>
                                                </div>
                                                <div className="col-md-5 col-12 ">{organisation.organisation.name}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-5 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">Role</p>
                                                </div>
                                                <div className="col-md-5 col-12 ">{Enums.UserRoles[organisation.roleId]}</div>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            {isInEdit && <>
                                <div className="card-footer border-top ">
                                    <div className="row client-edit-buttons">
                                        <div className="col-12 col-sm-auto ml-auto  pl-0">
                                            <div id="dashboard-actions">
                                                <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setIsInEdit(false)}>
                                                    <i className="fas fa-times"></i> Cancel</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => edit()}>
                                                    <i className="fas fa-check"></i> Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
            </>}
        </>
    )
}
export default ProfileManagement;