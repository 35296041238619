import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import StringHelpers from '../../utilities/stringHelpers';
import Loader from '../shared/loader';

function CancellationReason(props) {
    const [reasonId, setReasonId] = useState(props.match.params.reasonId)
    const [reason, setReason] = useState(null)
    const [description, setDescription] = useState("");
    const [name, setName] = useState("");
    const [isNew, setIsNew] = useState(false);
    const [isInEdit, setIsInEdit] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    const history = useHistory();

    const submit = function () {
        if (canSave) {
            Api.addCancellationReason(
                (data) => {
                    setReason(data);
                    setErrorMessage("");
                    setIsNew(false);
                },
                (error) => setErrorMessage(error),
                name, description, isActive,
                props.organisationId,
                props.token
            )
        }
    };

    const edit = function () {
        Api.editCancellationReason(
            (data) => {
                setIsInEdit(false);
                setReason(data);
                setErrorMessage("");
            },
            (error) => setErrorMessage(error),
            name, description, isActive,
            reasonId,
            props.organisationId,
            props.token
        )
    };

    const getReason = function () {
        Api.getCancellationReason(
            (data) => setReason(data),
            (error) => console.log(error),
            reasonId,
            props.organisationId,
            props.token
        )
    };

    useEffect(() => {
        if (reasonId !== undefined) {
            getReason();

        } else {
            setIsNew(true);
        }
    }, []);

    useEffect(() => {
        if (reason !== null) {
            setIsNew(false);

            setName(reason.name);
            setDescription(reason.description);
            setIsActive(reason.isActive);
        }
        setErrorMessage("");
    }, [reason, isInEdit]);

    useEffect(() => {
        setCanSave(!StringHelpers.isNullOrEmpty(description) && !StringHelpers.isNullOrWhiteSpace(name));
    }, [description, name]);

    var reasonListUrl = '/organisation/' + props.organisationId + '/cancellation-reasons';

    return (
        <>
            {!isNew && reason === null && 
                <Loader />
            }
            {reason !== null && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto d-flex align-items-center pr-0">
                                            <h5 className="mb-0">Cancellation Reason - {reason.name}</h5>
                                        </div>
                                        <div className="col-auto text-right pl-0 filter-buttons-wrapper">
                                            <Link className="btn btn-falcon-default btn-sm mr-1 ml-1" to={reasonListUrl}><i className="fas fa-reply"></i> Back</Link>
                                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setIsInEdit(!isInEdit)}>
                                                <i className="fas fa-edit"></i> Edit</button>
                                            <button className="btn btn-falcon-default btn-sm dropdown-toggle py-2 ml-3 dropdown-caret-none" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fas fa-ellipsis-h"></i></button>
                                            <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-start">
                                                <button type="button" className="dropdown-item text-danger" data-toggle="modal" data-target="#deleteModal">
                                                    <i className="fas fa-times"></i> Delete Reason</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="deleteModalLabel">Are you sure you want to delete this Cancellation Reason?</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn btn-danger btn-sm ml-2" data-dismiss="modal" onClick={() => Api.deleteCancellationReason(() => history.push(reasonListUrl), reasonId, props.organisationId, props.token)}>
                                                    Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!isInEdit && <>
                                    <div className="card-body bg-light border-top details-container">
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Name</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{name}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Description</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{description}</div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-1">Status</p>
                                            </div>
                                            <div className="col-md-6 col-12  ">{isActive && <i className="fas fa-check text-success"></i>}{!isActive && <i className="fas fa-times text-danger"></i>}</div>
                                        </div>
                                    </div>
                                </>}
                                {isInEdit && <>
                                    <div className="card-body bg-light border-top details-container">
                                    {errorMessage !== "" && <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>}
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Name<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">Description<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <textarea className="form-control mb-0" type="text" rows={4} value={description} onChange={(e) => setDescription(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className=" col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mt-1 mb-1">Status</p>
                                            </div>
                                            <div className=" col-md-6 col-12 custom-control custom-switch">
                                                <input className="custom-control-input" type="checkbox" id="status" checked={isActive} onChange={() => setIsActive(!isActive)} />
                                                <label className="custom-control-label ml-3" htmlFor="status"></label>
                                                {isActive && <>Active</>}{!isActive && <>Deactivated</>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer border-top ">
                                        <div className="row client-edit-buttons">
                                            <div className="col-12 col-sm-auto ml-auto  pl-0">
                                                <div id="dashboard-actions">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setIsInEdit(false)}>
                                                        <i className="fas fa-times"></i> Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => edit()}>
                                                        <i className="fas fa-check"></i> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>}

                            </div>
                        </div>
                    </div>
                </div>
            </>}
            {isNew && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-12 d-flex align-items-center pr-0">
                                            <h5 className="mb-0">Create New Cancellation Reason</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top">
                                {errorMessage !== "" && <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>}
                                    <div className="row">
                                        <div className=" col-md-5 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Name<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-5 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={name} autoFocus={true} onChange={(e) => setName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-5 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Description<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-5 col-12">
                                            <div className="form-group mb-1">
                                                <textarea className="form-control mb-0" type="text" rows={4} value={description} onChange={(e) => setDescription(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-5 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Status</p>
                                        </div>
                                        <div className=" col-md-5 col-12 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="status" checked={isActive} onChange={() => setIsActive(!isActive)} />
                                            <label className="custom-control-label ml-3" htmlFor="status"></label>
                                            {isActive && <>Active</>}{!isActive && <>Deactivated</>}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="row client-edit-buttons">
                                        <div className="col-6 col-sm-auto ml-auto  pl-0">
                                            <div id="dashboard-actions">
                                                <Link className="btn btn-falcon-default btn-sm" to={reasonListUrl}><i className="fas fa-times"></i> Cancel</Link>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => submit()}>
                                                    <i className="fas fa-check"></i> Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}
export default CancellationReason;