import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Loader from '../shared/loader';

function WardenDashboard(props) {
    const [sites, setSites] = useState(null);
    const [clients, setClients] = useState(null);
    const [client, setClient] = useState(null);

    const getSites = function () {
        Api.getClientSites(
            (data) => setSites(data),
            (error) => console.log(error),
            client.id,
            props.organisationId,
            props.token
        );
    }

    const getClients = function () {
        Api.getClients(
            (data) => setClients(data),
            () => setClients(null),
            props.organisationId,
            props.token
        );
    }

    useEffect(() => {
        if (client !== null) {
            getSites();
        } else {
            setSites(null);
        }
    }, [client]);


    useEffect(() => {
        getClients();
    }, []);

    return (
        <div className='container-fluid'>
            {(clients === null || (client !== null && sites === null)) && <Loader />}
            {clients !== null &&
              <>
                {client === null && 
                    <div className="row flex-center min-vh-50 py-6">
                        <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                            <div className="card">
                                <div className="card-header bg-light">
                                    <h5 className="mb-0">Select a client</h5>
                                </div>
                                <div className="card-body p-2 px-sm-2 py-sm-5">                                    
                          
                                    {clients !== null && clients.map((client) => 
                                        <a className="btn btn-link btn-block d-flex justify-content-between align-items-center" href="#" key={client.id} onClick={() => setClient(client)}>
                                            <span>{client.name}</span>
                                            <span className="fas fa-chevron-right fs--2 ml-1"></span>
                                        </a>
                                    )}                               
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                }                              
                {client !== null && sites !== null && 
                    <div className="row flex-center min-vh-50 py-6">
                        <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                            <div className="card">
                                <div className="card-header bg-light">
                                    <h5 className="mb-0">Select a site</h5>
                                </div>
                                <div className="card-body p-2 px-sm-2 pt-sm-5"> 
                                    {sites !== null && sites.map((site) => 
                                        <Link className="btn btn-link btn-block d-flex justify-content-between align-items-center" to={'/site/' + site.id}>                                        
                                            <span>{site.name}</span>
                                            <span className="fas fa-chevron-right fs--2 ml-1"></span>
                                        </Link>
                                    )}                               
                                  
                                    <div className="w-100 position-relative mt-sm-5">
                                        <hr className="text-300 mb-2" />
                                    </div>
                                    <div className="form-group mb-0">
                                        <div className="row no-gutters d-flex justify-content-start">
                                            <button className="btn btn-default btn-sm" onClick={() => setClient(null)}> <i className="fas fa-arrow-left"></i> Back</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                                                            
            </>
        
            }
        </div>
    )
}
export default WardenDashboard;