import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import UploadFile from '../shared/file-uploader';
import StringHelpers from '../../utilities/stringHelpers';
import Loader from '../shared/loader';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import RingGoPayments from './../breaches/_ringGoPayments';

var cloneDeep = require('lodash.clonedeep');
var hash = require('object-hash');

function WardenSite(props) {
    const WardenSiteViewStep = {
        EnterVrm: 0,
        AllowedVrm: 1,
        CheckPayments: 2,
        CreateTicket: 3,
        Review: 4
    }

    const [currentView, setCurrentView] = useState(WardenSiteViewStep.EnterVrm);

    const [vrm, setVrm] = useState("");
    const [siteId, setSiteId] = useState(props.match.params.siteId);
    const [site, setSite] = useState(null);
    const [allowList, setAllowList] = useState(null);
    const [allowedVrm, setAllowedVrm] = useState(null);

    const [model, setModel] = useState("");
    const [make, setMake] = useState("");
    const [colour, setColour] = useState("");
    const [notes, setNotes] = useState("");
    const [observedFrom, setObservedFrom] = useState(new Date());
    const [observedTo, setObservedTo] = useState(new Date());
    const [allContraventions, setAllContraventions] = useState(null);
    const [contraventions, setContraventions] = useState(null);
    const [contraventionId, setContraventionId] = useState(null);
    const [contraventionName, setContraventionName] = useState(null);
    const [attachments, setAttachments] = useState([]);

    const [canSave, setCanSave] = useState(false);

    const history = useHistory();
    const { addToast } = useToasts();

    const changeVrm = function (str) {
        if (!StringHelpers.isNullOrEmpty(str)) {
            setVrm(StringHelpers.cleanVrm(str));
        } else {
            setVrm("");
        }
    }

    const getAllowList = function () {
        Api.getAllowList(
            (data) => setAllowList(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        );
    }

    const getAllContraventions = function () {
        Api.getContraventions(
            (data) => setAllContraventions(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        )
    };

    const addAttachment = function (attachment) {
        setAttachments(attachments.concat([attachment]));
    };
    
    const removeAttachment = function(attachmentId) {
        let attachmentsClone = cloneDeep(attachments);
        let filtered = attachmentsClone.filter(function (x) {
            return x.id !== attachmentId;
        });

        setAttachments(filtered);
    };
    
    const setLookupData = function (data) {
        setColour(data.vehicleColour);
        setMake(data.vehicleMake);
        setModel(data.vehicleModel);
    };

    const getSiteContraventions = function () {
        Api.getSiteContraventions(
            (data) => setContraventions(data),
            (error) => console.log(error),
            props.organisationId,
            siteId,
            props.token
        )
    };

    const getSite = function () {
        Api.getSite(
            (data) => setSite(data),
            (error) => console.log(error),
            siteId,
            props.organisationId,
            props.token,
        )
    };

    const enterVrmNext = function () {

        let allowListItem = allowList.find(x => x.vrm === vrm.toUpperCase() && (StringHelpers.isNullOrEmpty(x.siteId) || x.siteId === siteId));
        if (allowListItem && allowListItem !== null) {

            setAllowedVrm(allowListItem);
            setMake(allowListItem.vehicleMake);
            setModel(allowListItem.vehicleModel);
            setColour(allowListItem.vehicleColour);
            
            setCurrentView(WardenSiteViewStep.AllowedVrm);
        } else {
            setCurrentView(WardenSiteViewStep.CheckPayments);
        }
    };

    const createTicket = function () {
        
        Api.addWardenBreach(
            (data) => {
                if (!data.isError) {
                    addToast("Breach logged successfully", {
                        appearance: "success",
                        autoDismiss: true,
                    });
                    reset();
                } else {
                    addToast("Error logging breach", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            },
            (error) => console.log(error),
            siteId, vrm, colour, make, model, contraventionId, attachments.map(x => x.id), observedFrom, observedTo, props.organisationId, props.token
        )
    };

    const reset = function () {
        setCurrentView(WardenSiteViewStep.EnterVrm);
        setVrm("");
        setAllowedVrm(null);
        setColour("");
        setMake("");
        setModel("");
        setNotes("");
        setObservedFrom(new Date());
        setObservedTo(new Date());
        setContraventionId(null);
        setContraventionName(null);
        setAttachments([]);
    };

    useEffect(() => {

        if (allContraventions && allContraventions !== null && allContraventions.length > 0) {
            let found = allContraventions.find(x => x.id === contraventionId);
            if (found && found !== null) {
                setContraventionName(found.name);
            }
            else {
                setContraventionName("");
            }
        }
    }, [contraventionId])

    useEffect(() => {
        setCanSave(!StringHelpers.anyAreNullOrEmpty([contraventionId, vrm, make, model, colour]));
    }, [contraventionId, vrm, make, model, colour]);

    useEffect(() => {
        if (siteId !== null && siteId !== undefined) {
            getSite();
            getSiteContraventions();
            getAllowList();
            getAllContraventions();
        }
    }, []);

    return (
        <div className="container-fluid">
            <div className="row flex-center min-vh-50 py-6">
                <div className="col-sm-12 col-xl-8 col-xxl-6">
                    <div className="card">
                        <div className="card-header bg-light">
                            <h5 className="mb-0">Create a ticket</h5>
                        </div>
                        <div className="card-body p-2 px-sm-2 pt-sm-5">
                            {currentView === WardenSiteViewStep.EnterVrm &&
                                <>
                                    <div className='container-fluid'>
                                        <div className="row mb-2">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">Vehicle Registration Mark (VRM)</label>
                                            <div className="col-sm-8">
                                                <input className="form-control form-control-sm" type="text" value={vrm} onChange={(e) => changeVrm(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-100 position-relative mt-sm-5">
                                        <hr className="text-300 mb-2" />
                                    </div>
                                    <div className="form-group mb-0">
                                        <div className="row no-gutters d-flex">
                                            <div className="col-12 d-flex justify-content-between">
                                                <div>
                                                    <Link className="btn btn-default btn-sm" to="/"> <i className="fas fa-arrow-left"></i> Back</Link>
                                                </div>
                                                <div className='d-flex justify-content-end'>
                                                    <button type="button" className="btn btn-default btn-sm ml-2" onClick={reset}>
                                                        <i className="fas fa-times"></i> Clear</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" disabled={StringHelpers.isNullOrEmpty(vrm)} onClick={enterVrmNext}>
                                                        Next <i className="fas fa-arrow-right"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                            {currentView === WardenSiteViewStep.CheckPayments &&
                                <>
                                    <RingGoPayments vrm={vrm} breachDate={new Date()} siteId={site?.id} organisationId={props.organisationId} token={props.token} />

                                    <div className="w-100 position-relative mt-sm-5">
                                        <hr className="text-300 mb-2" />
                                    </div>
                                    <div className="form-group mb-0">
                                        <div className="row no-gutters d-flex">
                                            <div className="col-12 d-flex justify-content-between">
                                                <button className="btn btn-default btn-sm" onClick={reset}> <i className="fas fa-arrow-left"></i> Back</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" onClick={() => setCurrentView(WardenSiteViewStep.CreateTicket)}>Next <i className="fas fa-arrow-right"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
          
                            {(currentView === WardenSiteViewStep.CreateTicket || currentView == WardenSiteViewStep.Review) &&
                                <>
                                    <div className='container-fluid'>
                                        <div className="row mb-2 align-items-center">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">Contravention</label>
                                            <div className="col-sm-8">
                                                {currentView === WardenSiteViewStep.Review &&
                                                    <p class="form-control-plaintext form-control-sm outline-none" >{contraventionName}</p>
                                                }
                                                {currentView === WardenSiteViewStep.CreateTicket &&
                                                    <select className='custom-select custom-select-sm' onChange={(e) => setContraventionId(e.target.value)}>
                                                        <option></option>
                                                        {contraventions !== null && allContraventions !== null && contraventions.map((item) => {
                                                            var contra = null;
                                                            allContraventions.forEach(contravention => {
                                                                if (item.contraventionId === contravention.id && contravention.contraventionType === 1) {
                                                                    contra = contravention;
                                                                }
                                                            });
                                                            if (contra !== null) {
                                                                return (
                                                                    <option value={contra.id} selected={contra.id === contraventionId}>{contra.name}</option>
                                                                )
                                                            }
                                                        })}
                                                    </select>
                                                }

                                            </div>
                                        </div>
                                        <div className="row mb-2 align-items-center">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">VRM</label>
                                            <div className="col-sm-8">
                                                {currentView === WardenSiteViewStep.Review &&
                                                    <div className="warden-plate-container"><p className="small-number-plate mb-0">{vrm}</p></div>
                                                }
                                                {currentView === WardenSiteViewStep.CreateTicket &&
                                                    <div className="form-group mb-1 warden-plate">
                                                        <input className="form-control mb-1 small-number-plate" type="text" value={vrm.toUpperCase()} onChange={(e) => changeVrm(e.target.value)} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">Vehicle Make</label>
                                            <div className="col-sm-8">
                                                {currentView === WardenSiteViewStep.Review &&
                                                    <p class="form-control-plaintext form-control-sm outline-none" >{make}</p>
                                                }
                                                {currentView === WardenSiteViewStep.CreateTicket &&
                                                    <input className="form-control form-control-sm" type="text" value={make} onChange={(e) => setMake(e.target.value)} />
                                                }
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">Vehicle Model</label>
                                            <div className="col-sm-8">
                                                {currentView === WardenSiteViewStep.Review &&
                                                    <p class="form-control-plaintext form-control-sm outline-none" >{model}</p>
                                                }
                                                {currentView === WardenSiteViewStep.CreateTicket &&
                                                    <input className="form-control form-control-sm" type="text" value={model} onChange={(e) => setModel(e.target.value)} />
                                                }
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">Color</label>
                                            <div className="col-sm-8">
                                                {currentView === WardenSiteViewStep.Review &&
                                                    <p class="form-control-plaintext form-control-sm outline-none" >{colour}</p>
                                                }
                                                {currentView === WardenSiteViewStep.CreateTicket &&
                                                    <input className="form-control form-control-sm" type="text" value={colour} onChange={(e) => setColour(e.target.value)} />
                                                }
                                            </div>
                                        </div>
                                        {currentView === WardenSiteViewStep.CreateTicket &&
                                            <div className="row mb-3">
                                                <div className="offset-md-4 col-auto">
                                                    <button className="btn btn-primary btn-sm" onClick={() => Api.wardenVehicleLookup((data) => setLookupData(data), vrm, props.organisationId, props.token)}>Lookup Details <i className="fas fa-search"></i></button>
                                                </div>
                                            </div>
                                        }
                                        <div className="row mb-2">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">Observed from</label>
                                            <div className="col-sm-8">
                                                {currentView === WardenSiteViewStep.Review &&
                                                    <p class="form-control-plaintext form-control-sm outline-none" >{moment(observedFrom).format('DD/MM/YYYY HH:mm')}</p>
                                                }
                                                {currentView === WardenSiteViewStep.CreateTicket &&
                                                    <input className="form-control form-control-sm" type="datetime-local" value={moment(observedFrom).format('YYYY-MM-DDTHH:mm')} onChange={(e) => setObservedFrom(e.target.value)} />
                                                }
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">Observed to</label>
                                            <div className="col-sm-8">
                                                {currentView === WardenSiteViewStep.Review &&
                                                    <p class="form-control-plaintext form-control-sm outline-none" >{moment(observedTo).format('DD/MM/YYYY HH:mm')}</p>
                                                }
                                                {currentView === WardenSiteViewStep.CreateTicket &&
                                                    <input className="form-control form-control-sm" type="datetime-local" value={moment(observedTo).format('YYYY-MM-DDTHH:mm')} onChange={(e) => setObservedTo(e.target.value)} />
                                                }
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">Notes</label>
                                            <div className="col-sm-8">
                                                {currentView === WardenSiteViewStep.Review &&
                                                    <p class="form-control-plaintext form-control-sm outline-none" >{notes}</p>
                                                }
                                                {currentView === WardenSiteViewStep.CreateTicket &&
                                                    <textarea className="form-control form-control-sm" value={notes} onChange={(e) => setNotes(e.target.value)} />
                                                }
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">Site</label>
                                            <div className="col-sm-8">
                                                <p class="form-control-plaintext form-control-sm outline-none" >{site.name}</p>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">Type</label>
                                            <div className="col-sm-8">
                                                <p class="form-control-plaintext form-control-sm outline-none" >MNPR</p>
                                            </div>
                                        </div>
                                        {attachments && attachments !== null && attachments.length > 0 &&
                                            <div className="row mb-2">
                                                <div className="offset-sm-4 col-sm-8">
                                                    <table className="table table-sm table-borderless mb-0 table-striped table-dashboard fs--1 data-table no-footer ">
                                                        <tbody>
                                                            {attachments.map((item) => <tr key={hash(item)}>
                                                                <td className="pl-0">
                                                                    {item.isImage &&

                                                                        <img src={item.url} className="attachment-thumb" style={{ width: "75px", height: "75px", objectFit: "cover" }} />

                                                                    }
                                                                    {!item.isImage && <>
                                                                        {item.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                                        {item.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                                        {item.contentType !== "application/word.doc" && item.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                                                    </>}
                                                                </td>
                                                                <td><span style={{wordBreak: "break-all"}}>{item.filename}</span></td>
                                                                <td className="text-right">
                                                                    {currentView === WardenSiteViewStep.CreateTicket &&
                                                                        <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => removeAttachment(item.id)}>
                                                                            <i className="fas fa-trash"></i><span className='d-none d-sm-inline'> Delete</span>
                                                                        </button>
                                                                    }
                                                                </td>
                                                            </tr>

                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        {currentView === WardenSiteViewStep.CreateTicket &&
                                            <>
                                                <div className="row mb-2">
                                                    <div className="offset-md-4 col-auto">
                                                        <button className="btn btn-falcon-default btn-sm mt-2" data-toggle="modal" data-target="#add-new-evidence"><i className="fas fa-plus"></i> Add Attachment</button>
                                                    </div>
                                                </div>
                                                <div className="modal fade save-dialog success-dialog" id="add-new-evidence" tabIndex="-1" role="dialog">
                                                    <UploadFile onSuccess={(attachment) => addAttachment(attachment)} type="Attachments" organisationId={props.organisationId} resizeImage />
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="w-100 position-relative mt-sm-5">
                                        <hr className="text-300 mb-2" />
                                    </div>
                                    <div className="form-group mb-0">
                                        <div className="row no-gutters d-flex">
                                            <div className="col-12 d-flex justify-content-between">
                                                {currentView === WardenSiteViewStep.Review &&
                                                    <button className="btn btn-default btn-sm" onClick={() => setCurrentView(WardenSiteViewStep.CreateTicket)}><i className="fas fa-arrow-left"></i> Back</button>
                                                }
                                                {currentView === WardenSiteViewStep.CreateTicket &&
                                                    <button className="btn btn-default btn-sm" onClick={reset}><i className="fas fa-arrow-left"></i> Back</button>
                                                }
                                                <div className='d-flex justify-content-end'>
                                                    {currentView === WardenSiteViewStep.Review &&
                                                        <button type="button" className="btn btn-success btn-sm ml-2" onClick={() => createTicket()}><i className="fas fa-check"></i> Submit</button>
                                                    }
                                                    {currentView === WardenSiteViewStep.CreateTicket &&
                                                        <button type="button" className="btn btn-primary btn-sm ml-2" disabled={!canSave} onClick={() => setCurrentView(WardenSiteViewStep.Review)}> <i className="fas fa-eye"></i> Review</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                            {currentView === WardenSiteViewStep.AllowedVrm &&

                                <>
                                    <div className='container-fluid'>
                                        <h4 className="mb-sm-5 my-3 text-center">Vehicle is on the Allow List</h4>

                                        <div className="row mb-2">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">VRM</label>
                                            <div className="col-sm-8"><p className="small-number-plate mb-0">{allowedVrm.vrm}</p></div>
                                        </div>
                                        <div className="row mb-2">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">Vehicle Make</label>
                                            <div className="col-sm-8">
                                                <p class="form-control-plaintext form-control-sm outline-none" >{allowedVrm.vehicleMake}</p>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">Vehicle Model</label>
                                            <div className="col-sm-8">
                                                <p class="form-control-plaintext form-control-sm outline-none" >{allowedVrm.vehicleModel}</p>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">Colour</label>
                                            <div className="col-sm-8">
                                                <p class="form-control-plaintext form-control-sm outline-none" >{allowedVrm.colour}</p>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">Site</label>
                                            <div className="col-sm-8">
                                                <p class="form-control-plaintext form-control-sm outline-none" >{allowedVrm.siteName && allowedVrm.siteName !== null && <>{allowedVrm.siteName}</>}{!allowedVrm.siteName && <>All</>}</p>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">Period</label>
                                            <div className="col-sm-8">

                                                {(allowedVrm.startDate === null || allowedVrm.endDate === null) &&
                                                    <p class="form-control-plaintext form-control-sm outline-none" >
                                                        Indefinite
                                                    </p>
                                                }
                                                {allowedVrm.startDate !== null && allowedVrm.endDate !== null &&
                                                    <p class="form-control-plaintext form-control-sm outline-none" >
                                                        {moment(allowedVrm.startDate).format("DD/MM/yyyy, HH:mm")} to {moment(allowedVrm.endDate).format("DD/MM/yyyy, HH:mm")}
                                                    </p>
                                                }
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <label class="col-sm-4 col-form-label col-form-label-sm">Notes</label>
                                            <div className="col-sm-8">
                                                <p class="form-control-plaintext form-control-sm outline-none" >{allowedVrm.notes}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-100 position-relative mt-sm-5">
                                        <hr className="text-300 mb-2" />
                                    </div>
                                    <div className="form-group mb-0">
                                        <div className="row no-gutters d-flex">
                                            <div className="col-12 d-flex justify-content-between">
                                                <button className="btn btn-default btn-sm" onClick={reset}> <i className="fas fa-arrow-left"></i> Back</button>
                                                {/* <button type="button" className="btn btn-success btn-sm ml-2" onClick={reset}>Next <i className="fas fa-arrow-right"></i></button> */}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WardenSite;