import { DeviceStatus } from '../../dashboard/deviceStatus';
import { useDevicesState } from "../../../utilities/useDevicesState";
import Enum from '../../../utilities/enum';
import Loader from '../../shared/loader';

export default function AllOrganisationCameras(props) {
    const { orderedCameras, getCameras, camerasLoading } = useDevicesState(props.organisationId, props.token);

    return (
        <div className="container-fluid">
            {
                orderedCameras ? (
                    <DeviceStatus
                        orderedDevices={orderedCameras}
                        deviceType={Enum.DeviceType[0]}
                        viewAll
                        organisationId={props.organisationId}
                        handleRefreshClick={getCameras}
                        isLoading={camerasLoading}
                    />
                ) : <Loader />
            }
        </div>
    )
}