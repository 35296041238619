import { ReportSummaryCard } from "./reportSummaryCard";
import { ReportTypeEnum } from "./reportsEnums";

const reportsSummaries = [
    {
        title: ReportTypeEnum["average-stay"],
        summaryDescription: "View report on the average duration of a parking session.",
        iconClass: "fas fa-clock",
        iconBackground: "dark",
        urlSlug: "average-stay",
    },
    {
        title: ReportTypeEnum["daily-totals"],
        summaryDescription: "View report on total number of vehicles that have visited the car park daily.",
        iconClass: "fas fa-sort-amount-up",
        iconBackground: "info",
        urlSlug: "daily-totals",
    },
    {
        title: ReportTypeEnum["parking-durations"],
        summaryDescription: "View report on total number of vehicles on the car park, broken down into durations.",
        iconClass: "fas fa-hourglass-half",
        iconBackground: "primary",
        urlSlug: "parking-durations",
    },
    {
        title: ReportTypeEnum["pcns-paid"],
        summaryDescription: "View report on total revenue of paid PCNS.",
        iconClass: "fas fa-coins",
        iconBackground: "success",
        urlSlug: "pcns-paid",
    },
    {
        title: ReportTypeEnum["pcns-issued"],
        summaryDescription: "View report on the number of PCNs issued.",
        iconClass: "fas fa-coins",
        iconBackground: "success",
        urlSlug: "pcns-issued",
    },
    {
        title: ReportTypeEnum["export-breaches"],
        summaryDescription: "Export a detailed report on breaches.",
        iconClass: "fas fa-exclamation-circle",
        iconBackground: "danger",
        urlSlug: "export-breaches",
    },
    {
        title: ReportTypeEnum["breaches"],
        summaryDescription: "View report on the total number of breaches.",
        iconClass: "fas fa-exclamation-circle",
        iconBackground: "danger",
        urlSlug: "breaches",
    },
    {
        title: ReportTypeEnum["contraventions"],
        summaryDescription: "View report on the type and quantity of contraventions breached.",
        iconClass: "fas fa-car",
        iconBackground: "warning",
        urlSlug: "contraventions",
    },
]
export default function Reports(props) {

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row justify-content-between">
                                <div className="col-4">
                                    <h5 className="mb-0">
                                        Reports
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body border-top reports-summaries-container-alt" >
                            {
                                reportsSummaries
                                    .map(item => <ReportSummaryCard
                                        key={ item.urlSlug }
                                        organisationId={ props.organisationId }
                                        reportSummary={ item }
                                    />)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}