import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Loader from '../shared/loader';

function SiteContravention(props) {
    const [contraventionId, setContraventionId] = useState(props.match.params.contraventionId);
    const [siteId, setSiteId] = useState(props.match.params.siteId);
    const [contravention, setContravention] = useState(null);
    const [siteContravention, setSiteContravention] = useState(null);
    const [siteContraventions, setSiteContraventions] = useState(null);
    const [selectedContravention, setSelectedContravention] = useState(null);
    const [allContraventions, setAllContraventions] = useState(null);

    const [isNew, setIsNew] = useState(false);
    const [isInEdit, setIsInEdit] = useState(false);
    const [canSave, setCanSave] = useState(false);

    const [reducedChargeAmount, setReducedChargeAmount] = useState(0);
    const [reducedChargeDays, setReducedChargeDays] = useState(0);
    const [fullChargeAmount, setFullChargeAmount] = useState(0);
    const [fullChargeDays, setFullChargeDays] = useState(0);
    const [status, setStatus] = useState(false);

    const [siteUrl, setSiteUrl] = useState("");
    const [site, setSite] = useState(null);

    const submit = function () {
        
        if (canSave) {
            Api.addSiteContravention(
                (data) => {
                    setContravention(data);
                    setIsInEdit(false);
                    getSiteContraventions();
                    getContravention();
                },
                (error) => {
                    console.log(error);
                    getSiteContraventions();
                },
                selectedContravention.id,
                reducedChargeAmount, reducedChargeDays, fullChargeAmount, fullChargeDays, status,
                props.organisationId,
                siteId,
                props.token
            )
        }
    };

    const getContravention = function () {
        Api.getContravention(
            (data) => setContravention(data),
            (error) => console.log(error),
            props.organisationId,
            contraventionId,
            props.token
        )
    };

    const getSiteContraventions = function () {
        Api.getSiteContraventions(
            (data) => setSiteContraventions(data),
            (error) => console.log(error),
            props.organisationId,
            siteId,
            props.token,
        )
    };

    const getAllContraventions = function () {
        Api.getContraventions(
            (data) => setAllContraventions(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        )
    };

    useEffect(() => {
        if (site !== null) {
            setSiteUrl("/organisation/" + props.organisationId + "/client/" + site.clientId + "/site/" + siteId)
        }
    }, [site]);

    useEffect(() => {
        if (contraventionId !== undefined) {
            getContravention();
            getSiteContraventions();
        } else {
            setIsNew(true);
        }
        getAllContraventions();
        if (site === null && siteId !== null) {
            Api.getSite(
                (data) => setSite(data),
                (error) => console.log(error),
                siteId,
                props.organisationId,
                props.token
            )
        }
    }, []);

    useEffect(() => {
        if (allContraventions !== null) {
            allContraventions.forEach(item => {
                if (item.id === contraventionId) {
                    setSelectedContravention(item);
                }
            });
        }
    }, [isNew, allContraventions]);

    useEffect(() => {
        if (selectedContravention !== null) {
            setCanSave(true);
        } else {
            setCanSave(false);
        }
    }, [selectedContravention, isInEdit]);

    useEffect(() => {
        if (siteContraventions !== null) {
            siteContraventions.forEach(item => {
                if (contraventionId === item.contraventionId) {
                    setSiteContravention(item);
                    setIsNew(false);
                }
            });
        }
    }, [siteContraventions, isInEdit]);

    useEffect(() => {
        if (siteContravention !== null) {
            setReducedChargeAmount(siteContravention.reducedChargeAmount)
            setReducedChargeDays(siteContravention.reducedChargeDays)
            setFullChargeDays(siteContravention.fullChargeDays)
            setFullChargeAmount(siteContravention.fullChargeAmount)
            setStatus(siteContravention.isActive)
            setSelectedContravention(contravention);
        } else {
            setIsNew(true);
        }

    }, [siteContravention, isInEdit]);

    return (
        <>{!siteContravention && !isNew && <Loader />}{siteContravention && siteContravention !== null && contravention && contravention !== null && <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h5 className="mb-0">Site Contravention - {contravention.name}</h5>
                                    </div>
                                    <Link className="btn btn-falcon-default btn-sm mr-3 py-1" type="button" to={siteUrl}><i className="fas fa-reply"></i> Back</Link>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="row justify-content-end align-items-center">

                                    <div className="col-auto">
                                        <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setIsInEdit(!isInEdit)}>
                                            <i className="fas fa-edit"></i> Edit</button>
                                    </div>
                                </div>
                            </div>
                            {!isInEdit && <>
                                <div className="card-body bg-light border-top details-container">
                                    <div className="row align-items-center mb-2">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Status</p>
                                        </div>
                                        <div className=" col-md-6 col-12 ">{siteContravention.isActive && <i className="fas fa-check text-success"></i>}{!siteContravention.isActive && <i className="fas fa-times text-danger"></i>}</div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold ">Reduced Charge Amount</p>
                                        </div>
                                        <div className=" col-md-6 col-12 ">{siteContravention.reducedChargeAmount}</div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold ">Reduced Charge Days</p>
                                        </div>
                                        <div className=" col-md-6 col-12 ">{siteContravention.reducedChargeDays}</div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold ">Full Charge Amount</p>
                                        </div>
                                        <div className=" col-md-6 col-12 ">{siteContravention.fullChargeAmount}</div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold ">Full Charge Days</p>
                                        </div>
                                        <div className=" col-md-6 col-12 ">{siteContravention.fullChargeDays}</div>
                                    </div>
                                </div>
                            </>}
                            {isInEdit && <>
                                <div className="card-body bg-light border-top details-container">
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Status</p>
                                        </div>
                                        <div className=" col-md-6 col-12 custom-control mt-1 custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="status" checked={status} onChange={() => setStatus(!status)} />
                                            <label className="custom-control-label ml-3 " htmlFor="status"></label>
                                            {status && <>Active</>}{!status && <>Deactivated</>}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Reduced Charge Amount</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="number" value={reducedChargeAmount} onChange={(e) => setReducedChargeAmount(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Reduced Charge Days</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="number" value={reducedChargeDays} onChange={(e) => setReducedChargeDays(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Full Charge Amount</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="number" value={fullChargeAmount} onChange={(e) => setFullChargeAmount(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Full Charge Days</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="number" value={fullChargeDays} onChange={(e) => setFullChargeDays(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="row client-edit-buttons">
                                        <div className="col-6 col-sm-auto ml-auto pl-0">
                                            <div id="dashboard-actions">
                                                <button className="btn btn-falcon-default btn-sm" onClick={() => setIsInEdit(false)}><i className="fas fa-times"></i> Cancel</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => submit()}>
                                                    <i className="fas fa-check"></i> Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
        </>}
            {isNew && selectedContravention && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3 details-container">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Add New Site Contravention</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top">
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Contravention</p>
                                        </div>
                                        <div className=" col-md-6 col-12 ">{selectedContravention.name}</div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Status</p>
                                        </div>
                                        <div className=" col-md-6 col-12 custom-control mt-1 custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="status" checked={status} onChange={() => setStatus(!status)} />
                                            <label className="custom-control-label ml-3 " htmlFor="status"></label>
                                            {status && <>Active</>}{!status && <>Deactivated</>}
                                        </div>
                                    </div>   
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Reduced Charge Amount</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="number" value={reducedChargeAmount} onChange={(e) => setReducedChargeAmount(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Reduced Charge Days</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="number" value={reducedChargeDays} onChange={(e) => setReducedChargeDays(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Full Charge Amount</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="number" value={fullChargeAmount} onChange={(e) => setFullChargeAmount(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className=" col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Full Charge Days</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="number" value={fullChargeDays} onChange={(e) => setFullChargeDays(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="row client-edit-buttons">
                                        <div className="col-6 col-sm-auto ml-auto pl-0">
                                            <div id="dashboard-actions">
                                                <Link className="btn btn-falcon-default btn-sm" to={siteUrl}><i className="fas fa-times"></i> Cancel</Link>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => submit()}>
                                                    <i className="fas fa-check"></i> Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}
export default SiteContravention;