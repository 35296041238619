import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import StringHelpers from '../../utilities/stringHelpers';
import arrayUtilities from '../../utilities/arrayUtilities';
import Loader from '../shared/loader';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function AllowedVrms(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [allowedVrms, setAllowedVrms] = useState(null);
    const [similarityThreshold, setSimilarityThreshold] = useState(65);

    const getALlowedVrms = function () {
        setIsLoading(true);

        if (!StringHelpers.isNullOrEmpty(props.vrm)) {
            Api.searchAllowedVrms(
                (data) => {
                    setAllowedVrms(data);
                    setIsLoading(false);
                },
                (error) => console.log(error),
                props.vrm,
                similarityThreshold / 100,
                props.siteId,
                props.organisationId,
                props.token
            )
        }
    };

    const onSimilarityThresholdChanged = function () {
        setTimeout(() => {
            getALlowedVrms();
        }, 250);
    };

    useEffect(() => {
        getALlowedVrms();
    }, []);

    return <div className="card mb-3">
        <div className="card-header">
            <div className="row align-items-center">
                <div className="col">
                    <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                            <button className="button-no-style button-card-header collapsed" id="ringgo-payments" type="button" data-toggle="collapse" data-target="#ringgo-payments-list" aria-expanded="true" aria-controls="ringgo-payments-list">
                                Allowed List&nbsp;
                                {isLoading &&
                                    <Loader />
                                }
                                {!isLoading &&
                                    <>
                                        {!arrayUtilities.isNullOrEmpty(allowedVrms) &&
                                            <span className={"translate-middle badge rounded-pill " + (allowedVrms.length > 0 ? "bg-success" : "bg-danger")}>
                                                <>{allowedVrms.length}</>
                                            </span>
                                        }
                                        &nbsp;<i className="fas fa-chevron-down"></i>
                                    </>
                                }
                            </button>
                        </h5>

                        <div className='d-flex justify-content-between'>
                            <div className='d-flex align-items-center mr-3'>
                                <label className='m-0 mr-3'>Similarity: {similarityThreshold}%</label>
                                <input class="form-range" type="range" min="0" max="100" value={similarityThreshold} onTouchEnd={onSimilarityThresholdChanged} onMouseUp={onSimilarityThresholdChanged} onChange={(e) => setSimilarityThreshold(Number(e.target.value))} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="card-body bg-light border-top details-container p-0 collapse" id="ringgo-payments-list" aria-labelledby="Allow List" data-parent="#ringgo-payments">
            <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                <thead className="bg-200 text-900">
                    <tr>
                        <th>VRM</th>
                        <th>Similarity</th>
                        <th>Driver Name</th>
                        <th>Vehicle Make</th>
                        <th>Vehicle Model</th>
                        <th>Site</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Created Date</th>
                        <th>Created By</th>
                    </tr>
                </thead>
                <tbody>
                    {!arrayUtilities.isNullOrEmpty(allowedVrms) && allowedVrms.map((item) => {
                        var url = "/organisation/" + props.organisationId + "/allowed-vrm/" + item.id;
                        let similarityPercent = (item.similarity * 100).toFixed(0);
                        let similarityClass = item.similarity > 0.99 ? "bg-success" : (item.similarity > 0.6 ? "bg-warning" : "bg-danger");
     
                        return (
                            <tr>
                                <td><Link to={url} className="number-plate-link"><h6 className="mb-0 small-number-plate" >{item.vrm}</h6></Link></td>
                                <td>
                                <div class="progress" style={{minWidth: "100px"}}>
                                    <div class={`progress-bar text-left pl-1 ${similarityClass}`} role="progressbar" style={{width: `${similarityPercent}%`}} aria-valuenow={similarityPercent} aria-valuemin="0" aria-valuemax="100">{similarityPercent}%</div>
                                </div>
                                </td>
                                <td>{item.driverName}</td>
                                <td>{item.vehicleMake}</td>
                                <td>{item.vehicleModel}</td>
                                <td>{item.siteName && <>{item.siteName}</>}{item.siteName === null && <>All</>}</td>
                                <td>{item.startDate === null ? "" : moment(item.startDate).format("DD/MM/yyyy HH:mm")}</td>
                                <td>{item.endDate === null ? "" : moment(item.endDate).format("DD/MM/yyyy HH:mm")}</td>
                                <td>{item.createdTimestamp === null ? "" : moment(item.createdTimestamp).format("DD/MM/yyyy HH:mm")}</td>
                                <td>{item.createdByUserName}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    </div>;
}