import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Enums from '../../utilities/enum';
import Loader from '../shared/loader';

function Contravention(props) {
    const [contraventionId, setContraventionId] = useState(props.match.params.contraventionId);
    const [contravention, setContravention] = useState(null);

    const [name, setName] = useState("");
    const [dvlaCode, setDvlaCode] = useState("");
    const [type, setType] = useState("");
    const [appealReply, setAppealReply] = useState("");
    const [status, setStatus] = useState(false);

    const [contraventionTypes, setContraventionTypes] = useState([]);
    const [contraventionListUrl, setContraventionListUrl] = useState("");

    const [isNew, setIsNew] = useState(false);
    const [isInEdit, setIsInEdit] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const history = useHistory();

    const submit = function () {
        if (canSave) {
            Api.addContravention(
                (data) => {
                    setContravention(data);
                    setIsNew(false);
                    setErrorMessage("");
            },
            (error) => setErrorMessage(error),
                name, dvlaCode, type, status, appealReply,
                props.organisationId,
                props.token
            );
            setIsNew(false);
        }
    };

    const edit = function () {
        if (canSave) {
            Api.editContravention(
                (data) => {
                    setIsInEdit(false);
                    setContravention(data);
                    setErrorMessage("");
            },
            (error) => setErrorMessage(error),
                name, dvlaCode, type, status, appealReply,
                props.organisationId,
                contraventionId,
                props.token
            );
        }
    };

    const getContravention = function () {
        Api.getContravention(
            (data) => setContravention(data),
            (error) => console.log(error),
            props.organisationId,
            contraventionId,
            props.token
        )
    };

    const setTypes = function (types) {
        var tempContraventionTypes = [];
        var i = 0;
        while (i < 2) {
            tempContraventionTypes.push(types[i])
            i++;
        }
        setContraventionTypes(tempContraventionTypes);
    };

    useEffect(() => {
        if (contravention !== null) {
            setName(contravention.name);
            setDvlaCode(contravention.dvlaCode);
            setType(contravention.contraventionType);
            setAppealReply(contravention.appealReply);
            setStatus(contravention.isActive);
        }
        setErrorMessage("");
    }, [contravention, isInEdit]);

    useEffect(() => {
        if (name === null || name === ""
            || type === null || type === ""
            || appealReply === null || appealReply === ""
            || dvlaCode === null || dvlaCode === ""
        ) {
            setCanSave(false);

        } else {
            setCanSave(true);
        }
    }, [name, type, dvlaCode, appealReply]);

    useEffect(() => {
        if (contraventionId !== null && contraventionId !== undefined) {
            getContravention();
        } else {
            setIsNew(true);
        }
        setTypes(Enums.ContraventionTypes);
        setContraventionListUrl("/organisation/" + props.organisationId + "/contravention-settings")
    }, []);

    return (<>
        {!contravention && !isNew && <Loader />}{contravention && contravention !== null && <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h5 className="mb-0">Contravention - {contravention.name}</h5>
                                    </div>
                                    <Link className="btn btn-falcon-default btn-sm mr-3 py-1" type="button" to={contraventionListUrl}><i className="fas fa-reply"></i> Back</Link>

                                    <button className="btn btn-falcon-default btn-sm dropdown-toggle py-2 dropdown-caret-none mr-3" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fas fa-ellipsis-h"></i></button>
                                    <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-start">
                                        <button type="button" className="dropdown-item text-danger" data-toggle="modal" data-target="#deleteModal">
                                            <i className="fas fa-times"></i> Delete Contravention</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3 ">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h5 className="mb-0">Details</h5>
                                    </div>
                                    <div className="col-auto">
                                        <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setIsInEdit(!isInEdit)}>
                                            <i className="fas fa-edit"></i> Edit</button>
                                    </div>
                                </div>
                            </div>
                            {!isInEdit && <>
                                <div className="card-body bg-light border-top details-container">
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Contravention</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{contravention.name}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">DVLA Code</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{contravention.dvlaCode}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Contravention Type</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{Enums.ContraventionTypes[contravention.contraventionType]}</div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Appeal Reply</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{contravention.appealReply}</div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-1">Status</p>
                                        </div>
                                        <div className="col-md-6 col-12  ">{contravention.isActive && <i className="fas fa-check text-success"></i>}{!contravention.isActive && <i className="fas fa-times text-danger"></i>}</div>
                                    </div>
                                </div>
                            </>}
                            {isInEdit && <>
                                <div className="card-body bg-light border-top details-container">
                                {errorMessage !== "" && <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>}
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mt-1 mb-1">Contravention<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mt-1 mb-1">DVLA Code<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={dvlaCode} onChange={(e) => setDvlaCode(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mt-1 mb-1">Contravention Type<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            <div className="form-group mb-0">
                                                <div className="dropdown">
                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-type" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{type === "" && <>Select Type</>}{Enums.ContraventionTypes[type]}</button>
                                                    <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-type" >
                                                        <div className="bg-white py-2 rounded-soft">
                                                            {contraventionTypes.map((type, i = 0) => {
                                                                i++;
                                                                return (
                                                                    <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(i - 1)}>{type}</button>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mt-1 mb-1">Appeal Reply<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <textarea rows={4} className="form-control mb-0" type="text" value={appealReply} onChange={(e) => setAppealReply(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1 mt-1 ">Status</p>
                                        </div>
                                        <div className="col-md-6 col-12 custom-control custom-switch mt-1">
                                            <input className="custom-control-input" type="checkbox" id="switch1" checked={status} onChange={() => setStatus(!status)} />
                                            <label className="custom-control-label ml-3" htmlFor="switch1"></label>
                                            {status && <>Active</>}{!status && <>Deactivated</>}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="row client-edit-buttons">
                                        <div className="col-12 col-sm-auto ml-auto  pl-0">
                                            <div id="dashboard-actions">
                                                <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setIsInEdit(false)}>
                                                    <i className="fas fa-times"></i> Cancel</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => edit()}>
                                                    <i className="fas fa-check"></i> Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteModalLabel">Are you sure you want to delete this contravention?</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger btn-sm ml-2" data-dismiss="modal" onClick={() => Api.deleteContravention(() => history.push(contraventionListUrl), props.organisationId, contraventionId, props.token)}>
                                Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>} {!contravention && isNew && <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3 ">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h5 className="mb-0">Create New Contravention</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body bg-light border-top details-container">
                            {errorMessage !== "" && <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>}
                                <div className="row">
                                    <div className="col-md-3 col-12 ">
                                        <p className="font-weight-semi-bold  mt-1 mb-1">Contravention<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="form-group mb-1">
                                            <input className="form-control mb-0" type="text" autoFocus={true} value={name} onChange={(e) => setName(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-12 ">
                                        <p className="font-weight-semi-bold  mt-1 mb-1">DVLA Code<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="form-group mb-1">
                                            <input className="form-control mb-0" type="text" value={dvlaCode} onChange={(e) => setDvlaCode(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-12 ">
                                        <p className="font-weight-semi-bold  mt-1 mb-1">Contravention Type<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-6 col-12 ">
                                        <div className="form-group mb-0">
                                            <div className="dropdown">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-type" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{type === "" && <>Select Type</>}{Enums.ContraventionTypes[type]}</button>
                                                <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-type" >
                                                    <div className="bg-white py-2 rounded-soft">
                                                        {contraventionTypes.map((type, i = 0) => {
                                                            i++;
                                                            return (
                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(i - 1)}>{type}</button>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-12 ">
                                        <p className="font-weight-semi-bold  mt-1 mb-1">Appeal Reply<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="form-group mb-1">
                                            <textarea rows={4} className="form-control mb-0" type="text" value={appealReply} onChange={(e) => setAppealReply(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-12 ">
                                        <p className="font-weight-semi-bold  mt-1 mb-1">Status</p>
                                    </div>
                                    <div className="col-md-6 col-12 custom-control custom-switch">
                                        <input className="custom-control-input" type="checkbox" id="site-status" checked={status} onChange={() => setStatus(!status)} />
                                        <label className="custom-control-label ml-3" htmlFor="site-status"></label>
                                        {status && <>Active</>}{!status && <>Deactivated</>}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer border-top ">
                                <div className="row client-edit-buttons">
                                    <div className="col-12 col-sm-auto ml-auto  pl-0">
                                        <div id="dashboard-actions">
                                            <Link className="btn btn-falcon-default btn-sm" to={contraventionListUrl}><i className="fas fa-times"></i> Cancel</Link>
                                            <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => submit()}>
                                                <i className="fas fa-check"></i> Confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>}
    </>)
}
export default Contravention;