import moment from 'moment';
import Enums from './enum';

const getDeviceStatusDetails = (pulse) => {
    const minsAgo = Math.abs(moment(pulse).diff(moment.now(), 'minutes'));
    const formattedElapsedTime = moment(pulse).fromNow();
    
    if (minsAgo >= 90) {
        return {
            status: 1,
            statusPillButton:
                <span className="badge badge rounded-pill badge-danger">
                    {formattedElapsedTime} <i className="fas fa-times"></i>
                </span>
        }

    } else if (minsAgo >= 30) {
        return {
            status: 2,
            statusPillButton:
                <span className="badge badge rounded-pill badge-warning">
                    {formattedElapsedTime} <i class="fas fa-exclamation"></i>
                </span>
        }
    } else if (minsAgo < 30) {
        return {
            status: 3,
            statusPillButton:
                <span className="badge badge rounded-pill badge-success">
                    {formattedElapsedTime} <i className="fas fa-check"></i>
                </span>
        }
    }
}


export const getDeviceStatus = (device, deviceType) => {
    let deviceStatusReturnUtils = {
        status: 0,
        statusPillButton:
            <span className="badge badge rounded-pill badge-danger">
                Unknown <i className="fas fa-question"></i>
            </span>
    }
    
    let pulse;

    switch (deviceType) {
        case Enums.DeviceType[0]:
            // camera
            pulse = device.lastHeartbeat;
            if (device.isActive && pulse !== null) {
                deviceStatusReturnUtils = getDeviceStatusDetails(pulse);
            }
            break;
        case Enums.DeviceType[1]:
            // kiosk
            pulse = device.lastPing;
            if (pulse !== null) {
                deviceStatusReturnUtils = getDeviceStatusDetails(pulse);
            }
            break;
        default:
            break;
    }
    return deviceStatusReturnUtils;
}