import React, { useState, useEffect } from "react";
import Api from "../api/api";
import Enums from "../../utilities/enum";
import { useToasts } from "react-toast-notifications";
import { AllSitesSummaries } from "./allSitesSummaries";
import { DatePicker } from "../shared/datePicker";

function EnforcementDashboard(props) {
	const [searchQuery, setSearchQuery] = useState("");
	const [filterEndTemp, setFilterEndTemp] = useState(null);
	const [filterEnd, setFilterEnd] = useState(null);
	const [filterStartTemp, setFilterStartTemp] = useState(null);
	const [filterStart, setFilterStart] = useState(null);
	const [type, setType] = useState(null);
	const [status, setStatus] = useState(null);

	const [sites, setSites] = useState(null);

	const [breachesToAllocate, setBreachesToAllocate] = useState(null);
	const [selectedSites, setSelectedSites] = useState([]);
	const [canAllocate, setCanAllocate] = useState(false);
	const [user, setUser] = useState(null);
	const [users, setUsers] = useState(null);
	const [siteSummaries, setSiteSummaries] = useState([]);

	const { addToast } = useToasts();

	var _breachesAllocateAbortController = null;

	const clearFilters = function () {
		setStatus(null);
		setType(null);
		setFilterStartTemp(null);
		setFilterEndTemp(null);
		setFilterEnd(null);
		setFilterStart(null);
	};

	const updateSites = function (value, index) {
		let tempArray = [];
		if (sites !== null) {
			sites.forEach(() => {
				tempArray.push(0);
			});
			if (!value) {
				tempArray[index] = 1;
			}
		}
		setSelectedSites(tempArray);
	};

	const allocateTickets = function (reallocate) {
		var i = 0;
		var status = [
			100, 101, 102, 103, 104, 105, 200, 201, 202, 210, 211, 212, 220, 221, 222, 300, 301,
			302, 303, 304, 305, 306, 307, 308, 309, 400, 401, 402, 403, 501, 503,
		];
		while (i < sites.length) {
			if (selectedSites[i] === 1) {
				if (_breachesAllocateAbortController) {
					_breachesAllocateAbortController.abort();
				}
				_breachesAllocateAbortController = new AbortController();
				const signal = _breachesAllocateAbortController.signal;
				Api.getBreaches(
					(data) => {
						setBreachesToAllocate(data);
						updateSites(true, null);
					},
					(error) => console.log(error),
					null,
					sites[i].id,
					null,
					reallocate,
					null,
					null,
					null,
					status,
					0,
					props.organisationId,
					props.token,
					signal
				);
			}
			i++;
		}
	};

	const getUsers = function () {
		Api.getUsers(
			(data) => setUsers(data),
			(error) => console.log(error),
			0,
			props.organisationId,
			props.token
		);
	};

	useEffect(() => {
		updateSites(true, null);
	}, [sites]);

	useEffect(() => {
		if (user && breachesToAllocate && breachesToAllocate.items.length !== 0) {
			var idsToAllocate = [];
			breachesToAllocate.items.forEach((breach) => {
				idsToAllocate.push(breach.id);
			});
			Api.assignTickets(
				() => {
					addToast("Tickets assigned successfully", {
						appearance: "success",
						autoDismiss: true,
					});
					getSites();
					setUser(null);
				},
				(error) => console.log(error),
				user.id,
				idsToAllocate,
				props.organisationId,
				props.token
			);
		}
	}, [breachesToAllocate]);

	useEffect(() => {
		if (user === null) {
			setCanAllocate(false);
		} else {
			setCanAllocate(false);

			selectedSites.forEach((site) => {
				if (site === 1) {
					setCanAllocate(true);
				}
			});
		}
	}, [user, selectedSites]);

	const getSites = function () {
		Api.getSites(
			(data) => setSites(data),
			(error) => console.log(error),
			props.organisationId,
			props.token
		);
	};

	const getSitesSummaries = () => {
		Api.getAllSitesSummaries(
			data => {setSiteSummaries(data);},
			() => {},
			searchQuery,
			filterStart,
			filterEnd,
			type,
			status,
			props.organisationId,
			props.token
		);
	};

	useEffect(() => {
		getSitesSummaries();
	}, [searchQuery, type, status, filterEnd, filterStart])

	useEffect(() => {
		getSites();
		getUsers();
	}, []);

	return (
		<div className="container-fluid">
			<div className="enforcement-main-nav bg-white">
				<div className="enforcement-main-nav-section">
					<div className="d-flex align-items-center pr-0">
						<h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
							Enforcement
						</h5>
					</div>
				</div>
				<div className="enforcement-main-nav-section">
					<div className="pr-2 border-right filter-buttons-wrapper d-flex align-items-center">
						<div className="input-group mr-1">
							<input
								className="form-control"
								value={searchQuery}
								type="text"
								placeholder="search..."
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
							<div className="input-group-append">
								<button
									className="btn btn-falcon-default btn-sm py-0"
									onClick={() => clearFilters()}>
									<i className="fas fa-times"></i> Clear
								</button>
							</div>
						</div>
						<button
							className="btn btn-falcon-default btn-sm mr-1 ml-1 nowrap"
							data-toggle="modal"
							data-target="#dateModal">
							Filter by Date
						</button>
						<div className="filter-buttons" id="select-type">
							<button
								className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1"
								id="select-type"
								type="button"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false">
								{type !== null ? (
									<>
										Type:&nbsp;
										{Enums.ContraventionTypes[Number(type)]}
									</>
								) : (
									<>Filter by Type</>
								)}
							</button>
							<div
								className="dropdown-menu dropdown-menu-right py-0"
								aria-labelledby="select-type">
								<div className="bg-white py-3 rounded-soft">
									<button
										className="dropdown-item text-base px-3 py-2"
										onClick={() => setType(null)}>
										All
									</button>
									{Object.entries(Enums.ContraventionTypes).map(
										([key, value]) => (
											<button
												key={key}
												onClick={() => setType(parseInt(key))}
												className="dropdown-item text-base px-3 py-2">
												{value}
											</button>
										)
									)}
								</div>
							</div>
						</div>
						<div className="filter-buttons" id="select-status">
							<button
								className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1"
								id="select-status"
								type="button"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false">
								{status !== null ? (
									<>Status:&nbsp;{Enums.BreachStatus[status]}</>
								) : (
									<>Filter by Status</>
								)}
							</button>
							<div
								className="dropdown-menu py-0 "
								aria-labelledby="select-status">
								<div className="bg-white py-3 rounded-soft tall-dropdown">
									<button
										className="dropdown-item text-base px-3 py-2"
										onClick={() => setStatus(null)}>
										All
									</button>
									{Object.entries(Enums.BreachStatus).map(
										([key, value]) => {
											return (
												<button
													key={key}
													className="dropdown-item text-base px-3 py-2"
													onClick={() =>
														setStatus([parseInt(key)])
													}>
													{value}
												</button>
											);
										}
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex align-items-center" style={{paddingLeft: "12px"}}>
						<button
							className="btn btn-falcon-default btn-sm mr-2"
							data-toggle="modal"
							data-target="#re-allocateModal">
							Re-Allocate
						</button>
						<button
							className="btn btn-falcon-default btn-sm"
							data-toggle="modal"
							data-target="#allocateModal">
							Allocate
						</button>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12 mb-3">
					<div
						className="modal fade"
						id="dateModal"
						tabIndex="-1"
						role="dialog"
						aria-labelledby="deleteModalLabel"
						aria-hidden="true">
						<div className="modal-dialog" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="deleteModalLabel">
										Choose the start and end date to filter by
									</h5>
									<button
										type="button"
										className="close"
										data-dismiss="modal"
										aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<DatePicker
										date={filterStartTemp}
										title="From"
										handleChange={(date) =>
											setFilterStartTemp(date)
										}
									/>
									<DatePicker
										date={filterEndTemp}
										title="To"
										handleChange={(date) => setFilterEndTemp(date)}
									/>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-falcon-default btn-sm ml-2"
										data-dismiss="modal">
										Cancel
									</button>
									<button
										type="button"
										className="btn btn-success btn-sm ml-2"
										data-dismiss="modal"
										onClick={
											() => {
												setFilterStart(filterStartTemp);
												setFilterEnd(filterEndTemp);
											}
										}>
										Filter
									</button>
								</div>
							</div>
						</div>
					</div>
					<div
						className="modal fade"
						id="allocateModal"
						tabIndex="-1"
						role="dialog"
						aria-labelledby="deleteModalLabel"
						aria-hidden="true">
						<div className="modal-dialog" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="deleteModalLabel">
										Select a new Site Breach Allocation
									</h5>
									<button
										type="button"
										className="close"
										data-dismiss="modal"
										aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<div className="row mb-2">
										<div className="col-12 ">
											<h5 className="text-danger font-weight-semi-bold">
												Important:
											</h5>
											<p className="font-weight-bold mt-1 mb-1">
												This will allocate the next 100 tickets
												currently not allocated to any user for
												the selected site.
											</p>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-12 ">
											<p className="font-weight-semi-bold mt-1 mb-1">
												<button
													className="button-no-style tag-list-button collapsed"
													id="sites"
													type="button"
													data-toggle="collapse"
													data-target="#siteList"
													aria-expanded="true"
													aria-controls="siteList">
													Select Site:
												</button>
											</p>
										</div>
										<div className="col-12">
											<div
												className="checkbox-dropdown"
												id="siteList"
												aria-labelledby="Tag List"
												data-parent="#sites">
												<div className="row justify-content-start">
													{sites &&
														sites.map((site, i) => {
															var isSelected = false;
															if (
																selectedSites[i] !== 0
															) {
																isSelected = true;
															}
															return (
																<div
																	key={i}
																	className="col-6">
																	<div className="form-group mb-0">
																		<div className="custom-control custom-checkbox">
																			<input
																				className="custom-control-input"
																				type="checkbox"
																				checked={isSelected}
																				id={site.id}
																				onChange={() => updateSites(isSelected,i)}
																			/>
																			<label
																				className="custom-control-label"
																				htmlFor={site.id}>
																				{site.name}
																			</label>
																		</div>
																	</div>
																</div>
															);
														})}
												</div>
											</div>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-12 ">
											<p className="font-weight-semi-bold pl-1 mt-1 mb-1">
												Select User
											</p>
										</div>
										<div className="col-12">
											<button
												className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1 edit-dropdown"
												id="select-status"
												type="button"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false">
												{user === null && <>Select User</>}
												{user !== null && (
													<>{user.firstName} {user.lastName}</>
												)}
											</button>
											<div
												className="dropdown-menu py-0"
												aria-labelledby="select-site">
												<div className="bg-white py-3 rounded-soft">
													{users &&
														users.map((user, index) => {
															if (
																user.organisations[0]
																	.roleId === 0 ||
																user.organisations[0]
																	.roleId === 1 ||
																user.organisations[0]
																	.roleId === 2
															) {
																return (
																	<button
																		key={index}
																		className="dropdown-item text-base px-3 py-2"
																		onClick={() => setUser(user)}>
																		{user.firstName}&nbsp;{user.lastName}
																	</button>
																);
															}
														})}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-falcon-default btn-sm ml-2"
										data-dismiss="modal">
										Cancel
									</button>
									<button
										type="button"
										className="btn btn-success btn-sm ml-2"
										data-dismiss="modal"
										disabled={!canAllocate}
										onClick={() => allocateTickets(false)}>
										Confirm
									</button>
								</div>
							</div>
						</div>
					</div>
					<div
						className="modal fade"
						id="re-allocateModal"
						tabIndex="-1"
						role="dialog"
						aria-labelledby="deleteModalLabel"
						aria-hidden="true">
						<div className="modal-dialog" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title" id="deleteModalLabel">
										Select a new Site Breach Re-Allocation
									</h5>
									<button
										type="button"
										className="close"
										data-dismiss="modal"
										aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<div className="row mb-2">
										<div className="col-12 ">
											<h5 className="text-danger font-weight-semi-bold">
												Important:
											</h5>
											<p className="font-weight-bold mt-1 mb-1">
												This will allocate the next 100 tickets
												allocated to any user for the selected
												site.
												<br />
												Including tickets already allocated to a
												user.
											</p>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-12 ">
											<p className="font-weight-semi-bold mt-1 mb-1">
												<button
													className="button-no-style tag-list-button collapsed"
													id="sites"
													type="button"
													data-toggle="collapse"
													data-target="#siteList"
													aria-expanded="true"
													aria-controls="siteList">
													Select Site:
												</button>
											</p>
										</div>
										<div className="col-12">
											<div
												className="checkbox-dropdown"
												id="siteList"
												aria-labelledby="Tag List"
												data-parent="#sites">
												<div className="row justify-content-start">
													{sites &&
														sites.map((site, i) => {
															var isSelected = false;
															if (selectedSites[i] !== 0) {isSelected = true}
															return (
																<div
																	key={i}
																	className="col-6">
																	<div className="form-group mb-0">
																		<div className="custom-control custom-checkbox">
																			<input
																				className="custom-control-input"
																				type="checkbox"
																				checked={isSelected}
																				id={site.id}
																				onChange={() => updateSites(isSelected,i)}
																			/>
																			<label
																				className="custom-control-label"
																				htmlFor={site.id}>
																				{site.name}
																			</label>
																		</div>
																	</div>
																</div>
															);
														})}
												</div>
											</div>
										</div>
									</div>
									<div className="row mb-2">
										<div className="col-12 ">
											<p className="font-weight-semi-bold pl-1 mt-1 mb-1">
												Select User
											</p>
										</div>
										<div className="col-12">
											<button
												className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1 edit-dropdown"
												id="select-status"
												type="button"
												data-toggle="dropdown"
												aria-haspopup="true"
												aria-expanded="false">
												{user === null && <>Select User</>}
												{user !== null && (
													<>
														{user.firstName} {user.lastName}
													</>
												)}
											</button>
											<div
												className="dropdown-menu py-0"
												aria-labelledby="select-site">
												<div className="bg-white py-3 rounded-soft">
													{users &&
														users.map((user, index) => (
															<button
																key={index}
																className="dropdown-item text-base px-3 py-2"
																onClick={() =>setUser(user)}>
																{user.firstName}{" "}
																{user.lastName}
															</button>
														))}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer">
									<button
										type="button"
										className="btn btn-falcon-default btn-sm ml-2"
										data-dismiss="modal">
										Cancel
									</button>
									<button
										type="button"
										className="btn btn-success btn-sm ml-2"
										data-dismiss="modal"
										disabled={!canAllocate}
										onClick={() => allocateTickets(null)}>
										Confirm
									</button>
								</div>
							</div>
						</div>
					</div>
					{siteSummaries.length > 0 && (
						<AllSitesSummaries
							status={status}
							type={type}
							filterEnd={filterEnd}
							filterStart={filterStart}
							searchQuery={searchQuery}
							siteSummaries={siteSummaries}
							organisationId={props.organisationId}
							token={props.token}
						/>
					)}
				</div>
			</div>
		</div>
	);
}

export default EnforcementDashboard;
