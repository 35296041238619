import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Enums from '../../utilities/enum';
import Loader from '../shared/loader';

function ReferenceNumberRanges(props) {
    const [numberRanges, setNumberRanges] = useState(null);
    const [contraventionTypes, setContraventionTypes] = useState(null);
    const newRefNumRangeUrl = "/organisation/" + props.organisationId + "/reference-number-range";

    const getNumberRanges = function () {
        Api.getNumberRanges(
            (data) => setNumberRanges(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        )
    };

    const setTypes = function () {
        var i = 0;
        var tempTypes = [];
        while (Enums.ContraventionTypes[i] !== undefined) {
            tempTypes.push(Enums.ContraventionTypes[i]);
            i++;
        }
        setContraventionTypes(tempTypes);
    };

    useEffect(() => {
        getNumberRanges();
        setTypes();
    }, []);

    return (
        <>
            {numberRanges == null && <Loader />}
            {contraventionTypes === null && numberRanges !== null && <Loader />}
            {contraventionTypes !== null && numberRanges !== null &&
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col-3">
                                            <h5 className="mb-0">Reference Number Ranges</h5>
                                        </div>
                                        <div className="col-9 text-right pl-0 filter-buttons-wrapper">
                                            <Link className="btn btn-success btn-sm ml-1" to={newRefNumRangeUrl}><i className="fas fa-plus"></i> Create New</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th>Contravention Type</th>
                                                <th>Prefix</th>
                                                <th>Start</th>
                                                <th>Min Digits</th>
                                                <th className="text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contraventionTypes !== null && numberRanges !== null && contraventionTypes.map((type) => {
                                                var numberRangeList = [];

                                                numberRanges.forEach(item => {
                                                    if (Enums.ContraventionTypes[item.contraventionType] === type) {
                                                        numberRangeList.push(item);
                                                    }
                                                });
                                                if (numberRangeList.length !== 0) {
                                                    return (
                                                        numberRangeList.map((numberRange) => {
                                                            var editUrl = newRefNumRangeUrl + "/" + numberRange.id
                                                            return (
                                                                <tr>
                                                                    <td><Link to={editUrl}><h6 className="mb-0">{type}</h6></Link></td>
                                                                    <td>{numberRange.prefix}</td>
                                                                    <td>{numberRange.start}</td>
                                                                    <td>{numberRange.minimumDisplayDigits}</td>
                                                                    <td className="text-center">{numberRange.isActive && <><i className="fas fa-check text-success"></i></>}{!numberRange.isActive && <><i className="fas fa-times text-danger"></i></>}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    )
                                                } else {
                                                    return (
                                                        <tr>
                                                            <td><h6 className="mb-0">{type}</h6></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    )
}
export default ReferenceNumberRanges;