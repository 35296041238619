import moment from 'moment';
import React from 'react';


function Footer() {
    const year = moment.now();
    return (
        <div className="container-fluid">
        <footer className="footer">
            <div className="row g-0 justify-content-between fs--1 mt-4 mb-3">
                <div className="col-12">
                    <p className="font-weight-semi-bold mb-0  text-center">
                    <a href="https://www.advancedparking.tech" target="_blank">Advanced Parking Tech Ltd</a> &copy; {moment(year).format("yyyy")} 
                    </p>
                </div>
            </div>
        </footer>
        </div>
    )
}

export default Footer;