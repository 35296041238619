import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Enums from '../../utilities/enum';
import Loader from '../shared/loader';

function ReferenceNumberRange(props) {
    const [refNumRange, setRefNumRange] = useState(null);
    const [refNumRangeId, setRefNumRangeId] = useState(props.match.params.numberRangeId);
    const [contraventionTypes, setContraventionTypes] = useState(null);

    const refNumListUrl = "/organisation/" + props.organisationId + "/reference-number-ranges";

    const [isNew, setIsNew] = useState(false);
    const [isInEdit, setIsInEdit] = useState(false);
    const [canSave, setCanSave] = useState(false);

    const [start, setStart] = useState(0);
    const [minDigits, setMinDigits] = useState(0);
    const [type, setType] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [prefix, setPrefix] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const getRefNumRange = function () {
        Api.getNumberRange(
            (data) => setRefNumRange(data),
            (error) => console.log(error),
            refNumRangeId,
            props.organisationId,
            props.token
        )
    };

    const setTypes = function () {
        var i = 0;
        var tempTypes = [];
        while (Enums.ContraventionTypes[i] !== undefined) {
            tempTypes.push(Enums.ContraventionTypes[i]);
            i++;
        }
        setContraventionTypes(tempTypes);
    };

    const addNew = function () {
        if (canSave) {
            Api.addNewNumberRange(
                (data) => {
                    setRefNumRange(data);
                    setIsNew(false);
                    setErrorMessage("");
                },
                (error) => setErrorMessage(error),
                prefix, start, minDigits, isActive, type,
                props.organisationId,
                props.token
            )
        }
    };

    const edit = function () {
        if (canSave) {
            Api.editNumberRange(
                (data) => {
                    setRefNumRange(data);
                    setIsInEdit(false);
                    setErrorMessage("");
                },
                (error) => setErrorMessage(error),
                prefix, start, minDigits, isActive, type, refNumRangeId,
                props.organisationId,
                props.token
            )
        }
    };

    useEffect(() => {
        if (type === null || minDigits < 1 || prefix === null || prefix === "" || start < 0) {
            setCanSave(false);
        } else {
            setCanSave(true);
        }
    }, [prefix, minDigits, type, start]);

    useEffect(() => {
        if (refNumRange !== null) {
            setStart(refNumRange.start);
            setPrefix(refNumRange.prefix);
            setMinDigits(refNumRange.minimumDisplayDigits);
            setIsActive(refNumRange.isActive);
            setType(refNumRange.contraventionType);
        }
        setErrorMessage("");
    }, [refNumRange, isInEdit]);

    useEffect(() => {
        if (refNumRangeId !== undefined) {
            getRefNumRange();
        } else {
            setIsNew(true);
        }
        setTypes();
    }, []);

    return (
        <>
            {!isNew && refNumRange === null && <Loader />}
            {!isNew && refNumRange !== null && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Reference Number Range for {Enums.ContraventionTypes[type]}</h5>
                                        </div>
                                        <Link className="btn btn-falcon-default btn-sm mr-3 py-1" type="button" to={refNumListUrl}><i className="fas fa-reply"></i> Back</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Details</h5>
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setIsInEdit(!isInEdit)}>
                                                <i className="fas fa-edit"></i> Edit</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Contravention Type</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{Enums.ContraventionTypes[type]}</div>
                                    </div>
                                    {!isInEdit && <>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Prefix</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{refNumRange.prefix}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Start</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{refNumRange.start}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Minimum Digits</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{refNumRange.minimumDisplayDigits}</div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-1">Status</p>
                                            </div>
                                            <div className="col-md-6 col-12  ">{refNumRange.isActive && <i className="fas fa-check text-success"></i>}{!refNumRange.isActive && <i className="fas fa-times text-danger"></i>}</div>
                                        </div>
                                    </>}
                                    {isInEdit && <>
                                        {errorMessage !== "" && <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>}
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Prefix<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="text" value={prefix} onChange={(e) => setPrefix(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Start<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="number" value={start} onChange={(e) => setStart(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Minimum Digits<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-0">
                                                    <input className="form-control mb-1" type="number" value={minDigits} onChange={(e) => setMinDigits(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Status</p>
                                            </div>
                                            <div className="col-md-6 col-12 custom-control custom-switch">
                                                <input className="custom-control-input" type="checkbox" id="is-active" checked={isActive} onChange={() => setIsActive(!isActive)} />
                                                <label className="custom-control-label ml-3" htmlFor="is-active"></label>
                                                {isActive && <>Active</>}{!isActive && <>Deactivated</>}
                                            </div>
                                        </div>
                                    </>}
                                </div>
                                {isInEdit &&
                                    <div className="card-footer border-top ">
                                        <div className="row client-edit-buttons">
                                            <div className="col-12 col-sm-auto ml-auto  pl-0">
                                                <div id="dashboard-actions">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setIsInEdit(false)}>
                                                        <i className="fas fa-times"></i> Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => edit()}>
                                                        <i className="fas fa-check"></i> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </>}
            {isNew && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="col-md-5 col-12 col-sm-auto d-flex align-items-center pr-0">
                                        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Add new Reference Number Range</h5>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                {errorMessage !== "" && <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>}
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Contravention Type<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            <div className="form-group mb-0">
                                                <div className="dropdown">
                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-type" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{type !== null && Enums.ContraventionTypes[type]}{type === null && <>Select the Contravention Type</>}</button>
                                                    <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-type" >
                                                        <div className="bg-white py-2 rounded-soft">
                                                            {contraventionTypes.map((type, i = 0) => {
                                                                i++;
                                                                return (
                                                                    <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(i - 1)}>{type}</button>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Prefix<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={prefix} onChange={(e) => setPrefix(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Start<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="number" value={start} onChange={(e) => setStart(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Minimum Digits<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="number" value={minDigits} onChange={(e) => setMinDigits(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Status</p>
                                        </div>
                                        <div className="col-md-6 col-12 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="is-active" checked={isActive} onChange={() => setIsActive(!isActive)} />
                                            <label className="custom-control-label ml-3" htmlFor="is-active"></label>
                                            {isActive && <>Active</>}{!isActive && <>Deactivated</>}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="row client-edit-buttons">
                                        <div className="col-12 col-sm-auto ml-auto  pl-0">
                                            <div id="dashboard-actions">
                                                <Link className="btn btn-falcon-default btn-sm" to={refNumListUrl}><i className="fas fa-times"></i> Cancel</Link>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => addNew()}>
                                                    <i className="fas fa-check"></i> Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}
export default ReferenceNumberRange;