export const OptionsSelector = ({ data, handleOptionSelect, selectedValue, label }) => {
    return (
        <div className="options-selector-container">
            <label for="options-selector" className="m-0">{ label }</label>
            <select
                id="options-selector"
                class="border rounded py-1 px-2"
                onChange={ (e) => handleOptionSelect(e.target.value) }
                value={ selectedValue }
            >
                { data && Object.entries(data).map(([key, value]) =><option key={ key } value={ key }>{ value }</option>) }
            </select>
        </div>
    )
}