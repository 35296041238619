import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Api from '../api/api';
import moment from 'moment';

import Loader from '../shared/loader';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import Pagination from '../shared/pagination';

function AllowList(props) {
    const [allowList, setAllowList] = useState(null);
    const [site, setSite] = useState(null);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState(null);

    const [filterEnd, setFilterEnd] = useState(null);
    const [filterEndTemp, setFilterEndTemp] = useState(null);
    const [filterStart, setFilterStart] = useState(null);
    const [filterStartTemp, setFilterStartTemp] = useState(null);

    const [pageNumber, setPageNumber] = useState(0);

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        var now = moment.now();
        var date = moment(now).format('DD/MM/yyyy');
        var fileName = 'Allow_List_' + date.toString();
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    const changePageNumber = function (num) {
        setPageNumber(num);
    };

    const getAllowList = function () {
        Api.getAllowList(
            (data) => setAllowList(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        );
    };

    const clearFilters = function () {
        setSearch("");
        setSite(null);
        setStatus(null);
        setFilterStart(null);
        setFilterStartTemp(null);
        setFilterEnd(null);
        setFilterEndTemp(null);
    };

    const setFilterDates = function () {
        setFilterStart(filterStartTemp);
        setFilterEnd(filterEndTemp);
    };

    const checkIfInDateRange = function (start, end) {
        const startDate = moment(start);
        const endDate = moment(end);
        const filterStartDate = moment(filterStart);
        const filterEndDate = moment(filterEnd);

        if (filterStart !== null && startDate.isValid()) {
            var diff = startDate.diff(filterStartDate, 'days');
            if (!(diff > 0)) {
                return false
            }
        }
        if (filterEnd !== null && endDate.isValid()) {
            var diff = endDate.diff(filterEndDate);
            if (!(diff < 0)) {
                return false
            }
        }
        return true;
    };

    useEffect(() => {
        setPageNumber(0);
    }, [search, filterStart, filterEnd, status, site, allowList]);

    const checkIfNotValid = function (status, start, end) {
        if (status === "Indefinite" || status === null) {
            return true;
        }
        const startDate = moment(start);
        const endDate = moment(end);
        const today = moment.now();
        if (status === "Active") {
            if (!(startDate.isValid() && endDate.isValid())) {
                return false;
            }
            else {
                if (startDate.diff(today) < 0 && endDate.diff(today) > 0)
                    return false;
            }
        } else if (status === "Deactivated") {
            if (startDate.diff(today) > 0 || endDate.diff(today) < 0) {
                return false;
            }
        }
        return true;
    };


    useEffect(() => {
        getAllowList();
    }, []);

    var count = 0;
    var addNewUrl = "/organisation/" + props.organisationId + "/allowed-vrm";
    return (
        <>{!allowList && <Loader />} {allowList && allowList !== null &&
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-2 col-sm-auto d-flex align-items-center pr-0">
                                            <h5 className="mb-0">Allow List</h5>
                                        </div>
                                        <div className="col-10 ml-auto text-right pl-0 filter-buttons-wrapper">
                                            <div className="input-group">
                                                {search === null && <>
                                                    <input className="form-control" value={null} type="text" placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                                </>}
                                                {search !== null && <>
                                                    <input className="form-control" value={search} type="text" autoFocus={true} placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                                </>}
                                                <div className="input-group-append">
                                                    <button className="btn btn-falcon-default btn-sm py-0" onClick={() => clearFilters()}><i className="fas fa-times"></i> Clear</button>
                                                </div>
                                            </div>
                                            <button className="btn btn-falcon-default btn-sm mr-1 ml-1 nowrap" data-toggle="modal" data-target="#dateModal">Filter by Date</button>
                                            <div className="filter-buttons" id="select-site">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{site && site !== null && <>{site.name}</>}{!site && <>Filter by Site</>}</button>
                                                <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-site" >
                                                    <div className="bg-white py-3 rounded-soft">
                                                        <button className="dropdown-item text-base px-3 py-2 edit-dropdown" onClick={() => setSite(null)}>All</button>
                                                        {props.sites && props.sites !== null && props.sites.map((currentSite) => {
                                                            return (
                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setSite(currentSite)}>{currentSite.name}</button>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="filter-buttons" id="select-status">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{status && status !== null && <>Status: {status}</>}{!status && <>Filter by Status</>}</button>
                                                <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-status" >
                                                    <div className="bg-white py-3 rounded-soft">
                                                        <button className="dropdown-item text-base px-3 py-2 edit-dropdown" onClick={() => setStatus(null)}>All</button>
                                                        <button className="dropdown-item text-base px-3 py-2 edit-dropdown" onClick={() => setStatus("Active")}>Active</button>
                                                        <button className="dropdown-item text-base px-3 py-2 edit-dropdown" onClick={() => setStatus("Deactivated")}>Deactivated</button>
                                                        <button className="dropdown-item text-base px-3 py-2 edit-dropdown" onClick={() => setStatus("Indefinite")}>Indefinite</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link className="btn btn-success btn-sm ml-1 nowrap" to={addNewUrl}><i className="fas fa-plus"></i> Add</Link>
                                            <button className="btn btn-primary btn-sm ml-1 nowrap" onClick={(e) => exportToCSV(allowList)}>Export</button>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="dateModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="deleteModalLabel">Choose the start and end date to filter by</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-12 ">
                                                            <p className="font-weight-semi-bold  mt-1 mb-1">Period Start</p>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="form-group mb-0">
                                                                {!filterStartTemp &&
                                                                    <>
                                                                        <input className="form-control mb-1" type="datetime-local" value={null} onChange={(e) => setFilterStartTemp(e.target.value)} />

                                                                    </>}
                                                                {filterStartTemp &&
                                                                    <input className="form-control mb-1" type="datetime-local" value={filterStartTemp} onChange={(e) => setFilterStartTemp(e.target.value)} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 ">
                                                            <p className="font-weight-semi-bold  mt-1 mb-1">Period End</p>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="form-group mb-0">
                                                                {!filterEndTemp &&
                                                                    <>
                                                                        <input className="form-control mb-1" type="datetime-local" value={null} onChange={(e) => setFilterEndTemp(e.target.value)} />
                                                                    </>}
                                                                {filterEndTemp &&
                                                                    <input className="form-control mb-1" type="datetime-local" value={filterEndTemp} onChange={(e) => setFilterEndTemp(e.target.value)} />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" data-dismiss="modal" onClick={() => setFilterDates()}>
                                                        Filter</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top">
                                    <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                        <thead className="bg-200 text-900">
                                            <tr>
                                                <th>Vehicle Registration</th>
                                                <th>Driver Name</th>
                                                <th>Vehicle Make</th>
                                                <th>Vehicle Model</th>
                                                <th>Site</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Created Date</th>
                                                <th>Created By</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allowList.filter(function (item) {
                                                var vrm = new String(item.vrm).toLowerCase();
                                                var driverName = new String(item.driverName).toLowerCase();
                                                var make = new String(item.vehicleMake).toLowerCase();
                                                var model = new String(item.vehicleModel).toLowerCase();
                                                return ((site === null || item.siteId === site.id || item.siteId === null) &&
                                                    (
                                                        search === null || search === "" ||
                                                        vrm.search(search.toLowerCase()) !== -1
                                                        || driverName.search(search.toLowerCase()) !== -1
                                                        || make.search(search.toLowerCase()) !== -1
                                                        || model.search(search.toLowerCase()) !== -1
                                                    )
                                                    && (status === null || (item.startDate === null && item.endDate === null && status === "Indefinite")
                                                        || !checkIfNotValid(status, item.startDate, item.endDate)
                                                    )
                                                    && checkIfInDateRange(item.startDate, item.endDate).valueOf()
                                                )
                                            })
                                                .map((item, i) => {
                                                    count = i + 1;
                                                    if (i + 1 > (pageNumber * 100) && i < ((pageNumber + 1) * 100)) {
                                                        i++;
                                                        var url = "/organisation/" + props.organisationId + "/allowed-vrm/" + item.id;
                                                        return (
                                                            <tr>
                                                                <td><Link to={url} className="number-plate-link"><h6 className="mb-0 small-number-plate">{item.vrm}</h6></Link></td>
                                                                <td>{item.driverName}</td>
                                                                <td>{item.vehicleMake}</td>
                                                                <td>{item.vehicleModel}</td>
                                                                <td>{item.siteName && <>{item.siteName}</>}{item.siteName === null && <>All</>}</td>
                                                                <td>{item.startDate !== null && <>{moment(item.startDate).format("DD/MM/yyyy HH:mm")}</>}</td>
                                                                <td>{item.endDate !== null && <>{moment(item.endDate).format("DD/MM/yyyy HH:mm")}</>}</td>
                                                                <td>{item.createdTimestamp === null ? "" : moment(item.createdTimestamp).format("DD/MM/yyyy HH:mm")}</td>
                                                                <td>{item.createdByUserName}</td>
                                                            </tr>
                                                        )
                                                    }
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="d-flex justify-content-center row align-items-center">
                                        <div className="col-auto align-items-center">
                                            <Pagination totalResults={count} currentPage={pageNumber} changePageNumber={changePageNumber} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        </>
    )
}

export default AllowList;