import { Link } from "react-router-dom/cjs/react-router-dom"
export const ReportsWrapper = (props) => {

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row justify-content-between">
                                <div className="col-4">
                                    <h5 className="mb-0">
                                        {props.title}
                                    </h5>
                                </div>
                                {
                                    props.backButtonUrl && 
                                    <div>
                                        <Link 
                                        className="btn btn-falcon-default btn-sm py-1 mr-3" 
                                        type="button" 
                                        to={ props.backButtonUrl }>
                                            <i className="fas fa-reply"></i> Back
                                        </Link>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="card-body border-top d-flex flex-column">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}