import { useState, useEffect } from "react";
import Api from '../components/api/api';

export const useDevicesState = (organisationId, token) => {
    const [kiosks, setKiosks] = useState(null);
    const [orderedCameras, setOrderedCameras] = useState(null);
    const allCamerasUrl = '/organisation/' + organisationId + '/devices' + '/cameras';
    const allKiosksUrl = '/organisation/' + organisationId + '/devices' + '/kiosks';
    const [camerasLoading, setCamerasLoading] = useState(false);
    const [kiosksLoading, setKiosksLoading] = useState(false);

    const getCameras = () => {
        setCamerasLoading(true);
        Api.getCameras(
            (data) => {
                setOrderedCameras(data.sort((d1, d2) => new Date(d1.lastHeartbeat).getTime() - new Date(d2.lastHeartbeat).getTime()));
                setCamerasLoading(false);
            },
            (error) => console.log(error),
            organisationId,
            token
        )
    };

    const getKiosks = () => {
        setKiosksLoading(true);
        Api.getKioskDevices(
            (data) => {
                setKiosks(data);
                setKiosksLoading(false);
            },
            (error) => console.log(error),
            organisationId,
            token
        )
    };

    useEffect(() => {
        getCameras();
        getKiosks();
    }, []);

    return {
        orderedCameras,
        kiosks,
        allCamerasUrl,
        allKiosksUrl,
        getCameras,
        getKiosks,
        camerasLoading,
        kiosksLoading
    }
}