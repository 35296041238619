import { useEffect, useState } from "react";
import moment from 'moment';


export default function OutstandingBalancePane(props) {

    const [isEditing, setIsEditing] = useState(false);

    const [reducedChargeAmount, setReducedChargeAmount] = useState(null);
    const [reducedChargeExpiry, setReducedChargeExpiry] = useState(null);

    const cancel = function () {
        setReducedChargeAmount(props.breach.reducedChargeAmount);

        let issueDate = new Date(props.breach.issueDate);
        setReducedChargeExpiry(moment(new Date(issueDate.getFullYear(), issueDate.getMonth(), issueDate.getDate())).add(props.breach.reducedChargeDays, 'days'));
        setIsEditing(false);
    }

    const save = function () {

        let reducedChargeDays = Math.abs(moment(props.breach.issueDate).diff(reducedChargeExpiry, 'days')) + 1;
        let roundedReducedChargeAmount = isNaN(reducedChargeAmount) ? 0 : Math.round(Number(reducedChargeAmount) * 100) / 100;
        if (props.onSave) {
            props.onSave(reducedChargeDays, roundedReducedChargeAmount);
        }

        setIsEditing(false);
    }

    useEffect(() => {
        cancel();
    }, []);

    return <>
        <h6>Outstanding Balance</h6>
        <div className="display-4 fs-4 mb-3 font-weight-normal text-sans-serif">£{props.breach.outstandingBalance.toFixed(2)}</div>

        {reducedChargeAmount !== null &&
            <div className="row mb-2">
                <label className="col-sm-4 col-form-label col-form-label-sm">Reduced Charge Amount</label>
                <div className="col-sm-8">
                    {!isEditing &&
                        <p className="form-control-plaintext form-control-sm outline-none">£{reducedChargeAmount.toFixed(2)}</p>
                    }
                    {isEditing &&
                        <input className="form-control form-control-sm" type="number" min={0} step={0.01} value={reducedChargeAmount} onChange={(e) => setReducedChargeAmount(e.target.value)} />
                    }
                </div>
            </div>
        }

        {reducedChargeExpiry !== null &&
            <div className="row mb-2">
                <label className="col-sm-4 col-form-label col-form-label-sm">Reduced Charge Expiry</label>
                <div className="col-sm-8">
                    {!isEditing &&
                        <p className="form-control-plaintext form-control-sm outline-none">{reducedChargeExpiry.format("DD/MM/yyyy")}</p>
                    }
                    {isEditing &&
                        <input className="form-control form-control-sm" type="date" value={reducedChargeExpiry.format("yyyy-MM-DD")} min={moment().format("yyyy-MM-DD")} onChange={(e) => setReducedChargeExpiry(moment(e.target.value))} />
                    }
                </div>
            </div>
        }

        <div className="row mb-2">
            <label className="col-sm-4 col-form-label col-form-label-sm">Full Charge Amount</label>
            <div className="col-sm-8">
                <p className="form-control-plaintext form-control-sm outline-none">£{props.breach.fullChargeAmount.toFixed(2)}</p>
            </div>
        </div>

        <div className="row">
            <div className="offset-md-4 col-auto">
                {!isEditing &&
                    <button className="btn-sm btn btn-falcon-default" onClick={() => setIsEditing(true)}><i className="fas fa-edit"></i> Edit Reduced Charge</button>
                }
                {isEditing &&
                    <>
                        <button className="btn-sm btn btn-danger mr-1" onClick={cancel}><i className="fas fa-times"></i> Cancel</button>
                        <button className="btn-sm btn btn-success mx-1" onClick={save}><i className="fas fa-check"></i> Confirm</button>

                    </>
                }
            </div>
        </div>
    </>

}