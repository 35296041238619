import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Loader from '../shared/loader';
import Api from '../api/api';

export default function LetterTemplates(props) {
    const [availableDocuments, setAvailableDocuments] = useState(null);
    const [letterTemplates, setLetterTemplates] = useState(null);

    const getDocuments = function () {
        Api.getLetterTemplates(
            (data) => setAvailableDocuments(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        )
    };

    const downloadTemplate = function (id) {
        //use the save as thing that is in gps -> viewReport.js

    };

    useEffect(() => {
        getDocuments();
    }, [])

    var addNewDocUrl = "/organisation/" + props.organisationId + "/document-template";
    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                          <div className="card mb-3">
                            <div className="card-header">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto d-flex align-items-center pr-0">
                                        <h5 className="mb-0">Letter Templates</h5>
                                    </div>
                                    <div className="col-auto ml-auto text-right pl-0 filter-buttons-wrapper">
                                        <Link to={addNewDocUrl} className="btn btn-falcon-default btn-sm"><i className="fas fa-plus"></i> New</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <table className="table table-sm table-striped fs--1 mb-0">
                                    <thead className="bg-200 text-900">
                                        <tr>
                                            <th className='align-middle'>Name</th>
                                            <th className="text-center align-middle">Status</th>
                                            <th className="align-middle"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {availableDocuments !== null && availableDocuments.map((doc) => {
                                            var url = addNewDocUrl + "/" + doc.id;
                                            var charge;
                                            if(doc.additionalChargeType === 0){
                                                charge = '£' + (Math.round(doc.additionalCharge * 100) / 100).toFixed(2);
                                            } else {
                                                charge = doc.additionalCharge + '%';
                                            }
                                            return (
                                                <tr>
                                                    <td className='align-middle'><Link to={url}><h6 className="mb-0">{doc.name}</h6></Link></td>
                                                    <td className="text-center align-middle">{doc.isActive && <><i className="fas fa-check text-success"></i></>}{!doc.isActive && <><i className="fas fa-times text-danger"></i></>}</td>
                                                    <td className="text-right align-middle">
                                                        <button className="btn btn-falcon-default btn-link btn-sm py-2 dropdown-toggle dropdown-caret-none mr-3" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fas fa-ellipsis-h"></i></button>
                                                        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-start">
                                                            <button type="button" className="dropdown-item btn-sm" onClick={() => downloadTemplate(doc.id)}>Download</button>
                                                            <Link to={url} type="button" className="dropdown-item btn-sm">Edit</Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        {availableDocuments === null && <><tr>
                                            <td colSpan={6}>
                                                <Loader />
                                            </td></tr></>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}