import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Api from '../api/api';
import Enums from '../../utilities/enum';
import moment from 'moment';
import stringHelpers from '../../utilities/stringHelpers';
import arrayUtilities from '../../utilities/arrayUtilities';

var hash = require('object-hash');

function InviteUser(props) {
    const [emailAddress, setEmailAddress] = useState("");
    const [userRole, setUserRole] = useState(null);
    const [canSave, setCanSave] = useState(false);
    const [userManagUrl, setUserManagUrl] = useState("");
    const [roles, setRoles] = useState(null);
    const [clients, setClients] = useState(null);
    const [clientId, setClientId] = useState(null);
    const [sites, setSites] = useState(null);
    const [siteIds, setSiteIds] = useState([]);
    const [showClients, setShowClients] = useState(false);

    const history = useHistory();

    const setUserRoles = function (roles) {
        var tempRoles = [];
        var i = 0;
        while (true) {
            if (roles[i] === undefined) {
                break;
            } else {
                tempRoles.push(roles[i])
                i++;
            }
        }
        setRoles(tempRoles);
    }

    const submit = function () {
        if (canSave) {
            var invite = new Object();
            invite.emailAddress = emailAddress;
            invite.role = userRole;
            invite.status = 0;
            invite.organisationId = props.organisationId;
            invite.organisationName = props.organisationName;
            var nowDate = moment.now();
            var stringNowDate = moment(nowDate).format("DD/MM/yyyy HH:mm");
            invite.dateCreated = stringNowDate;

            //do api add invite when endpoint there
            Api.addInvitation(
                () => history.push(userManagUrl),
                (error) => console.log(error),
                emailAddress, userRole, clientId, siteIds, 
                props.organisationId,
                props.token
            )
        }
    }

    const toggleSiteId = function (siteId) {
        setSiteIds(arrayUtilities.toggle(siteId, siteIds));
    };

    useEffect(() => {
        if (emailAddress === null || emailAddress === "" || userRole === null) {
            setCanSave(false);
        } else {
            let emailRegex = /\S+@\S+\.\S+/;
            if (emailRegex.test(emailAddress)) {
                setCanSave(true);
            } else {
                setCanSave(false);
            }

        }
    }, [emailAddress, userRole])

    useEffect(() => {
        if (!stringHelpers.isNullOrEmpty(clientId)) {
            Api.getClientSites((data) => {
                setSites(data);
            }, () => {
                setSites(null);
                setSiteIds([]);                
            }, clientId, props.organisationId, props.token)
        } else {
            setSites(null);
            setSiteIds([]);
        }
    }, [clientId])

    useEffect(() => {
        setUserRoles(Enums.UserRoles);
        setUserManagUrl("/organisation/"+ props.organisationId+"/user-management");

        Api.getClients((data) => {
            setClients(data);
        }, () => {}, props.organisationId, props.token);
    }, [])

    useEffect(() => {
        if (clients && (userRole === 4 || userRole === 5 || userRole === 6)) {
            setShowClients(true);
        } else setShowClients(false);
    }, [userRole])

    return (
        <>
            {roles !== null && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-2 col-sm-auto d-flex align-items-center pr-0">
                                            <h5 className="mb-0">Invite User</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top">
                                    <div className="row">
                                        <div className=" col-md-5 col-12 ">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Email Address*</p>
                                        </div>
                                        <div className="col-md-5 col-12">
                                            <div className="form-group mb-1">
                                                <input className="w-100 border rounded py-2 px-3" type="text" autoFocus={true} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">User Role*</p>
                                        </div>
                                        <div className="col-md-5 col-12 ">
                                            <div className="form-group mb-1">
                                            <select name="user-role-list" className="w-100 border rounded py-2 px-3" onChange={e => setUserRole(Number(e.currentTarget.value))} >
                                                        <option value="">Select the User Role</option>
                                                        {
                                                            roles.map((userRole, i = 0) => {
                                                                i++;
                                                                return (
                                                                    <option key={i} value={i - 1}>{userRole}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        showClients &&
                                            <div className="row">
                                                <div className="col-md-5 col-12 ">
                                                    <p className="font-weight-semi-bold mb-1">Client</p>
                                                </div>
                                                <div className="col-md-5 col-12 ">
                                                    <div className="form-group mb-0">
                                                        <select name="client-list" className="w-100 border rounded py-2 px-3" onChange={e => setClientId(e.currentTarget.value)} >
                                                            <option value="">Select a Client</option>
                                                            {
                                                                clients.map((client, index) =>
                                                                    <option value={client.id} key={client.id} index={index}>{client.name}</option>
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                    {(userRole === 4 || userRole === 5 || userRole === 6) && !stringHelpers.isNullOrEmpty(clientId) && !arrayUtilities.isNullOrEmpty(sites) &&
                                        <div className="row">
                                            <div className="col-md-5 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Sites</p>
                                            </div>
                                            
                                            <div className="col-md-5 col-12 ">
                                                {
                                                    sites.map((site, i) => {
                                                        return <div className="custom-control custom-checkbox" key={hash({ site, siteIds })} index={i}>
                                                            <input className="custom-control-input" type="checkbox" checked={siteIds.includes(site.id)} id={`site-${site.id}`} onChange={() => toggleSiteId(site.id)} />
                                                            <label className="custom-control-label col-form-label col-form-label-sm" htmlFor={`site-${site.id}`}>{site.name}</label>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="row client-edit-buttons">
                                        <div className="col-12 col-sm-auto ml-auto  pl-0">
                                            <div id="dashboard-actions">
                                                <Link className="btn btn-falcon-default btn-sm" to={userManagUrl}><i className="fas fa-times"></i> Cancel</Link>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => submit()}>
                                                    <i className="fas fa-check"></i> Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}

export default InviteUser;