import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';

import Loader from '../shared/loader';
import moment from 'moment';

import StringHelpers from '../../utilities/stringHelpers';

import Pagination from '../shared/pagination';
import stringHelpers from '../../utilities/stringHelpers';

function ClientLanding(props) {

    const clientId = props.user.organisations.find(x => x.organisation.id === props.organisationId)?.clientId;

    const [sites, setSites] = useState(null);
    const [client, setClient] = useState(null);

    //allow list
    const [vrm, setVrm] = useState("");
    const [site, setSite] = useState(null);
    const [vehicleMake, setVehicleMake] = useState("");
    const [vehicleModel, setVehicleModel] = useState("");
    const [colour, setColour] = useState("");
    const [driverName, setDriverName] = useState("");
    const [driverMobileNumber, setDriverMobileNumber] = useState("");
    const [driverEmailAddress, setDriverEmailAddress] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [indefinite, setIndefinite] = useState(false);
    const [reason, setReason] = useState("");
    const [canSave, setCanSave] = useState(false);

    //filtering
    const [search, setSearch] = useState(null);
    const [filterEnd, setFilterEnd] = useState(null);
    const [filterEndTemp, setFilterEndTemp] = useState(null);
    const [filterStart, setFilterStart] = useState(null);
    const [filterStartTemp, setFilterStartTemp] = useState(null);

    const getSites = function () {
        Api.getClientSites(
            (data) => setSites(data),
            (error) => console.log(error),
            clientId,
            props.organisationId, props.token
        )
    };

    const clearFilters = function () {
        setSearch("");
        setFilterStart(null);
        setFilterStartTemp(null);
        setFilterEnd(null);
        setFilterEndTemp(null);
    };

    const setFilterDates = function () {
        setFilterStart(filterStartTemp);
        setFilterEnd(filterEndTemp);
    };

    const getClient = function () {
        Api.getSingleClient(
            (data) => setClient(data),
            (error) => console.log(error),
            props.organisationId,
            clientId,
            props.token
        )
    };

    const clearVrmData = function () {
        setVrm("");
        setVehicleMake("");
        setVehicleModel("");
        setColour("");
        setSite(null);
        setDriverName("");
        setDriverMobileNumber("");
        setIndefinite(false);
        setStartDate("");
        setEndDate("");
        setReason("");
    };

    const submit = function () {
        if (canSave) {
            var submitSiteId;
            var siteName;
            if (site !== null) {
                submitSiteId = site.id;
                siteName = site.name;
            } else {
                submitSiteId = null;
                siteName = "All Sites";
            }
            Api.addAllowedVrm(
                () => {
                    clearFilters();
                    clearVrmData();
                },
                (error) => {
                    console.log(error)
                },
                vrm,
                vehicleMake,
                vehicleModel,
                siteName,
                driverName,
                driverMobileNumber,
                startDate,
                endDate,
                reason,
                submitSiteId,
                null,
                null,
                props.organisationId,
                props.token
            );
        }
    };

    useEffect(() => {

        setCanSave(site && site !== null && !StringHelpers.isNullOrEmpty(vrm) && (indefinite || (startDate && startDate !== "" && endDate && endDate !== "")));

    }, [site, vrm, indefinite, startDate, endDate]);

    useEffect(() => {
        getClient();
        getSites();
    }, []);

    return (
        <>
            {client === null && <Loader />}
            {sites !== null && client !== null && <>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto d-flex align-items-center">
                                            <h5 className="mb-0">{client.name}</h5>
                                        </div>
                                        <div className="col-auto filter-buttons-wrapper">
                                            <button className="btn btn-danger btn-sm mr-2" onClick={() => clearFilters()}><i className="fas fa-times"></i> Clear</button>
                                            {search === null && <>
                                                <input className="form-control mr-1" value={null} type="text" placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                            </>}
                                            {search !== null && <>
                                                <input className="form-control mr-1" value={search} type="text" autoFocus={true} placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                            </>}
                                            <button className="btn btn-falcon-default btn-sm ml-1" data-toggle="modal" data-target="#dateModal">Filter by Date</button>
                                            <button className="btn btn-primary btn-sm ml-2" data-toggle="modal" data-target="#allow-list-modal"><i className="fas fa-plus"></i> Add to Allow List</button>                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade" id="dateModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title" id="deleteModalLabel">Choose the start and end date to filter by</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Period Start</p>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group mb-0">
                                                            {!filterStartTemp &&
                                                                <>
                                                                    <input className="form-control mb-1" type="datetime-local" value={null} onChange={(e) => setFilterStartTemp(e.target.value)} />
                                                                </>}
                                                            {filterStartTemp &&
                                                                <input className="form-control mb-1" type="datetime-local" value={filterStartTemp} onChange={(e) => setFilterStartTemp(e.target.value)} />}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <p className="font-weight-semi-bold  mt-1 mb-1">Period End</p>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group mb-0">
                                                            {!filterEndTemp &&
                                                                <>
                                                                    <input className="form-control mb-1" type="datetime-local" value={null} onChange={(e) => setFilterEndTemp(e.target.value)} />
                                                                </>}
                                                            {filterEndTemp &&
                                                                <input className="form-control mb-1" type="datetime-local" value={filterEndTemp} onChange={(e) => setFilterEndTemp(e.target.value)} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" data-dismiss="modal" onClick={() => setFilterDates()}>
                                                    Filter</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    <div className="modal fade allow-list-modal" id="allow-list-modal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="deleteModalLabel">Add to Allow List</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="row">
                                                        <div className="col-md-5 col-12 ">
                                                            <p className="font-weight-semi-bold  mt-1 mb-1">VRM*</p>
                                                        </div>
                                                        <div className="col-md-5 col-12 ">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-1 small-number-plate" type="text" value={vrm} onChange={(e) => setVrm(e.target.value.toUpperCase())} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-5 col-12 ">
                                                            <p className="font-weight-semi-bold  mt-1 mb-1">Vehicle Make</p>
                                                        </div>
                                                        <div className="col-md-5 col-12 ">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="text" value={vehicleMake} onChange={(e) => setVehicleMake(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-5 col-12 ">
                                                            <p className="font-weight-semi-bold  mt-1 mb-1">Vehicle Model</p>
                                                        </div>
                                                        <div className="col-md-5 col-12 ">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="text" value={vehicleModel} onChange={(e) => setVehicleModel(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-5 col-12 ">
                                                            <p className="font-weight-semi-bold  mt-1 mb-1">Colour</p>
                                                        </div>
                                                        <div className="col-md-5 col-12 ">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="text" value={colour} onChange={(e) => setColour(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-5 col-12 ">
                                                            <p className="font-weight-semi-bold  mt-1 mb-1">Site</p>
                                                        </div>
                                                        <div className="col-md-5 col-12 ">
                                                            <div className="form-group mb-1">
                                                                <div className="dropdown">
                                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm edit-dropdown" id="select-site" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{site === null && <>Select site</>}{site !== null && <>{site.name}</>}</button>
                                                                    <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-site" >
                                                                        <div className="bg-white py-3 rounded-soft">
                                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setSite(null)}>Select site</button>
                                                                            {sites !== null && sites.map((site) => {
                                                                                return (
                                                                                    <button className="dropdown-item text-base px-3 py-2" onClick={() => setSite(site)}>{site.name}</button>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-5 col-12 ">
                                                            <p className="font-weight-semi-bold  mt-1 mb-1">Driver Name</p>
                                                        </div>
                                                        <div className="col-md-5 col-12">
                                                            <div className="form-group mb-0">
                                                                <input className="form-control mb-1" type="text" value={driverName} onChange={(e) => setDriverName(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-5 col-12 ">
                                                            <p className="font-weight-semi-bold  mt-1 mb-1">Driver Mobile Number</p>
                                                        </div>
                                                        <div className="col-md-5 col-12">
                                                            <div className="form-group mb-0">
                                                                <input className="form-control mb-1" type="text" value={driverMobileNumber} onChange={(e) => setDriverMobileNumber(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-5 col-12 ">
                                                            <p className="font-weight-semi-bold  mt-1 mb-1">Driver Email Address</p>
                                                        </div>
                                                        <div className="col-md-5 col-12">
                                                            <div className="form-group mb-1">
                                                                <input className="form-control mb-0" type="text" value={driverEmailAddress} onChange={(e) => setDriverEmailAddress(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-5 col-12 ">
                                                            <p className="font-weight-semi-bold mb-1 ">Indefinite Period</p>
                                                        </div>
                                                        <div className="col-md-5 col-12 custom-control custom-switch">
                                                            <input className="custom-control-input" type="checkbox" id="switch1" checked={indefinite} onChange={() => {
                                                                if (!indefinite) {
                                                                    setStartDate(null);
                                                                    setEndDate(null);
                                                                }
                                                                setIndefinite(!indefinite)
                                                            }} />
                                                            <label className="custom-control-label ml-3" htmlFor="switch1"></label>
                                                        </div>
                                                    </div>
                                                    {!indefinite && <>
                                                        <div className="row">
                                                            <div className="col-md-5 col-12 ">
                                                                <p className="font-weight-semi-bold  mt-1 mb-1">Period Start*</p>
                                                            </div>
                                                            <div className="col-md-5 col-12">
                                                                <div className="form-group mb-0">
                                                                    <input className="form-control mb-1" type="datetime-local" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-5 col-12 ">
                                                                <p className="font-weight-semi-bold  mt-1 mb-1">Period End*</p>
                                                            </div>
                                                            <div className="col-md-5 col-12">
                                                                <div className="form-group mb-0">
                                                                    <input className="form-control mb-1" type="datetime-local" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>}
                                                    <div className="row">
                                                        <div className="col-md-5 col-12 ">
                                                            <p className="font-weight-semi-bold mt-1 mb-1">Reason</p>
                                                        </div>
                                                        <div className="col-md-5 col-12">
                                                            <div className="form-group mb-1">
                                                                <textarea className="form-control mb-0" rows={3} value={reason} onChange={(e) => setReason(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">
                                                        <i className="fas fa-times"></i> Cancel</button>
                                                    <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} data-dismiss="modal" onClick={() => submit()}>
                                                        <i className="fas fa-check"></i> Confirm</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tickets-table">

                                        <div className="table-body">
                                            {sites !== null && sites.map((site) => {
                                                return (
                                                    <SiteAllowList {...props} siteId={site.id} siteName={site.name} search={search} start={filterStart} end={filterEnd} />
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}
export default ClientLanding;

function SiteAllowList(props) {
    const [buttonId, setButtonId] = useState("");
    const [listId, setListId] = useState("");
    const [loading, setLoading] = useState(false);
    const [siteId, setSiteId] = useState(props.siteId)
    const [allowList, setAllowList] = useState(null);

    const [pageNumber, setPageNumber] = useState(0);
    const [amountOnPage, setAmountOnPage] = useState(100);
    const [lastPage, setLastPage] = useState(false);
    const [pageCount, setPageCount] = useState(0);

    const getAllowList = function () {
        Api.getSiteAllowList(
            (data) => {
                setAllowList(data);
                setLoading(false);
            },
            (error) => console.log(error),
            props.organisationId, props.siteId, props.token
        )
    };

    const changePageNumber = function (num) {
        setPageNumber(num);
    };

    const checkIfInDateRange = function (start, end) {
        const startDate = moment(start);
        const endDate = moment(end);
        const filterStartDate = moment(props.start);
        const filterEndDate = moment(props.end);

        if (props.start !== null && startDate.isValid()) {
            var diff = startDate.diff(filterStartDate, 'days');
            if (!(diff > 0)) {
                return false
            }
        }
        if (props.end !== null && endDate.isValid()) {
            var diff = endDate.diff(filterEndDate);
            if (!(diff < 0)) {
                return false
            }
        }
        return true;
    };

    const isLastPage = function () {
        if (allowList !== null) {
            setLastPage(false)
            var length = allowList.length;
            setPageCount(Math.ceil(length / 100));
            if (pageNumber + 1 >= Math.ceil(length / 100)) {
                setLastPage(true);
            }
        }
    };

    useEffect(() => {
        isLastPage();
    }, [allowList, pageNumber, amountOnPage]);

    useEffect(() => {
        setPageNumber(0);
    }, [amountOnPage]);

    useEffect(() => {
        if (siteId !== null) {
            getAllowList();
            setButtonId("#button-" + siteId);
            setListId("#list-" + siteId);
        }
    }, [siteId])

    useEffect(() => {
        setLoading(true);
        getAllowList();
        isLastPage();
        setPageNumber(0);
    }, [props.search, props.start, props.end]);

    return (
        <>
            {allowList !== null && <>
                <div className="table-row">
                    <button className="table-item button-no-style text-left py-3 collapsed" id={buttonId.substring(1)} type="button" data-toggle="collapse" data-target={listId} aria-expanded="true" aria-controls={buttonId.substring(1)}>{props.siteName} <i className="fas fa-chevron-down"></i></button>
                </div>
                <div className="collapse" id={listId.substring(1)} aria-labelledby="Allow List" data-parent={buttonId}>
                    {!loading && <>
                        <div className="card-body bg-light border-top details-container p-0" >
                            <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                <thead className="bg-200 text-900">
                                    <tr>
                                        <th>Vehicle Registration</th>
                                        <th>Driver Name</th>
                                        <th>Vehicle Make</th>
                                        <th>Vehicle Model</th>
                                        <th>Site</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Created Date</th>
                                        <th>Created By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allowList !== null && allowList.filter(function (item) {
                                        var vrm = new String(item.vrm).toLowerCase();
                                        var driverName = new String(item.driverName).toLowerCase();
                                        var make = new String(item.vehicleMake).toLowerCase();
                                        var model = new String(item.vehicleModel).toLowerCase();
                                        return (
                                            (
                                                props.search === null || props.search === "" ||
                                                vrm.search(props.search.toLowerCase()) !== -1
                                                || driverName.search(props.search.toLowerCase()) !== -1
                                                || make.search(props.search.toLowerCase()) !== -1
                                                || model.search(props.search.toLowerCase()) !== -1
                                            )
                                            && checkIfInDateRange(item.startDate, item.endDate).valueOf()
                                        )
                                    })
                                        .map((item, i) => {
                                            if (i + 1 > (pageNumber * amountOnPage) && i < ((pageNumber + 1) * amountOnPage)) {
                                                i++;
                                                var url = '/allowed-vrm/' + item.id;
                                                return (
                                                    <tr>
                                                        <td><Link to={url}><h6 className="mb-0 small-number-plate">{item.vrm}</h6></Link></td>
                                                        <td>{item.driverName}</td>
                                                        <td>{item.vehicleMake}</td>
                                                        <td>{item.vehicleModel}</td>
                                                        <td>{item.siteName && <>{item.siteName}</>}{item.siteName === null && <>All</>}</td>
                                                        <td>{item.startDate !== null && <>{moment(item.startDate).format("DD/MM/yyyy")}</>}</td>
                                                        <td>{item.endDate !== null && <>{moment(item.endDate).format("DD/MM/yyyy")}</>}</td>
                                                        <td>{item.createdTimestamp === null ? "" : moment(item.createdTimestamp).format("DD/MM/yyyy HH:mm")}</td>
                                                        <td>{item.createdByUserName}</td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                </tbody>
                            </table>
                        </div>
                        <div className="pt-3 border-top ">
                            <div className="d-flex justify-content-center row align-items-center">
                                <div className="col-auto align-items-center">
                                    <Pagination totalResults={allowList.length} currentPage={pageNumber} changePageNumber={changePageNumber} />
                                </div>
                            </div>
                        </div>
                    </>}
                    {loading &&
                        <div className="small-loader"><Loader /></div>}
                </div>
            </>}
        </>
    )
}