import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import moment from 'moment';
import Loader from '../shared/loader';
import Pagination from '../shared/pagination';
import api from '../api/api';
import stringHelers from '../../utilities/stringHelpers';
import arrayUtilities from '../../utilities/arrayUtilities';
import { Link } from 'react-router-dom';

function CameraData(props) {
    const [cameraData, setCameraData] = useState(null);

    const [cameraFromDate, setCameraFromDate] = useState(null);
    const [cameraToDate, setCameraToDate] = useState(null);
    const [cameraFilterEndTemp, setCameraFilterEndTemp] = useState(null);
    const [cameraFilterStartTemp, setCameraFilterStartTemp] = useState(null);

    const [cameraSite, setCameraSite] = useState(null);
    const [cameraSearch, setCameraSearch] = useState(null);
    const [vrm, setVrm] = useState("");
    const [vrmLoading, setVrmLoading] = useState("");

    const [cameraLastPage, setCameraLastPage] = useState(false);
    const [cameraPageNumber, setCameraPageNumber] = useState(0);
    const [cameraLoading, setCameraLoading] = useState(true);
    const [cameraPageCount, setCameraPageCount] = useState(0);

    const getCameraData = function () {
        var cameraSiteId = null;
        if (cameraSite !== null) {
            cameraSiteId = cameraSite.id;
        }
        Api.getCameraData(
            (data) => {
                setCameraData(data);
                setVrmLoading(false);
                setCameraLoading(false);
            },
            (error) => console.log(error),
            cameraSearch, cameraSiteId, cameraFromDate, cameraToDate, cameraPageNumber,
            props.organisationId,
            props.token
        )
    };

    const changeCameraPageNumber = function (num) {
        setCameraPageNumber(num);
    };

    const setFilterDates = function () {
        setCameraFromDate(cameraFilterStartTemp);
        setCameraToDate(cameraFilterEndTemp);
    };

    const isLastPageCamera = function () {
        if (cameraData !== null && cameraData !== undefined) {
            setCameraLastPage(false)
            setCameraPageCount(Math.ceil(cameraData.totalCount / 100));
            if (cameraPageNumber + 1 >= Math.ceil(cameraData.totalCount / 100)) {
                setCameraLastPage(true)
            }
        }
    };

    const clearFilters = function () {
        setCameraSearch(null);
        setCameraToDate(null);
        setCameraFilterStartTemp(null);
        setCameraFromDate(null);
        setCameraFilterEndTemp(null);
        setVrm("");
        setCameraSite(null);
    }

    const editVrm = function (id) {
        //set loader
        var noSpecialChars = new RegExp("^[0-9,a-z]*$");
        if (!noSpecialChars.test(vrm)) {
            setVrmLoading(true);

            api.editCameraData(
                () => {
                    getCameraData();
                    setVrm("");
                },
                () => setVrmLoading(false),
                props.organisationId, id, vrm,
                props.token
            )
        }
        else {
            setVrmLoading(false);
        }
    }

    useEffect(() => {
        isLastPageCamera();
    }, [cameraData]);

    useEffect(() => {
        setCameraLoading(true);
        getCameraData();
        setCameraPageNumber(0);
    }, [cameraFromDate, cameraToDate, cameraSite, cameraSearch]);

    useEffect(() => {
        setCameraLoading(true);
        getCameraData();
    }, [cameraPageNumber])

    useEffect(() => {
        getCameraData();
    }, [])

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="row justify-content-between">
                                    <div className="col-4">
                                        <h5 className="mb-0">
                                            Camera Data
                                        </h5>
                                    </div>
                                    <div className="col-auto text-right mt-1 mt-xl-0 pl-xl-0 filter-buttons-wrapper">
                                        <div className="input-group mr-1">
                                            {cameraSearch === null && <>
                                                <input className="form-control" value={null} type="text" placeholder="search..." onChange={(e) => setCameraSearch(e.target.value)} />
                                            </>}
                                            {cameraSearch !== null && <>
                                                <input className="form-control" value={cameraSearch} type="text" autoFocus={true} placeholder="search..." onChange={(e) => setCameraSearch(e.target.value)} />
                                            </>}
                                            <div className="input-group-append">
                                                <button className="btn btn-falcon-default btn-sm py-0" onClick={() => clearFilters()}><i className="fas fa-times"></i> Clear</button>
                                            </div>
                                        </div>
                                        <button className="btn btn-falcon-default btn-sm mr-1 ml-1 nowrap" data-toggle="modal" data-target="#dateModal">Filter by Date</button>
                                        <div className="filter-buttons" id="select-site">
                                            <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{cameraSite && cameraSite !== null && <>{cameraSite.name.substring(0, 8)}</>}{!cameraSite && <>Filter by Site</>}</button>
                                            <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-site" >
                                                <div className="bg-white py-3 rounded-soft">
                                                    <button className="dropdown-item text-base px-3 py-2 edit-dropdown" onClick={() => setCameraSite(null)}>All</button>
                                                    {props.sites && props.sites !== null && props.sites.map((currentSite) => {
                                                        return (
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setCameraSite(currentSite)}>{currentSite.name}</button>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary btn-sm mr-1 ml-1 nowrap" onClick={() => { clearFilters(); setCameraLoading(true); getCameraData(); }}><i className="fas fa-redo"></i> Refresh</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body bg-light border-top details-container p-0" id="camera-data-list" aria-labelledby="Allow List" data-parent="#camera-data">
                                <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                    <thead className="bg-200 text-900">
                                        <tr>
                                            <th>Read VRM</th>
                                            <th>Images</th>
                                            <th>&nbsp;</th>
                                            <th>Confidence</th>
                                            <th>Site</th>
                                            <th>Camera</th>
                                            <th>Direction</th>
                                            <th>ROI</th>
                                            <th>Timestamp</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cameraLoading && <><tr>
                                            <td colSpan={9}>
                                                <Loader />
                                            </td></tr></>}
                                        {!cameraLoading && cameraData !== null && cameraData.items !== undefined && cameraData.items.map((item, i) => {
                                            i++;

                                            if (!item.imageUrls) {
                                                return null;
                                            }

                                            var direction = item.motion?.toString();

                                            if (!stringHelers.isNullOrEmpty(direction)) {
                                                direction = direction[0].toUpperCase() + direction.substring(1);
                                            }

                                            var buttonThumbId = '#button-thumb-' + item.id;
                                            var modalThumbId = '#modal-thumb-' + item.id;
                                            var buttonPlateId = '#button-plate-' + item.id;
                                            var modalPlateId = '#modal-plate-' + item.id;

                                            var siteDetailsUrl = `/organisation/${props.organisationId}/client/${props.sites.find(site => site.id === item.siteId)?.clientId}/site/${item.siteId}`;
                                            var cameraDetailsUrl = `/organisation/${props.organisationId}/site/${item.siteId}/camera/${item.cameraId}`

                                            return (

                                                <tr key={item.id}>
                                                    <td>
                                                        <button className="button-no-style number-plate-link" id={buttonPlateId.substring(1)} data-target={modalPlateId} data-toggle="modal"><h6 className="mb-0 small-number-plate">{item.vrm}</h6></button>

                                                        <div className="modal fade" id={modalPlateId.substring(1)} role="dialog" data-parent={buttonPlateId} aria-labelledby={modalPlateId}>
                                                            <div className="modal-dialog raw-data-pane" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5 className="modal-title">Edit VRM</h5>
                                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        {!vrmLoading &&
                                                                            <div className='row justify-content-center align-items-center'>
                                                                                <div className='col-12'>
                                                                                    <img src={item.imageUrls[0]} className="m-1" />

                                                                                </div>
                                                                                <div className='col-6'>
                                                                                    <img src={item.imageUrls[1]} className="m-1" />

                                                                                </div>
                                                                                <div className='col-6'>
                                                                                    <div className="form-group mb-1">
                                                                                        <input className="form-control mb-1 small-number-plate" type="text" placeholder={item.vrm} value={vrm} onChange={(e) => setVrm(e.target.value.toUpperCase())} />
                                                                                    </div>
                                                                                </div>
                                                                                {vrm !== "" &&
                                                                                    <div className='col-auto'>
                                                                                        <div className='button-container'>
                                                                                            <button className='btn btn-sm btn-success m-1' onClick={() => editVrm(item.id)}>Edit</button>
                                                                                            <button className='btn btn-sm btn-danger m-1' onClick={() => setVrm("")}>Cancel</button>
                                                                                        </div>
                                                                                    </div>}
                                                                            </div>
                                                                        }
                                                                        {vrmLoading && <div className="small-loader">
                                                                            <Loader />
                                                                        </div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {!arrayUtilities.isNullOrEmpty(item.imageUrls) && item.imageUrls.length > 1 &&
                                                            <img height={50} src={item.imageUrls[1]} className="m-1" />
                                                        }
                                                    </td>
                                                    <td>
                                                        {!arrayUtilities.isNullOrEmpty(item.imageUrls) && item.imageUrls.length > 0 && <>
                                                            <button className="button-no-style number-plate-link" id={buttonThumbId.substring(1)} data-target={modalThumbId} data-toggle="modal">
                                                                <img height={50} src={item.imageUrls[0]} className="m-1" />
                                                            </button>


                                                            <div className="modal fade" id={modalThumbId.substring(1)} role="dialog" data-parent={buttonThumbId} aria-labelledby={modalThumbId}>
                                                                <div className="modal-dialog raw-data-pane" role="document">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">{item.vrm}</h5>
                                                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                                <span aria-hidden="true">&times;</span>
                                                                            </button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <div className='row justify-content-center'>
                                                                                <img src={item.imageUrls[0]} className="m-1" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>}
                                                    </td>
                                                    <td>{item.confidence}%</td>
                                                    <td>
                                                        <Link to={siteDetailsUrl}>
                                                            <h6 className="m-0">{item.siteName}</h6>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <Link to={cameraDetailsUrl}>
                                                            <h6 className="m-0">{item.cameraName}</h6>
                                                        </Link>
                                                    </td>
                                                    <td>{direction}</td>
                                                    <td>{item.roi}</td>
                                                    <td>
                                                        {item.timestamp !== null && <>{moment(item.timestamp).format("DD/MM/yyyy")}</>} {item.timestamp !== null && <>{moment(item.timestamp).format("HH:mm")}</>}
                                                    </td>
                                                </tr>

                                            )
                                        }
                                        )}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-center row align-items-center">
                                    <div className="col-auto align-items-center">
                                        {!cameraLoading &&
                                            <Pagination totalResults={cameraData.totalCount} currentPage={cameraPageNumber} changePageNumber={changeCameraPageNumber} />}
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="dateModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="deleteModalLabel">Choose the start and end date to filter by</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">Period Start</p>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group mb-0">
                                                        {!cameraFilterStartTemp &&
                                                            <>
                                                                <input className="form-control mb-1" type="datetime-local" value={null} onChange={(e) => setCameraFilterStartTemp(e.target.value)} />
                                                            </>}
                                                        {cameraFilterStartTemp &&
                                                            <input className="form-control mb-1" type="datetime-local" value={cameraFilterStartTemp} onChange={(e) => setCameraFilterStartTemp(e.target.value)} />}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 ">
                                                    <p className="font-weight-semi-bold  mt-1 mb-1">Period End</p>
                                                </div>
                                                <div className="col-12">
                                                    <div className="form-group mb-0">
                                                        {!cameraFilterEndTemp &&
                                                            <>
                                                                <input className="form-control mb-1" type="datetime-local" value={null} onChange={(e) => setCameraFilterEndTemp(e.target.value)} />
                                                            </>}
                                                        {cameraFilterEndTemp &&
                                                            <input className="form-control mb-1" type="datetime-local" value={cameraFilterEndTemp} onChange={(e) => setCameraFilterEndTemp(e.target.value)} />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                            <button type="button" className="btn btn-success btn-sm ml-2" data-dismiss="modal" onClick={() => setFilterDates()}>
                                                Filter</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="card mb-3">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <h5 className="mb-0">
                                            <button className="button-no-style button-card-header collapsed" id="pd-data" type="button" data-toggle="collapse" data-target="#pd-data-list" aria-expanded="true" aria-controls="pd-data-list">
                                                Pay & Display Data <i className="fas fa-chevron-down"></i>
                                            </button>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body bg-light border-top details-container collapse" id="pd-data-list" aria-labelledby="Allow List" data-parent="#pd-data">hello</div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
export default CameraData;