import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Loader from '../shared/loader';

function Organisations(props) {
    const [organisations, setOrganisations] = useState(null);

    const [status, setStatus] = useState(null);
    const [city, setCity] = useState(null);
    const [search, setSearch] = useState(null);

    const [cityFilterList, setCityFilterList] = useState(null);

    const getOrganisations = function () {
        Api.getOrganisations(
            (data) => setOrganisations(data),
            (error) => console.log(error),
            props.token)
    }

    const setCityList = function () {
        if (organisations !== null) {
            var list = new Array();
            organisations.forEach(organisation => {
                if (!list.includes(organisation.townOrCity)) {
                    list.push(organisation.townOrCity);
                }
            });
            setCityFilterList(list);
        }
    }

    const clearFilters = function() {
        setSearch(null);
        setCity(null);
        setStatus(null);
    }

    useEffect(() => {
        getOrganisations()
    }, [])

    useEffect(() => {
        setCityList()
    }, [organisations])

    return (
        //need to check role - if not org admin then not show add buttons

        <>
            {!organisations && <Loader />}{organisations && organisations !== null && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-2 col-sm-auto d-flex align-items-center pr-0">
                                            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Your Organisations</h5>
                                        </div>
                                        <div className="col-auto ml-auto text-right pl-0 filter-buttons-wrapper">
                                        <div className="input-group">
                                            {search === null && <>
                                                <input className="form-control" value={null} type="text" placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                            </>}
                                            {search !== null && <>
                                                <input className="form-control" value={search} type="text" autoFocus={true} placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                            </>}
                                            <div className="input-group-append">
                                                <button className="btn btn-falcon-default btn-sm py-0" onClick={() => clearFilters()}><i className="fas fa-times"></i> Clear</button>
                                            </div>
                                        </div>
                                            <div className="filter-buttons" id="select-city">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-city" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{city && city !== null &&<>City: {city}</>}{city === null && <>Filter by City</>}</button>
                                                <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-city" >
                                                    <div className="bg-white py-3 rounded-soft">
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setCity(null)}>All</button>
                                                                {cityFilterList && cityFilterList !== null && cityFilterList.map((currentCity) => {
                                                                    return(
                                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setCity(currentCity)}>{currentCity}</button>
                                                                    )
                                                                })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="filter-buttons" id="select-status">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{status && <>Status: Active</>}{!status && status !== null && <>Status: Deactivated</>}{status === null && <>Filter by Status</>}</button>
                                                <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-status" >
                                                    <div className="bg-white py-3 rounded-soft">
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(null)}>All</button>
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(true)}>Active</button>
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(false)}>Deactivated</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link className="btn btn-success btn-sm ml-1 nowrap" to="/organisation"><i className="fas fa-plus"></i> Add</Link>
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                    <thead className="bg-200 text-900">
                                        <tr>
                                            <th>Name</th>
                                            <th>Town/City</th>
                                            <th className="text-center">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {organisations.filter(function (organisation) {
                                            var townOrCity = new String(organisation.townOrCity).toLowerCase();
                                            var name = new String(organisation.name).toLowerCase();
                                            return ((organisation.townOrCity === city || city === null) && (organisation.isActive === status || status === null) && (search === null || townOrCity.search(search.toLowerCase()) !== -1 || name.search(search.toLowerCase()) !== -1) )
                                        }).map((organisation) => {
                                                var url = "/organisation/" + organisation.id;
                                                return (
                                                    <tr>
                                                        <td><Link to={url}><h6 className="mb-0">{organisation.name}</h6></Link></td>
                                                        <td>{organisation.townOrCity}</td>
                                                        <td className="text-center">{organisation.isActive && <><i className="fas fa-check text-success"></i></>}{!organisation.isActive && <><i className="fas fa-times text-danger"></i></>}</td>
                                                    </tr>)
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}

export default Organisations;