import React from 'react';
import authentication from '../authentication/b2c';

function NonUserDashboard() {
    return(
        <div className="container">
            <div className="row justify-content-center align-items-center h-100">
                <div className="col-auto justify-content-center no-user">
                    <p className="text-center font-weight-semi-bold">You are not authorised to view this page.</p>
                    <p className="text-center font-weight-semi-bold">Please check your email and spam for an invite link.</p>
                    <button className="btn btn-falcon-default m-auto" onClick={() => authentication.signOut()}>Log Out</button>
                </div>
            </div>
        </div>
    )
}
export default NonUserDashboard;