import { DevicesStatusTable } from "./devicesStatusTable";
import { getDeviceStatus } from "../../utilities/getDeviceStatus";
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { EmptyState } from "./emptyState";
import Loader from "../shared/loader";

export const DeviceStatus = (props) => {

    const [filteredDevices, setFilteredDevices] = useState([]);

    useEffect(() => {
        if (props.orderedDevices) {
            const temp = props.orderedDevices.map(item => {
                const deviceStatus = getDeviceStatus(item, props.deviceType);
                return {
                    filteredDevice: item,
                    status: deviceStatus.status,
                    pillButton: deviceStatus.statusPillButton
                }
            })
            if (!props.viewAll) {
                setFilteredDevices(temp.filter(item => item.status < 3))
            }
            else if (props.viewAll) {
                setFilteredDevices(temp);
            }
        }
    }, [props.orderedDevices, props.viewAll]);

    return (
        <div className="card">
            <div className="card-header">
                <div className="row align-items-center justify-content-between px-2">
                    <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">{props.deviceType} Status</h5>
                    <div className="d-flex">
                        <button
                            onClick={props.handleRefreshClick}
                            className="btn btn-falcon-default btn-sm d-flex align-items-center"
                        >
                            Refresh
                            <i className="fas fa-sync d-flex align-items-center ml-2"></i>
                        </button>
                        <Link to={props.viewAllUrl ?? '/'}>
                            <button className="btn btn-falcon-default btn-sm ml-2 d-flex align-items-center">
                                {
                                    props.viewAllUrl
                                        ? <span>View All<i className="ml-2 fas fa-chevron-right"></i></span>
                                        : <span><i className="fas fa-chevron-left mr-2"></i>Back to Dashboard</span>
                                }
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            {
                props.isLoading ? (<Loader />)
                    : props.orderedDevices.length === 0
                        ? (<EmptyState iconClass="fas fa-question fa-7x" iconColor="#c9c9c9" />)
                        : (
                            filteredDevices.length > 0
                                ? (
                                    <div className="card-body bg-light border-top details-container p-0">
                                        <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer">
                                            <thead className="bg-200 text-900">
                                                <tr>
                                                    <th>{props.deviceType}</th>
                                                    <th>Site</th>
                                                    <th className="text-right pr-3">Last Active</th>
                                                    {
                                                        props.viewAll && props.deviceType === "Kiosk" &&
                                                        <th>&nbsp;</th>
                                                    }
                                                </tr>
                                            </thead>
                                            {
                                                <DevicesStatusTable
                                                    deviceType={props.deviceType}
                                                    filteredDevices={filteredDevices}
                                                    organisationId={props.organisationId}
                                                    viewAll={props.viewAll}
                                                    handleDelete={id => props.handleDelete(id)}
                                                />
                                            }
                                        </table>
                                    </div>
                                )
                                : (
                                    <EmptyState
                                        iconClass="fas fa-check-circle fa-7x"
                                        iconColor="#28a745"
                                    />
                                )
                        )
            }
        </div>
    )
}