import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Loader from '../shared/loader';

function PayDisplayMachines(props) {
    const [payDisplayId, setPayDisplayId] = useState(props.match.params.payDisplayId);
    const [payDisplay, setPayDisplay] = useState(null);
    const [siteId, setSiteId] = useState(props.match.params.siteId);
    const [site, setSite] = useState(null);
    const [siteUrl, setSiteUrl] = useState("");

    const [isNew, setIsNew] = useState(false);
    const [isInEdit, setIsInEdit] = useState(false);
    const [canSave, setCanSave] = useState(false);

    const [name, setName] = useState("");
    const [cityCode, setCityCode] = useState("");
    const [machineId, setMachineId] = useState("");
    const [flowbirdTerminalId, setFlowbirdTerminalId] = useState("");
    const [status, setStatus] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const history = useHistory();

    const getPayDisplay = function () {
        Api.getPayDisplay(
            (data)=> setPayDisplay(data),
            (error) => console.log(error),
            payDisplayId,
            props.organisationId,
            props.token
        )
    };

    const submit = function () {
        if(canSave){
            Api.addPayDisplay(
                (data) => {
                    setPayDisplay(data);
                    setIsNew(false)
                    setErrorMessage("");
                    history.push("pay-display-machine/" + data.id);
                },
                (error) => setErrorMessage(error),
                name, cityCode, machineId, flowbirdTerminalId, status,
                siteId,
                props.organisationId,
                props.token,
            )
        }
    };

    const edit = function () {
        if (canSave) {
            Api.editPayDisplay(
                (data) => {
                    setPayDisplay(data);
                    setIsInEdit(false);
                    setErrorMessage("");
                },
                (error) => setErrorMessage(error),
                payDisplayId,
                name, cityCode, machineId, flowbirdTerminalId, status,
                siteId,
                props.organisationId,
                props.token,
            )
        }
    };

    useEffect(() => {
        if (name === null || name === "") {
            setCanSave(false)
        } else {
            setCanSave(true);
        }
    }, [name, cityCode, machineId]);

    useEffect(() => {
        if (site !== null) {
                setSiteUrl("/organisation/"+props.organisationId+"/client/"+ site.clientId + "/site/" + siteId)
        }
    }, [site]);

    useEffect(() => {
        if (payDisplay !== null) {
            setName(payDisplay.name)
            setCityCode(payDisplay.cityCode)
            setMachineId(payDisplay.machineId)
            setStatus(payDisplay.isActive)
            setFlowbirdTerminalId(payDisplay.flowbirdTerminalId);
        }
        setErrorMessage("");
    }, [payDisplay, isInEdit]);

    useEffect(() => {
        if (payDisplayId !== undefined) {
            getPayDisplay();
        } else {
            setIsNew(true);
        }
        if(site === null && siteId !== null){
            Api.getSite(
                (data) => setSite(data),
                (error) => console.log(error),
                siteId,
                props.organisationId,
                props.token
            )
        }
    }, []);
    return (
        <>
            {!payDisplay && !isNew && <Loader />} {payDisplay && payDisplay !== null && !isNew && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h5 className="mb-0">Pay and Display Machine - {payDisplay.name}</h5>
                                    </div>
                                    <Link className="btn btn-falcon-default btn-sm mr-3 py-1" type="button" to={siteUrl}><i className="fas fa-reply"></i> Back</Link>
                                    <button className="btn btn-falcon-default btn-sm dropdown-toggle py-2 mr-3 dropdown-caret-none" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fas fa-ellipsis-h"></i></button>
                                        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-start">
                                            <button type="button" className="dropdown-item text-danger" data-toggle="modal" data-target="#deleteModal">
                                                <i className="fas fa-times"></i> Delete Machine</button>
                                        </div>
                                </div>
                                </div>
                            </div>
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Details</h5>
                                        </div>
                                        <div className="col-auto">
                                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setIsInEdit(!isInEdit)}>
                                                <i className="fas fa-edit"></i> Edit</button>
                                        </div>
                                    </div>
                                </div>
                                {!isInEdit && <>
                                    <div className="card-body bg-light border-top details-container">
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Machine Name</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{payDisplay.name}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">City Code</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{payDisplay.cityCode}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Machine ID</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{payDisplay.machineId}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Flowbird Terminal ID</p>
                                            </div>
                                            <div className="col-md-6 col-12 ">{payDisplay.flowbirdTerminalId}</div>
                                        </div>
                                        <div className="row align-items-center">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mb-3">Status</p>
                                            </div>
                                            <div className="col-md-6 col-12 mb-3">{payDisplay.isActive && <i className="fas fa-check text-success"></i>}{!payDisplay.isActive && <i className="fas fa-times text-danger"></i>}</div>
                                        </div>
                                    </div>
                                </>}
                                {isInEdit && <>
                                    <div className="card-body bg-light border-top details-container">
                                    {errorMessage !== "" && <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>}
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Machine Name<span className="req"></span></p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">City Code</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={cityCode} onChange={(e) => setCityCode(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Machine ID</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={machineId} onChange={(e) => setMachineId(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold  mt-1 mb-1">Flowbird Terminal ID</p>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="form-group mb-1">
                                                    <input className="form-control mb-0" type="text" value={flowbirdTerminalId} onChange={(e) => setFlowbirdTerminalId(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 col-12 ">
                                                <p className="font-weight-semi-bold mb-1">Status</p>
                                            </div>
                                            <div className="col-md-6 col-12 custom-control custom-switch">
                                                <input className="custom-control-input" type="checkbox" id="switch1" checked={status} onChange={() => setStatus(!status)} />
                                                <label className="custom-control-label ml-3" htmlFor="switch1"></label>
                                                {status && <>Active</>}{!status && <>Deactivated</>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer border-top ">
                                    <div className="row client-edit-buttons">
                                        <div className="col-12 col-sm-auto ml-auto  pl-0">
                                            <div id="dashboard-actions">
                                                <button className="btn btn-falcon-default btn-sm" onClick={()=> setIsInEdit(false)}><i className="fas fa-times"></i> Cancel</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => edit()}>
                                                    <i className="fas fa-check"></i> Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="deleteModalLabel">Are you sure you want to delete this pay and display machine?</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                                            <button type="button" className="btn btn-danger btn-sm ml-2" data-dismiss="modal" onClick={() => Api.deletePayDisplay(() => history.push(siteUrl), props.organisationId, payDisplayId, props.token)}>
                                                Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
            </>} {isNew && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="col-md-5 col-12 col-sm-auto d-flex align-items-center pr-0">
                                        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Add new Pay and Display Machine</h5>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                {errorMessage !== "" && <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>}
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Machine Name<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" autoFocus={true} value={name} onChange={(e) => setName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">City Code</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={cityCode} onChange={(e) => setCityCode(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Machine ID</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={machineId} onChange={(e) => setMachineId(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Flowbird Terminal ID</p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-0">
                                                <input className="form-control mb-1" type="text" value={flowbirdTerminalId} onChange={(e) => setFlowbirdTerminalId(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Status</p>
                                        </div>
                                        <div className="col-md-6 col-12 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="switch1" checked={status} onChange={() => setStatus(!status)} />
                                            <label className="custom-control-label ml-3" htmlFor="switch1"></label>
                                            {status && <>Active</>}{!status && <>Deactivated</>}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="row client-edit-buttons">
                                        <div className="col-12 col-sm-auto ml-auto  pl-0">
                                            <div id="dashboard-actions">
                                                <Link className="btn btn-falcon-default btn-sm" to={siteUrl}><i className="fas fa-times"></i> Cancel</Link>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => submit()}>
                                                    <i className="fas fa-check"></i> Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}
export default PayDisplayMachines;