import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Enums from '../../utilities/enum';
import Loader from '../shared/loader';

function Contraventions(props) {
    const [contraventions, setContraventions] = useState(null);
    const [search, setSearch] = useState(null);
    const [status, setStatus] = useState(null);
    const [type, setType] = useState(null);
    const [contraventionTypes, setContraventionTypes] = useState([]);
    const [dvlaCode, setDvlaCode] = useState(null);
    const getContraventions = function () {
        Api.getContraventions(
            (data) => setContraventions(data),
            (error) => console.log(error),
            props.organisationId,
            props.token
        )
    }
    const clearFilters = function () {
        setSearch(null);
        setDvlaCode(null);
        setStatus(null);
        setType(null);
    }
    const setTypes = function (types) {
        var tempContraventionTypes = [];
        var i = 0;
        while (i < 2) {
            tempContraventionTypes.push(types[i])
            i++;
        }
        setContraventionTypes(tempContraventionTypes);
    }
    useEffect(() => {
        getContraventions()
        setTypes(Enums.ContraventionTypes);
    }, [])
    var addNewUrl = "/organisation/" + props.organisationId + "/contravention";
    return (
        <>
            {!contraventions && <Loader />}{contraventions && contraventions !== null && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-1 d-flex align-items-center pr-0">
                                            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Contraventions</h5>
                                        </div>
                                        <div className="col-auto ml-auto text-right pl-0 filter-buttons-wrapper">
                                        <div className="input-group mr-1">
                                            {search === null && <>
                                                <input className="form-control" value={null} type="text" placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                            </>}
                                            {search !== null && <>
                                                <input className="form-control" value={search} type="text" autoFocus={true} placeholder="search..." onChange={(e) => setSearch(e.target.value)} />
                                            </>}
                                            <div className="input-group-append">
                                                <button className="btn btn-falcon-default btn-sm py-0" onClick={() => clearFilters()}><i className="fas fa-times"></i> Clear</button>
                                            </div>
                                        </div>
                                            <div className="filter-buttons" id="select-type">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-city" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{type === null && <>Select Type</>}{Enums.ContraventionTypes[type]}</button>
                                                <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-type" >
                                                    <div className="bg-white py-3 rounded-soft">
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(null)}>All</button>
                                                        {contraventionTypes.map((type, i = 0) => {
                                                                i++;
                                                                return (
                                                                    <button className="dropdown-item text-base px-3 py-2" onClick={() => setType(i - 1)}>{type}</button>
                                                                )
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="select-dvla-code">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-dvla-code" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{dvlaCode && <>Code: {dvlaCode}</>}{dvlaCode === null && <>Filter by DVLA Code</>}</button>
                                                <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-dvla-code" >
                                                    <div className="bg-white py-3 rounded-soft">
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setDvlaCode(null)}>All</button>
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setDvlaCode("dvlaCode")}>Code 1</button>
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setDvlaCode("Code 2")}>Code 2</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="filter-buttons" id="select-status">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 ml-1" id="select-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{status && <>Status: Active</>}{!status && status !== null && <>Status: Deactivated</>}{status === null && <>Filter by Status</>}</button>
                                                <div className="dropdown-menu dropdown-menu-right py-0" aria-labelledby="select-status" >
                                                    <div className="bg-white py-3 rounded-soft">
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(null)}>All</button>
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(true)}>Active</button>
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setStatus(false)}>Deactivated</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link className="btn btn-success btn-sm mr-1 ml-1 nowrap" to={addNewUrl}><i className="fas fa-plus"></i> Add</Link>
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                                    <thead className="bg-200 text-900">
                                        <tr>
                                            <th>Contravention</th>
                                            <th>DVLA Code</th>
                                            <th>Type</th>
                                            <th className="text-center">Appeal Reply</th>
                                            <th className="text-center">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contraventions.filter(function(contravention){
                                            var description = new String(contravention.name).toLowerCase();
                                            var itemDvlaCode = new String(contravention.dvlaCode).toLowerCase();
                                            var itemType = new String(Enums.ContraventionTypes[contravention.contraventionType]).toLowerCase();

                                            return((type === null || type === contravention.contraventionType) && (status == null || status === contravention.isActive) && (dvlaCode == null || dvlaCode === contravention.dvlaCode) && (search === null || description.search(search.toLowerCase()) !== -1 || itemDvlaCode.search(search.toLowerCase()) !== -1 || itemType.search(search.toLowerCase()) !== -1))
                                        }).map((contravention) => {
                                            var url = "/organisation/" + props.organisationId + "/contravention/" + contravention.id;
                                            var appealReply = false;
                                            if(contravention.appealReply !== "" || contravention.appealReply !== null){
                                                appealReply = true;
                                            }
                                            return (
                                                <tr>
                                                    <td><Link to={url}><h6 className="mb-0">{contravention.name}</h6></Link></td>
                                                        <td>{contravention.dvlaCode}</td>
                                                        <td>{Enums.ContraventionTypes[contravention.contraventionType]}</td>
                                                        <td className="text-center">{appealReply && <><i className="fas fa-check text-success"></i></>}{!appealReply && <><i className="fas fa-times text-danger"></i></>}</td>
                                                        <td className="text-center">{contravention.isActive && <><i className="fas fa-check text-success"></i></>}{!contravention.isActive && <><i className="fas fa-times text-danger"></i></>}</td>
                                                </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}
export default Contraventions;