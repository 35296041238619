import Enums from './enum';
import moment from 'moment';
import { Link } from 'react-router-dom';

export const dataTableCellUtils = (data, key) => {

    if (key === "breachStatus") return Enums.BreachStatus[data[key]]

    if (key === "vrm") {
        return <Link to={data.breachUrl} className="number-plate-link"><h6 className="mb-0 small-number-plate">{data[key]}</h6></Link>
    }

    if (key === "pcnReference" || key === "referenceNumber") {
        return <Link to={data.breachUrl}><h6 style={{color: "#5e6e82", margin: "0"}}>{data[key]}</h6></Link>
    }

    if (key === "siteName") {
        return <Link to={data.siteUrl}><h6 style={{color: "#5e6e82", margin: "0"}}>{data[key]}</h6></Link>
    }

    if (moment(data[key]).isValid()) return moment(data[key]).format("DD/MM/yyyy")
    
    return data[key];
}