import { useEffect, useState } from "react";
import api from "../api/api";
import Loader from "../shared/loader";
import { DataTable } from "../shared/dataTable";
import Pagination from "../shared/pagination";

const EnforcementSiteBreachesEnum = {
    "referenceNumber": "Reference Number",
    "vrm": "Reference VRM",
    "siteName": "Site",
    "contraventionName": "Contravention",
    "timestamp": "Contravention Date",
    "breachStatus": "Status",
};

export const EnforcementSiteDetails = (props) => {
    const [renderedItems, setRenderedItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);

    const fetchBreaches = (page) => {
        setIsLoading(true);
        api.getBreaches(
            (data) => { setRenderedItems(data); setIsLoading(false) },
            () => { },
            props.searchQuery,
            props.selectedSiteId,
            null,
            null,
            props.filterStart,
            props.filterEnd,
            props.type,
            props.status,
            page,
            props.organisationId,
            props.token
        );
    };

    useEffect(() => window.scrollTo({ top: 0 }), []);

    useEffect(() => {
        fetchBreaches(0);
        setPageNumber(0);
    }, [props.filterStart, props.searchQuery, props.filterEnd, props.type, props.status, props.selectedSiteId]);

    return (
        <div className="card card-body p-0 bg-white" style={ { minHeight: "500px" } }>
            {
                isLoading
                    ? <div className="h-100"><Loader /></div>
                    : <>
                        <DataTable
                            enum={ EnforcementSiteBreachesEnum }
                            tableData={ renderedItems?.items?.map(element => (
                                {
                                    ...element,
                                    breachUrl: `/organisation/${ props.organisationId }/breach/${ element.id }`,
                                    siteUrl: `/organisation/${ props.organisationId }/client/${ props.selectedClientId }/site/${ props.selectedSiteId }`
                                }))
                            }
                        />
                        <div className="d-flex justify-content-center row align-items-center pt-2">
                            <div className="col-auto align-items-center">
                                <Pagination
                                    totalResults={ renderedItems.totalCount }
                                    currentPage={ pageNumber }
                                    changePageNumber={ newPageNumber => { 
                                        fetchBreaches(newPageNumber); 
                                        setPageNumber(newPageNumber) 
                                    } } />
                            </div>
                        </div>
                    </>
            }
        </div>
    )
}