import Api from "../api/api";
import { useEffect, useState } from "react";
import { ReportsWrapper } from "./reportsWrapper";
import { DateRangeTypeEnum, ReportTypeEnum, VehicleTypeEnum, ReportsEnumsList } from "./reportsEnums";
import { fetchPreviousFullMonth } from "../../utilities/dateUtilities";
import { DatePicker } from "../shared/datePicker";
import Enums from "../../utilities/enum";
import { saveAs } from 'file-saver';
import { OptionsSelector } from "../shared/optionsSelector";
import { ReportTable } from "./reportTable";
import Loader from "../shared/loader";
import { EmptyState } from "../dashboard/emptyState";
import { ReportsCharts } from "./reportsCharts";
import stringHelpers from "../../utilities/stringHelpers";
import { createGroupedChartTraces } from "./chartUtils";

export default function Report(props) {

    const [reportsData, setReportsData] = useState(null);
    const [fromDate, setFromDate] = useState(fetchPreviousFullMonth().firstDate);
    const [toDate, setToDate] = useState(fetchPreviousFullMonth().lastDate);
    const [isLoading, setIsLoading] = useState(true);
    const [vehicleType, setVehicleType] = useState(0);
    const [selectedSite, setSelectedSite] = useState(props.sites[0].id);
    const [selectedStatus, setSelectedStatus] = useState(0);
    const [selectedDateRange, setSelectedDateRange] = useState(0);
    const [chartStyle, setChartStyle] = useState("bar");
    const [totalCount, setTotalCount] = useState(null);
    const [chartData, setChartData] = useState(null);

    const reportsLandingUrl = `/organisation/${ props.organisationId }/reports`;
    const fetchExportBreachesReports = () => {
        Api.getExportBreachReports(
            reportData => { setReportsData(reportData); setIsLoading(false) },
            () => setIsLoading(false),
            parseInt(selectedStatus), fromDate, 0, props.organisationId, props.token
        )
    }

    const fetchPCNReports = () => {
        Api.getPcnsReports(
            reportData => {
                switch (props.typeOfReport) {
                    case "pcns-issued":
                        setReportsData(reportData.map(item => (
                            {
                                label: item.siteName,
                                value: stringHelpers.formatDecimal(item.pcnsIssued, 0),
                            }
                        )))
                        setTotalCount(stringHelpers.formatDecimal(reportData.reduce((acc, curr) => acc + curr.pcnsIssued, 0), 0));
                        break;
                    case "pcns-paid":
                        const temp = reportData.map(item => (
                            {
                                label: item.name,
                                amount: stringHelpers.formatDecimal(item.amount, 2, "£"),
                                count: stringHelpers.formatDecimal(item.count, 0),
                                subTotal: stringHelpers.formatDecimal((item.amount * item.count), 2, "£")
                            }
                        ));
                        setReportsData(temp);
                        setTotalCount(stringHelpers.formatDecimal(reportData.reduce((acc, curr) => acc + (curr.amount * curr.count), 0), 2, "£"))
                        break;
                }
                setIsLoading(false);
            },
            () => { setIsLoading(false) },
            fromDate, toDate, 0, props.organisationId, props.token, props.typeOfReport
        )
    }

    const fetchReportsBySite = (siteId) => {
        Api.getReportsDataBySite(
            reportData => { 
                if (props.typeOfReport === "contraventions") {
                    setReportsData(
                        reportData.map(item => (
                            {
                                label: item.label,
                                contraventionName: item.contraventionName,
                                contraventionType: item.contraventionType,
                                value: item.value,
                                shortLabel: item.shortLabel
                            }
                        ))
                    )
                }
                else setReportsData(reportData); 
                props.typeOfReport === "daily-totals" && 
                setTotalCount(stringHelpers.formatDecimal(reportData.reduce((acc, curr) => {acc += curr.value; return acc}, 0), 0))
                setIsLoading(false) 
            },
            () => setIsLoading(false),
            fromDate, toDate, 0, parseInt(vehicleType), parseInt(selectedDateRange), parseInt(selectedStatus), props.organisationId, siteId, props.token, props.typeOfReport
        )
    }

    const handleResetFilters = () => {
        setFromDate(fetchPreviousFullMonth().firstDate);
        setToDate(fetchPreviousFullMonth().lastDate);
        setVehicleType(0);
        setSelectedSite(props.sites[0].id);
        setSelectedDateRange(0);
        setReportsData(null);
        setSelectedStatus(0);
    }

    useEffect(() => {
        setIsLoading(true);
        if (props.typeOfReport === "export-breaches") {
            fetchExportBreachesReports();
        }
        else if (props.typeOfReport === "pcns-paid" || props.typeOfReport === "pcns-issued") {
            fetchPCNReports();
        }
        else fetchReportsBySite(props.sites[0].id);
        return () => handleResetFilters();
    }, []);

    useEffect(() => {
        setIsLoading(true);
        if (props.typeOfReport === "export-breaches") {
            fetchExportBreachesReports();
        }
        else if (props.typeOfReport === "pcns-paid" || props.typeOfReport === "pcns-issued") {
            fetchPCNReports();
        }
        else fetchReportsBySite(selectedSite);

    }, [fromDate, toDate, vehicleType, selectedSite, selectedDateRange, props.typeOfReport, selectedStatus])

    useEffect(() => {
        if (reportsData && reportsData.length > 0) {
            if (props.typeOfReport === "pcns-paid") {
                const amountOptions = reportsData.reduce((acc, curr) => {
                    if (!acc.includes(curr.amount)) acc.push(curr.amount);
                    return acc;
                }, [])

                const countsBySiteName = reportsData.reduce((acc, curr) => {
                    acc[curr.label] = {
                        ...acc[curr.label],
                        [`${ curr.amount }`]: curr.count
                    }
                    return acc
                }, {})

                setChartData(createGroupedChartTraces(amountOptions, countsBySiteName));
            }
            else if (props.typeOfReport === "contraventions") {
                const contraventionsNameOptions = reportsData.reduce((acc, curr) => {
                    if (!acc.includes(curr.contraventionName)) acc.push(curr.contraventionName);
                    return acc;
                }, [])

                const valuesByDate = reportsData.reduce((acc, curr) => {
                    acc[curr.shortLabel] = {
                        ...acc[curr.shortLabel],
                        [`${ curr.contraventionName }`]: curr.value
                    }
                    return acc
                }, {})

                setChartData(createGroupedChartTraces(contraventionsNameOptions, valuesByDate))
            }
            else {
                const regularXAxis = reportsData.map(item => {
                    return props.typeOfReport === "pcns-issued" ? `${item.label.slice(0, 15)}...` : (item.shortLabel || item.label)
                });
                const regularYAxis = reportsData.map(item => item.value);

                setChartData([
                    { x: regularXAxis, y: regularYAxis, type: chartStyle, marker: { color: '#ff7e34' } },
                ])
            }
        }
    }, [reportsData, chartStyle])

    const handleDownload = (reportFormat) => {

        if (props.typeOfReport === "export-breaches") {
            Api.getExportBreachReports(
                (blob, filename) => { saveAs(blob, filename); },
                () => { setIsLoading(false) },
                selectedStatus,
                fromDate,
                reportFormat,
                props.organisationId,
                props.token
            );
        }

        if (props.typeOfReport === "pcns-issued" || props.typeOfReport === "pcns-paid") {
            Api.getPcnsReports(
                (blob, filename) => { saveAs(blob, filename) },
                () => { setIsLoading(false) },
                fromDate,
                toDate,
                reportFormat,
                props.organisationId,
                props.token,
                props.typeOfReport
            )
        }

        else Api.getReportsDataBySite(
            (blob, filename) => { saveAs(blob, filename) },
            () => { setIsLoading(false) },
            fromDate,
            toDate,
            reportFormat,
            parseInt(vehicleType),
            parseInt(selectedDateRange),
            parseInt(selectedStatus),
            props.organisationId,
            selectedSite,
            props.token,
            props.typeOfReport
        )
    }

    const filtersByReportType = {
        "average-stay": [
            <DatePicker date={ fromDate } title="From" handleChange={ date => setFromDate(date) } />,
            <DatePicker date={ toDate } title="To" handleChange={ date => setToDate(date) } />,
            <OptionsSelector
                label="Site"
                data={ props.sites.reduce((acc, curr) => {
                    acc[curr.id] = curr.name;
                    return acc;
                }, {}) }
                selectedValue={ selectedSite }
                handleOptionSelect={ (value) => setSelectedSite(value) }
            />,
            <OptionsSelector
                label="Vehicle Type"
                data={ VehicleTypeEnum }
                selectedValue={ vehicleType }
                handleOptionSelect={ (value) => setVehicleType(parseInt(value)) }
            />,
            <OptionsSelector
                label="Date Grouping"
                data={ DateRangeTypeEnum }
                selectedValue={ selectedDateRange }
                handleOptionSelect={ (value) => setSelectedDateRange(parseInt(value)) }
            />
        ],
        "daily-totals": [
            <DatePicker date={ fromDate } title="From" handleChange={ date => setFromDate(date) } />,
            <DatePicker date={ toDate } title="To" handleChange={ date => setToDate(date) } />,
            <OptionsSelector
                label="Site"
                data={ props.sites.reduce((acc, curr) => {
                    acc[curr.id] = curr.name;
                    return acc;
                }, {}) }
                selectedValue={ selectedSite }
                handleOptionSelect={ (value) => setSelectedSite(value) }
            />,
            <OptionsSelector
                label="Vehicle Type"
                data={ VehicleTypeEnum }
                selectedValue={ vehicleType }
                handleOptionSelect={ (value) => setVehicleType(parseInt(value)) }
            />,
            <OptionsSelector
                label="Date Grouping"
                data={ DateRangeTypeEnum }
                selectedValue={ selectedDateRange }
                handleOptionSelect={ (value) => setSelectedDateRange(parseInt(value)) }
            />
        ],
        "parking-durations": [
            <DatePicker date={ fromDate } title="From" handleChange={ date => setFromDate(date) } />,
            <DatePicker date={ toDate } title="To" handleChange={ date => setToDate(date) } />,
            <OptionsSelector
                label="Site"
                data={ props.sites.reduce((acc, curr) => {
                    acc[curr.id] = curr.name;
                    return acc;
                }, {}) }
                selectedValue={ selectedSite }
                handleOptionSelect={ (value) => setSelectedSite(value) }
            />,
            <OptionsSelector
                label="Vehicle Type"
                data={ VehicleTypeEnum }
                selectedValue={ vehicleType }
                handleOptionSelect={ (value) => setVehicleType(parseInt(value)) }
            />,
            <OptionsSelector
                label="Date Grouping"
                data={ DateRangeTypeEnum }
                selectedValue={ selectedDateRange }
                handleOptionSelect={ (value) => setSelectedDateRange(parseInt(value)) }
            />
        ],
        "breaches": [
            <DatePicker date={ fromDate } title="From" handleChange={ date => setFromDate(date) } />,
            <DatePicker date={ toDate } title="To" handleChange={ date => setToDate(date) } />,
            <OptionsSelector
                label="Site"
                data={ props.sites.reduce((acc, curr) => {
                    acc[curr.id] = curr.name;
                    return acc;
                }, {}) }
                selectedValue={ selectedSite }
                handleOptionSelect={ (value) => setSelectedSite(value) }
            />,
            <OptionsSelector
                label="Date Grouping"
                data={ DateRangeTypeEnum }
                selectedValue={ selectedDateRange }
                handleOptionSelect={ (value) => setSelectedDateRange(parseInt(value)) }
            />,
            <OptionsSelector
                label="Status"
                data={ Enums.BreachStatus }
                selectedValue={ selectedStatus }
                handleOptionSelect={ (value) => setSelectedStatus(value) }
            />
        ],
        "contraventions": [
            <DatePicker date={ fromDate } title="From" handleChange={ date => setFromDate(date) } />,
            <DatePicker date={ toDate } title="To" handleChange={ date => setToDate(date) } />,
            <OptionsSelector
                label="Site"
                data={ props.sites.reduce((acc, curr) => {
                    acc[curr.id] = curr.name;
                    return acc;
                }, {}) }
                selectedValue={ selectedSite }
                handleOptionSelect={ (value) => setSelectedSite(value) }
            />,
            <OptionsSelector
                label="Date Grouping"
                data={ DateRangeTypeEnum }
                selectedValue={ selectedDateRange }
                handleOptionSelect={ (value) => setSelectedDateRange(parseInt(value)) }
            />,
        ],
        "export-breaches": [
            <DatePicker date={ fromDate } title="Date" handleChange={ date => setFromDate(date) } />,
            <OptionsSelector
                label="Status"
                data={ Enums.BreachStatus }
                selectedValue={ selectedStatus }
                handleOptionSelect={ (value) => setSelectedStatus(value) }
            />,
        ],
        "pcns-issued": [
            <DatePicker date={ fromDate } title="From" handleChange={ date => setFromDate(date) } />,
            <DatePicker date={ toDate } title="To" handleChange={ date => setToDate(date) } />,
        ],
        "pcns-paid": [
            <DatePicker date={ fromDate } title="From" handleChange={ date => setFromDate(date) } />,
            <DatePicker date={ toDate } title="To" handleChange={ date => setToDate(date) } />,
        ],
    }

    return (
        <ReportsWrapper
            title={ ReportTypeEnum[props.typeOfReport] }
            backButtonUrl={ reportsLandingUrl }
        >
            <div className="reports-filters-container mb-3">
                <div className="d-flex reports-filters">
                    <div className="d-flex" style={ { gap: "0.5rem" } }>
                        { filtersByReportType[props.typeOfReport].map(item => item) }
                    </div>
                    <button
                        className="btn btn-secondary btn-sm nowrap report-refresh"
                        onClick={ handleResetFilters }
                    >
                        <i className="fas fa-redo mr-2"></i>Reset Filters
                    </button>
                </div>
                <button 
                    onClick={ () => handleDownload(1) } 
                    class="btn btn-sm btn-primary report-download"
                >
                    Download<i class="ml-2 fas fa-download"></i>
                </button>
            </div>
            {
                isLoading
                    ? <Loader />
                    : <>
                        {
                            reportsData && reportsData.length > 0 ? (
                                <div className="mt-3 d-flex flex-column">
                                    {
                                        props.typeOfReport !== "export-breaches" && chartData &&
                                        <>
                                            {
                                                props.typeOfReport !== "pcns-paid" && props.typeOfReport !== "contraventions" &&
                                                    <div className="pl-6">
                                                        <button
                                                            className={ `btn btn-${ chartStyle === "bar" ? "dark" : "secondary" } btn-sm mr-1 nowrap` }
                                                            onClick={ () => setChartStyle("bar") }><i class="fas fa-chart-bar mr-2"></i>Bar Graph
                                                        </button>
                                                        <button
                                                            className={ `btn btn-${ chartStyle === "scatter" ? "dark" : "secondary" } btn-sm mr-1 ml-1 nowrap` }
                                                            onClick={ () => setChartStyle("scatter") }><i class="fas fa-chart-line mr-2"></i>Line Graph
                                                        </button>
                                                    </div>
                                            }
                                            <ReportsCharts data={ chartData } chartStyle={ chartStyle } toggleChartType={ style => setChartStyle(style) } />
                                        </>
                                    }
                                    <ReportTable totalCount={ totalCount } tableData={ reportsData } enum={ ReportsEnumsList[props.typeOfReport] } />
                                </div>
                            ) : <EmptyState message="No Data available for the selected filters" />
                        }
                    </>
            }
        </ReportsWrapper>
    )
}