import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Api from '../api/api';
import Modal from 'react-bootstrap/Modal';
import Enums from '../../utilities/enum';
import moment from 'moment';
import Loader from '../shared/loader';
import UploadFiles from '../shared/file-uploader';
import stringHelpers from '../../utilities/stringHelpers';

function TransferLiability(props) {
    const [transfer, setTransfer] = useState(null);
    const [isNew, setIsNew] = useState(false);
    const [breachId, setBreachId] = useState(props.match.params.breachId);
    const [contactTypes, setContactTypes] = useState(null);
    const [breach, setBreach] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [files, setFiles] = useState([]);
    const [canTransfer, setCanTransfer] = useState(false);
    const history = useHistory();

    const [designationLiable, setDesignationLiable] = useState(null);
    const [titleLiable, setTitleLiable] = useState("");
    const [firstNameLiable, setFirstNameLiable] = useState("");
    const [lastNameLiable, setLastNameLiable] = useState("");
    const [companyNameLiable, setCompanyNameLiable] = useState("");
    const [address1Liable, setAddress1Liable] = useState("");
    const [address2Liable, setAddress2Liable] = useState("");
    const [address3Liable, setAddress3Liable] = useState("");
    const [cityLiable, setCityLiable] = useState("");
    const [postcodeLiable, setPostcodeLiable] = useState("");
    const [countryLiable, setCountryLiable] = useState("United Kingdom");
    const [contactNumberLiable, setContactNumberLiable] = useState("");
    const [emailAddressLiable, setEmailAddressLiable] = useState("");
    const [appealLiable, setAppealLiable] = useState("");

    const [title, setTitle] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [city, setCity] = useState("");
    const [postcode, setPostcode] = useState("");
    const [country, setCountry] = useState("United Kingdom");
    const [contactNumber, setContactNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [transferFileList, setTransferFileList] = useState([]);

    const [fileNotifier, setFileNotifier] = useState(null);
    const [fileLiable, setFileLiable] = useState(null);


    const [showNotifier, setShowNotifier] = useState(false);
    const [showLiable, setShowLiable] = useState(false);

    const handleCloseNotifier = () => setShowNotifier(false);
    const handleShowNotifier = () => setShowNotifier(true);
    const handleCloseLiable = () => setShowLiable(false);
    const handleShowLiable = () => setShowLiable(true);

    const getTransfer = function () {
        Api.getTransfersLiability(
            (data) => setTransfer(data),
            (error) => console.log(error),
            breachId,
            props.organisationId,
            props.token
        )
    };

    const decline = function () {
        Api.declineTransferLiability(
            () => history.push(backUrl),
            (error) => console.log(error),
            breachId, transfer.liabilityParty.id,
            props.organisationId,
            props.token
        )
    };

    const accept = function () {
        Api.acceptTransferLiability(
            () => history.push(backUrl),
            (error) => console.log(error),
            breachId, transfer.liabilityParty.id,
            props.organisationId,
            props.token
        )
    };

    const removeFileUpload = function (index) {
        var i = 0;
        var j = 0;
        var currentFileIds = [];
        transferFileList.forEach(id => {
            if (i !== index) {
                currentFileIds.push(id);
            }
            i++;
        });

        var currentFiles = [];
        files.forEach(file => {
            if (j !== index) {
                currentFiles.push(file);
            }
            j++;
        });
        setTransferFileList(currentFileIds);
        setFiles(currentFiles);
    };

    const newFileUpload = function (file) {
        var currentFileIds = [];
        transferFileList.forEach(id => {
            currentFileIds.push(id);
        });
        var currentFiles = [];
        files.forEach(file => {
            currentFiles.push(file);
        });
        currentFiles.push(file);
        currentFileIds.push(file.id);

        setFiles(currentFiles);
        setTransferFileList(currentFileIds);
    };


    const getBreach = function () {
        Api.getBreach(
            (data) => setBreach(data),
            (error) => console.log(error),
            breachId,
            props.organisationId,
            props.token
        )
    };

    const submit = function () {
        if (canTransfer) {
            var notifier = new Object();
            notifier.firstName = firstName;
            notifier.lastName = lastName;
            notifier.companyName = companyName;
            notifier.addressLine1 = address1;
            notifier.addressLine2 = address2;
            notifier.addressLine3 = address3;
            notifier.townOrCity = city;
            notifier.postCode = postcode;
            notifier.country = country;
            notifier.mobilePhoneNumber = contactNumber;
            notifier.emailAddress = emailAddress;
            notifier.contactType = 0;
            if (fileNotifier !== null) {
                notifier.attachmentId = fileNotifier.id
            }
            var liabilityParty = new Object();
            liabilityParty.firstName = firstNameLiable;
            liabilityParty.lastName = lastNameLiable;
            liabilityParty.companyName = companyNameLiable;
            liabilityParty.addressLine1 = address1Liable;
            liabilityParty.addressLine2 = address2Liable;
            liabilityParty.addressLine3 = address3Liable;
            liabilityParty.townOrCity = cityLiable;
            liabilityParty.postCode = postcodeLiable;
            liabilityParty.country = countryLiable;
            liabilityParty.mobilePhoneNumber = contactNumberLiable;
            liabilityParty.emailAddress = emailAddressLiable;
            if (fileLiable !== null) {
                liabilityParty.attachmentId = fileLiable.id;
            }
            liabilityParty.contactType = designationLiable;

            Api.addTransferLiability(
                (data) => setTransfer(data),
                (error) => console.log(error),
                notifier, liabilityParty, breachId,
                props.organisationId, props.token
            );
        }
    };

    const setTypes = function (types) {
        var tempTypes = [];
        var i = 0;
        while (true) {
            if (types[i] === undefined) {
                break;
            } else {
                tempTypes.push(types[i])
                i++;
            }
        }
        setContactTypes(tempTypes);
    };

    useEffect(() => {
        setCanTransfer(((!stringHelpers.isNullOrEmpty(firstName) && !stringHelpers.isNullOrEmpty(lastName)) || !stringHelpers.isNullOrEmpty(companyName)) && 
            !stringHelpers.isNullOrEmpty(address1) && 
            !stringHelpers.isNullOrEmpty(city) && 
            !stringHelpers.isNullOrEmpty(postcode) && 
            !stringHelpers.isNullOrEmpty(emailAddress) &&
            ((!stringHelpers.isNullOrEmpty(firstNameLiable) && !stringHelpers.isNullOrEmpty(lastNameLiable)) || !stringHelpers.isNullOrEmpty(companyNameLiable)) && 
            !stringHelpers.isNullOrEmpty(address1Liable) && 
            !stringHelpers.isNullOrEmpty(cityLiable) && 
            !stringHelpers.isNullOrEmpty(postcodeLiable) && 
            !stringHelpers.isNullOrEmpty(emailAddressLiable));
    }, [firstName, lastName, address1, city, postcode, emailAddress, firstNameLiable, lastNameLiable, address1Liable, cityLiable, postcodeLiable, emailAddressLiable, designationLiable]);

    useEffect(() => {
        if (transfer === null) {
            setIsNew(true);
        } else {
            setIsNew(false);
        }
    }, [transfer]);
    
    useEffect(() => {
        if (breachId !== undefined) {
            getBreach();
            getTransfer();
            setTypes(Enums.ContactTypes);
        }
    }, []);

    var backUrl = '/organisation/' + props.organisationId + '/breach/' + breachId;

    return (
        <>
            {transfer === null && !isNew && <Loader />}
            {transfer !== null && breach !== null && <>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Transfer of Liability - {breach.referenceNumber}</h5>
                                        </div>
                                        <Link className="btn btn-falcon-default btn-sm mr-3 py-1" type="button" to={backUrl}><i className="fas fa-reply"></i> Back</Link>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top transfer-container">
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <h5 className="text-center mb-3">
                                                Notifier
                                            </h5>
                                            <div className="row">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">First Name</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">{transfer.notifier.firstName}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">Last Name</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">{transfer.notifier.lastName}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">Company Name</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">{transfer.notifier.companyName}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">Email Address</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">{transfer.notifier.emailAddress}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">Contact Number</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">{transfer.notifier.mobilePhoneNumber}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">Contact Type</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">
                                                    {Enums.ContactTypes[transfer.notifier.contactType]}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">Contact Source</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">
                                                    {Enums.ContactSource[transfer.notifier.contactSource]}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <p className="font-weight-semi-bold mb-3">Address</p>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    {transfer.notifier.addressLine1 && <>{transfer.notifier.addressLine1}<br /></>}
                                                    {transfer.notifier.addressLine2 && <>{transfer.notifier.addressLine2}<br /></>}
                                                    {transfer.notifier.addressLine3 && <>{transfer.notifier.addressLine3}<br /></>}
                                                    {transfer.notifier.townOrCity && <>{transfer.notifier.townOrCity}<br /></>}
                                                    {transfer.notifier.county && <>{transfer.notifier.county}<br /></>}
                                                    {transfer.notifier.postCode && <>{transfer.notifier.postCode}<br /></>}
                                                    {transfer.notifier.country && <>{transfer.notifier.country}<br /></>}
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-1">Status</p>
                                                </div>
                                                <div className="col-md-6 col-12  ">{transfer.notifier.isActive && <i className="fas fa-check text-success"></i>}{!transfer.notifier.isActive && <i className="fas fa-times text-danger"></i>}</div>
                                            </div>
                                            {transfer.notifier.attachment !== null &&
                                                <div className="row align-items-center">
                                                    <div className="col-md-6 col-12 ">
                                                        <p className="font-weight-semi-bold mb-0">Attachment</p>
                                                    </div>
                                                    <div className="col-md-6 col-12 ">
                                                        <div className="row align-items-center">
                                                            <div className="col-6">
                                                                <a href={transfer.notifier.attachment.url} target="_blank"><h6 className="mb-0">{transfer.notifier.attachment.filename}</h6></a>
                                                            </div>
                                                            <div className="col-6">
                                                                {transfer.notifier.attachment.isImage && <img src={transfer.notifier.attachment.url} className="attachment-thumb" />}
                                                                {!transfer.notifier.attachment.isImage && <>
                                                                    {transfer.notifier.attachment.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                                    {transfer.notifier.attachment.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                                    {transfer.notifier.attachment.contentType !== "application/word.doc" && transfer.notifier.attachment.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                                                </>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <h5 className="text-center mb-3">
                                                Liable Party
                                            </h5>
                                            <div className="row">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">First Name</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">{transfer.liabilityParty.firstName}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">Last Name</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">{transfer.liabilityParty.lastName}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">Company Name</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">{transfer.liabilityParty.companyName}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">Email Address</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">{transfer.liabilityParty.emailAddress}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">Contact Number</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">{transfer.liabilityParty.mobilePhoneNumber}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">Contact Type</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">
                                                    {Enums.ContactTypes[transfer.liabilityParty.contactType]}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-3">Contact Source</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">
                                                    {Enums.ContactSource[transfer.liabilityParty.contactSource]}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <p className="font-weight-semi-bold mb-3">Address</p>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    {transfer.liabilityParty.addressLine1 && <>{transfer.liabilityParty.addressLine1}<br /></>}
                                                    {transfer.liabilityParty.addressLine2 && <>{transfer.liabilityParty.addressLine2}<br /></>}
                                                    {transfer.liabilityParty.addressLine3 && <>{transfer.liabilityParty.addressLine3}<br /></>}
                                                    {transfer.liabilityParty.townOrCity && <>{transfer.liabilityParty.townOrCity}<br /></>}
                                                    {transfer.liabilityParty.county && <>{transfer.liabilityParty.county}<br /></>}
                                                    {transfer.liabilityParty.postCode && <>{transfer.liabilityParty.postCode}<br /></>}
                                                    {transfer.liabilityParty.country && <>{transfer.liabilityParty.country}<br /></>}
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-md-6 col-12 ">
                                                    <p className="font-weight-semi-bold  mb-1">Status</p>
                                                </div>
                                                <div className="col-md-6 col-12  ">{transfer.liabilityParty.isActive && <i className="fas fa-check text-success"></i>}{!transfer.liabilityParty.isActive && <i className="fas fa-times text-danger"></i>}</div>
                                            </div>
                                            {transfer.liabilityParty.attachment !== null &&
                                                <div className="row align-items-center">
                                                    <div className="col-md-6 col-12 ">
                                                        <p className="font-weight-semi-bold mb-0">Attachment</p>
                                                    </div>
                                                    <div className="col-md-6 col-12 ">
                                                        <div className="row align-items-center">
                                                            <div className="col-6">
                                                                <a href={transfer.liabilityParty.attachment.url} target="_blank"><h6 className="mb-0">{transfer.liabilityParty.attachment.filename}</h6></a>
                                                            </div>
                                                            <div className="col-6">
                                                                {transfer.liabilityParty.attachment.isImage && <img src={transfer.liabilityParty.attachment.url} className="attachment-thumb" />}
                                                                {!transfer.liabilityParty.attachment.isImage && <>
                                                                    {transfer.liabilityParty.attachment.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                                    {transfer.liabilityParty.attachment.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                                    {transfer.liabilityParty.attachment.contentType !== "application/word.doc" && transfer.liabilityParty.attachment.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                                                </>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-sm-auto ">
                                            <div id="dashboard-actions">
                                                <button type="button" className="btn btn-danger btn-sm ml-2" onClick={() => decline()}>
                                                    <i className="fas fa-times"></i> Decline</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" onClick={() => accept()}>
                                                    <i className="fas fa-check"></i> Accept</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
            {isNew && <>
                <div className="card my-3">
                    <div className="card-header themed-title">
                        <div className="row align-items-center justify-space-between">
                            <div className="col">
                                <h4 className="mb-0 ">
                                    Create Transfer of Liability Request
                                </h4>
                            </div>
                            <Link className="btn btn-falcon-default btn-sm mr-3 py-1" type="button" to={backUrl}><i className="fas fa-reply"></i> Back</Link>
                        </div>
                    </div>
                    <div className="card-body bg-light border-top">
                        <div className="row">
                            <div className="col-12">
                                <h5 className="mb-2">Notifier Details</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-12 ">
                                <p className="font-weight-semi-bold mb-1">Title</p>
                            </div>
                            <div className="col-md-6 col-12 ">
                                <div className="form-group mb-0">
                                    <div className="dropdown">
                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-title" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{title !== "" && <>{title}</>}{title === "" && <>Select Title</>}</button>
                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-title" >
                                            <div className="bg-white py-2 rounded-soft">
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Mr")}>Mr</button>
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Mrs")}>Mrs</button>
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Miss")}>Miss</button>
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Ms")}>Ms</button>
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Dr")}>Dr</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">First Name</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Last Name</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Company Name</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Address Line 1<span className="req"></span></p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={address1} onChange={(e) => setAddress1(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Address Line 2</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={address2} onChange={(e) => setAddress2(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Address Line 3</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={address3} onChange={(e) => setAddress3(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">City<span className="req"></span></p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Postcode<span className="req"></span></p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={postcode} onChange={(e) => setPostcode(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-12 ">
                                <p className="font-weight-semi-bold mb-1">Country</p>
                            </div>
                            <div className="col-md-6 col-12 ">
                                <div className="form-group mb-0">
                                    <div className="dropdown">
                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-title" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{country !== "" && <>{country}</>}{country === "" && <>Select Country</>}</button>
                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-title" >
                                            <div className="bg-white py-2 rounded-soft">
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setCountry("United Kingdom")}>United Kingdom</button>
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setCountry("France")}>France</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Contact Number</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Email Address<span className="req"></span></p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Supporting Document<span className="req"></span></p>
                            </div>
                            {fileNotifier !== null &&
                                <div className="col-12 col-md-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <a href={fileNotifier.url} target="_blank"><h6 className="m">{fileNotifier.filename}</h6></a>
                                        </div>
                                        <div className="col-5">
                                            {fileNotifier.isImage &&
                                                <div className="d-flex justify-content-center my-2">
                                                    <img src={fileNotifier.url} className="attachment-thumb" />
                                                </div>}
                                            {!fileNotifier.isImage && <>
                                                {fileNotifier.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                {fileNotifier.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                {fileNotifier.contentType !== "application/word.doc" && fileNotifier.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                            </>}
                                        </div>
                                        <div className="col-3">
                                            <button className="btn btn-sm btn-danger" onClick={() => setFileNotifier(null)}>Remove</button>
                                        </div>
                                    </div>
                                </div>}
                            {fileNotifier === null &&
                                <div className="col-auto">
                                    <button className="btn btn-falcon-default mt-1" onClick={handleShowNotifier}>
                                        Add Supporting Document
                                    </button>
                                </div>}
                        </div>
                        <Modal show={showNotifier} onHide={handleCloseNotifier}>
                            <UploadFiles onSuccess={(file) => setFileNotifier(file)} type="Document" hideDone={true} organisationId={props.organisationId} />
                            <Modal.Footer>
                                <button className="btn btn-falcon-default mt-1" onClick={handleCloseNotifier}>
                                    Done
                                </button>
                            </Modal.Footer>
                        </Modal>
                        <hr className="border-dashed border-bottom-0"></hr>
                        <div className="row">
                            <div className="col-12">
                                <h5>Liable Party Details</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-12">
                                <p className="font-weight-semi-bold mb-1">Liable Party Designation<span className="req"></span></p>
                            </div>
                            <div className="col-md-6 col-12 ">
                                {contactTypes !== null && contactTypes.map((item, i = 0) => {
                                    i++;
                                    var text = `The Liable party was/is the ` + item + ` of the vehicle`
                                    var checked = false;
                                    if (designationLiable === i - 1) {
                                        checked = true;
                                    }
                                    return (
                                        <div class="form-check mb-2">
                                            <input class="form-check-input my-0" type="radio" name="flexRadioDefault" id={i} checked={checked} onClick={() => setDesignationLiable(i - 1)} />
                                            <label class="form-check-label ml-3 mb-0" for={i}>
                                                {text}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-12 ">
                                <p className="font-weight-semi-bold mb-1">Title</p>
                            </div>
                            <div className="col-md-6 col-12 ">
                                <div className="form-group mb-0">
                                    <div className="dropdown">
                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-title" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{titleLiable !== "" && <>{titleLiable}</>}{titleLiable === "" && <>Select Title</>}</button>
                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-title" >
                                            <div className="bg-white py-2 rounded-soft">
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitleLiable("Mr")}>Mr</button>
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitleLiable("Mrs")}>Mrs</button>
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitleLiable("Miss")}>Miss</button>
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitleLiable("Ms")}>Ms</button>
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitleLiable("Dr")}>Dr</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">First Name</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={firstNameLiable} onChange={(e) => setFirstNameLiable(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Last Name</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={lastNameLiable} onChange={(e) => setLastNameLiable(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Company Name</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={companyNameLiable} onChange={(e) => setCompanyNameLiable(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Address Line 1<span className="req"></span></p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={address1Liable} onChange={(e) => setAddress1Liable(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Address Line 2</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={address2Liable} onChange={(e) => setAddress2Liable(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Address Line 3</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={address3Liable} onChange={(e) => setAddress3Liable(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">City<span className="req"></span></p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={cityLiable} onChange={(e) => setCityLiable(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Postcode<span className="req"></span></p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={postcodeLiable} onChange={(e) => setPostcodeLiable(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-12 ">
                                <p className="font-weight-semi-bold mb-1">Country</p>
                            </div>
                            <div className="col-md-6 col-12 ">
                                <div className="form-group mb-0">
                                    <div className="dropdown">
                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-title" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{countryLiable !== "" && <>{countryLiable}</>}{countryLiable === "" && <>Select Country</>}</button>
                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-title" >
                                            <div className="bg-white py-2 rounded-soft">
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setCountryLiable("United Kingdom")}>United Kingdom</button>
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setCountryLiable("France")}>France</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Contact Number</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={contactNumberLiable} onChange={(e) => setContactNumberLiable(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Email Address<span className="req"></span></p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={emailAddressLiable} onChange={(e) => setEmailAddressLiable(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Supporting Document<span className="req"></span></p>
                            </div>
                            {fileLiable !== null &&
                                <div className="col-12 col-md-6">
                                    <div className="row align-items-center">
                                        <div className="col-4">
                                            <a href={fileLiable.url} target="_blank"><h6 className="m">{fileLiable.filename}</h6></a>
                                        </div>
                                        <div className="col-5">
                                            {fileLiable.isImage &&
                                                <div className="d-flex justify-content-center my-2">
                                                    <img src={fileLiable.url} className="attachment-thumb" />
                                                </div>}
                                            {!fileLiable.isImage && <>
                                                {fileLiable.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                {fileLiable.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                {fileLiable.contentType !== "application/word.doc" && fileLiable.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                            </>}
                                        </div>
                                        <div className="col-3">
                                            <button className="btn btn-sm btn-danger" onClick={() => setFileLiable(null)}>Remove</button>
                                        </div>
                                    </div>
                                </div>}
                            {fileLiable === null &&
                                <div className="col-auto">
                                    <button className="btn btn-falcon-default mt-1" onClick={handleShowLiable}>
                                        Add Supporting Document
                                    </button>
                                </div>}
                        </div>
                        <Modal show={showLiable} onHide={handleCloseLiable}>
                            <UploadFiles onSuccess={(file) => setFileLiable(file)} type="Document" hideDone={true} organisationId={props.organisationId} />
                            <Modal.Footer>
                                <button className="btn btn-falcon-default mt-1" onClick={handleCloseLiable}>
                                    Done
                                </button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div className="card-footer border-top">
                        <div className="row client-edit-buttons">
                            <div className="col-6 col-sm-auto ml-auto  pl-0">
                                <div id="dashboard-actions">
                                    <Link className="btn btn-falcon-default btn-sm" to={backUrl}><i className="fas fa-times"></i> Cancel</Link>
                                    <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canTransfer} onClick={() => submit()}>
                                        <i className="fas fa-check"></i> Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}
export default TransferLiability;