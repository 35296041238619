import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Api from '../api/api';
import Enums from '../../utilities/enum';
import StringHelpers from '../../utilities/stringHelpers';
import moment from 'moment';
import UploadFiles from '../shared/file-uploader';
import Loader from '../shared/loader';


function Appeal(props) {
    const [appealId, setAppealId] = useState(props.match.params.appealId);
    const [appeal, setAppeal] = useState(null);
    const [isNew, setIsNew] = useState(false);
    const [breachId, setBreachId] = useState(props.match.params.breachId);
    const [breach, setBreach] = useState(null);
    const [canAppeal, setCanAppeal] = useState(false);
    const [showRespond, setShowRespond] = useState(false);
    const [appealResponse, setAppealResponse] = useState("");
    const [canSaveResponse, setCanSaveResponse] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const [files, setFiles] = useState([]);

    //appeal info
    const [title, setTitle] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [city, setCity] = useState("");
    const [postcode, setPostcode] = useState("");
    const [country, setCountry] = useState("United Kingdom");
    const [contactNumber, setContactNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [appealReason, setAppealReason] = useState("");
    const [appealFileList, setAppealFileList] = useState([]);
    const [contactTypes, setContactTypes] = useState(null);
    const [attachments, setAttachments] = useState(null);
    const [appellantType, setAppellantType] = useState(null);

    const history = useHistory();

    const getAppeal = function () {
        Api.getAppeal(
            (data) => {
                setAppeal(data);
                setIsLoading(false);
            },
            (error) => setErrorMessage(error),
            breachId, appealId,
            props.organisationId,
            props.token
        )
    };

    const removeFileUpload = function (index) {
        var i = 0;
        var j = 0;
        var currentFileIds = [];
        appealFileList.forEach(id => {
            if (i !== index) {
                currentFileIds.push(id);
            }
            i++;
        });

        var currentFiles = [];
        files.forEach(file => {
            if (j !== index) {
                currentFiles.push(file);
            }
            j++;
        });
        setAppealFileList(currentFileIds);
        setFiles(currentFiles);
    };

    const newFileUpload = function (file) {
        var currentFileIds = [];
        appealFileList.forEach(id => {
            currentFileIds.push(id);
        });
        var currentFiles = [];
        files.forEach(file => {
            currentFiles.push(file);
        });
        currentFiles.push(file);
        currentFileIds.push(file.id);

        setFiles(currentFiles);
        setAppealFileList(currentFileIds);
    };

    const getBreach = function () {
        Api.getBreach(
            (data) => setBreach(data),
            (error) => setErrorMessage(error),
            breachId,
            props.organisationId,
            props.token
        )
    };

    const respond = function () {
        setShowRespond(true);
    };

    const decline = function () {
        setIsLoading(true);

        Api.declineAppeal(
            () => history.push(backUrl),
            (error) => {
                setIsLoading(false); 
                setErrorMessage(error)
            },
            breachId, appealId, appealResponse,
            props.organisationId,
            props.token
        )
    };

    const accept = function () {
        setIsLoading(true);

        Api.acceptAppeal(
            () => history.push(backUrl),
            (error) => {
                setIsLoading(false); 
                setErrorMessage(error);
            },
            breachId, appealId, appealResponse,
            props.organisationId,
            props.token
        )
    };

    const submit = function () {
        if (canAppeal) {
            var details = new Object();
            details.firstName = firstName;
            details.lastName = lastName;
            details.addressLine1 = address1;
            details.addressLine2 = address2;
            details.addressLine3 = address3;
            details.townOrCity = city;
            details.postCode = postcode;
            details.country = country;
            details.mobilePhoneNumber = contactNumber;
            details.emailAddress = emailAddress;
            details.contactType = appellantType;
            details.companyName = companyName;

            Api.addAppeal(
                (data) => {
                    setAppeal(data);
                    setIsNew(false);
                },
                (error) => setErrorMessage(error),
                details, appealReason, appealFileList, breachId, props.organisationId, props.token,
            );
        }
    };

    const setTypes = function (types) {
        var tempTypes = [];
        var i = 0;
        while (true) {
            if (types[i] === undefined) {
                break;
            } else {
                tempTypes.push(types[i])
                i++;
            }
        }
        setContactTypes(tempTypes);
    };

    useEffect(() => {
        if (appeal !== null) {
            setAttachments(appeal.attachments);
        }
    }, [appeal]);

    useEffect(() => {
        setCanSaveResponse(StringHelpers.isNullOrEmpty(appealResponse));
    }, [appealResponse])

    useEffect(() => {
        if (appellantType !== null && firstName !== null && firstName !== "" && lastName !== null && lastName !== "" && address1 !== null && address1 !== "" && city !== null && city !== "" &&
            postcode !== null && postcode !== "" && emailAddress !== null && emailAddress !== "" && appealReason !== null && appealReason !== "" && appealFileList.length !== 0) {
            setCanAppeal(true);
        } else {
            setCanAppeal(false);
        }
    }, [appellantType, firstName, lastName, address1, city, postcode, emailAddress, appealReason, appealFileList]);

    useEffect(() => {
        if (breachId !== null) {
            getBreach();
            if (appealId !== undefined) {
                getAppeal();
            } else {
                setIsNew(true);
                setIsLoading(false);
                setTypes(Enums.ContactTypes);
            }
        };
    }, []);

    var backUrl = '/organisation/' + props.organisationId + '/breach/' + breachId;
    return (
        <>
            {isLoading && <Loader />}
            {!isLoading && appeal !== null && breach !== null &&
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mb-3">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <h5 className="mb-0">Appeal - {breach.referenceNumber}</h5>
                                        </div>
                                        <Link className="btn btn-falcon-default btn-sm mr-3 py-1" type="button" to={backUrl}><i className="fas fa-reply"></i> Back</Link>
                                    </div>
                                </div>
                                <div className="card-body bg-light border-top details-container">
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-3">Status</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{Enums.AppealStatus[appeal.appealStatus]}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-3">Reason</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{appeal.appealReason}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-3">Time Stamp</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{moment(appeal.timestamp).format("DD/MM/yyyy")} {moment(appeal.timestamp).format("HH:mm")}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-3">First Name</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{appeal.contactDetails.firstName}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-3">Last Name</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{appeal.contactDetails.lastName}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-3">Company Name</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{appeal.contactDetails.companyName}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-3">Email Address</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{appeal.contactDetails.emailAddress}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-3">Contact Number</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{appeal.contactDetails.mobilePhoneNumber}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-3">Contact Type</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            {Enums.ContactTypes[appeal.contactDetails.contactType]}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-3">Contact Source</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            {Enums.ContactSource[appeal.contactDetails.contactSource]}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <p className="font-weight-semi-bold mb-3">Address</p>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            {appeal.contactDetails.addressLine1 && <>{appeal.contactDetails.addressLine1}<br /></>}
                                            {appeal.contactDetails.addressLine2 && <>{appeal.contactDetails.addressLine2}<br /></>}
                                            {appeal.contactDetails.addressLine3 && <>{appeal.contactDetails.addressLine3}<br /></>}
                                            {appeal.contactDetails.townOrCity && <>{appeal.contactDetails.townOrCity}<br /></>}
                                            {appeal.contactDetails.county && <>{appeal.contactDetails.county}<br /></>}
                                            {appeal.contactDetails.postCode && <>{appeal.contactDetails.postCode}<br /></>}
                                            {appeal.contactDetails.country && <>{appeal.contactDetails.country}<br /></>}
                                        </div>
                                    </div>
                                    <hr className="border-dashed border-bottom-0"></hr>
                                    <div className="row align-items-center">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-0">Attachments</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            {attachments !== null && attachments.map((item) => {
                                                var now = moment.now();
                                                var url = item.url + '&ts=' + moment(now).milliseconds();
                                                return (
                                                    <div className="row align-items-center">
                                                        <div className="col-6">
                                                            <a href={url} target="_blank"><h6 className="mb-0">{item.filename}</h6></a>
                                                        </div>
                                                        <div className="col-6">
                                                            <a href={url} target="_blank">
                                                                {item.isImage && <img src={url} className="attachment-thumb" />}
                                                                {!item.isImage && <>
                                                                    {item.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                                    {item.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                                    {item.contentType !== "application/word.doc" && item.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                                                </>}
                                                            </a>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    {showRespond && 
                                        <>
                                            <hr className="border-dashed border-bottom-0"></hr>
                                            <div className="row align-items-center">
                                                <div className="col-md-3 col-12 ">
                                                    <p className="font-weight-semi-bold mb-0">Appeal Response</p>
                                                </div>
                                                <div className="col-md-6 col-12 ">
                                                    <div className="form-group mb-1">
                                                            <textarea rows={8} className="form-control mb-0" type="text" value={appealResponse} onChange={(e) => setAppealResponse(e.target.value)} />
                                                        </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="row client-edit-buttons">
                                        <div className="col-12 col-sm-auto ml-auto  pl-0">
                                            <div id="dashboard-actions">
                                                {appeal.appealStatus === 0 && <>
                                                    {!showRespond &&
                                                        <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={respond}>
                                                            <i className="fas fa-reply"></i> Respond</button>
                                                    }
                                                    {showRespond && <>
                                                        <button type="button" className="btn btn-danger btn-sm ml-2" disabled={canSaveResponse} onClick={() => decline()}>
                                                            <i className="fas fa-times"></i> Decline</button>
                                                        <button type="button" className="btn btn-success btn-sm ml-2" disabled={canSaveResponse} onClick={() => accept()}>
                                                            <i className="fas fa-check"></i> Accept</button>
                                                        </>
                                                    }
                                                </>}
                                                {appeal.appealStatus !== 0 && <>
                                                    <Link to={backUrl} className="btn btn-falcon-default btn-sm ml-2">
                                                        <i className="fas fa-reply"></i> Back</Link>
                                                </>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            {!isLoading && isNew && <>
                <div className="card my-3">
                    <div className="card-header themed-title">
                        <div className="row align-items-center justify-space-between">
                            <div className="col">
                                <h4 className="mb-0 ">
                                    Create Appeal
                                </h4>
                            </div>
                            <Link className="btn btn-falcon-default btn-sm mr-3 py-1" type="button" to={backUrl}><i className="fas fa-reply"></i> Back</Link>
                        </div>
                    </div>
                    <div className="card-body bg-light border-top">
                        <div className="row">
                            <div className="col-12">
                                <p className="font-weight-bold mb-3">Apellant Details</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-12 ">
                                <p className="font-weight-semi-bold mb-1">Liable Party Designation<span className="req"></span></p>
                            </div>
                            <div className="col-md-6 col-12 ">
                                {contactTypes !== null && contactTypes.map((item, i = 0) => {
                                    i++;
                                    var text = `When the contravention occurred I was the vehicle's ` + item;
                                    var checked = false;
                                    if (appellantType === i - 1) {
                                        checked = true;
                                    }
                                    return (
                                        <div class="form-check mb-2">
                                            <input class="form-check-input my-0" type="radio" name="flexRadioDefault" id={i} checked={checked} onClick={() => setAppellantType(i - 1)} />
                                            <label class="form-check-label ml-3 mb-0" for={i}>
                                                {text}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-12 ">
                                <p className="font-weight-semi-bold mb-1">Title<span className="req"></span></p>
                            </div>
                            <div className="col-md-6 col-12 ">
                                <div className="form-group mb-0">
                                    <div className="dropdown">
                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-title" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{title !== "" && <>{title}</>}{title === "" && <>Select Title</>}</button>
                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-title" >
                                            <div className="bg-white py-2 rounded-soft">
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Mr")}>Mr</button>
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Mrs")}>Mrs</button>
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Miss")}>Miss</button>
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Ms")}>Ms</button>
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Dr")}>Dr</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">First Name<span className="req"></span></p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Last Name<span className="req"></span></p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Comapny Name</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Address Line 1<span className="req"></span></p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={address1} onChange={(e) => setAddress1(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Address Line 2</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={address2} onChange={(e) => setAddress2(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Address Line 3</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={address3} onChange={(e) => setAddress3(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">City<span className="req"></span></p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Postcode<span className="req"></span></p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={postcode} onChange={(e) => setPostcode(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-12 ">
                                <p className="font-weight-semi-bold mb-1">Country</p>
                            </div>
                            <div className="col-md-6 col-12 ">
                                <div className="form-group mb-0">
                                    <div className="dropdown">
                                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-title" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{country !== "" && <>{country}</>}{country === "" && <>Select Country</>}</button>
                                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-title" >
                                            <div className="bg-white py-2 rounded-soft">
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setCountry("United Kingdom")}>United Kingdom</button>
                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setCountry("France")}>France</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Contact Number</p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Email Address<span className="req"></span></p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <input className="form-control mb-0" type="text" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <hr className="border-dashed border-bottom-0"></hr>
                        <div className="row">
                            <div className="col-12">
                                <h4>Your Reason for appealing the Charge Notice</h4>
                                <p className="font-weight-semi-bold mb-3">Please complete the following screen, outlining the reason for your appeal. Please provide as much information as possible that will help our team to
                                    investigate the matter and attach any evidence you may have that supports your case. When supplying attachments we accept photographs in JPEG format and will accept documents in either scanned pdf format
                                    or word documents. We cannot accept zip files.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Appeal Reason<span className="req"></span></p>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group mb-1">
                                    <textarea rows={8} className="form-control mb-0" type="text" value={appealReason} onChange={(e) => setAppealReason(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-12 col-md-3">
                                <p className="font-weight-semi-bold mt-1 mb-1">Supporting Documents<span className="req"></span></p>
                            </div>
                            {files.length !== 0 &&
                                <div className="col-12 col-md-6">
                                    {files.map((file, i = 0) => {
                                        i++;
                                        return (
                                            <div className="row align-items-center">
                                                <div className="col-4">
                                                    <a href={file.url} target="_blank"><h6 className="m">{file.filename}</h6></a>
                                                </div>
                                                <div className="col-5">
                                                    {file.isImage &&
                                                        <div className="d-flex justify-content-center my-2">
                                                            <img src={file.url} className="attachment-thumb" />
                                                        </div>}
                                                    {!file.isImage && <>
                                                        {file.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                        {file.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                        {file.contentType !== "application/word.doc" && file.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                                    </>}
                                                </div>
                                                <div className="col-3">
                                                    <button className="btn btn-sm btn-danger" onClick={() => removeFileUpload(i - 1)}>Remove</button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>}
                            <div className="col-auto">
                                <button className="btn btn-falcon-default my-3" data-toggle="modal" data-target="#add-new-photos">Add Supporting Documents</button>
                            </div>
                        </div>
                        <div className="modal fade save-dialog success-dialog" id="add-new-photos" tabIndex="-1" role="dialog">
                            <UploadFiles onSuccess={(file) => newFileUpload(file)} type="Documents" organisationId={props.organisationId} />
                        </div>
                    </div>
                    <div className="card-footer border-top">
                        <div className="row client-edit-buttons">
                            <div className="col-6 col-sm-auto ml-auto  pl-0">
                                <div id="dashboard-actions">
                                    <Link className="btn btn-falcon-default btn-sm" to={backUrl}><i className="fas fa-times"></i> Cancel</Link>
                                    <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canAppeal} onClick={() => submit()}>
                                        <i className="fas fa-check"></i> Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}
export default Appeal;