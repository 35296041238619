import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Api from '../api/api';
import StringHelpers from '../../utilities/stringHelpers';
import Enum from '../../utilities/enum';

export default function ConditionDialog(props) {

    const [letterTemplateConditionTypes, setLetterTemplateConditionTypes] = useState(null);
    const [condition, setCondition] = useState(props.condition ? props.condition.letterTemplateConditionType : null);
    const [showArgument, setShowArgument] = useState(false);
    const [argument, setArgument] = useState(props.condition ? props.condition.argument : null);
    const [argumentText, setArgumentText] = useState("");
    
    const getAllConditions = function () {
        Api.getEnums(
            (data) => { setLetterTemplateConditionTypes(data.letterTemplateConditionType); },
            (error) => console.log(error),
            props.token
        )
    };

    const cancel = function() {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    const save = function() {
        if (props.onSave) {
            props.onSave(condition, argument);
        }
    };

    useEffect(() => {
        getAllConditions();
    }, []);

    useEffect(() => {
        setShowArgument(false);

        if (letterTemplateConditionTypes !== null) {
            let letterTemplateConditionType = letterTemplateConditionTypes[condition];
            if (!StringHelpers.isNullOrEmpty(letterTemplateConditionType) && letterTemplateConditionType.indexOf("{{0}}") > -1) {
                setShowArgument(true);
                setArgumentText(letterTemplateConditionType.replace("{{0}}", (StringHelpers.isNullOrEmpty(argument) ? "n" : (Number(condition) === 0) ? Enum.BreachStatus[argument] : argument)));
            } else {
                setShowArgument(false);
                setArgument("");
                setArgumentText(letterTemplateConditionTypes[condition]);
            }
        };

    }, [condition, argument, letterTemplateConditionTypes]);

    return <Modal show size="lg">
    <Modal.Header>
      <Modal.Title>Edit Condition</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {letterTemplateConditionTypes !== null && Object.keys(letterTemplateConditionTypes).length > 0 &&
            <div className="row mb-3">
                <label class="col-sm-3 col-form-label col-form-label-sm">Condition</label>  
                <div className="col-sm-9">
                    <div id="select-status">
                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 edit-dropdown ws-normal" id="select-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{condition !== null && <>{argumentText}</>}{condition === null && <>Select Condition</>}</button>
                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-status">
                            <div className="bg-white py-3 rounded-soft tall-dropdown">                      
                                {Object.keys(letterTemplateConditionTypes).map((item, i) =>                                
                                    <button className="btn-sm dropdown-item text-base px-3 py-2" onClick={() => setCondition(item)}>{letterTemplateConditionTypes[item]}</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        {showArgument &&
            <div className="row mb-3">
                <label class="col-sm-3 col-form-label col-form-label-sm">Argument</label>  
                <div className="col-sm-9">
                    {Number(condition) === 0 &&
                      <div id="select-status">
                        <button className="btn btn-falcon-default dropdown-toggle btn-sm mr-1 edit-dropdown ws-normal" id="select-status" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{!StringHelpers.isNullOrEmpty(argument) && <>{Enum.BreachStatus[argument]}</>}{StringHelpers.isNullOrEmpty(argument) && <>Select Status</>}</button>
                        <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-status">
                            <div className="bg-white py-3 rounded-soft tall-dropdown">                      
                                {Object.keys(Enum.BreachStatus).map((item, i) =>                                
                                    <button className="btn-sm dropdown-item text-base px-3 py-2" onClick={() => setArgument(item)}>{Enum.BreachStatus[item]}</button>
                                )}
                            </div>
                        </div>
                    </div>
                    }
                    {Number(condition) !== 0 &&
                      <input className="form-control form-control-sm" type="text" value={argument} onChange={(e) => setArgument(e.target.value)} />
                    }
                </div>
            </div>
        }
    </Modal.Body>
    <Modal.Footer>
        <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={cancel}>Cancel</button>
        <button type="button" className="btn btn-success btn-sm ml-2" data-dismiss="modal" onClick={save}>Save</button>
    </Modal.Footer>
  </Modal>
    
   
}