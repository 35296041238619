import React, { useState, useEffect, useRef } from 'react';
import Api from '../api/api';
import StringHelpers from '../../utilities/stringHelpers';
import Loader from '../shared/loader';
import moment from 'moment';

export default function FlowbirdPayments(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [flowbirdPayments, setFlowbirdPayments] = useState(null);    
    const [similarityThreshold, setSimilarityThreshold] = useState(65);
    const [dayRange, setDayRange] = useState(1);
    
    const getFlowbirdPayments = function() {
        setIsLoading(true);

        let breachDate = new Date(props.breachDate);
        let toDate = new Date(Date.UTC(breachDate.getFullYear(), breachDate.getMonth(), breachDate.getDate() + 1, 23, 59, 59));
        let fromDate = new Date(Date.UTC(breachDate.getFullYear(), breachDate.getMonth(), breachDate.getDate() - dayRange, 0, 0, 0));

        if (!StringHelpers.isNullOrEmpty(props.vrm)) {
            Api.searchFlowbird(
                (data) => {
                    setFlowbirdPayments(data);
                    setIsLoading(false)
                },
                (error) => console.log(error),
                props.vrm,
                similarityThreshold / 100,
                fromDate,
                toDate,
                props.siteId,
                props.organisationId,
                props.token
            )
        }
    };

    const changeSimilarityThresholdTimeout = useRef();
    const changeSimilarityThreshold = function(x) {
        
        setSimilarityThreshold(Number(x));

        if (changeSimilarityThresholdTimeout.current) {
            clearTimeout(changeSimilarityThresholdTimeout.current);
            changeSimilarityThresholdTimeout.current = null;
        }

        changeSimilarityThresholdTimeout.current = setTimeout(() => {
            getFlowbirdPayments();
        }, 1000);
    };

    

    const changeDayRangeTimeout = useRef();
    const changeDayRange = function(x) {
        
        setDayRange(Number(x));

        if (changeDayRangeTimeout.current) {
            clearTimeout(changeDayRangeTimeout.current);
            changeDayRangeTimeout.current = null;
        }

        changeDayRangeTimeout.current = setTimeout(() => {
            getFlowbirdPayments();
        }, 1000);
    };


    useEffect(() => {
        getFlowbirdPayments();
    }, []);

    return <div className="card mb-3">
        <div className="card-header">
            <div className="row align-items-center">
                <div className="col">
                    <div className="d-flex justify-content-between">
                        <h5 className="mb-0">
                            <button className="button-no-style button-card-header collapsed" id="flowbird-payments" type="button" data-toggle="collapse" data-target="#flowbird-payments-list" aria-expanded="true" aria-controls="flowbird-payments-list">
                                Flowbird Payments&nbsp;
                                {isLoading &&
                                    <Loader />
                                }
                                {!isLoading &&
                                    <>
                                        {flowbirdPayments !== null && 
                                            <span className={"translate-middle badge rounded-pill " + (flowbirdPayments.totalCount > 0 ? "bg-success" : "bg-danger")}>
                                                <>{flowbirdPayments.totalCount}</>
                                            </span>
                                        }
                                        &nbsp;<i className="fas fa-chevron-down"></i>
                                    </>
                                }
                            </button>
                        </h5>
                        
                        <div className='d-flex justify-content-between'>
                        <div className='d-flex align-items-center mr-3'>
                            <label className='m-0 mr-3'>Similarity: {similarityThreshold}%</label>
                            <input class="form-range" type="range" min="0" max="100" value={similarityThreshold} onChange={(e) => changeSimilarityThreshold(e.target.value) } />
                        </div>
                        
                        <div className='d-flex align-items-center'>
                            <label className='m-0 mr-3'>Previous {dayRange} day{dayRange > 1 && <>s</>}</label>
                            <input class="form-range" type="range" min="1" max="14" value={dayRange} onChange={(e) => changeDayRange(e.target.value) } />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="card-body bg-light border-top details-container p-0 collapse" id="flowbird-payments-list" aria-labelledby="Allow List" data-parent="#flowbird-payments">
            <table className="table table-sm mb-0 table-striped table-dashboard fs--1 data-table border-bottom border-200 no-footer ">
                <thead className="bg-200 text-900">
                    <tr>
                        <th>VRM</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <td>Paid Duration</td>
                        <th>Site</th>
                        <th className='text-right'>Amount Paid</th>
                    </tr>
                </thead>
                <tbody>
                    {flowbirdPayments !== null && flowbirdPayments.items.map((item) => {
                        return (
                            <tr>
                                <td><h6 className="mb-0 small-number-plate">{item.vrm}</h6></td>
                                <td>{moment(item.startDateLocal).format('DD MMMM YYYY, HH:mm:ss')}</td>
                                <td>{moment(item.endDateLocal).format('DD MMMM YYYY, HH:mm:ss')}</td>
                                <td>{item.payIntervalUnits} minutes ({item.payIntervalUnits / 60} hours)</td>
                                <td>{item.siteName}</td>
                                <td className="text-right">£{item.amount.toFixed(2)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    </div>;
}