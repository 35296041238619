import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import { Stage, Layer, Ellipse, Rect, Transformer } from 'react-konva';
import { Modal } from 'react-bootstrap';

export default function RedactionPane(props) {

    const [modalVisible, setModalVisible] = useState(false);
    const [rectangles, setRectangles] = useState([]);
    const [ovals, setOvals] = useState([]);
    const [selectedId, selectShape] = useState(null);

    const checkDeselect = (e) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            selectShape(null);
        }
    };

    const addNewRect = function () {
        var id = 'rect' + rectangles.length;
        const newRect = {
            x: 250,
            y: 200,
            width: 100,
            height: 100,
            fill: 'black',
            id: id,
        };

        var tempArray = [];
        rectangles.forEach(item => {
            tempArray.push(item);
        });
        tempArray.push(newRect)
        setRectangles(tempArray);

        selectShape(id);
    }

    const addNewOval = function () {
        var id = 'oval' + ovals.length;
        const newOval = {
            x: 250,
            y: 200,
            width: 100,
            height: 100,
            fill: 'black',
            id: id,
        };

        var tempArray = [];
        ovals.forEach(item => {
            tempArray.push(item);
        });
        tempArray.push(newOval)
        setOvals(tempArray)

        selectShape(id);
    }

    const deleteItem = function () {
        var tempRects = [];
        rectangles.forEach(item => {
            if (selectedId !== item.id) {
                tempRects.push(item);
            }
        });
        setRectangles(tempRects)
        var tempOvals = [];

        ovals.forEach(item => {
            if (selectedId !== item.id) {
                tempOvals.push(item);
            }
        });
        setOvals(tempOvals)

    }

    const confirm = function () {
        var redactions = [];

        rectangles.forEach(item => {
            var redaction = new Object();
            redaction.redactionShape = 0;
            redaction.x = parseInt(item.x);
            redaction.y = parseInt(item.y);
            redaction.width = parseInt(item.width);
            redaction.height = parseInt(item.height);
            console.log(item)

            redactions.push(redaction);
        });

        ovals.forEach(item => {
            var redaction = new Object();
            redaction.redactionShape = 1;
            redaction.x = parseInt(item.x) - item.width / 2;
            redaction.y = parseInt(item.y) - item.height / 2;
            redaction.width = parseInt(item.width);
            redaction.height = parseInt(item.height);

            redactions.push(redaction);
        });

        if (redactions !== []) {
            if (props.isAttachment) {
                Api.redactAttachment(
                    () => {
                        setRectangles([]);
                        setOvals([]);
                        props.onChange();
                    },
                    (error) => console.log(error),
                    500, 400,
                    redactions,
                    props.organisationId,
                    props.id,
                    props.token
                )
            } else {
                Api.redact(
                    () => {
                        setRectangles([]);
                        setOvals([]);
                        props.onChange();
                    },
                    (error) => console.log(error),
                    500, 400,
                    redactions,
                    props.organisationId,
                    props.id,
                    props.token
                )
            }
        }

        setModalVisible(false);
    }
    return (
        <>
            <button className="button-no-style text-center" onClick={() => setModalVisible(true)}>
                <img src={props.url} className="attachment-thumb" />
            </button>
            <Modal show={modalVisible} onHide={() => setModalVisible(false)} className="redaction-pane">
               
                    <div className="redaction-pane-image">
                        <img src={props.url} />
                    </div>

                    <Stage
                        width={500}
                        height={400}
                        onMouseDown={checkDeselect}
                        onTouchStart={checkDeselect}
                    >
                        <Layer>
                            {rectangles.length > 0 && rectangles.map((rect, i) => {
                                return (
                                    <Rectangle
                                        key={i}
                                        shapeProps={rect}
                                        isSelected={rect.id === selectedId}
                                        onSelect={() => {
                                            selectShape(rect.id);
                                        }}
                                        onChange={(newAttrs) => {
                                            const rects = rectangles.slice();
                                            rects[i] = newAttrs;
                                            setRectangles(rects);
                                        }}
                                    />
                                );
                            })}
                            {ovals.length > 0 && ovals.map((oval, j) => {
                                return (
                                    <Oval
                                        key={j}
                                        shapeProps={oval}
                                        isSelected={oval.id === selectedId}
                                        onSelect={() => {
                                            selectShape(oval.id);
                                        }}
                                        onChange={(newAttrs) => {
                                            const oval = ovals.slice();
                                            oval[j] = newAttrs;
                                            setOvals(oval);
                                        }}
                                    />
                                );
                            })}
                        </Layer>
                    </Stage>
                    <div className="redaction-pane-buttons">
                        <div className="row">
                            <button className="btn btn-primary btn-sm" onClick={() => addNewRect()}>Add Box <i className="fas fa-plus"></i></button>
                            <button className="btn btn-primary btn-sm" onClick={() => addNewOval()}>Add Oval <i className="fas fa-plus"></i></button>
                        </div>
                        <div className="row">
                            <button className="btn btn-danger btn-sm" onClick={() => deleteItem()}>Delete Selected <i className="fas fa-times"></i></button>
                            <button className="btn btn-success btn-sm" onClick={() => confirm()}>Confirm <i className="fas fa-check"></i></button>
                        </div>
                    </div>
            </Modal>
        </>
    )
}

const Rectangle = ({ shapeProps, isSelected, onSelect, onChange }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Rect
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                }}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        // set minimal value
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    rotateEnabled={false}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};

const Oval = ({ shapeProps, isSelected, onSelect, onChange }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Ellipse
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x(),
                        y: e.target.y(),
                    });
                }}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    onChange({
                        ...shapeProps,
                        x: node.x(),
                        y: node.y(),
                        // set minimal value
                        width: Math.max(5, node.width() * scaleX),
                        height: Math.max(node.height() * scaleY),
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    rotateEnabled={false}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};
