import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Api from '../api/api';
import Enums from '../../utilities/enum';
import StringHelpers from '../../utilities/stringHelpers';
import UploadFile from '../shared/file-uploader';
import Loader from '../shared/loader';

function ContactDetails(props) {
    const [contactDetailsId, setContactDetailsId] = useState(props.match.params.detailsId);
    const [breachId, setBreachId] = useState(props.match.params.breachId);
    const [contactDetails, setContactDetails] = useState(null);
    const [contactDetailsList, setContactDetailsList] = useState(null);
    const [breach, setBreach] = useState(null);
    const [returnUrl, setReturnUrl] = useState("");

    const [isNew, setIsNew] = useState(false);
    const [isInEdit, setIsInEdit] = useState(false);
    const [canSave, setCanSave] = useState(false);

    const [title, setTitle] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [address3, setAddress3] = useState("");
    const [city, setCity] = useState("");
    const [postcode, setPostcode] = useState("");
    const [country, setCountry] = useState("United Kingdom");
    const [contactNumber, setContactNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [evidenceFile, setEvidenceFile] = useState(null);
    const [isActive, setIsActive] = useState(false);

    const [contactTypes, setContactTypes] = useState(null);
    const [contactType, setContactType] = useState(null);
    const [contactSources, setContactSources] = useState(null);
    const [contactSource, setContactSource] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const history = useHistory();

    const getBreach = function () {
        Api.getBreach(
            (data) => setBreach(data),
            (error) => console.log(error),
            breachId,
            props.organisationId,
            props.token
        )
    };

    const setTypes = function (types) {
        var tempTypes = [];
        var i = 0;
        while (true) {
            if (types[i] === undefined) {
                break;
            } else {
                tempTypes.push(types[i])
                i++;
            }
        }
        setContactTypes(tempTypes);
    };

    const setSources = function (types) {
        var tempSources = [];
        var i = 0;
        while (true) {
            if (types[i] === undefined) {
                break;
            } else {
                tempSources.push(types[i])
                i++;
            }
        }
        setContactSources(tempSources);
    };

    const edit = function () {
        const attachmentId = evidenceFile === null ? null : evidenceFile.id;
        if (canSave) {
            Api.editContactDetails(
                (data) => {
                    setIsInEdit(false);
                    setContactDetails(data);
                    setErrorMessage("");
                },
                (error) => {
                    setErrorMessage(error);
                    getContactDetails();
                },
                firstName, lastName, title, companyName, emailAddress, contactNumber, contactType, contactSource, address1, address2, address3, city, postcode, country, isActive, attachmentId, contactDetailsId, breachId, props.organisationId, props.token
            )
        }
    };

    const submit = function () {
        const attachmentId = evidenceFile === null ? null : evidenceFile.id;
        if (canSave) {
            Api.addContactDetails(
                (data) => {
                    setIsNew(false);
                    setContactDetails(data);
                    setErrorMessage("");
                },
                (error) => setErrorMessage(error),
                firstName, lastName, title, companyName, emailAddress, contactNumber, contactType, contactSource, address1, address2, address3, city, postcode, country, isActive, attachmentId, breachId, props.organisationId, props.token
            )
        }
    };

    const getContactDetails = function () {
        Api.getContactDetails(
            (data) => setContactDetailsList(data),
            (error) => setErrorMessage(error),
            breachId, props.organisationId, props.token
        )
    };

    useEffect(() => {
        if (contactDetailsList !== null) {
            contactDetailsList.forEach(item => {
                if (item.id === contactDetailsId) {
                    setContactDetails(item);
                }
            });
        }
    }, [contactDetailsList]);

    useEffect(() => {
        if (contactDetails !== null) {
            setFirstName(contactDetails.firstName);
            setLastName(contactDetails.lastName);
            setAddress1(contactDetails.addressLine1);
            setAddress2(contactDetails.addressLine2);
            setAddress3(contactDetails.addressLine3);
            setCity(contactDetails.townOrCity);
            setPostcode(contactDetails.postCode);
            setCountry(contactDetails.country);
            setContactNumber(contactDetails.mobilePhoneNumber);
            setEmailAddress(contactDetails.emailAddress);
            setContactType(contactDetails.contactType);
            setContactSource(contactDetails.contactSource);
            setEvidenceFile(contactDetails.attachment);
            setTitle(contactDetails.title);
            setCompanyName(contactDetails.companyName);
        }
        setErrorMessage("");
    }, [contactDetails, isInEdit]);

    useEffect(() => {
        setCanSave(!StringHelpers.isNullOrEmpty(firstName) && !StringHelpers.isNullOrEmpty(lastName) && !StringHelpers.isNullOrEmpty(address1) && !StringHelpers.isNullOrEmpty(city) && !StringHelpers.isNullOrEmpty(postcode) && !StringHelpers.isNullOrEmpty(emailAddress) && contactType !== null && contactSource !== null);
    }, [firstName, lastName, address1, city, postcode, emailAddress, contactSource, contactType]);

    useEffect(() => {
        if (breachId !== null) {
            getBreach();
            if (contactDetailsId !== undefined) {
                getContactDetails();
            } else {
                setIsNew(true);
            }
            setReturnUrl("/organisation/" + props.organisationId + "/breach/" + breachId);
        }
        setTypes(Enums.ContactTypes);
        setSources(Enums.ContactSource);

    }, []);

    return (<>
        {(breach == null || contactDetails == null) && !isNew && <Loader />}
        {breach !== null && contactDetails !== null && <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-header ">
                                <div className="row align-items-center justify-content-between ">
                                    <div className="col d-flex align-items-center pr-0">
                                        <h5 className="">Contact - {contactDetails.firstName} {contactDetails.lastName}</h5>
                                    </div>
                                    <div className="col text-right pl-0 filter-buttons-wrapper">
                                        <Link to={returnUrl} className="btn btn-falcon-default btn-sm mr-2" ><i className="fas fa-reply"></i> Back</Link>
                                        <button className="btn btn-falcon-default btn-sm dropdown-toggle py-2 dropdown-caret-none" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fas fa-ellipsis-h"></i></button>
                                        <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-start">
                                            <button type="button" className="dropdown-item text-danger" data-toggle="modal" data-target="#redactModal">
                                                <div className="icon-container"><i className="fas fa-eye-slash"></i></div> Redact Contact</button>
                                            <button type="button" className="dropdown-item text-danger" data-toggle="modal" data-target="#deleteModal">
                                                <div className="icon-container"><i className="fas fa-times"></i></div> Delete Contact</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h5 className="mb-0">Details</h5>
                                    </div>
                                    <div className="col-auto">
                                        <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setIsInEdit(!isInEdit)}>
                                            <i className="fas fa-edit"></i> Edit</button>
                                    </div>
                                </div>
                            </div>
                            {!isInEdit &&
                                <div className="card-body bg-light border-top details-container">
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Title</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{contactDetails.title}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">First Name</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{contactDetails.firstName}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Last Name</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{contactDetails.lastName}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Company Name</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{contactDetails.companyName}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Email Address</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{contactDetails.emailAddress}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Contact Number</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{contactDetails.mobilePhoneNumber}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Contact Type</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            {Enums.ContactTypes[contactDetails.contactType]}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Contact Source</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            {Enums.ContactSource[contactDetails.contactSource]}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <p className="font-weight-semi-bold mb-3">Address</p>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            {contactDetails.addressLine1 && <>{contactDetails.addressLine1}<br /></>}
                                            {contactDetails.addressLine2 && <>{contactDetails.addressLine2}<br /></>}
                                            {contactDetails.addressLine3 && <>{contactDetails.addressLine3}<br /></>}
                                            {contactDetails.townOrCity && <>{contactDetails.townOrCity}<br /></>}
                                            {contactDetails.county && <>{contactDetails.county}<br /></>}
                                            {contactDetails.postCode && <>{contactDetails.postCode}<br /></>}
                                            {contactDetails.country && <>{contactDetails.country}<br /></>}
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold  mb-1">Status</p>
                                        </div>
                                        <div className="col-md-6 col-12  ">{contactDetails.isActive && <i className="fas fa-check text-success"></i>}{!contactDetails.isActive && <i className="fas fa-times text-danger"></i>}</div>
                                    </div>
                                    <div className="row mt-3 align-items-center">
                                        <div className="col-12 col-md-3">
                                            <p className="font-weight-semi-bold mb-0">Attachment</p>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            {contactDetails.attachment &&
                                                <div className="row align-items-center">
                                                    <div className="col-6">
                                                        <a href={contactDetails.attachment.url} target="_blank"><h6 className="mb-0">{contactDetails.attachment.filename}</h6></a>
                                                    </div>
                                                    <div className="col-6">
                                                        {contactDetails.attachment.isImage && <img src={contactDetails.attachment.url} className="attachment-thumb" />}
                                                        {!contactDetails.attachment.isImage && <>
                                                            {contactDetails.attachment.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                            {contactDetails.attachment.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                            {contactDetails.attachment.contentType !== "application/word.doc" && contactDetails.attachment.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                                        </>}
                                                    </div>
                                                </div>}
                                            {!contactDetails.attachment &&
                                                <>No Attachment</>
                                            }
                                        </div>
                                    </div>
                                </div>}
                            {isInEdit && <>
                                <div className="card-body bg-light border-top details-container">
                                    {errorMessage !== "" && <div className="row">
                                        <div className="offset-md-3 col-md-6">
                                            <p className="error-message">
                                                {errorMessage}
                                            </p>
                                        </div>
                                    </div>}
                                <div className="row mb-2">
                                    <label class="col-sm-2 col-form-label col-form-label-sm">Title</label>                                    
                                    <div className="col-sm-10"> 
                                        <select class="custom-select custom-select-sm" onChange={(e) => setTitle(e.target.value)}>
                                            <option value="Mr" selected={title === "Mr"}>Mr</option>
                                        </select>
                                    </div>
                                </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Title</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            <div className="form-group mb-0">
                                                <div className="dropdown">
                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-title" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{title !== "" && <>{title}</>}{title === "" && <>Select Title</>}</button>
                                                    <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-title" >
                                                        <div className="bg-white py-2 rounded-soft">
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Mr")}>Mr</button>
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Mrs")}>Mrs</button>
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Miss")}>Miss</button>
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Ms")}>Ms</button>
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Dr")}>Dr</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <p className="font-weight-semi-bold mt-1 mb-1">First Name<span className="req"></span></p>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Last Name<span className="req"></span></p>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Company Name</p>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Email Address<span className="req"></span></p>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Contact Number</p>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Contact Type<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            <div className="form-group mb-0">
                                                <div className="dropdown">
                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-desig" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{contactType !== null && <>{Enums.ContactTypes[contactType]}</>}{contactType === null && <>Select Contact Type</>}</button>
                                                    <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-desig" >
                                                        <div className="bg-white py-2 rounded-soft">
                                                            {contactTypes !== null && contactTypes.map((type, i = 0) => {
                                                                i++;
                                                                return (
                                                                    <button className="dropdown-item text-base px-3 py-2" onClick={() => setContactType(i - 1)}>The Contact was/is the {type} of the vehicle</button>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Contact Source<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            <div className="form-group mb-0">
                                                <div className="dropdown">
                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-source" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{contactSource !== null && <>{Enums.ContactSource[contactSource]}</>}{contactSource === null && <>Select Contact Source</>}</button>
                                                    <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-source" >
                                                        <div className="bg-white py-2 rounded-soft">
                                                            {contactSources !== null && contactSources.map((i = 0) => {
                                                                i++;
                                                                return (
                                                                    <button className="dropdown-item text-base px-3 py-2" onClick={() => setContactSource(i - 1)}>{Enums.ContactSource[i - 1]}</button>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Address Line 1<span className="req"></span></p>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={address1} onChange={(e) => setAddress1(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Address Line 2</p>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={address2} onChange={(e) => setAddress2(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Address Line 3</p>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={address3} onChange={(e) => setAddress3(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <p className="font-weight-semi-bold mt-1 mb-1">City<span className="req"></span></p>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-3">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Postcode<span className="req"></span></p>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={postcode} onChange={(e) => setPostcode(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Country<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12 ">
                                            <div className="form-group mb-0">
                                                <div className="dropdown">
                                                    <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-title" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{country !== "" && <>{country}</>}{country === "" && <>Select Country</>}</button>
                                                    <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-title" >
                                                        <div className="bg-white py-2 rounded-soft">
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setCountry("United Kingdom")}>United Kingdom</button>
                                                            <button className="dropdown-item text-base px-3 py-2" onClick={() => setCountry("France")}>France</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-3 col-12 ">
                                            <p className="font-weight-semi-bold mb-1">Status</p>
                                        </div>
                                        <div className="col-md-6 col-12 custom-control custom-switch">
                                            <input className="custom-control-input" type="checkbox" id="switch1" checked={isActive} onChange={() => setIsActive(!isActive)} />
                                            <label className="custom-control-label ml-3" htmlFor="switch1"></label>
                                            {isActive && <>Active</>}{!isActive && <>Deactivated</>}
                                        </div>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-3">
                                            <p className="font-weight-semi-bold mt-1 mb-1">Attachment</p>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            {evidenceFile !== null &&
                                                <div className="row align-items-center">
                                                    <div className="col-4">
                                                        <a href={evidenceFile.url} target="_blank"><h6 className="m">{evidenceFile.filename}</h6></a>
                                                    </div>
                                                    <div className="col-5">
                                                        {evidenceFile.isImage &&
                                                            <div className="d-flex justify-content-center my-2">
                                                                <img src={evidenceFile.url} className="attachment-thumb" />
                                                            </div>}
                                                        {!evidenceFile.isImage && <>
                                                            {evidenceFile.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                            {evidenceFile.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                            {evidenceFile.contentType !== "application/word.doc" && evidenceFile.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                                        </>}
                                                    </div>
                                                    <div className="col-3">
                                                        <button className="btn btn-sm btn-danger" onClick={() => setEvidenceFile(null)}>Remove</button>
                                                    </div>
                                                </div>}
                                            <div className="row">
                                                <div className="col-auto">
                                                    {evidenceFile === null &&
                                                        <button className="btn btn-falcon-default my-3" data-toggle="modal" data-target="#add-new-evidence">Add Attachment</button>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade save-dialog success-dialog" id="add-new-evidence" tabIndex="-1" role="dialog">
                                        <UploadFile onSuccess={(file) => setEvidenceFile(file)} type="Attachments" organisationId={props.organisationId} />
                                    </div>
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="row client-edit-buttons">
                                        <div className="col-12 col-sm-auto ml-auto  pl-0">
                                            <div id="dashboard-actions">
                                                <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setIsInEdit(false)}>
                                                    <i className="fas fa-times"></i> Cancel</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => edit()}>
                                                    <i className="fas fa-check"></i> Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteModalLabel">Are you sure you want to delete this Contact?</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger btn-sm ml-2" data-dismiss="modal"
                                onClick={() => Api.deleteContactDetails(() => history.push(returnUrl), contactDetailsId, breachId, props.organisationId, props.token)}
                            >
                                Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="redactModal" tabIndex="-1" role="dialog" aria-labelledby="readactModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="redactModalLabel">Are you sure you want to redact the details in this Contact?</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger btn-sm ml-2" data-dismiss="modal"
                                onClick={() => Api.redactContactDetails(() => getContactDetails(), contactDetailsId, breachId, props.organisationId, props.token)}
                            >
                                Redact</button>
                        </div>
                    </div>
                </div>
            </div>
        </>}
        {isNew && <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-header ">
                                <div className="row align-items-center justify-content-between ">
                                    <div className="col d-flex align-items-center pr-0">
                                        <h5 className="">Add New Contact</h5>
                                    </div>
                                    <div className="col text-right pl-0 filter-buttons-wrapper">
                                        <Link to={returnUrl} className="btn btn-falcon-default btn-sm mr-2" ><i className="fas fa-reply"></i> Back</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body bg-light border-top details-container">
                                {errorMessage !== "" && <div className="row">
                                    <div className="offset-md-3 col-md-6">
                                        <p className="error-message">
                                            {errorMessage}
                                        </p>
                                    </div>
                                </div>}
                                <div className="row">
                                    <div className="col-md-3 col-12 ">
                                        <p className="font-weight-semi-bold mb-1">Title</p>
                                    </div>
                                    <div className="col-md-6 col-12 ">
                                        <div className="form-group mb-0">
                                            <div className="dropdown">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-title" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{title !== "" && <>{title}</>}{title === "" && <>Select Title</>}</button>
                                                <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-title" >
                                                    <div className="bg-white py-2 rounded-soft">
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Mr")}>Mr</button>
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Mrs")}>Mrs</button>
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Miss")}>Miss</button>
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Ms")}>Ms</button>
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setTitle("Dr")}>Dr</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <p className="font-weight-semi-bold mt-1 mb-1">First Name<span className="req"></span></p>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group mb-1">
                                            <input className="form-control mb-0" type="text" autoFocus={true} value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Last Name<span className="req"></span></p>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group mb-1">
                                            <input className="form-control mb-0" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Company Name</p>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group mb-1">
                                            <input className="form-control mb-0" type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Email Address<span className="req"></span></p>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group mb-1">
                                            <input className="form-control mb-0" type="text" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Contact Number</p>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group mb-1">
                                            <input className="form-control mb-0" type="text" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-12 ">
                                        <p className="font-weight-semi-bold mb-1">Contact Type<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-6 col-12 ">
                                        <div className="form-group mb-0">
                                            <div className="dropdown">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-desig" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{contactType !== null && <>{Enums.ContactTypes[contactType]}</>}{contactType === null && <>Select Contact Type</>}</button>
                                                <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-desig" >
                                                    <div className="bg-white py-2 rounded-soft">
                                                        {contactTypes !== null && contactTypes.map((type, i = 0) => {
                                                            i++;
                                                            return (
                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setContactType(i - 1)}>The Contact was/is the {type} of the vehicle</button>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-12 ">
                                        <p className="font-weight-semi-bold mb-1">Contact Source<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-6 col-12 ">
                                        <div className="form-group mb-0">
                                            <div className="dropdown">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-source" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{contactSource !== null && <>{Enums.ContactSource[contactSource]}</>}{contactSource === null && <>Select Contact Source</>}</button>
                                                <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-source" >
                                                    <div className="bg-white py-2 rounded-soft">
                                                        {contactSources !== null && contactSources.map((type, i = 0) => {
                                                            i++;
                                                            return (
                                                                <button className="dropdown-item text-base px-3 py-2" onClick={() => setContactSource(i - 1)}>{Enums.ContactSource[i - 1]}</button>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Address Line 1<span className="req"></span></p>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group mb-1">
                                            <input className="form-control mb-0" type="text" value={address1} onChange={(e) => setAddress1(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Address Line 2</p>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group mb-1">
                                            <input className="form-control mb-0" type="text" value={address2} onChange={(e) => setAddress2(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Address Line 3</p>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group mb-1">
                                            <input className="form-control mb-0" type="text" value={address3} onChange={(e) => setAddress3(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <p className="font-weight-semi-bold mt-1 mb-1">City<span className="req"></span></p>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group mb-1">
                                            <input className="form-control mb-0" type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-3">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Postcode<span className="req"></span></p>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group mb-1">
                                            <input className="form-control mb-0" type="text" value={postcode} onChange={(e) => setPostcode(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-12 ">
                                        <p className="font-weight-semi-bold mb-1">Country<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-6 col-12 ">
                                        <div className="form-group mb-0">
                                            <div className="dropdown">
                                                <button className="btn btn-falcon-default dropdown-toggle btn-sm mb-1 edit-dropdown" id="select-title" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{country !== "" && <>{country}</>}{country === "" && <>Select Country</>}</button>
                                                <div className="dropdown-menu py-0 edit-dropdown" aria-labelledby="select-title" >
                                                    <div className="bg-white py-2 rounded-soft">
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setCountry("United Kingdom")}>United Kingdom</button>
                                                        <button className="dropdown-item text-base px-3 py-2" onClick={() => setCountry("France")}>France</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 col-12 ">
                                        <p className="font-weight-semi-bold mb-1">Status</p>
                                    </div>
                                    <div className="col-md-6 col-12 custom-control custom-switch">
                                        <input className="custom-control-input" type="checkbox" id="switch1" checked={isActive} onChange={() => setIsActive(!isActive)} />
                                        <label className="custom-control-label ml-3" htmlFor="switch1"></label>
                                        {isActive && <>Active</>}{!isActive && <>Deactivated</>}
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-12 col-md-3">
                                        <p className="font-weight-semi-bold mt-1 mb-1">Attachment</p>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        {evidenceFile !== null &&
                                            <div className="row align-items-center">
                                                <div className="col-4">
                                                    <a href={evidenceFile.url} target="_blank"><h6 className="m">{evidenceFile.filename}</h6></a>
                                                </div>
                                                <div className="col-5">
                                                    {evidenceFile.isImage &&
                                                        <div className="d-flex justify-content-center my-2">
                                                            <img src={evidenceFile.url} className="attachment-thumb" />
                                                        </div>}
                                                    {!evidenceFile.isImage && <>
                                                        {evidenceFile.contentType === "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-6x fa-file-pdf"></i></div>}
                                                        {evidenceFile.contentType === "application/word.doc" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-word"></i></div>}
                                                        {evidenceFile.contentType !== "application/word.doc" && evidenceFile.contentType !== "application/pdf" && <div className="d-flex justify-content-center my-2"><i className="far fa-file-alt"></i></div>}
                                                    </>}
                                                </div>
                                                <div className="col-3">
                                                    <button className="btn btn-sm btn-danger" onClick={() => setEvidenceFile(null)}>Remove</button>
                                                </div>
                                            </div>}
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                {evidenceFile === null &&
                                                    <button className="btn btn-falcon-default my-3" data-toggle="modal" data-target="#add-new-evidence">Add Attachment </button>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal fade save-dialog success-dialog" id="add-new-evidence" tabIndex="-1" role="dialog">
                                    <UploadFile onSuccess={(file) => setEvidenceFile(file)} type="Attachments" organisationId={props.organisationId} />
                                </div>
                            </div>
                            <div className="card-footer border-top ">
                                <div className="row client-edit-buttons">
                                    <div className="col-12 col-sm-auto ml-auto  pl-0">
                                        <div id="dashboard-actions">
                                            <Link type="button" className="btn btn-falcon-default btn-sm ml-2" to={returnUrl}>
                                                <i className="fas fa-times"></i> Cancel</Link>
                                            <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => submit()}>
                                                <i className="fas fa-check"></i> Confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>}
    </>)
}
export default ContactDetails;