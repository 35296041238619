import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Api from '../api/api';
import Loader from '../shared/loader';
import { SketchPicker } from 'react-color';

function Tag(props) {
    const [tag, setTag] = useState(null);
    const [tagId, setTagId] = useState(props.match.params.tagId)

    const [isNew, setIsNew] = useState(false);
    const [isInEdit, setIsInEdit] = useState(false);
    const [canSave, setCanSave] = useState(false);

    const [tagName, setTagName] = useState("");
    const [tagColour, setTagColour] = useState("");
    const [tagColourStyle, setTagColourStyle] = useState(null);

    const [color, setColor] = useState('FFFFFF');

    const [tagUrl, setTagUrl] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const history = useHistory();

    const getTag = function () {
        Api.getTag(
            (data) => setTag(data),
            (error) => console.log(error),
            props.organisationId,
            tagId,
            props.token
        )
    };

    const edit = function () {
        if (canSave) {
            Api.editTag(
                (data) => {
                    setIsInEdit(false);
                    setTag(data);
                    setErrorMessage("");
                },
                (error) => setErrorMessage(error),
                tagName,
                color,
                tag.id,
                props.organisationId,
                props.token
            );
        }
    };

    const submit = function () {
        if (canSave) {
            Api.addTag(
                (data) => {
                    setTag(data);
                    setIsNew(false);
                    setErrorMessage("");
                    history.push("tag/" + data.id);
                },
                (error) => setErrorMessage(error),
                tagName,
                color,
                props.organisationId,
                props.token
            )
        }
    };

    const handleColorChange = function (color) {
        setColor(color.hex.substring(1, 7));
    };

    useEffect(() => {
        if (tagId !== undefined) {
            getTag();
        }
        else {
            setIsNew(true);
        }
        setTagUrl("/organisation/" + props.organisationId + "/tag-settings")
    }, []);

    useEffect(() => {
        if (tag !== null) {
            const style = {
                backgroundColor: '#' + tag.colourCode,
            }
            setTagColourStyle(style);
            setTagName(tag.name);
            setColor(tag.colourCode);
        }
        setErrorMessage("");
    }, [tag, isInEdit]);

    useEffect(() => {
        if (tagName === null || tagName === "" || color === null || color === "") {
            setCanSave(false);
        }
        else {
            setCanSave(true);
        }
    }, [tagName, color]);

    return (
        <>{!tag && !isNew && <Loader />} {tag && tag !== null && !isNew && <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h5 className="mb-0">Tag - {tag.name}</h5>
                                    </div>
                                    <Link className="btn btn-falcon-default btn-sm mr-3 py-1" type="button" to={tagUrl}><i className="fas fa-reply"></i> Back</Link>
                                    <button className="btn btn-falcon-default btn-sm dropdown-toggle mr-3 py-2 dropdown-caret-none" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"><i className="fas fa-ellipsis-h"></i></button>
                                    <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-start">
                                        <button type="button" className="dropdown-item text-danger" data-toggle="modal" data-target="#deleteModal">
                                            <i className="fas fa-times"></i> Delete Tag</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h5 className="mb-0">Details</h5>
                                    </div>
                                    <div className="col-auto">
                                        <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => setIsInEdit(!isInEdit)}>
                                            <i className="fas fa-edit"></i> Edit</button>
                                    </div>
                                </div>
                            </div>
                            {!isInEdit && <>
                                <div className="card-body bg-light border-top details-container">
                                    <div className="row">
                                        <div className="col-md-2 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Name</p>
                                        </div>
                                        <div className="col-md-6 col-12 ">{tag.name}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 col-12 ">
                                            <p className="font-weight-semi-bold  mb-3">Colour Code</p>
                                        </div>
                                        <div className="col-md-6 col-12 color-code-row"><div className="color-code-row">{tag.colourCode}<div className="tag-colour-disc" style={tagColourStyle}></div></div></div>
                                    </div>
                                </div>
                            </>}
                            {isInEdit && <>
                                <div className="card-body bg-light border-top details-container">
                                {errorMessage !== "" && <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>}
                                    <div className="row">
                                        <div className="col-md-2 col-12 ">
                                            <p className="font-weight-semi-bold  mb-1">Name<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={tagName} onChange={(e) => setTagName(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 col-12 ">
                                            <p className="font-weight-semi-bold  mb-1">Colour Code<span className="req"></span></p>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="form-group mb-1">
                                                <input className="form-control mb-0" type="text" value={color} onChange={(e) => setColor(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 col-12">
                                        </div>
                                        <div className="col-md-6 col-12 color-picker">
                                            <SketchPicker
                                                color={'#' + color}
                                                onChange={handleColorChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-top ">
                                    <div className="row client-edit-buttons">
                                        <div className="col-12 col-sm-auto ml-auto  pl-0">
                                            <div id="dashboard-actions">
                                                <button type="button" className="btn btn-falcon-default btn-sm ml-2" onClick={() => setIsInEdit(false)}>
                                                    <i className="fas fa-times"></i> Cancel</button>
                                                <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => edit()}>
                                                    <i className="fas fa-check"></i> Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="deleteModal" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteModalLabel">Are you sure you want to delete this tag?</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-falcon-default btn-sm ml-2" data-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-danger btn-sm ml-2" data-dismiss="modal" onClick={() => Api.deleteTag(() => history.push(tagUrl), props.organisationId, tag.id, props.token)}>
                                Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>} {isNew && <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-3">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h5 className="mb-0">Add new Tag</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body bg-light border-top details-container">
                            {errorMessage !== "" && <div className="row">
                                            <div className="offset-md-3 col-md-6">
                                                <p className="error-message">
                                                    {errorMessage}
                                                </p>
                                            </div>
                                        </div>}
                                <div className="row align-items-center">
                                    <div className="col-md-5 col-12 ">
                                        <p className="font-weight-semi-bold mb-1">Name<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-5 col-12">
                                        <div className="form-group mb-0">
                                            <input className="form-control mb-1" type="text" autoFocus={true} value={tagName} onChange={(e) => setTagName(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-md-5 col-12 ">
                                        <p className="font-weight-semi-bold mb-1">Tag Background Colour<span className="req"></span></p>
                                    </div>
                                    <div className="col-md-5 col-12">
                                        <div className="form-group mb-0">
                                            <input className="form-control mb-1" placeholder="e.g. FFFFFF" type="text" value={color} onChange={(e) => setColor(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5 col-12">
                                    </div>
                                    <div className="col-md-5 col-12 color-picker">

                                        <SketchPicker
                                            color={'#' + color}
                                            onChange={handleColorChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer border-top ">
                                <div className="row client-edit-buttons">
                                    <div className="col-12 col-sm-auto ml-auto  pl-0">
                                        <div id="dashboard-actions">
                                            <Link className="btn btn-falcon-default btn-sm" to={tagUrl}><i className="fas fa-times"></i> Cancel</Link>
                                            <button type="button" className="btn btn-success btn-sm ml-2" disabled={!canSave} onClick={() => submit()}>
                                                <i className="fas fa-check"></i> Confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>}
        </>
    )
}
export default Tag;